<template>
  <v-container v-if="menu">
    <v-row justify="center">
      <v-col cols="12" class="pa-0">
        <v-sheet class="main_text--text shadow pa-4">
          <v-row class="mx-0">
            <v-col cols="12" sm="12" md="6">
              <v-text-field
                :label="$t('Common.menu_name')"
                :hide-details="true"
                v-model="menu.name"
                color="takein"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <v-row class="h-100" align="end">
                <v-btn
                  color="takein"
                  small
                  dark
                  :ripple="false"
                  depressed
                  class="mr-2"
                  @click.stop="saveMenu()"
                  >{{ $t('Common.save_close') }}</v-btn
                >
                <v-btn
                  small
                  dark
                  color="grey"
                  :ripple="false"
                  depressed
                  class="mr-2"
                  @click="back()"
                >
                  <v-icon size="16" left>mdi-close</v-icon>
                  {{ $t('Common.cancel') }}
                </v-btn>
                <v-spacer v-if="$vuetify.breakpoint.mdAndUp" />
                <v-btn
                  color="error"
                  small
                  dark
                  :ripple="false"
                  depressed
                  class="mr-2"
                  @click="$refs.deleteConfirmationDialog.openDialog()"
                >
                  <v-icon size="16" left>mdi-delete</v-icon>
                  {{ $t('Common.delete') }}
                </v-btn>
              </v-row>
            </v-col>
          </v-row>
          <v-row align="center" class="mx-3 my-4">
            <v-checkbox
              color="takein"
              :hide-details="true"
              v-model="menu.isActive"
              :label="$t('Common.is_active')"
              dense
              :disabled="isActiveDisabled"
              @change="changeVisibility"
              class="mt-1 mr-4"
            ></v-checkbox>
            <v-checkbox
              color="takein"
              :hide-details="true"
              :label="$t('Common.followers_only')"
              dense
              class="mt-1"
              :disabled="!menu.isActive"
              v-model="menu.followerOnly"
              @change="isPublicOrFollower('follower')"
            ></v-checkbox>
          </v-row>
          <v-row align="center" class="mx-3">
            <span class="underlined main_text--text">{{
              $t('Common.when_menu_available')
            }}</span>
          </v-row>
          <v-row align="center" class="mx-3 mb-4">
            <v-checkbox
              color="takein"
              :hide-details="true"
              v-model="useBusinessHours"
              :label="$t('Common.use_business_hours')"
              dense
              class="mt-1 mr-4"
            ></v-checkbox>
          </v-row>
          <v-row align="center" class="mx-3 my-4" v-if="!useBusinessHours">
            <v-col cols="12" sm="12" md="6" class="pa-0">
              <v-select
                color="takein"
                prepend-icon="calendar_today"
                multiple
                :items="['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']"
                v-model="menu.weekday"
                :label="$t('Common.weekdays')"
                class="mr-4"
              ></v-select>
            </v-col>
            <v-col>
              <v-select
                color="takein"
                :items="startHourList"
                v-model="menu.fromHour"
                @change="menu.endHour = null"
                :label="$t('Common.from')"
              ></v-select>
            </v-col>
            <v-col>
              <v-select
                v-if="menu.fromHour"
                color="takein"
                :items="endHourList"
                v-model="menu.endHour"
                :label="$t('Common.to')"
              ></v-select>
            </v-col>
          </v-row>
          <v-row class="mx-3 my-4">
            <v-spacer />
            <v-col cols="12" md="6" lg="5" xl="4">
              <v-card>
                <vue-perfect-scrollbar
                  style="height:240px"
                  :settings="settings"
                  v-if="isParent"
                >
                  <v-list v-if="menu.children && menu.children.length > 0">
                    <draggable
                      :list="menu.children"
                      style="height:240px"
                      group="submenus"
                      @change="evt => addNewProduct(evt)"
                    >
                      <v-list-item
                        v-for="(submenuId, key) in menu.children"
                        :key="key"
                      >
                        <template>
                          <v-list-item-content>
                            {{ getProductName(submenuId) }}
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-icon
                              color="red"
                              @click="removeMenu(submenuId)"
                              small
                              >clear</v-icon
                            >
                          </v-list-item-action>
                        </template>
                      </v-list-item>
                    </draggable>
                  </v-list>
                  <draggable
                    v-else
                    :list="menu.children"
                    group="products"
                    @change="evt => addNewProduct(evt)"
                    >{{ $t('Common.drag_and_drop_menu') }}</draggable
                  >
                </vue-perfect-scrollbar>

                <vue-perfect-scrollbar
                  style="height:240px"
                  :settings="settings"
                  v-else
                >
                  <v-list v-if="menu.items && menu.items.length > 0">
                    <draggable
                      :list="menu.items"
                      group="products"
                      @change="evt => addNewProduct(evt)"
                    >
                      <v-list-item
                        v-for="(product, key) in productInMenu()"
                        :key="key"
                      >
                        <template v-if="product">
                          <v-list-item-avatar
                            v-if="product.photos && product.photos.length > 0"
                          >
                            <img :src="product.photos[0].imageUrl" />
                          </v-list-item-avatar>

                          <v-list-item-content>
                            {{ product.name }}
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-icon
                              color="red"
                              @click="removeProduct(product.id)"
                              small
                              >clear</v-icon
                            >
                          </v-list-item-action>
                        </template>
                      </v-list-item>
                    </draggable>
                  </v-list>
                  <draggable
                    v-else
                    :list="menu.items"
                    group="products"
                    style="height:240px"
                    @change="evt => addNewProduct(evt)"
                    >{{ $t('Common.drag_and_drop_product') }}</draggable
                  >
                </vue-perfect-scrollbar>
              </v-card>
            </v-col>
            <v-spacer />
          </v-row>
          <v-row class="mx-0">
            <v-col>
              <v-sheet class="main_text--text shadow pa-4">
                <v-row align="center" class="pl-4">
                  <v-icon color="takein" class="subtitle1"
                    >fas fa-hamburger</v-icon
                  >
                  <span v-if="isParent" class="title main_text--text mx-4">
                    {{ $t('Common.sub_menus') }}
                  </span>
                  <span v-else class="title main_text--text mx-4">
                    {{ $t('Common.products') }}
                  </span>
                  <v-text-field
                    color="takein"
                    append-icon="mdi-magnify"
                    :label="$t('Common.search')"
                    v-model="searchText"
                    dense
                    :hide-details="true"
                  ></v-text-field>
                  <v-checkbox
                    v-if="!isParent"
                    color="takein"
                    :hide-details="true"
                    v-model="availableMarket"
                    :label="$t('Common.available_market')"
                    dense
                    class="mt-1 mx-2"
                  ></v-checkbox>
                </v-row>
                <v-divider />
                <v-row justify="center" class="mx-1">
                  <draggable
                    :list="filteredProducts"
                    :group="{ name: 'products', pull: 'clone', put: false }"
                    class="d-flex flew-row overflow-auto"
                    handle=".product-handle"
                  >
                    <div v-for="(id, key) in filteredProducts" :key="key">
                      <v-card class="ma-4" width="200">
                        <v-img
                          v-if="!isParent && getProductImage(id)"
                          class="image-rect"
                          :src="getProductImage(id)"
                        >
                          <div
                            class="w-100 h-100 d-flex flex-column justify-space-between"
                          >
                            <div class="product-handle pa-1">
                              <v-icon class="white--text"
                                >mdi-cursor-move</v-icon
                              >
                            </div>
                            <div class="product-name px-2">
                              <p class="title mb-0 white--text text-center">
                                {{ getProductName(id) | truncate(13) }}
                              </p>
                            </div>
                          </div>
                        </v-img>
                        <div v-else class="image-rect">
                          <div
                            class="w-100 h-100 d-flex flex-column justify-space-between"
                          >
                            <div class="product-handle pa-1">
                              <v-icon class="white--text"
                                >mdi-cursor-move</v-icon
                              >
                            </div>
                            <div class="d-flex justify-center">
                              <v-icon large color="grey">mdi-image-off</v-icon>
                            </div>
                            <div class="product-name px-2">
                              <p class="title mb-0 white--text text-center">
                                {{ getProductName(id) | truncate(13) }}
                              </p>
                            </div>
                          </div>
                        </div>
                      </v-card>
                    </div>
                  </draggable>
                </v-row>
              </v-sheet>
            </v-col>
          </v-row>
        </v-sheet>
      </v-col>

      <delete-confirmation-dialog
        ref="deleteConfirmationDialog"
        heading="Deleting Menu"
        message="Are you sure you want to delete this menu?"
        @onConfirm="onDeleteMenu"
      ></delete-confirmation-dialog>
      <v-snackbar
        v-model="snackbar"
        :bottom="true"
        :color="snackType"
        :timeout="snackTimeout"
      >
        {{ snackMessage }}
        <v-btn color="white" text @click="snackbar = false">Close</v-btn>
      </v-snackbar>
      <v-row>
        <v-snackbar :timeout="6000" color="red" v-model="editSnackbar">
          {{ snackBarContent }}
          <v-btn color="white" text @click="editSnackbar = false">Close</v-btn>
        </v-snackbar>
      </v-row>
    </v-row>
  </v-container>
</template>

<script>
import draggable from 'vuedraggable'
import { mapGetters } from 'vuex'
import moment from 'moment'
export default {
  components: {
    draggable
  },
  data: () => {
    return {
      snackTimeout: 2500,
      snackbar: false,
      snackMessage: '',
      snackType: 'success',
      businessDays: [],
      startHour: null,
      startHourList: null,
      dishList: null,
      settings: {
        maxScrollbarLength: 160
      },
      id: null,
      useBusinessHours: false,
      searchText: '',
      availableMarket: false,
      menu: null,
      subMenus: null,
      snackBarContent: '',
      editSnackbar: false
    }
  },
  computed: {
    ...mapGetters({
      profile: 'profile'
    }),
    productIds() {
      if (this.dishList && this.dishList.length > 0)
        return this.dishList.map(d => d.id)
      else return []
    },
    filteredProducts() {
      let list = []
      if (!this.menu) return list
      if (this.isParent) {
        if (this.subMenus && this.subMenus.length > 0) {
          list = this.subMenus.filter(
            menu =>
              menu.name &&
              menu.name.toLowerCase().includes(this.searchText.toLowerCase()) &&
              menu.id !== this.menu.id
          )
        }
      } else {
        if (this.dishList && this.dishList.length > 0) {
          if (this.availableMarket)
            list = this.dishList.filter(
              dish =>
                dish.name &&
                dish.name
                  .toLowerCase()
                  .includes(this.searchText.toLowerCase()) &&
                dish.visibility === 'public'
            )

          list = this.dishList.filter(
            dish =>
              dish.name &&
              dish.name.toLowerCase().includes(this.searchText.toLowerCase())
          )
        }
      }
      return list.map(d => d.id)
    },
    filteredDishListIds() {
      let list = []
      if (this.menu && this.menu.items) {
        list = this.dishList.filter(p => !this.menu.items.includes(p.id))
      }
      return list.map(d => d.id)
    },
    endHourList() {
      if (this.menu) {
        return this.eachHalfHour(this.convertTime(this.menu.fromHour), '23:30')
      }
    },
    isActiveDisabled() {
      if (!this.menu) return true
      if (this.isParent) {
        return !this.menu.children || this.menu.children.length === 0
      } else {
        return !this.menu.items || this.menu.items.length === 0
      }
    },
    isParent() {
      return this.menu.type && this.menu.type === 'parent'
    }
  },
  watch: {},
  beforeMount() {
    this.startHourList = this.eachHalfHour('00:00', `23:30`)

    if (!this.dishList) {
      this.$store
        .dispatch('loadDishList', { uid: this.profile.id })
        .then(data => {
          this.dishList = data
        })
    }
    this.loadMenu(this.$route.params.id)
    if (this.profile.businessHours && this.profile.businessHours.length > 0) {
      this.useBusinessHours = true
    } else {
      this.useBusinessHours = false
    }
  },
  mounted() {
    console.log(this.$route, 'Kkk')
    this.loadMenu(this.$route.params.id)
  },
  methods: {
    back() {
      this.$router.go(-1)
      // this.$router.push('/profile/wizard/menus/menus_sub/new_menu')
    },
    convertTime(time) {
      if (time) {
        // converts 12 hour to 24 hour
        const [hour, modifier] = time.split(' ')
        let [hours, minutes] = hour.split(':')
        if (hours === '12') {
          hours = '00'
        }
        if (modifier === 'pm') {
          hours = parseInt(hours, 10) + 12
        }
        return `${hours}:${minutes}`
      }
    },
    getProductName(productID) {
      let item
      if (this.isParent) {
        item = this.subMenus.filter(p => p.id === productID)[0]
      } else {
        item = this.dishList.filter(p => p.id === productID)[0]
      }
      return item.name
    },
    getProductImage(productID) {
      const product = this.dishList.filter(p => p.id === productID)[0]
      return product && product.photos && product.photos.length > 0
        ? product.photos[0].url || product.photos[0].imageUrl
        : null
    },
    loadMenu(id) {
      if (!id) return

      this.id = id
      this.$store.dispatch('loadMenus', { uid: this.uid }).then(data => {
        this.subMenus = data.filter(menu => menu.type !== 'parent')
        this.menu = data.filter(menu => menu.id === id)[0]
        if (!this.menu) return
        this.useBusinessHours = this.menu.useBusinessHours || false
      })
    },
    onDeleteMenu() {
      this.$store.dispatch('deleteMenu', this.menu.id).then(() => {
        this.$refs.deleteConfirmationDialog.close()
        this.back()
      })
    },
    saveMenu() {
      if (this.checkScheduleWithinBusinessHour()) {
        this.$store.dispatch('setLoading', true)
        this.menu.useBusinessHours = this.useBusinessHours
        this.$store
          .dispatch('saveMenus', { menu: this.menu, uid: this.uid })
          .then(() => {
            this.$store.dispatch('setLoading', false)
            this.snackMessage = 'Saved successfully.'
            this.snackType = 'success'
            this.snackbar = true
            // this.$store.dispatch('setMessage', { title: 'Saved' })
            this.back()
          })
          .finally(() => {
            this.$store.dispatch('setLoading', false)
          })
      } else {
        this.editSnackbar = true
      }
    },
    changeVisibility() {
      if (this.menu) return
      // turns visibility off if there are no items in menu
      if (this.isParent) {
        if (!this.menu.children || this.menu.children.length == 0) {
          this.menu.isActive = false
        }
      } else {
        if (!this.menu.items || this.menu.items.length == 0) {
          this.menu.isActive = false
        }
      }
      // check if isActive has been toggled
      if (!this.menu.isActive) {
        this.menu.isPublic = false
        this.menu.followerOnly = false
      }
    },
    // menu cannot be both public and follower only
    isPublicOrFollower(change) {
      if (change === 'public' && this.menu.isPublic) {
        this.menu.followerOnly = false
      } else if (change == 'follower' && this.menu.followerOnly) {
        this.menu.isPublic = false
      }
    },
    getSubMenuName(id) {
      const smenu = this.subMenus.filter(sm => sm.id === id)[0]
      if (smenu) return smenu.name
    },
    removeMenu: function(subId) {
      console.log(`Removed ${subId} from  ${this.menu.id}`)
      if (!this.menu) {
        return
      }
      const list = this.menu.children.filter(item => item !== subId)
      this.menu.children = list

      this.changeVisibility()
      this.$store
        .dispatch('saveMenus', { menu: this.menu, uid: this.profile.uid })
        .then(() => {
          this.$store.dispatch('setLoading', false)
          this.snackMessage = 'Saved successfully.'
          this.snackType = 'success'
          this.snackbar = true
          // this.$store.dispatch('setMessage', { title: 'Saved' })
        })
    },
    addNewProduct: function(evt) {
      if (this.isParent) {
        this.menu.children = [...new Set(this.menu.children)]
      } else {
        this.menu.items = [...new Set(this.menu.items)]
      }

      // this.$store.dispatch('saveMenus', {
      //   menu: this.menu,
      //   uid: this.profile.uid
      // })
    },
    productInMenu() {
      let list = []

      if (!this.menu) {
        return
      }
      this.menu.items.forEach(pid => {
        if (pid.length > 0) {
          let product = this.dishList.filter(p => p.id === pid)
          list.push(product[0])
        }
      })

      return list
    },
    removeProduct: function(id) {
      console.log(`Remove ${id} from  ${this.menu.id}`)

      if (!this.menu) {
        return
      }
      const list = this.menu.items.filter(item => item !== id)
      this.menu.items = list
      this.changeVisibility()
      this.$store
        .dispatch('saveMenus', { menu: this.menu, uid: this.profile.uid })
        .then(() => {
          this.$store.dispatch('setLoading', false)
          this.snackMessage = 'Saved successfully.'
          this.snackType = 'success'
          this.snackbar = true
          // this.$store.dispatch('setMessage', { title: 'Saved' })
        })
    },
    checkScheduleWithinBusinessHour() {
      // menu does not have designated time, do not need to check menu schedule
      if (!this.menu.repeat) {
        return true
      }

      if (this.useBusinessHours) {
        return true
      }

      if (!this.menu.fromHour || !this.menu.endHour) {
        this.snackBarContent = 'Missing Schedule End Time!'
        return false
      }

      if (
        !this.profile.businessHours ||
        this.profile.businessHours.length === 0
      ) {
        this.snackBarContent = 'This chef has invalid business hour!'
        return false
      }

      let businessWeekDays = [],
        businessDays = [],
        businessStartHours = [],
        businessEndHours = []

      businessWeekDays = [
        ...this.profile.businessHours.map(d => d.business_days)
      ]
      for (let weekdays of businessWeekDays) {
        businessDays = [...businessDays, ...weekdays]
      }
      businessStartHours = [...this.profile.businessHours.map(d => d.fromHour)]
      businessEndHours = [...this.profile.businessHours.map(d => d.endHour)]
      businessDays = Array.from(new Set(businessDays))
      businessStartHours = Array.from(new Set(businessStartHours)).sort(
        (a, b) => moment(a).unix - moment(b).unix
      )
      businessEndHours = Array.from(new Set(businessEndHours)).sort(
        (a, b) => moment(a).unix - moment(b).unix
      )

      const businessStartTime = businessStartHours[0],
        businessEndTime = businessEndHours[0]

      if (
        moment(businessStartTime, 'h:mma').isAfter(
          moment(this.menu.fromHour, 'h:mma')
        ) ||
        moment(businessEndTime, 'h:mma').isBefore(
          moment(this.menu.endHour, 'h:mma')
        )
      ) {
        this.snackBarContent = 'The schedule times are out of business hour!'
        return false
      }

      for (let i = 0; i < this.menu.weekday.length; i++) {
        if (!businessDays.includes(this.menu.weekday[i])) {
          this.snackBarContent = 'The menu week days are out of business days!'
          return false
        }
      }

      return true
    }
  }
}
</script>
<style scoped>
.shadow {
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
    0px 4px 8px rgba(0, 0, 0, 0.04);
}
.border-box {
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
    0px 4px 8px rgba(0, 0, 0, 0.04);
}
.product-handle {
  background-color: rgba(0, 0, 0, 0.6);
  width: 32px;
  cursor: move;
  border-bottom-right-radius: 5px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2) !important;
}
.product-name {
  background-color: rgba(0, 0, 0, 0.6);
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2) !important;
}
.underlined {
  text-decoration: underline;
}
.image-rect {
  overflow: hidden;
  width: 100%;
  height: 140px;
}
</style>
