import firebase, { analytics } from '@/firebase-init'
import Geohash from 'latlon-geohash'
import moment from 'moment'

export default {
  state: {
    user: null,
    newUser: false,
    userLocation: null,
    lang: 'en'
  },
  mutations: {
    setUser(state, payload) {
      state.user = payload
    },
    setNewUser(state, payload) {
      state.newUser = payload
    },
    setUserlocation(state, payload) {
      state.userLocation = payload
    },
    setLanguage(state, payload) {
      state.lang = payload
    }
  },
  actions: {
    signUserUp({ commit, dispatch }, payload) {
      commit('setLoading', true)
      commit('clearError')

      firebase
        .auth()
        .createUserWithEmailAndPassword(payload.email, payload.password)
        .then(data => {
          let user = data.user
          if (!user) {
            console.error('User not bounded')
            commit('setError', { message: 'Unable to create user!' })
            return
          }
          commit('setLoading', false)

          analytics.logEvent('biz_sign_up', {
            method: 'email',
            isAnonymous: user.isAnonymous,
            emailVerified: user.emailVerified
          })
          const newUser = {
            id: user.uid,
            name: payload.name ? payload.name : user.displayName || user.email,
            phone: payload.phone,
            email: user.email,
            avatar: user.photoURL ? user.photoURL : user.photoUrl,
            newProfile: true,
            showOnboard: payload.showOnboard,
            type: payload.type
          }

          commit('setUser', newUser)
          dispatch('saveProfile', newUser)
          user
            .sendEmailVerification()
            .then(function(emailSent) {
              console.log('emailSent ', emailSent)
            })
            .catch(function(error) {
              console.log('emailSent error ', error)
            })
        })
        .catch(error => {
          commit('setLoading', false)
          commit('setError', error)
          console.log(error)
        })
    },
    signUserIn({ commit }, payload) {
      commit('setLoading', true)
      commit('clearError')
      firebase
        .auth()
        .signInWithEmailAndPassword(payload.email, payload.password)
        .then(user => {
          console.log('logged user:', user)
          analytics.setUserId(user.uid)
          analytics.setUserProperties({ type: 'producer' })
          commit('setLoading', false)
          analytics.logEvent('login', {
            method: 'email',
            category: 'login',
            action: 'login',
            isAnonymous: user.isAnonymous,
            emailVerified: user.emailVerified
          })
          const newUser = {
            id: user.uid,
            name: user.displayName,
            email: user.email,
            avatar: user.photoURL ? user.photoURL : user.photoUrl
          }
          commit('setUser', newUser)
        })
        .catch(error => {
          commit('setLoading', false)
          commit('setError', {
            message:
              'An invalid User ID or Password was entered. Please try again. '
          })
          //commit('setError', error)
          console.log(error)
        })
    },
    signUserInGoogle({ commit, dispatch }, payload) {
      commit('setLoading', true)
      commit('clearError')
      firebase
        .auth()
        .signInWithPopup(new firebase.auth.GoogleAuthProvider())
        .then(data => {
          let user = data.user
          console.log('google user', user)
          console.log('payload', payload)

          commit('setLoading', false)
          analytics.logEvent('login', {
            method: 'google',
            isAnonymous: user.isAnonymous,
            emailVerified: user.emailVerified,
            category: 'login',
            action: 'login'
          })
          const newUser = {
            id: user.uid,
            name: user.displayName,
            email: user.email,
            avatar: user.photoURL ? user.photoURL : user.photoUrl
          }
          commit('setUser', newUser)
          if (data.additionalUserInfo.isNewUser) {
            newUser.type = 'chef'
            newUser.newProfile = true
            dispatch('saveProfile', newUser)
          }
        })
        .catch(error => {
          commit('setLoading', false)
          commit('setError', error)
          console.log(error)
        })
    },
    signUserWithPhone({ commit, dispatch }, payload) {
      payload.confirmationResult
        .confirm(payload.verificationCode)
        .then(function(result) {
          // User signed in successfully.
          var user = result.user
          analytics.logEvent('login', {
            method: 'phone',
            isAnonymous: user.isAnonymous,
            emailVerified: user.emailVerified,
            category: 'login',
            action: 'login'
          })
          const newUser = {
            id: user.uid,
            name: user.displayName,
            email: user.email,
            phone: user.phone,
            avatar: user.photoURL ? user.photoURL : user.photoUrl,
            type: 'chef'
          }
          commit('setUser', newUser)
          if (result.additionalUserInfo.isNewUser) {
            newUser.newProfile = true
            dispatch('saveProfile', newUser)
          }

          // ...
        })
        .catch(function(error) {
          console.log(error)
          // User couldn't sign in (bad verification code?)
          // ...
        })
      // Redirect the user to the authenticated page
    },
    signUserInFacebook({ commit, dispatch }, payload) {
      commit('setLoading', true)
      commit('clearError')
      firebase
        .auth()
        .signInWithPopup(new firebase.auth.FacebookAuthProvider())
        .then(data => {
          let user = data.user
          analytics.logEvent('login', {
            method: 'facebook',
            isAnonymous: user.isAnonymous,
            emailVerified: user.emailVerified,
            category: 'login',
            action: 'login'
          })
          commit('setLoading', false)

          const newUser = {
            id: user.uid,
            name: user.displayName,
            email: user.email,
            avatar: user.photoURL ? user.photoURL : user.photoUrl,
            type: 'chef'
          }
          commit('setUser', newUser)

          if (data.additionalUserInfo.isNewUser) {
            newUser.newProfile = true
            dispatch('saveProfile', newUser)
          }

          console.log('Facebook user:', user)
          if (!user.emailVerified) {
            firebase
              .auth()
              .currentUser.sendEmailVerification()
              .then(function() {
                console.log('emailSent ', user.email)
              })
              .catch(function(error) {
                console.log('emailSent error ', error)
              })
          }
        })
        .catch(error => {
          commit('setLoading', false)
          commit('setError', error)
          console.log(error)
        })
    },
    signUserInTwitter({ commit, dispatch }, payload) {
      commit('setLoading', true)
      commit('clearError')
      firebase
        .auth()
        .signInWithPopup(new firebase.auth.TwitterAuthProvider())
        .then(data => {
          let user = data.user
          console.log('Twitter user', data)
          const newUser = {
            id: user.uid,
            name: user.displayName,
            email: user.email,
            avatar: user.photoURL ? user.photoURL : user.photoUrl,
            type: 'chef'
          }
          analytics.logEvent('login', {
            method: 'twitter',
            isAnonymous: user.isAnonymous,
            emailVerified: user.emailVerified,
            category: 'login',
            action: 'login'
          })
          commit('setUser', newUser)

          if (data.additionalUserInfo.isNewUser) {
            newUser.newProfile = true
            dispatch('saveProfile', newUser)
          }

          if (!user.emailVerified) {
            firebase
              .auth()
              .currentUser.sendEmailVerification()
              .then(function(emailSent) {
                console.log('emailSent ', emailSent)
              })
              .catch(function(error) {
                console.log('emailSent error ', error)
              })
          }
        })
        .catch(error => {
          commit('setLoading', false)
          commit('setError', error)
          console.log(error)
        })
    },
    SaveUserLocation({ commit }, payload) {
      if (payload) {
        commit('setUserlocation', payload)
      }
    },
    setUserLocation({ commit, getters, dispatch }) {
      var options = {
        enableHighAccuracy: false,
        timeout: 10000,
        maximumAge: 600000
      }

      commit('setLoading', true)
      return getPosition(options)
        .then(position => {
          let lowGeohash = Geohash.encode(
            position.coords.latitude,
            position.coords.longitude,
            7
          )
          let highGeohash = Geohash.encode(
            position.coords.latitude,
            position.coords.longitude,
            this.precision
          )
          console.log(`HighGeohash precision ${this.precision}`, position)
          let userLocation = {
            lowGeohash: lowGeohash,
            highGeohash: highGeohash,
            latitude: position.coords.latitude,
            longitude: position.coords.longitude
          }
          commit('setLoading', false)
          console.log('userLocation', userLocation)
          commit('setUserlocation', userLocation)
          return userLocation
        })
        .catch(err => {
          commit('setLoading', false)
          console.error(err)
          // if (err.code && err.code == 3) {
          //timeout
          // Setting user's profile location
          if (getters.defaultAddress) {
            let userLocation = {
              lat: getters.defaultAddress.latitude,
              lng: getters.defaultAddress.longitude
            }
            dispatch('saveUserLocation', userLocation)
          } else {
            //let userLocation = this.$localStorage.get('userlocation')
            //console.log('Saved userLocation from LS', userLocation)
            //return null
          }
          return null
          //}
        })
    },
    saveUserLocation({ commit }, location) {
      let lat = location.lat || location.latitude
      let lng = location.lng || location.longitude
      let lowGeohash = Geohash.encode(lat, lng, 7)
      let highGeohash = Geohash.encode(lat, lng)
      let userLocation = {
        lowGeohash: lowGeohash,
        highGeohash: highGeohash,
        latitude: lat,
        longitude: lng
      }

      console.log('user location', userLocation)
      commit('setUserlocation', userLocation)
    },
    saveUserFCMToken({ getters }, token) {
      let uid = getters.user.id
      console.log(
        `Saving FCM Token: ${uid} platform: ${navigator.platform} : `,
        token
      )

      firebase
        .firestore()
        .collection('profile')
        .doc(uid)
        .collection('fcm_token')
        .doc(navigator.platform)
        .set(
          {
            token: token,
            updated: moment.utc().valueOf()
          },
          { merge: true }
        )
        .catch(e => console.error('Saving error', e))
    },
    autoSignIn({ getters, commit, dispatch, state }, payload) {
      console.log('autoSignIn:', payload)
      // https://youtu.be/ZNt_e5ojGzQ?t=399
      // Exta check like displayName
      if (
        payload &&
        payload.metadata.creationTime == payload.metadata.lastSignInTime
      ) {
        // this is is new user
        commit('setNewUser', true)

        // setTimeout(function() {
        //   console.log(
        //     'Added intentional 2 second delay for post signup; gives enough time to load the profile'
        //   )
        // }, 2000)
      }

      analytics.setUserId(payload.uid)
      analytics.setUserProperties({ type: 'producer' })
      analytics.logEvent('login', {
        isAnonymous: payload.isAnonymous,
        emailVerified: payload.emailVerified,
        category: 'login',
        action: 'login'
      })

      commit('setUser', {
        id: payload.uid,
        name: payload.displayName,
        email: payload.email,
        phoneNumber: payload.phoneNumber,
        avatar: payload.photoURL ? payload.photoURL : payload.photoUrl,
        emailVerified: payload.emailVerified,
        showOnboard: (state.user && state.user.showOnboard) || null
      })
      var ua = navigator.userAgent.toLowerCase()
      if (ua.indexOf('safari') != -1) {
        if (ua.indexOf('chrome') <= -1) {
          console.log('Skipping FCM permission check')
          commit('setLoading', false)
          return
        }
      }
      console.log('Request FCM message permission')
      const messaging = firebase.messaging()
      messaging
        .requestPermission()
        .then(function() {
          console.log('Notification permission granted.')
          return messaging.getToken()
        })
        .then(t => {
          console.log('token', t)
          commit('setUser', {
            token: t,
            id: payload.uid,
            name: payload.displayName,
            email: payload.email,
            avatar: payload.photoURL ? payload.photoURL : payload.photoUrl,
            emailVerified: payload.emailVerified
          })
          dispatch('saveUserFCMToken', t)
        })
        .catch(function(err) {
          console.error('Unable to get permission to notify.', err)
        })
    },
    resetPassword({ commit }, payload) {
      firebase
        .auth()
        .sendPasswordResetEmail(payload)
        .then(function() {
          console.log('Reset password sent to ', payload)
        })
        .catch(function(error) {
          commit('setError', error)
          console.log(error)
        })
    },
    changePassword({ commit }, newPassword) {
      var user = firebase.auth().currentUser
      var credential = firebase.auth.EmailAuthProvider.credential(
        user.email,
        newPassword
      )
      return user
        .updatePassword(newPassword)
        .then(function() {
          console.log('Password update')
          return user
            .reauthenticateWithCredential(credential)
            .then(function() {
              commit('setMessage', {
                title: 'Password Update',
                body: 'Thanks for update'
              })
            })
            .catch(function(error) {
              commit('setError', error)
              console.error(error)
            })
        })
        .catch(function(error) {
          commit('setError', error)
          console.error('Error on change password:', error)
        })
    },
    logout: async ({ commit }) => {
      await firebase.auth().signOut()
      commit('setUser', null)
      commit('setProfile', null)
      document.cookie = '__session='
    },
    deleteAccount({ commit, getters }) {
      let uid = getters.user.id
      let name = getters.user.name
      let email = getters.user.email

      firebase
        .firestore()
        .collection('profile')
        .doc(uid)
        .collection('actions')
        .add({ action: 'delete', name, email })
        .then(() => {
          console.log('Delete requested')
          firebase.auth().signOut()
          document.cookie = '__session='
        })
        .catch(error => {
          commit('setError', error)
        })
      /* user
        .delete()
        .then(function() {
          console.log('Account deleted')
          commit('setUser', null)
          commit('setProfile', null)
        })
        .catch(function(error) {
          commit('setError', error)
          console.log(error)
        })*/
    },
    changeLanguage({ commit }, payload) {
      commit('setLanguage', payload)
    }
  },
  getters: {
    user(state) {
      return state.user
    },
    newUser(state) {
      return state.newUser
    },
    userVerified(state) {
      return state.user ? state.user.emailVerified : false
    },
    userLocation(state) {
      return state.userLocation
    },
    lang(state) {
      return state.lang
    }
  }
}

var getPosition = function(options) {
  return new Promise(function(resolve, reject) {
    navigator.geolocation.getCurrentPosition(resolve, reject, options)
  })
}
