<template>
  <v-container grid-list-xs v-if="!loading">
    <v-stepper v-model="stepperValue">
      <v-stepper-items>
        <v-stepper-content step="1">
          <v-layout column align-center justify-center v-show="!edit">
            <v-flex xs12 sm6>
              <div
                v-show="$refs.upload && $refs.upload.dropActive"
                class="drop-active"
              >
                <h4>Drop files to upload</h4>
              </div>
              <div class="avatar-upload">
                <div class="text-center p-2">
                  <label for="avatar">
                    <v-img
                      width="400"
                      v-if="files.length"
                      :src="files[0].url"
                    />
                    <v-img v-else width="150" src="/svg/camera_photo.svg" />
                    <h4 class="pt-2">Upload or Drop files or take picture</h4>
                  </label>
                </div>

                <div class="text-center p-2">
                  <file-upload
                    extensions="gif,jpg,jpeg,png,webp"
                    accept="image/png,image/gif,image/jpeg,image/webp"
                    name="avatar"
                    class="btn btn-primary"
                    :drop="!edit"
                    v-model="files"
                    @input-filter="inputFilter"
                    @input-file="inputFile"
                    ref="upload"
                  >
                  </file-upload>
                </div>
              </div>
            </v-flex>
          </v-layout>

          <v-layout
            column
            align-center
            justify-center
            v-if="files.length > 0 && !edit"
          >
            <v-flex xs12 sm6>
              <v-btn color="success" @click.prevent="upload">Next</v-btn>
              <v-btn text @click.prevent="$refs.upload.clear">Cancel</v-btn>
            </v-flex>
          </v-layout>
          <v-layout
            column
            align-center
            justify-center
            v-show="files.length && edit"
          >
            <v-flex xs12 class="avatar-edit" v-if="files.length">
              <img ref="editImage" height="400" :src="files[0].url" />
            </v-flex>
            <v-flex xs12>
              <v-btn color="success" icon @click.prevent="editSave">
                <v-icon large dark>check_circle</v-icon>
              </v-btn>
              <v-btn text @click.prevent="$refs.upload.clear">Cancel</v-btn>
            </v-flex>
          </v-layout>
        </v-stepper-content>
        <v-stepper-content step="2">
          <v-layout column justify-center>
            <!-- <v-flex xs12 class="avatar-edit" v-if="files.length"> -->
              <v-flex xs12 class="avatar-edit" >
              Edit Info
              <v-text-field
                v-model="name"
                required
                label="Dish Name"
                id="id"
              ></v-text-field>

              <v-textarea label="Description" model="description"> </v-textarea>
            </v-flex>
            <v-layout row wrap>
              <v-flex xs12 >
                Location:
                <v-icon color="green" @click.prevent="getLocationList()">fas fa-map-marker-alt</v-icon>

              </v-flex>
            </v-layout>

            <v-flex xs12>
              <v-btn color="success" icon @click.prevent="saveInfo" :disabled="!name">
                <v-icon large dark>keyboard_arrow_right</v-icon>
              </v-btn>
              <v-btn text @click.prevent="$refs.upload.clear">Cancel</v-btn>
            </v-flex>
          </v-layout>
        </v-stepper-content>
        <v-stepper-content step="3">
          <v-layout column justify-center>
            <v-flex xs12 class="avatar-edit">
              Share:

              <v-checkbox
                label="Facebook"
                v-model="fb"
                value="value"
              ></v-checkbox>
              <v-checkbox
                label="Instagram"
                v-model="instagram"
                value="value"
              ></v-checkbox>
              <v-checkbox
                label="Twitter"
                v-model="twitter"
                value="value"
              ></v-checkbox>
              <vue-goodshare-facebook has_icon></vue-goodshare-facebook>
              <vue-goodshare-pinterest
                has_counter
                has_icon
              ></vue-goodshare-pinterest>

              <vue-goodshare-twitter
                has_counter
                has_icon
              ></vue-goodshare-twitter>
            </v-flex>
            <v-flex xs12>
              <v-btn color="success" @click.prevent="saveInfo"> Post </v-btn>
              <v-btn text @click.prevent="cancelSave()">Cancel</v-btn>
            </v-flex>
          </v-layout>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-container>
</template>

<script>
import Cropper from 'cropperjs'
import FileUpload from 'vue-upload-component'
import 'cropperjs/dist/cropper.min.css'
import { mapGetters } from 'vuex'
import VueGoodshareFacebook from 'vue-goodshare/src/providers/Facebook.vue'
import VueGoodsharePinterest from 'vue-goodshare/src/providers/Pinterest.vue'
import VueGoodshareReddit from 'vue-goodshare/src/providers/Reddit.vue'
import VueGoodshareTwitter from 'vue-goodshare/src/providers/Twitter.vue'
import VueGoodshare from 'vue-goodshare'
export default {
  components: {
    FileUpload,
    VueGoodshareFacebook,
    VueGoodsharePinterest,
    VueGoodshareReddit,
    VueGoodshareTwitter,
    VueGoodshare
  },

  data() {
    return {
      stepperValue: 1,

      files: [],
      fb: null,
      twitter: null,
      instagram: null,
      name: null,
      description: null,
      edit: false,
      cropper: false
    }
  },
  computed: {
    ...mapGetters({
      loading: 'loading',

    })
  },
  watch: {
    edit(value) {
      if (value) {
        this.$nextTick(function() {
          if (!this.$refs.editImage) {
            return
          }

          let cropper = new Cropper(this.$refs.editImage, {
            aspectRatio: 1 / 1,
            viewMode: 1
          })
          this.cropper = cropper
        })
      } else {
        if (this.cropper) {
          this.cropper.destroy()
          this.cropper = false
        }
      }
    }
  },

  methods: {
    editSave() {
      this.edit = false

      let oldFile = this.files[0]

      let binStr = atob(
        this.cropper
          .getCroppedCanvas()
          .toDataURL(oldFile.type)
          .split(',')[1]
      )
      let arr = new Uint8Array(binStr.length)
      for (let i = 0; i < binStr.length; i++) {
        arr[i] = binStr.charCodeAt(i)
      }

      let file = new File([arr], oldFile.name, { type: oldFile.type })

      this.$refs.upload.update(oldFile.id, {
        file,
        type: file.type,
        size: file.size,
        active: true
      })
    },
    getLocationList () {
      var options = {
        enableHighAccuracy: false,
        timeout: 10000,
        maximumAge: 600000
      }
      console.log('Getting GPS location and saving to storage')

      return this.GeoCoder(options).then(position => {
        console.log('GeoCoder position:', position)
      })
    },
    alert(message) {
      alert(message)
    },

    inputFile(newFile, oldFile) {
      if (newFile && !oldFile) {
        this.$nextTick(function() {
          this.edit = true
        })
      }
      if (!newFile && oldFile) {
        this.edit = false
      }
    },

    inputFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        if (!/\.(gif|jpg|jpeg|png|webp)$/i.test(newFile.name)) {
          this.alert('Your choice is not a picture')
          return prevent()
        }
      }

      if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
        newFile.url = ''
        let URL = window.URL || window.webkitURL
        if (URL && URL.createObjectURL) {
          newFile.url = URL.createObjectURL(newFile.file)
        }
      }
    },
    saveInfo() {
      this.stepperValue = 3
    },
    cancelSave() {
      this.stepperValue = 1
    },
    upload() {
      // this.uploadProgress = true
      this.stepperValue = 2
      // this.$store
      //   .dispatch('uploadDishPhoto', {
      //     file: this.files[0].file
      //   })
      //   .then(() => {
      //     this.files = []
      //     //this.$router.go(this.$router.currentRoute)
      //   })
    }
  }
}
</script>
<style scoped>
.text-center .btn {
  margin: 0 0.5rem;
}

.drop-active {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: fixed;
  z-index: 9999;
  opacity: 0.6;
  text-align: center;
  background: #000;
}

.drop-active h4 {
  margin: -0.5em 0 0;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 40px;
  color: #fff;
  border-radius: 50%;
  padding: 0;
}
</style>
