import firebase from '@/firebase-init'

export default {
  state: {
    operatingCities: [],
    communityList: [],
    consents: {},
    serviceFees: null,
    deliverySubsidy: [],
    carouselList: []
  },

  mutations: {
    setOperatingCities(state, payload) {
      state.operatingCities = payload
    },
    setCommunities(state, payload) {
      state.communityList = payload
    },
    setCarousels(state, payload) {
      state.carouselList = payload
    },
    setServiceFees(state, payload) {
      state.serviceFees = payload
    },
    setDeliverySubsidy(state, payload) {
      state.deliverySubsidy = payload
    }
  },
  actions: {
    getOperatingCities({ commit }) {
      commit('setLoading', true)
      const ref = firebase
        .firestore()
        .collection('admin')
        .doc('cities')
        .collection('operatingCities')

      return ref
        .get()
        .then(dataSnapshot => {
          if (dataSnapshot.empty) {
            commit('setLoading', false)
            console.log('Error, no available cities')
          } else {
            console.log('Getting Operating Cities')
            const citiesList = []
            dataSnapshot.forEach(data => {
              if (data.exists) {
                const city = data.data()
                city.id = data.id
                citiesList.push(city)
              }
            })
            commit('setLoading', false)
            commit('setOperatingCities', citiesList)
          }
        })
        .catch(error => {
          commit('setLoading', false)
          commit('setError', error)
          console.log(error)
        })
    },
    getCommunities({ commit }) {
      commit('setLoading', true)
      const ref = firebase.firestore().collection('community')

      return ref
        .get()
        .then(dataSnapshot => {
          if (dataSnapshot.empty) {
            commit('setLoading', false)
            console.log('Error, no available communities')
          } else {
            console.log('Getting Communities')
            const list = []
            dataSnapshot.forEach(data => {
              if (data.exists) {
                const community = data.data()
                community.id = data.id
                list.push(community)
              }
            })
            commit('setLoading', false)
            commit('setCommunities', list)
          }
        })
        .catch(error => {
          commit('setLoading', false)
          commit('setError', error)
          console.log(error)
        })
    },
    getOrderFees({ commit }) {
      //commit('setLoading', true)
      const ref = firebase
        .firestore()
        .collection('admin')
        .doc('settings')

      return ref
        .get()
        .then(settings => {
          if (settings.exists) {
            console.log('Successfully Retrieved Order Fee Values')
            commit('setLoading', false)
            commit(
              'setDeliverySubsidy',
              settings.data().default_delivery_subsidy
            )

            commit('setServiceFees', settings.data().serviceFees)
            return settings.data().serviceFees
          } else {
            console.log('Did Not Find Any Admin Fee Values')
          }
        })
        .catch(error => {
          commit('setLoading', false)
          commit('setError', error)
          console.log(error)
        })
    },
    saveAdminSettings({ commit }, payload) {
      commit('setLoading', true)
      const ref = firebase
        .firestore()
        .collection('admin')
        .doc('settings')

      return ref
        .set(payload, { merge: true })
        .then(settings => {
          console.log('Successfully Saved Service Fee')
          commit('setLoading', false)
          commit('setServiceFees', settings)
        })
        .catch(error => {
          commit('setLoading', false)
          commit('setError', error)
          console.log(error)
        })
    },

    saveOrderFees({ commit }, payload) {
      commit('setLoading', true)
      const ref = firebase
        .firestore()
        .collection('admin')
        .doc('settings')

      return ref
        .update({
          [payload.key]: payload.amount
        })
        .then(() => {
          console.log('Successfully Saved Service Fee')
          commit('setLoading', false)
        })
        .catch(error => {
          commit('setLoading', false)
          commit('setError', error)
          console.log(error)
        })
    },
    getUpdates(context) {
      context.commit('setLoading', true)
      return firebase
        .firestore()
        .collection('admin/updates/product')
        .get()
        .then(snapshot => {
          let list = []
          snapshot.forEach(data => {
            const obj = data.data()
            obj.id = data.id
            list.push(obj)
          })
          context.commit('setLoading', false)
          return list
        })
    },
    getUpdate(context, payload) {
      context.commit('setLoading', false)
      return firebase
        .firestore()
        .doc(`admin/updates/product/${payload.id}`)
        .get()
        .then(snapshot => {
          context.commit('setLoading', false)
          return snapshot.data()
        })
    },
    saveUpdate(context, payload) {
      const ref = firebase
        .firestore()
        .collection('admin')
        .doc('updates')
        .collection('product')
      if (payload.id) {
        return ref.doc(payload.id).set(payload, { merge: true })
      } else {
        return ref.add(payload)
      }
    },
    deleteUpdate(_, payload) {
      console.log('Deleting update: ', payload)
      firebase
        .firestore()
        .collection('admin')
        .doc('updates')
        .collection('product')
        .doc(payload)
        .delete()
        .then(() => console.log('deleted'))
        .catch(e => console.error('unable to delete: ', e))
    },
    getCarousels({ commit }) {
      commit('setLoading', true)
      const ref = firebase
        .firestore()
        .collection('admin')
        .doc('settings')
        .collection('carousel')

      return ref
        .get()
        .then(dataSnapshot => {
          const list = []
          if (dataSnapshot.empty) {
            commit('setLoading', false)
            console.log('Error, no available carousels')
          } else {
            console.log('Getting Carousels')
            dataSnapshot.forEach(data => {
              if (data.exists) {
                const carousel = data.data()
                carousel.id = data.id
                list.push(carousel)
              }
            })
            commit('setLoading', false)
            commit('setCarousels', list)
          }
        })
        .catch(error => {
          commit('setLoading', false)
          commit('setError', error)
          console.log(error)
        })
    },
    getCarousel(_, payload) {
      return firebase
        .firestore()
        .collection('admin')
        .doc('settings')
        .collection('carousel')
        .doc(payload)
        .get()
        .then(querySnapshot => {
          let carousel = null
          if (querySnapshot.exists) {
            carousel = querySnapshot.data()
            carousel.id = querySnapshot.id
          }
          return carousel
        })
    },
    deleteCarousel(_, payload) {
      return firebase
        .firestore()
        .collection('admin')
        .doc('settings')
        .collection('carousel')
        .doc(payload)
        .delete()
        .then(() => {
          console.log(payload + ' Carousel deleted')
          return true
        })
        .catch(error => {
          console.log('Error from carousel deleted', error)
          return false
        })
    },
    saveCarousel(_, payload) {
      console.log('Saving carousel: ', payload)
      if (!payload.id) {
        firebase
          .firestore()
          .collection('admin')
          .doc('settings')
          .collection('carousel')
          .add(payload)
      } else {
        firebase
          .firestore()
          .collection('admin')
          .doc('settings')
          .collection('carousel')
          .doc(payload.id)
          .set(payload, { merge: true })
      }
    },
    updateCarousels({ dispatch }, payload) {
      const { list } = payload
      let sfRef = null
      let batch = firebase.firestore().batch()
      list.forEach(carousel => {
        sfRef = firebase
          .firestore()
          .collection('admin')
          .doc('settings')
          .collection('carousel')
          .doc(carousel.id)
        batch.update(sfRef, carousel)
      })

      return batch.commit().then(function() {
        return dispatch('getCarousels')
      })
    }
  },
  getters: {
    operatingCities(state) {
      return state.operatingCities
    },
    communityList(state) {
      return state.communityList
    },
    carouselList(state) {
      return state.carouselList
    },
    serviceFees(state) {
      return state.serviceFees
    },
    deliverySubsidy(state) {
      return state.deliverySubsidy
    }
  }
}
