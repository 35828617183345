import firebase from '@/firebase-init'
import moment from 'moment'
export default {
  state: {},
  mutations: {
    setFoodTypes(state, payload) {
      state.foodTypes = payload
    }
  },
  actions: {
    loadSupportTickets() {
      return firebase
        .firestore()
        .collection('/support/product/technical')
        .orderBy(`createdTime`, 'desc')
        .limit(100)
        .get()
        .then(querySnapshot => {
          if (!querySnapshot.empty) {
            return querySnapshot.docs
          }
        })
    },
    loadSupportTicket(context, payload) {
      return firebase
        .firestore()
        .doc(`/support/product/technical/${payload.id}`)
        .get()
        .then(querySnapshot => {
          return querySnapshot.data()
        })
    },
    loadPushMessages() {
      return firebase
        .firestore()
        .collection('/admin/updates/notification')
        .orderBy(`createdTime`, 'desc')
        .limit(100)
        .get()
        .then(querySnapshot => {
          if (!querySnapshot.empty) {
            return querySnapshot.docs
          }
        })
    },
    loadPushMessage(_, id) {
      return firebase
        .firestore()
        .collection('/admin/updates/notification')
        .doc(id)
        .get()
        .then(data => {
          if (data.exists) {
            return { ...data.data(), id: data.id }
          }
        })
    },
    savePushMessages(ctx, payload) {
      if (payload.id) {
        return firebase
          .firestore()
          .collection('/admin/updates/notification')
          .doc(payload.id)
          .set(payload, { merge: true })
      }
      payload.createdTime = moment.utc().valueOf()
      return firebase
        .firestore()
        .collection('/admin/updates/notification')
        .add(payload)
    },
    loadOperatingCity(ctx, payload) {
      console.log(`loading operating city`, payload)
      return firebase
        .firestore()
        .collection('admin')
        .doc('cities')
        .collection('operatingCities')
        .doc(payload.id)
        .get()
        .then(querySnapshot => {
          return querySnapshot.data()
        })
    },
    saveOperatingCity(ctx, payload) {
      console.log('Saving: ', payload)
      if (!payload.id) {
        payload.createdTime = moment.utc().valueOf()
        firebase
          .firestore()
          .collection('admin')
          .doc('cities')
          .collection('operatingCities')

          .add(payload)
      } else {
        payload.updatedTime = moment.utc().valueOf()
        firebase
          .firestore()
          .collection('admin')
          .doc('cities')
          .collection('operatingCities')
          .doc(payload.id)
          .set(payload, { merge: true })
      }
    },
    loadCommunity(_, payload) {
      console.log(`loading community`, payload)
      return firebase
        .firestore()
        .collection('community')
        .doc(payload.id)
        .get()
        .then(querySnapshot => {
          return querySnapshot.data()
        })
    },
    loadCommunityMembers(_, payload) {
      console.log(`loading community members`, payload)
      return firebase
        .firestore()
        .collection('profile')
        .where('communities', 'array-contains', payload.id)
        .get()
        .then(data =>
          data.docs.map(d => ({
            id: d.id,
            ...d.data()
          }))
        )
    },
    saveCommunity(ctx, payload) {
      console.log('Saving community: ', payload)
      if (!payload.id) {
        payload.createdTime = moment.utc().valueOf()
        firebase
          .firestore()
          .collection('community')
          .add(payload)
      } else {
        payload.updatedTime = moment.utc().valueOf()
        firebase
          .firestore()
          .collection('community')
          .doc(payload.id)
          .set(payload, { merge: true })
      }
    },
    deleteCommunity(ctx, payload) {
      console.log('Deleting community: ', payload)

      return firebase
        .firestore()
        .collection('community')
        .doc(payload)
        .delete()
        .then(() => {
          console.log('Community deleted')
          return true
        })
        .catch(error => {
          console.log('Error from community deleted', error)
          return false
        })
    }
  },
  getters: {
    food(state) {
      return state.food
    }
  }
}
