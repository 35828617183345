// Component ProductVisibilityDialog can now be independent, and the dialog
content can be used from both // ProductStatus.vue and DishDetail.vue. // Upon
cancel, ProductVisibilityDialog returns false and 'show' to be set as variables
// confirmHide and cookingVisibility, respectively, in the parent component.

<template>
  <v-card>
    <v-card-title>Want to hide your product?</v-card-title>
    <v-card-text
      >If you hide, this product will be remove from TakeIn marketplace
      listing.</v-card-text
    >
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="green darken-1" text @click.native="closeEvent"
        >Close
      </v-btn>
      <v-btn color="green darken-1" text @click.native="agreeEvent"
        >Agree</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  data() {
    return {}
  },
  methods: {
    closeEvent() {
      this.$emit('closeAction', false, 'public')
    },
    agreeEvent() {
      this.$emit('confirmVisibility')
    }
  }
}
</script>
