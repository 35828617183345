<template>
  <v-container grid-list-xs>
    <beautiful-chat
      :agentProfile="agentProfile"
      :onMessageWasSent="onMessageWasSent"
      :messageList="messageList"
      :newMessagesCount="newMessagesCount"
      :isOpen="isChatOpen"
      :close="closeChat"
      :colors="colors"
      :showTypingIndicator="showTypingIndicator"
      :open="openChat"
      :showEmoji="true"
      :title="title"
      :participants="chatParticipants"
      :titleImageUrl="titleImageUrl"
      :showFile="true"
    />
  </v-container>
</template>

<script>
import Chat from 'vue-beautiful-chat'
import Vue from 'vue'

Vue.use(Chat, {})

export default {
  props: {
    profile: {
      type: Object,
      required: true
    },
    incomingMessage: {
      type: Array
    },
    chatParticipants: {
      type: Array
    }
  },
  data() {
    return {
      showTypingIndicator: '',
      newMessagesCount: 0,
      messageList: [],
      colors: null,
      titleImageUrl:
        'https://storage.googleapis.com/download.takein.com/apps/logo/takein_sym_100.png',
      isChatOpen: false
    }
  },
  computed: {
    agentProfile() {
      return { teamName: this.profile.name, imageUrl: this.profile.avatar }
    },
    title() {
      if (this.chatParticipants.length == 0) {
        return 'Chat'
      } else {
        return undefined
      }
    }
  },
  watch: {
    incomingMessage: function() {
      if (this.incomingMessage) {
        console.log('Adding message to the list:', this.incomingMessage[0])
        this.messageList.push(this.incomingMessage[0])
        this.newMessagesCount = this.isChatOpen
          ? this.newMessagesCount
          : this.newMessagesCount + 1
      }
    }
  },
  created() {
    this.colors = {
      header: {
        bg: '#4e8cff',
        text: '#ffffff'
      },
      launcher: {
        bg: '#4e8cff'
      },
      messageList: {
        bg: '#ffffff'
      },
      sentMessage: {
        bg: '#4e8cff',
        text: '#ffffff'
      },
      receivedMessage: {
        bg: '#eaeaea',
        text: '#222222'
      },
      userInput: {
        bg: '#f4f7f9',
        text: '#565867'
      }
    }
  },
  methods: {
    sendMessage(message) {
      console.log('message is: ', message)
      if (message.length > 0) {
        this.$emit('sendMessage', message)
        this.newMessagesCount = this.isChatOpen
          ? this.newMessagesCount
          : this.newMessagesCount + 1
        this.onMessageWasSent(message)
      }
    },
    onMessageWasSent(message) {
      this.messageList = [...this.messageList, message]

      if (message.data.text.length > 0) {
        this.$emit('sendMessage', message.data)
      }
    },
    openChat() {
      this.isChatOpen = true
      this.newMessagesCount = 0
    },
    closeChat() {
      this.isChatOpen = false
    }
  }
}
</script>
<style>
.sc-header--img {
  width: 60px;
}
.sc-chat-window,
.sc-launcher {
  /*padding-top: 40px; */
  z-index: 100;
  height: 50%;
}
</style>
