import firebase, { analytics } from '@/firebase-init'
import axios from 'axios'
import _ from 'lodash'
import { globalMixin } from 'fod-core'
const moment = require('moment')

const ThumbPartner = '%2F'
const ThumbPrefix = 'thumb_'

function changeImageUrl(source, pattern) {
  let pos = source.lastIndexOf(ThumbPartner)
  let dest =
    source.substring(0, pos + ThumbPartner.length) +
    pattern +
    source.substring(pos + ThumbPartner.length - 1 + 1)
  return dest
}
export default {
  state: {
    /*
    Chef: Public user will view profile of a chef
    Profile: Chef user want to view/mange the profile
    */
    chef: null,
    nearbyChefs: [],
    profile: null,
    // userProfile = buyer's profile
    userProfile: null,
    approvalStatus: null,
    profileDocRef: null,
    isAdmin: null,
    album: [],
    adminProducers: [],
    profiles: []
  },
  mutations: {
    setProfile(state, payload) {
      state.profile = payload
    },
    setProfileDocRef(state, payload) {
      state.profileDocRef = payload
    },
    setUserProfile(state, payload) {
      state.userProfile = payload
    },
    setChef(state, payload) {
      state.chef = payload
    },
    setAlbum(state, payload) {
      state.album = payload
    },
    setApprovalStatus(state, payload) {
      state.approvalStatus = payload
    },
    clearNearbyChefs(state) {
      state.nearbyChefs = []
    },
    addNearbyChef(state, payload) {
      console.log('Trying to add nearbyChefs', payload)
      if (!state.nearbyChefs.find(f => f.id === payload.id)) {
        state.nearbyChefs.push(payload)
      }
    },
    setNearbyChefs(state, payload) {
      state.nearbyChefs = payload
    },
    setIsAdmin(state, payload) {
      state.isAdmin = payload
    },
    setAdminProducers(state, payload) {
      state.adminProducers = payload
    },
    setProfiles(state, payload) {
      state.profiles = payload
    }
  },
  actions: {
    saveOnboarding(context, payload) {
      const uid = payload.uid ? payload.uid : context.getters.user.id

      payload.name = context.getters.profile.name
      payload.updatedTime = moment.utc().valueOf()
      payload.action = 'onboard'
      context.commit('setLoading', true)
      firebase
        .firestore()
        .collection('profile')
        .doc(uid)
        .collection('actions')
        .doc('onboard')
        .set(payload, { merge: true })
        .then(() => {
          context.commit('setLoading', false)
        })
        .catch(error => {
          context.commit('setLoading', false)
          context.commit('setError', error)
        })
    },
    getOnboarding(context, payload) {
      let uid = payload && payload.uid ? payload.uid : context.getters.user.id

      return firebase
        .firestore()
        .collection('profile')
        .doc(uid)
        .collection('actions')
        .doc('onboard')
        .get()
        .then(d => {
          return d.data()
        })
    },

    updateProfile({ commit, getters }, payload) {
      commit('setLoading', true)
      let uid = payload.id || getters.user.id
      return firebase
        .firestore()
        .collection('profile')
        .doc(uid)
        .set(payload, { merge: true })
        .then(() => {
          if (uid === getters.profile.id) {
            let profile = getters.profile
            profile = _.merge(profile, payload)
            commit('profile', profile)
            return true
          }
          commit('setLoading', false)
        })
        .catch(error => {
          commit('setLoading', false)
          commit('setError', error)
          console.log(error)
        })
    },

    saveProfile({ commit, getters, dispatch }, payload) {
      commit('setLoading', true)

      let uid = payload.id || getters.user.id

      let user = firebase.auth().currentUser
      console.log(uid, user, 'current id, user!!!')
      let profile = {
        type: payload.type || null,
        name: payload.name || null,
        handle: payload.handle || null,
        title: payload.title || null,
        name_search: payload.name
          ? payload.name.toLowerCase().replace(/ /g, '')
          : null,
        businessName: payload.businessName || null,
        businessHours: payload.businessHours || null,
        businessPhone: payload.businessPhone || null,
        orderPhoneExtension: payload.orderPhoneExtension || null,
        secondaryPhone: payload.secondaryPhone || null,
        businessEmail: payload.businessEmail || null,
        emailOrder: payload.emailOrder || null,
        emailMessages: payload.emailMessages || null,
        emailComplains: payload.emailComplains || null,
        emailNotifications: payload.emailNotifications || null,
        hasBusiness: payload.hasBusiness || null,
        profileStatus: payload.profileStatus || null,
        showOnboard: payload.showOnboard || null,
        email: payload.email || getters.user.email || null,
        phone: payload.phone || getters.user.phone || null,
        website: payload.website || null,
        facebook: payload.facebook || null,
        pinterest: payload.pinterest || null,
        instagram: payload.instagram || null,
        linkedin: payload.linkedin || null,
        categories: payload.categories,
        yelp: payload.yelp || null,
        twitter: payload.twitter || null,
        description: payload.description || null,
        photoUrl: getters.user.photoUrl || null,
        shareLink: payload.shareLink || null,
        cuisine: payload.cuisine || null,
        updatedTime: moment.utc().valueOf()
      }
      // if (payload.status) {
      //   profile.status = payload.status
      // }
      if (payload.experiences) {
        profile.experiences = payload.experiences
      }
      if (payload.permits) {
        profile.permits = payload.permits
      }
      console.log(`Saving profile for ${uid}  email ${payload.email}`)

      //let user = firebase.auth().currentUser
      analytics.logEvent('biz_profile_save', {
        value: payload.name,
        id: uid,
        uid,
        category: 'profile',
        action: 'save'
      })
      if (payload.newProfile) {
        firebase
          .firestore()
          .collection('profile')
          .doc(uid)
          .set(profile, { merge: true })
          .then(() => {
            const adminProfile = {
              approvalStatus: 'pending',
              created: moment.utc().valueOf(),
              name: payload.email
            }

            firebase
              .firestore()
              .collection('admin')
              .doc('accounts')
              .collection('chefs')
              .doc(uid)
              .set(adminProfile, { merge: true })
              .then(() => {
                dispatch('loadProfile')
                commit('setLoading', false)
              })
          })
          .catch(error => {
            commit('setLoading', false)
            commit('setError', error)
            console.log(error)
          })
      } else {
        firebase
          .firestore()
          .collection('profile')
          .doc(uid)
          .set(profile, { merge: true })
          // .then(() => {
          //   if (payload.image) {
          //     const filename = payload.image.name
          //     const ext = filename.slice(filename.lastIndexOf('.'))
          //     let key = Math.random()
          //       .toString(36)
          //       .substring(6)
          //     return firebase
          //       .storage()
          //       .ref('profile/' + uid + '/' + key + ext)
          //       .put(payload.image)
          //   }
          // })
          .then(() => {
            if (payload.email) {
              user
                .updateProfile({
                  displayName: payload.name,
                  email: payload.email
                })
                .then(() => {
                  user
                    .updateEmail(payload.email)
                    .then(function() {
                      // Update successful.
                    })
                    .catch(function(error) {
                      console.error(error)
                      return error
                    })
                })
                .catch(function(error) {
                  console.error(error)
                  return error
                })
            } else {
              console.warn('User email and name is missing', payload)
            }
          })
          // .then(fileData => {
          //   if (fileData) {
          //     fileData.ref.getDownloadURL().then(imageUrl => {
          //       if (imageUrl.indexOf('mp4') < -1) {
          //         imageUrl = changeImageUrl(imageUrl, ThumbPrefix)
          //       }
          //       console.log(
          //         `Updating user:${uid}  profilePicture: ${imageUrl} url`
          //       )
          //       firebase
          //         .firestore()
          //         .collection('profile')
          //         .doc(uid)
          //         .update({ profilePicture: imageUrl })
          //     })
          //   }
          // })
          .then(() => {
            dispatch('loadProfile')
            commit('setLoading', false)
          })
          .catch(error => {
            commit('setLoading', false)
            commit('setError', error)
            console.log(error)
          })
      }
    },
    saveProfileAddress({ commit, getters, dispatch }, payload) {
      commit('setLoading', true)
      let key = getters.user.id
      let userLocation = getters.userLocation
      if (payload.latitude && payload.longitude) {
        let location = new firebase.firestore.GeoPoint(
          payload.latitude,
          payload.longitude
        )
        payload.location = location
      } else {
        if (userLocation) {
          let location = new firebase.firestore.GeoPoint(
            userLocation.latitude,
            userLocation.longitude
          )
          payload.location = location
          payload.userLocation = userLocation
        }
      }
      console.log('location', location)
      firebase
        .firestore()
        .collection('profile')
        .doc(key)
        .update({ address: payload })
        .then(() => {
          dispatch('loadProfile')
          commit('setLoading', false)
        })
        .catch(error => {
          commit('setLoading', false)
          commit('setError', error)
          console.log(error)
        })
    },
    savePermit({ commit, getters, dispatch }, payload) {
      commit('setLoading', true)
      let key = payload.profile.id || getters.user.id

      firebase
        .firestore()
        .collection('profile')
        .doc(key)
        .update(payload.permitData)
        .then(() => {
          if (payload.profile.id) {
            dispatch('loadUserProfile', { uid: key })
          } else {
            dispatch('loadProfile')
          }
          commit('setLoading', false)
        })
        .catch(error => {
          commit('setLoading', false)
          commit('setError', error)
          console.log(error)
        })
    },

    getProfiles({ commit }, payload) {
      const type = payload.type ? payload.type : 'customer'
      commit('setLoading', true)
      const name = payload.name
      const email = payload.email
      let profileRef = firebase.firestore().collection('profile')

      if (name && name.length > 0) {
        console.log('Searching by name: ' + name)
        let encode = name + String.fromCharCode(name.charCodeAt(0) + 1)
        profileRef = profileRef
          .where('name_search', '>=', name)
          .where('name_search', '<=', encode)
      }
      if (email && email.length > 0) {
        console.log('Searching by email: ' + email)
        let encode = email + String.fromCharCode(email.charCodeAt(0) + 1)
        profileRef = profileRef
          .where('email', '>=', email)
          .where('email', '<=', encode)
      }

      console.log('Getting profiles of type', type)
      return profileRef
        .get()
        .then(snapshot => {
          const profileList = []
          snapshot.forEach(data => {
            if (data.exists) {
              const profile = data.data()
              profile.id = data.id
              profileList.push(profile)
            }
          })
          console.log(`Loaded ${profileList.length} profiles`)
          commit('setProfiles', profileList)
          commit('setLoading', false)
          return profileList
        })
        .catch(error => {
          console.log(error)
          commit('setLoading', false)
          commit('setError', error)
        })
    },
    getAdminProducers({ commit }) {
      commit('setLoading', true)
      let producerRef = firebase
        .firestore()
        .collection('admin')
        .doc('accounts')
        .collection('chefs')

      producerRef
        .get()
        .then(snapshot => {
          const producerList = []
          snapshot.forEach(data => {
            if (data.exists) {
              const producer = data.data()
              producer.id = data.id
              producerList.push(producer)
            }
          })
          commit('setAdminProducers', producerList)
          commit('setLoading', false)
        })
        .catch(error => {
          console.log(error)
          commit('setLoading', false)
          commit('setError', error)
        })
    },
    async checkAdmin(context) {
      return firebase
        .firestore()
        .collection('admin')
        .doc('accounts')
        .collection('admins')
        .doc(context.getters.user.id)
        .get()
        .then(data => {
          if (data.exists && data.data().credentials > 0) {
            console.log('User is an admin')
            context.commit('setIsAdmin', true)
            return true
          }
          context.commit('setIsAdmin', false)
        })
    },
    async loadProfile({ commit, dispatch, getters }) {
      commit('setLoading', true)
      let user = getters.user
      let uid = getters.user.id

      let ref = firebase
        .firestore()
        .collection('profile')
        .doc(uid)
      let profileDocRef = ref.onSnapshot(
        data => {
          let profile = { id: uid, user: getters.user.email }
          if (data.exists) {
            profile = {
              user: getters.user.email,
              ...data.data()
            }

            profile.id = data.id
            if (!profile.phone) {
              profile.phone = getters.user.phoneNumber
            }
          } else {
            console.log('Profile does not exist', profile)
          }

          if (!profile.email) {
            profile.email = getters.user.email
          }
          if (!profile.name) {
            profile.name = getters.user.name
          }
          if (profile.avatar) {
            profile.profilePicture = profile.avatar
          }
          if (!profile.profilePicture) {
            profile.profilePicture = getters.user.photoUrl
          }
          if (profile.type === 'chef') {
            firebase
              .firestore()
              .collection('admin')
              .doc('accounts')
              .collection('chefs')
              .doc(uid)
              .get()
              .then(data => {
                if (data.exists) {
                  let account = data.data()
                  commit('setChefRating', account)
                  commit('setApprovalStatus', account.approvalStatus)
                  let bank = data.data().bank || null
                  if (bank) {
                    bank.id = data.id
                  }
                  dispatch('setBankAccount', bank).then(() => {
                    commit('setLoading', false)
                  })
                } else {
                  dispatch('setBankAccount', null).then(() => {
                    commit('setLoading', false)
                  })
                }
                commit('setLoading', false)
              })
              .catch(error => {
                dispatch('setBankAccount', null).then(() => {
                  commit('setLoading', false)
                })
                console.error('Error', error)
                commit('setApprovalStatus', 'not available')
                commit('setLoading', false)
              })
          } else {
            commit('setLoading', false)
          }
          commit('setProfile', profile)
          dispatch('loadFoodTypes')
        },
        function(error) {
          commit('setLoading', false)
          //ref.set({ name: user.name, email: user.email, uid: uid })
          console.error(error)
          console.error(`unable to read profile`, user)
        }
      )

      commit('setProfileDocRef', profileDocRef)
    },

    loadUserProfile({ commit }, payload) {
      commit('setLoading', true)
      return firebase
        .firestore()
        .collection('profile')
        .doc(payload.uid)
        .get()
        .then(data => {
          let profile = {}
          if (data.exists) {
            profile = { ...data.data() }
            profile.id = data.id

            commit('setUserProfile', profile)
            commit('setLoading', false)
            return profile
          } else {
            console.log('Profile does not exist', profile)
          }

          commit('setLoading', false)
        })
        .catch(error => {
          commit('setLoading', false)
          console.log(error)
          return error
        })
    },
    loadUserProfileByHandle({ commit }, payload) {
      commit('setLoading', true)
      console.log('Loading profile by handle:', payload)
      return firebase
        .firestore()
        .collection('profile')
        .where('handle', '==', payload)
        .get()
        .then(querySnapshot => {
          let profile = {}
          querySnapshot.forEach(function(data) {
            profile = { id: data.id, ...data.data() }
            commit('setUserProfile', profile)
            commit('setLoading', false)
            return profile
          })

          //console.log('Profile does not exist', payload)

          commit('setLoading', false)
        })
        .catch(error => {
          commit('setLoading', false)
          console.log(error)
          return error
        })
    },
    getProfileByEmail(_, payload) {
      return firebase
        .firestore()
        .collection('profile')
        .where('email', '==', payload)
        .get()
        .then(querySnapshot => {
          let profile = null
          querySnapshot.forEach(function(data) {
            profile = { uid: data.id, ...data.data() }
            return profile
          })
          return profile
        })
    },
    getProfileByHandle(_, payload) {
      return firebase
        .firestore()
        .collection('profile')
        .where('handle', '==', payload)
        .get()
        .then(querySnapshot => {
          let profile = null
          querySnapshot.forEach(function(data) {
            profile = { uid: data.id, ...data.data() }
            return profile
          })
          return profile
        })
    },
    checkHandle({ getters }, payload) {
      let profile = getters.profile
      console.log('Checking handle:', payload)
      if (profile.handle === payload) {
        return false
      }
      let api = `https://derby.${process.env.VUE_APP_DOMAIN}`
      return axios.get(`${api}/handle?name=${payload}`).then(({ data }) => {
        if (data) {
          return data.code == 200
        }
      })
    },

    loadChefById({ commit, dispatch, state }, id) {
      commit('setLoading', true)
      commit('setDishList', null)
      commit('setAlbum', null)
      console.log('Loading chef by id', id)

      // check local state, if not found then call Firebase
      let local = state.nearbyChefs.find(c => c.id === id)
      if (local) {
        console.log('Loaded chef from local cache', local)
        commit('setLoading', false)
        commit('setChef', local)
        return local
      } else {
        return firebase
          .firestore()
          .collection('profile')
          .doc(id)
          .get()
          .then(data => {
            let chef = null
            if (data.exists) {
              chef = { id: data.id, ...data.data() }
              if (chef.profileStatus !== 'hide') {
                dispatch('getChefRating', id)
              } else {
                console.error('Chef is not approved or hidden', id)
                commit('setError', {
                  message: "Sorry, Chef's profile is hidden!"
                })
                commit('setLoading', false)
                return null
              }
            } else {
              console.log('No data exist for given profile', id)
            }
            commit('setChef', chef)
            commit('setLoading', false)
            return chef
          })
          .catch(error => {
            commit('setLoading', false)
            commit('setError', {
              message: 'Can not find Chef for given id' + error
            })
            console.log(error)
          })
      }
    },
    clearNearbyChefs({ commit }) {
      commit('clearNearbyChefs')
    },
    addNearbyChef({ commit, dispatch }, id) {
      console.log('Loading chef by id', id)
      firebase
        .firestore()
        .collection('profile')
        .doc(id)
        .get()
        .then(data => {
          let chef = null
          if (data.exists) {
            chef = { id: data.id, ...data.data() }

            if (chef.profileStatus !== 'hide') {
              dispatch('getChefRating', chef.id)
              commit('addNearbyChef', chef)
            } else {
              console.log(
                'Skip adding Chef  b/c its not approved or hidden',
                chef
              )
            }
          } else {
            console.error('Unable to load chef for given id', id)
          }
        })
        .catch(error => {
          commit('setError', {
            message: 'Can not find Chef for given id' + error
          })
          console.log(error)
        })
    },
    loadAlbum({ commit, getters }, uid) {
      const currentUID = uid || getters.user.id

      commit('setLoading', true)
      firebase
        .firestore()
        .collection('albums')
        .doc(currentUID)
        .get()
        .then(data => {
          const photos = []
          if (data.exists) {
            const list = data.data()
            if (list && list.album) {
              let obj = list.album
              for (let key in obj) {
                var url = obj[key].url || obj[key].imageUrl
                if (url) {
                  photos.push(obj[key])
                } else {
                  console.log('User ' + uid + ' has invalid album ' + data.id)
                }
              }
            }
          }
          commit('setLoading', false)
          commit('setAlbum', photos)
        })
        .catch(error => {
          commit('setLoading', false)
          commit('setError', error)
          console.log(error)
        })
    },
    removePhoto({ commit, getters }, payload) {
      commit('setLoading', true)
      const photoId = payload.photoId || payload,
        uid = payload.uid || getters.user.id
      let album = getters.album

      album = album.filter(a => a.id !== photoId)
      console.log(album, 'refreshed album!!!')
      firebase
        .firestore()
        .collection('albums')
        .doc(uid)
        .set({ album: album })
        .then(() => {
          commit('setLoading', false)
          commit('setAlbum', album)
        })
        .catch(error => {
          commit('setLoading', false)
          commit('setError', error)
          console.log(error)
        })
    },
    albumDescriptionUpdate({ getters }, payload) {
      firebase
        .firestore()
        .collection('albums')
        .doc(payload.uid || getters.user.id)
        .update({ album: payload.album })
    },
    youtubeLinkUpload({ commit, getters }, payload) {
      commit('setLoading', true)

      let uid = payload.uid || getters.user.id
      let album = getters.album
      let key = Math.random()
        .toString(36)
        .substring(6)
      // imageUrl will be deprecated
      album.push({
        id: key,
        url: payload.webUrl,
        imageUrl: payload.webUrl
      })
      commit('setAlbum', album)
      commit('setLoading', false)
      return firebase
        .firestore()
        .collection('albums')
        .doc(uid)
        .set({ album: album })
    },
    albumFileUpload({ commit, getters }, payload) {
      commit('setLoading', true)
      const { userid, file, description } = payload
      let uid = userid || getters.user.id
      let album = getters.album
      let putData = payload.src ? payload.src : payload.image

      let key = Math.random()
        .toString(36)
        .substring(6)
      const filename = file.name
      const ext = filename.slice(filename.lastIndexOf('.'))
      let ref = null

      if (putData.length > 10) {
        ref = firebase
          .storage()
          .ref('album')
          .child(uid)
          .child(key + ext)
          .putString(putData, 'data_url')
      } else {
        ref = firebase
          .storage()
          .ref('album')
          .child(uid)
          .child(key + ext)
          .put(payload.file)
      }
      return ref
        .then(fileData => {
          fileData.ref.getDownloadURL().then(imageUrl => {
            if (imageUrl.indexOf('mp4') < 0) {
              imageUrl = changeImageUrl(imageUrl, ThumbPrefix)
            }
            album.push({
              id: key,
              url: imageUrl,
              imageUrl: imageUrl,
              description: description
            })
            commit('setAlbum', album)
            return firebase
              .firestore()
              .collection('albums')
              .doc(uid)
              .set({ album: album })
          })
        })
        .then(() => {
          setInterval(() => {
            commit('setLoading', false)
          }, 1000 * 2)
        })
        .catch(error => {
          commit('setLoading', false)
          commit('setError', error)
          console.log(error)
        })
    },
    albumUrlUpload({ commit, getters }, payload) {
      commit('setLoading', true)
      let uid = payload.uid || getters.user.id
      let album = getters.album
      let key = Math.random()
        .toString(36)
        .substring(6)
      // imageUrl will be deprecated
      album.push({
        id: key,
        url: payload.imageUrl,
        imageUrl: payload.imageUrl
      })

      commit('setAlbum', album)
      return firebase
        .firestore()
        .collection('albums')
        .doc(uid)
        .set({ album: album })
        .then(() => {
          setInterval(() => {
            commit('setLoading', false)
          }, 1000 * 2)
        })
        .catch(error => {
          commit('setLoading', false)
          commit('setError', error)
          console.log(error)
        })
    },
    uploadMedia({ commit, getters }, payload) {
      commit('setLoading', true)
      const { uid, img, file, type } = payload
      let userid = uid || getters.user.id
      const ext = file.name.slice(file.name.lastIndexOf('.'))
      let path = 'profile/' + userid + `/${type}/` + userid + ext
      console.log('Uploading ', path)
      let ref = null
      if (!file.type.includes('video/')) {
        ref = firebase
          .storage()
          .ref(path)
          .putString(img, 'data_url')
      } else {
        ref = firebase
          .storage()
          .ref(path)
          .putString(img)
      }
      return ref
        .then(fileData => {
          return fileData.ref.getDownloadURL().then(imageUrl => {
            console.log(imageUrl, 'imageURL!!!')
            if (imageUrl.indexOf('mp4') < 0) {
              imageUrl = changeImageUrl(imageUrl, ThumbPrefix)
            }

            return firebase
              .firestore()
              .collection('profile')
              .doc(userid)
              .update({ [type]: imageUrl })
          })
        })
        .then(() => {
          commit('setLoading', false)
          commit('setMessage', {
            title: 'Uploading',
            body: 'It may take few seconds.'
          })
        })
        .catch(error => {
          commit('setLoading', false)
          commit('setError', error)
          console.error('error: ', error)
        })
    },
    uploadBusinessLogo({ commit, getters }, payload) {
      let file = payload.file
      commit('setLoading', true)
      let uid = payload.uid || getters.user.id
      const filename = file.name
      const ext = filename.slice(filename.lastIndexOf('.'))
      let path = 'profile/' + uid + '/' + uid + ext

      return firebase
        .storage()
        .ref(path)
        .put(file)
        .then(fileData => {
          return fileData.ref.getDownloadURL().then(imageUrl => {
            console.log(imageUrl, 'updated Logo!!!!!!!')
            return firebase
              .firestore()
              .collection('profile')
              .doc(uid)
              .update({ businessLogo: imageUrl })
          })
        })
        .then(() => {
          commit('setLoading', false)
        })
        .catch(error => {
          console.log('Failed to Uploaded ', path)
          commit('setLoading', false)
          commit('setError', error)
          console.error('error: ', error)
        })
    },
    uploadUserAvatar({ commit, getters }, payload) {
      let file = payload.file
      let type = payload.type
      commit('setLoading', true)
      let uid = payload.uid || getters.user.id
      const filename = file.name
      const ext = filename.slice(filename.lastIndexOf('.'))
      let path = 'profile/' + uid + `/${type}/` + uid + ext

      return firebase
        .storage()
        .ref(path)
        .put(file)
        .then(fileData => {
          return fileData.ref.getDownloadURL().then(imageUrl => {
            if (imageUrl.indexOf('mp4') < 0) {
              imageUrl = changeImageUrl(imageUrl, ThumbPrefix)
            }
            return firebase
              .firestore()
              .collection('profile')
              .doc(uid)
              .update({ [type]: imageUrl })
          })
        })
        .then(() => {
          commit('setLoading', false)
        })
        .catch(error => {
          console.log('Failed to Uploaded ', path)
          commit('setLoading', false)
          commit('setError', error)
          console.error('error: ', error)
        })
    },
    updateAccount({ commit }, payload) {
      console.log(payload)
      let id = payload.id
      let type = payload.type
      delete payload.id
      delete payload.type
      commit('setLoading', true)

      return firebase
        .firestore()
        .collection('admin')
        .doc('accounts')
        .collection(type)
        .doc(id)
        .update(payload)
        .then(() => {
          console.log('Updated Account', id, 'with', payload)
          commit('setLoading', false)
        })
        .catch(error => {
          commit('setLoading', false)
          commit('setError', error)
          console.log(error)
        })
    },
    verifyEmailByAdmin(ctx, payload) {
      payload.updatedTime = moment.utc().valueOf()
      payload.type = 'email'
      console.log('Verifying  email for:', payload)
      firebase
        .firestore()
        .collection('admin')
        .doc('accounts')
        .collection('verify')
        .doc(payload.uid)
        .set(payload)
        .then(() => {
          ctx.commit('setMessage', {
            title: 'Submitted',
            body: 'Email should be verified in few seconds.'
          })
        })
        .catch(e => {
          console.error(e)
        })
    }
  },
  getters: {
    profile(state) {
      return state.profile
    },
    userProfile(state) {
      return state.userProfile
    },
    isChef(state) {
      return state.profile ? state.profile.type === 'chef' : false
    },

    album(state) {
      return state.album
    },
    chef(state) {
      return state.chef
    },
    hasAddress(state) {
      return (
        state.profile &&
        state.profile.addresses &&
        state.profile.addresses.length > 0
      )
    },
    validProfile(state, getters) {
      // add acceptedTerms logic
      return getters.hasAddress && getters.user && getters.user.emailVerified
    },
    approvalStatus(state) {
      return state.approvalStatus
    },
    // deprecated (use approvalStatus)
    chefApprovalStatus(state) {
      return state.approvalStatus || 'pending'
    },
    nearbyChefs(state) {
      //return state.nearbyChefs.filter( c => c.profileStatus !== 'hide')
      return state.nearbyChefs
    },
    nearbyChef(state) {
      return id => state.nearbyChefs.find(c => c.id === id)
    },
    defaultAddress(state) {
      if (!state.profile) {
        return null
      }
      let address = globalMixin.methods.profileAddress(state.profile, 'pickup')

      return address
    },
    isAdmin: state => {
      return state.isAdmin
    },
    adminProducers(state) {
      return state.adminProducers
    },
    profiles(state) {
      return state.profiles
    }
  }
}
