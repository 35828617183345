<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12">
        <v-sheet class="shadow pa-8" v-if="modifier">
          <v-row align="end">
            <v-col>
              <span class="main_text--text">
                {{
                  $route.params.mode === 'new'
                    ? $t('Common.add_modifier')
                    : $t('Common.edit_modifier')
                }}
              </span>
            </v-col>
          </v-row>
          <v-row class="my-4" align="center">
            <v-col cols="12" sm="5" v-if="dishSelected">
              <span class="grey--text subtitle-2"
                >{{ $t('Common.product_name') }}:</span
              >
              <span class="main_text--text subtitle-2">{{
                dishSelected.name
              }}</span>
            </v-col>
            <v-col>
              <v-btn
                color="takein"
                small
                :ripple="false"
                depressed
                class="mr-2 white--text"
                @click="saveModifier()"
                :disabled="!canSave"
                >{{ $t('Common.save_close') }}</v-btn
              >
              <v-btn
                small
                dark
                color="grey"
                :ripple="false"
                depressed
                class="mr-6"
                @click="back()"
              >
                <v-icon size="16" left>mdi-close</v-icon>
                {{ $t('Common.cancel') }}
              </v-btn>
              <v-btn
                color="error"
                small
                dark
                :ripple="false"
                depressed
                class="mr-2"
                @click="deleteModifier()"
              >
                <v-icon size="16" left>mdi-delete</v-icon>
                {{ $t('Common.delete') }}
              </v-btn>
            </v-col>
          </v-row>
          <v-row align="end">
            <v-col cols="6">
              <v-text-field
                color="takein"
                :label="$t('Common.modifier_name')"
                :hide-details="true"
                v-model="modifier.name"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3">
              <v-combobox
                v-model="modifier.min"
                :items="minSelections"
                :label="$t('Common.minimun_required')"
                :hide-details="true"
                color="takein"
                class="mb-4"
              ></v-combobox>
            </v-col>
            <v-col cols="3" v-if="modifier.min != null">
              <v-combobox
                v-model="modifier.max"
                :items="maxSelections"
                :label="$t('Common.maximum_selection')"
                :hide-details="true"
                color="takein"
                class="mb-4"
              ></v-combobox>
            </v-col>
          </v-row>

          <v-row class="mt-4">
            <v-col>
              <span class="main_text--text mr-4">{{
                $t('Common.modifier_options')
              }}</span>
              <v-btn
                class="mx-4"
                fab
                dark
                small
                depressed
                color="takein"
                @click.native.stop="addOption"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row
            class="my-4"
            v-if="modifier && modifier.options && modifier.options.length > 0"
          >
            <v-col>
              <template v-for="(option, index) in modifier.options">
                <v-col cols="12" :key="index" class="py-1">
                  <v-card>
                    <v-card-text>
                      <v-row align="center">
                        <v-col cols="7">
                          <v-text-field
                            color="takein"
                            :label="$t('Common.name_of_options')"
                            placeholder="(i.e. Avocado)"
                            v-model="modifier.options[index].name"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="2">
                          <v-currency-field
                            color="takein"
                            :label="$t('Common.optional_fee')"
                            v-bind="currency_config"
                            v-model="modifier.options[index].price"
                          ></v-currency-field>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col cols="2">
                          <v-checkbox
                            small
                            light
                            color="takein"
                            :label="$t('Common.available')"
                            v-model="modifier.options[index].available"
                          ></v-checkbox>
                        </v-col>
                        <v-col cols="1">
                          <v-icon color="red" @click="deleteOption(index)"
                            >delete</v-icon
                          >
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </template>
            </v-col>
          </v-row>
        </v-sheet>
      </v-col>
    </v-row>

    <delete-confirmation-dialog
      ref="deleteConfirmationDialog"
      :heading="deleteHeading"
      :message="deleteMessage"
      @onConfirm="onDeleteModifier"
    ></delete-confirmation-dialog>
    <v-snackbar
      v-model="snackbar"
      :bottom="true"
      :color="snackType"
      :timeout="snackTimeout"
    >
      {{ snackMessage }}
      <v-btn color="white" text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  components: {},
  data() {
    return {
      snackTimeout: 2500,
      snackbar: false,
      snackMessage: '',
      snackType: 'success',
      saved: false,
      deleteHeading: this.$t('Common.delete_modifier'),
      deleteMessage: this.$t('Common.delete_modifier_info'),
      modifier: {
        name: null,
        options: [],
        min: null,
        max: null
      },
      currency_config: {
        decimal: '.',
        thousands: ',',
        prefix: '$ ',
        precision: 2,
        masked: false,
        allowBlank: false,
        min: 0.0,
        max: Number.MAX_SAFE_INTEGER
      },
      dishSelected: null
    }
  },
  computed: {
    ...mapGetters({
      profile: 'profile',
      dishList: 'dishList',
      dish: 'dish'
    }),
    minSelections() {
      return [...Array(21).keys()]
    },
    maxSelections() {
      if (this.modifier && this.modifier.min) {
        return [...Array(11).keys()].map(x => x + this.modifier.min)
      }
      return []
    },
    canSave() {
      if (!this.modifier) return false
      return this.modifier.name && this.modifier.min && this.modifier.max
    }
  },
  mounted() {
    console.log(this.$route.params, this.dish, 'routing!!!')
    const that = this
    if (this.dish) {
      this.dishSelected = this.dish
      if (this.$route.params.id) {
        this.modifier = this.dishSelected.modifiers
          ? this.dishSelected.modifiers[this.$route.params.id]
          : null
        if (!this.modifier) {
          this.$store.dispatch('loadModifiers').then(list => {
            this.modifier = list[that.$route.params.id]
          })
        }
      }
      this.saved = true
    } else {
      if (this.$route.params.id) {
        this.$store.dispatch('loadModifiers').then(list => {
          this.modifier = list.filter(
            item => item.id === that.$route.params.id
          )[0]
        })
      }
    }
  },
  methods: {
    addOption() {
      this.modifier.options = this.modifier.options || []
      this.modifier.options.unshift({
        id: `${Math.floor(Math.random()) + new Date().getTime()}`,
        name: '',
        price: 0.0,
        available: true
      })
    },
    saveModifier() {
      if (this.canSave) {
        this.$store.dispatch('saveModifier', this.modifier).then(id => {
          this.snackMessage = 'Option saved successfully'
          this.snackType = 'success'
          this.snackbar = true
          // this.$store.dispatch('setMessage', {
          //   title: 'Saved',
          //   body: 'Option saved successfully'
          // })
          if (id) {
            this.modifier.id = id
          }
          this.saved = true
          this.back()
        })
      }
    },
    deleteModifier() {
      this.deleteHeading = this.$t('Common.delete_modifier')
      this.$refs.deleteConfirmationDialog.openDialog()
    },
    onDeleteModifier() {
      this.$store.dispatch('deleteModifier', this.modifier.id).then(() => {
        this.$refs.deleteConfirmationDialog.close()
        this.$router.replace('/profile/wizard/menus/manage_modifiers')
      })
    },
    deleteOption(index) {
      console.log('Deleting option:', index)
      this.modifier.options.splice(index, 1)
    },
    back() {
      if (this.saved && this.canSave) {
        this.$router.replace('/profile/wizard/menus/manage_modifiers')
      } else {
        this.deleteHeading = this.$t('Common.cancel_without_saving')
        this.$refs.deleteConfirmationDialog.openDialog()
      }
    }
  }
}
</script>
<style scoped>
.shadow {
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
    0px 4px 8px rgba(0, 0, 0, 0.04);
}
</style>
