<template>
  <div>
    <v-row v-if="showApprovalPendingMessage">
      <v-col cols="12" sm="10" v-if="chefApprovalStatus == 'pending'">
        <v-alert
          outlined
          type="error"
          color="indigo darken-2"
          :value="true"
          icon="error_outline"
          v-html="$t('Chef.account_pending_message')"
        >
        </v-alert>
      </v-col>
    </v-row>

    <v-row v-if="profile.profileStatus === 'hide' && !isAdmin">
      <v-col cols="12" sm="10">
        <v-alert
          outlined
          type="error"
          color="red darken-2"
          :value="true"
          icon="error_outline"
        >
          <span v-html="$t('Chef.profile_hidden')"></span>
        </v-alert>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      chefApprovalStatus: 'chefApprovalStatus',
      isChef: 'isChef',
      user: 'user',
      isAdmin: 'isAdmin',
      profile: 'profile',
      validProfile: 'validProfile'
    }),
    showApprovalPendingMessage() {
      return (
        this.isChef &&
        this.user.emailVerified &&
        this.chefApprovalStatus !== 'approved'
      )
    }
  }
}
</script>
