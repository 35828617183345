/**
 * Core Invite
 */
import firebase from '@/firebase-init'
import moment from 'moment'


export default {
  state: {

  },
  mutations: {

  },
  actions: {
    invite: async ({ commit }, payload) => {
      payload.status = 'submit'
      payload.updatedAt = new Date().getTime()
      firebase
        .firestore()
        .collection('invitation')
        .add(payload)
        .then((d) => {
          // add points
          // dispatch('updatePoints', { type: 'invite' })
          console.log('invitation sent', payload)
          return d
        })
        .catch(error => {
          commit('setError', error)
          console.log(error)
        })
    },
    // checks db if invite from the user has been sent to the same person
    // if invite has not been accepted or ignored, it will return true
    inviteExists: async ({ commit }, payload) => {
      return firebase
        .firestore()
        .collection('invitation')
        .where('to', '==', payload.to)
        .where('sender', '==', payload.sender)
        .where('status', '==', 'sent')
        .get()
        .then(doc => {
          return !doc.empty
        })
        .catch(error => {
          commit('setError', error)
          console.log(error)
        })
    },
    getInvites: async ({ commit, getters }, payload) => {
      let email = getters.user.email
      let q = 'to'
      if (payload.type === 'out'){
        q = 'from.email'
      }
      return firebase
        .firestore()
        .collection('invitation')
        .where(q, '==', email)
        .where('status', '==', 'sent')
        .get()
        .then(querySnapshot => {
          const list = []
          if (querySnapshot.size > 0) {
            querySnapshot.forEach(data => {
              if (data.exists) {
                const obj = data.data()
                obj.id = data.id
                list.push(obj)
              } else {
                console.log('No data exists')
              }
            })
          }
          return list
        })
        .catch(e => {
          commit('setLoading', false)
          console.error(e)
          commit('setError', { message: 'Unable to accept this invite!' })
        })
    },
    ignoreInvite: async ({ commit, getters }, payload) => {
      commit('setLoading', true)
      let uid = getters.user.id
      await firebase
        .firestore()
        .collection('invitation')
        .doc(payload)
        .update({
          status: 'ignored',
          uid: uid,
          reviewDate: new Date().getTime()
        })
        .then(() => {
          commit('setLoading', false)
        })
        .catch(e => {
          commit('setLoading', false)
          console.error(e)
          commit('setError', { message: 'Unable to update this invite!' })
        })
    },
    acceptInvite: async ({ commit, dispatch, getters }, payload) => {
      console.log('Accepting Invite', payload)
      commit('setLoading', true)

      let uid = getters.user.id
      await firebase
        .firestore()
        .collection('invitation')
        .doc(payload)
        .update({
          status: 'accepted',
          uid: uid,
          acceptDate: moment().utc().valueOf(),
        })
        .then(() => {})
        .catch(e => {
          commit('setLoading', false)
          console.error(e)
          commit('setError', { message: 'Unable to accept this invite!' })
        })

      return firebase
        .firestore()
        .collection('invitation')
        .doc(payload)
        .get()
        .then(d => {
          if (d.data()) {
            console.log('Loading sender', d.data())
            return firebase
              .firestore()
              .collection('profile')
              .doc(d.data().sender)
              .get()
              .then(u => {
                commit('setLoading', false)

                let profile = { ...u.data() }
                profile.id = u.id
                dispatch('requestFollow', {
                  from: uid,
                  to: u.id,
                  invitation_id: payload
                })
                return profile
              })
          }
          commit('setLoading', false)
        })
        .catch(e => {
          commit('setLoading', false)
          console.error(e)
          commit('setError', { message: 'Unable to accept this invite!' })
        })

      // Firebase asks to create index
    },
  },
  getters: {

  }
}
