import firebase, { analytics } from '@/firebase-init'
import moment from 'moment'
import Vue from 'vue'
const delay = ms => new Promise(res => setTimeout(res, ms))

export default {
  state: {
    order: null,
    orders: []
  },
  mutations: {
    setOrder(state, payload) {
      state.order = payload
    },
    addOrder(state, payload) {
      if (!state.order) {
        state.orders = []
      }
      state.orders.push(payload)
    },
    setOrders(state, payload) {
      state.orders = payload
    },
    setOperatingCities(state, payload) {
      state.operatingCities = payload
    },

    setDeliverySubsidy(state, payload) {
      state.deliverySubsidy = payload
    }
  },
  actions: {
    submitOrder({ commit, getters, dispatch }, payload) {
      if (!getters.user) {
        console.error('error, not user detected')
        return
      }
      let uid = getters.user.id
      if (uid === payload.chefId) {
        commit('setError', {
          message: 'You can not request order for your own product!'
        })
        return
      }
      payload.uid = uid
      payload.buyerUserId = uid
      payload.orderDate = new Date().getTime()
      console.log('Submitting order', payload)
      //commit('setLoading', true)

      if (uid == uid) {
        return firebase
          .firestore()
          .collection('orders')
          .doc(uid)
          .collection('buyer-orders')
          .add(payload)
          .then(data => {
            commit('setLoading', false)
            payload.id = data.id
            commit('setOrder', payload)
            if (payload.payment && payload.orderType === 'now') {
              payload.payment.orderId = data.id
              payload.payment.chefId = payload.chefId
              payload.payment.description = payload.foodName
              return dispatch('submitCharge', payload.payment)
            }
            return payload
          })
          .catch(error => {
            commit('setLoading', false)
            commit('setError', error)
            console.log(error)
            return null
          })
      } else {
        return dispatch('submitCharge', payload.payment)
      }
    },

    submitRefund(context, payload) {
      let uid =
        payload.uid && context.getters.isAdmin
          ? payload.uid
          : context.getters.user.id

      if (!uid) uid = payload.uid
      console.log('submitRefund for ' + uid, payload)
      analytics.logEvent('submit_refund', payload)
      //commit('setLoading', true)
      firebase
        .firestore()
        .collection('profile')
        .doc(uid)
        .collection('refund')
        .add(payload)
    },
    submitExtraCharge(context, payload) {
      console.log('submit extra charge for ', payload)
      analytics.logEvent('submit_charge', payload)
      payload.status = 'new'
      //commit('setLoading', true)
      return firebase
        .firestore()
        .collection('profile')
        .doc(payload.uid)
        .collection('extraCharge')
        .add(payload)
    },
    setOrderStatus({ commit, getters }, payload) {
      let uid = payload.uid ? payload.uid : getters.user.id
      const collection = payload.collection
        ? payload.collection
        : 'seller-orders'
      payload.actionPlatform = 'web'
      console.log('Setting Order status', payload)
      analytics.logEvent('biz_order_save', {
        value: payload.status,
        uid: payload.uid,
        id: payload.id,
        category: 'order',
        action: 'save'
      })
      //commit('setLoading', true)
      const orderRef = firebase
        .firestore()
        .collection('orders')
        .doc(uid)
        .collection(collection)
        .doc(payload.id)

      let req = {
        updatedTime: new Date().getTime()
      }
      if (payload.status) {
        req.status = payload.status
      }

      if (payload.action) {
        req.action = payload.action
      }
      if (payload.delivery_status) {
        req.delivery_status = payload.delivery_status
        req.delivery = req.delivery || {}
        req.cooking_status = payload.delivery_status
        req.delivery.delivery_status = payload.delivery_status
      }
      if (payload.leadTime) {
        req.leadTime = payload.leadTime
      }
      if (payload.archive) {
        req.active = false
      }

      if (payload.newDeliveryTime) {
        req.delivery.newDeliveryTime = payload.newDeliveryTime
      }
      if (payload.deliveryDate) {
        req.deliveryDate = payload.deliveryDate
      }
      if (payload.response) {
        req.chefResponse = payload.response
      }
      if (payload.pickupReadyTime) {
        req.pickupReadyTime = payload.pickupReadyTime
      }

      if (payload.acceptDate) {
        req.acceptDate = payload.acceptDate
      }
      // there is a refund object
      if (payload.refund) {
        req.payment_status = 'refunding'
        req.refund = payload.refund
      }
      if (payload.delivery_track) {
        req.delivery.delivery_track = payload.delivery_track
      }
      if (payload.courier) {
        req.delivery.courier = payload.courier
      }

      console.log('Setting Order status', req)
      Vue.gtm.trackEvent({
        event: 'takein_event', // Event type [default = 'interaction'] (Optional)
        category: 'order',
        action: 'set_status',
        value: payload.status,
        method: 'set_status',
        payload: { method: 'set_status', name: payload.status }
      })

      return orderRef
        .set(req, { merge: true })
        .then(function() {
          commit('setLoading', false)
          console.log('Document successfully updated!')
        })
        .catch(function(error) {
          // The document probably doesn't exist.
          commit('setLoading', false)
          console.error('Error updating document: ', error)
        })
    },
    setCookingStatus(context, payload) {
      let uid = context.getters.user.id
      return firebase
        .firestore()
        .collection('orders')
        .doc(uid)
        .collection('seller-orders')
        .doc(payload.id)
        .update({ cooking_status: payload.cooking_status })
        .then(function() {
          context.commit('setLoading', false)
          console.log('Document successfully updated!')
        })
        .catch(function(error) {
          // The document probably doesn't exist.
          context.commit('setLoading', false)
          console.error('Error updating document: ', error)
        })
    },
    async findOrders(context, payload = {}) {
      let ref = firebase
        .firestore()
        .collectionGroup(payload.collection || 'seller-orders')
        .orderBy('deliveryDateTime', 'desc')
        .limit(100)

      if (payload.recent) {
        const now = moment
          .utc()
          .add(-(payload.pastDay || 1), 'd')
          .valueOf()
        ref = ref.where('deliveryDateTime', '>=', now)
      }
      if (payload.active) {
        console.log('Loading active orders')
        ref = ref.where('status', 'in', ['new', 'accepted'])
      }
      console.log('find order by:', payload)

      return await ref.get().then(querySnapshot => {
        const list = []
        querySnapshot.forEach(data => {
          if (data.exists) {
            const obj = data.data()
            obj.id = data.id
            list.push(obj)
          }
        })
        console.log('all producer loaded orders :', list.length)
        return list
      })
    },
    async getOrdersFromRange(context, payload = {}) {
      let ref = firebase
        .firestore()
        .collectionGroup(payload.collection || 'seller-orders')
        .orderBy('deliveryDateTime', 'desc')
        .limit(100)

      const { from, to, range } = payload
      if (from && to && range) {
        ref = ref
          .where('deliveryDateTime', '>=', new Date(from).getTime())
          .where('deliveryDateTime', '<=', new Date(to).getTime())
      }
      console.log('get orders by date range:', payload)

      return await ref.get().then(querySnapshot => {
        const list = []
        querySnapshot.forEach(data => {
          if (data.exists) {
            const obj = data.data()
            obj.id = data.id
            if (range.includes(obj.deliveryDate)) {
              list.push(obj)
            }
          }
        })
        console.log('all producer loaded orders by date ranges :', list.length)
        return list
      })
    },
    loadSellerOrders(context, payload = {}) {
      let ref = firebase
        .firestore()
        .collectionGroup('seller-orders')
        .orderBy('deliveryDateTime', 'desc')
        .limit(100)
      const now = moment
        .utc()
        .add(payload.active ? -2 : -30, 'd')
        .valueOf()
      if (payload.recent) {
        ref = ref.where('deliveryDateTime', '>=', now)
      }
      if (payload.active) {
        console.log('Loading active orders')
        ref = ref.where('status', 'in', ['new', 'accepted'])
      }
      /*if (!payload.all) {
        ref = ref.where('active', '==', true)
      }*/

      return ref.onSnapshot(function(querySnapshot) {
        const list = []
        querySnapshot.forEach(data => {
          if (data.exists) {
            const obj = data.data()
            obj.id = data.id
            list.push(obj)
          }
        })
        console.log('all producer loaded orders :', list.length)
        context.commit('setOrders', list)
      })
    },
    fetchOrders(context, payload = {}) {
      console.log('Payload: ', payload)
      if (payload.pid) {
        return firebase
          .firestore()
          .collection('orders')
          .doc(payload.pid)
          .collection('seller-orders')
          .where('buyerUserId', '==', payload.uid)
          .orderBy('updatedTime', 'desc')
          .limit(100)
          .get()
          .then(querySnapshot => {
            const list = []
            querySnapshot.forEach(data => {
              if (data.exists) {
                const obj = data.data()
                obj.id = data.id
                list.push(obj)
              }
            })
            return list
          })
      }
      return firebase
        .firestore()
        .collection('orders')
        .doc(payload.uid)
        .collection('buyer-orders')
        .orderBy('updatedTime', 'desc')
        .limit(100)
        .get()
        .then(querySnapshot => {
          const list = []
          querySnapshot.forEach(data => {
            if (data.exists) {
              const obj = data.data()
              obj.id = data.id
              list.push(obj)
            }
          })
          return list
        })
    },
    async loadProducerOrdersFromRange(context, payload) {
      const { uid, from, to, range } = payload
      let ref = firebase
        .firestore()
        .collection('orders')
        .doc(uid)
        .collection('seller-orders')
        .orderBy('deliveryDateTime', 'desc')
        .limit(100)

      ref = ref
        .where('deliveryDateTime', '>=', new Date(from).getTime())
        .where('deliveryDateTime', '<=', new Date(to).getTime())
      context.commit('setLoading', true)

      return await ref.get().then(querySnapshot => {
        const list = []
        querySnapshot.forEach(data => {
          if (data.exists) {
            const obj = data.data()
            obj.id = data.id
            if (range.includes(obj.deliveryDate)) {
              list.push(obj)
            }
          }
        })
        console.log('loaded orders by date ranges :', list.length)
        context.commit('setLoading', false)
        return list
      })
    },
    loadOrders(context, payload = {}) {
      let uid =
        payload.uid && context.getters.isAdmin
          ? payload.uid
          : context.getters.user.id

      if (!uid) uid = payload.uid

      let collection = 'seller-orders'

      let ref = firebase
        .firestore()
        .collection('orders')
        .doc(uid)
        .collection(collection)
        .orderBy('updatedTime', 'desc')
        .limit(100)

      context.commit('setLoading', true)
      let observer = ref.onSnapshot(querySnapshot => {
        let list = []
        querySnapshot.forEach(data => {
          if (data.exists) {
            const obj = data.data()
            obj.id = data.id
            list.push(obj)
          }
        })
        console.log(`loaded orders for ${uid}:`, list.length)
        context.commit('setOrders', list)
        context.commit('setLoading', false)
      })

      return observer
    },
    loadOrder({ commit, getters, dispatch }, payload) {
      const uid = payload.uid && getters.isAdmin ? payload.uid : getters.user.id

      let collection = 'buyer-orders'
      if (payload.source) {
        collection = payload.source + '-orders'
      } else {
        if (getters.isChef) {
          collection = 'seller-orders'
        }
      }
      analytics.logEvent('biz_order_load', {
        value: collection,
        category: 'order',
        action: 'load',
        uid: uid,
        id: payload.orderId
      })
      console.log(`loading order ${uid}/${payload.orderId} from ${collection}`)
      commit('setLoading', true)
      let observer = firebase
        .firestore()
        .collection('orders')
        .doc(uid)
        .collection(collection)
        .doc(payload.orderId)
        .onSnapshot(data => {
          if (data.exists) {
            const order = data.data()
            order.id = data.id
            //dispatch('findById', order.foodId)
            // if (order.buyerUserId) {
            //   dispatch('findById', order.foodId)
            // }
            order.delivery = order.delivery || {}
            order.payment = order.payment || {}
            commit('setOrder', order)
            commit('setLoading', false)
            console.log('Order loaded', payload.orderId)
            return order
          } else {
            console.error(`Unable to load order ${uid}/${payload.orderId}`)
            dispatch('setError', {
              message: 'Unable to load the order ' + payload.orderId
            })
          }
          commit('setLoading', false)
        })
      return observer
    },

    loadOrdersOfDish({ commit, getters }, dishId) {
      commit('setLoading', false)
      let uid = getters.user.id
      commit('setOrders', [])
      return firebase
        .firestore()
        .collection('dish')
        .doc(dishId)
        .get()
        .then(data => {
          if (data.exists) {
            const dish = data.data()
            if (dish.meta && dish.meta.orders) {
              dish.meta.orders.forEach(orderId => {
                firebase
                  .firestore()
                  .collection('orders')
                  .doc(uid)
                  .collection('seller-orders')
                  .doc(orderId)
                  .get()
                  .then(data => {
                    if (data.exists) {
                      const order = data.data()
                      order.id = data.id

                      commit('addOrder', order)
                    }
                  })

                  .catch(error => {
                    console.log(error)
                    commit('setLoading', false)
                    commit('setOrders', [])
                    commit('setError', error)
                  })
              })
            }
          } else {
            console.log(`Requested dish does not exist  ${dishId}`)
            //commit('setError', { message: 'Requested food does not exist' })
          }
          commit('setLoading', false)
          return null
        })
        .catch(error => {
          console.log(error)
          commit('setLoading', false)
          commit('setError', error)
        })
    },
    async setDeliveryRequest(context, payload) {
      let uid = payload.uid || context.getters.user.id
      let code = payload.code
      console.log('setDeliveryRequest to', payload)
      await firebase
        .firestore()
        .collection('orders')
        .doc(uid)
        .collection('seller-orders')
        .doc(payload.id)
        .set({ delivery: { action: code } }, { merge: true })
        .catch(e => {
          console.error(e)
        })
    },
    async getDeliveryTrack(_, payload) {
      return await firebase
        .firestore()
        .collection(
          `orders/${payload.uid}/seller-orders/${payload.orderId}/track`
        )
        .orderBy('updated', 'desc')
        .get()
        .then(querySnapshot => {
          const list = []
          querySnapshot.forEach(data => {
            if (data.exists) {
              const obj = data.data()
              obj.id = data.id
              list.push(obj)
            }
          })
          return list
        })
    },
    async resubmitDeliveryRequest(context, payload) {
      payload.code = 'resubmitting'
      await context.dispatch('setDeliveryRequest', payload)
      payload.delivery_status = 'rescheduled'

      payload.status = 'accepted'
      payload.delivery_track = {}
      payload.courier = {}
      payload.newDeliveryTime = moment.utc().valueOf()
      await context.dispatch('setOrderStatus', payload)

      await delay(3000)

      payload.code = 'resubmit'
      await context.dispatch('setDeliveryRequest', payload)
    },
    async loadOrderReview(ctx, payload) {
      return await firebase
        .firestore()
        .collection('reviews')
        .doc(payload.orderId)
        .get()
        .then(d => d.data())
    },
    async loadReviews(ctx, payload) {
      console.log('Loading orders of ', payload)
      return await firebase
        .firestore()
        .collection('reviews')
        .where('producer_id', '==', payload.uid)
        .get()
        .then(data =>
          data.docs.map(d => ({
            id: d.id,
            ...d.data()
          }))
        )
    }
    /*getOrderFees({ commit }) {
      
      const ref = firebase
        .firestore()
        .collection('admin')
        .doc('settings')

      return ref
        .get()
        .then(settings => {
          if (settings.exists) {
            console.log('Successfully Retrieved Order Fee Values')
            commit('setLoading', false)
            commit(
              'setDeliverySubsidy',
              settings.data().default_delivery_subsidy
            )
            
          } else {
            console.log('Did Not Find Any Admin Fee Values')
          }
        })
        .catch(error => {
          commit('setLoading', false)
          commit('setError', error)
          console.log(error)
        })
    }*/
  },
  getters: {
    order: state => {
      return state.order
    },
    activeOrders: state => {
      // order stays active in n minutes until
      const minutes = 30
      const timeLimit = moment
        .utc()
        .add(-minutes, 'm')
        .valueOf()
      let orders = state.orders
        ? state.orders
            .filter(o => {
              return (
                o.status === 'accepted' ||
                o.status === 'confirmed' ||
                o.updatedTime >= timeLimit
              )
            })
            .filter(p => p.status != 'new')
        : []
      console.log(orders)
      if (orders.length == 0) {
        return []
      } else {
        return orders.sort((a, b) =>
          a.deliveryDateTime > b.deliveryDateTime ? -1 : 1
        )
      }
    },
    archiveOrders: state => {
      const minutes = 1
      const timeLimit = moment
        .utc()
        .add(-minutes, 'm')
        .valueOf()
      let orders = state.orders
        ? state.orders.filter(o => {
            return (
              !(
                o.status === 'accepted' ||
                o.status === 'confirmed' ||
                o.status === 'payment_accepted' ||
                o.status === 'submitted' ||
                o.status === 'new'
              ) && o.updatedTime < timeLimit
            )
          })
        : []
      if (orders.length == 0) {
        return []
      } else {
        return orders.sort((a, b) =>
          a.deliveryDateTime > b.deliveryDateTime ? -1 : 1
        )
      }
    },
    newOrders: state => {
      let orders = state.orders
        ? state.orders.filter(o => {
            return o.status === 'new'
          })
        : []
      if (orders.length == 0) {
        return []
      } else {
        return orders.sort((a, b) =>
          a.deliveryDateTime > b.deliveryDateTime ? -1 : 1
        )
      }
    },
    orders: state => {
      return state.orders
    }
  }
}
