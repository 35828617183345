<template>
  <v-sheet class="d-flex flex-column pa-4 shadow mb-8 mt-4" v-if="profile">
    <ValidationObserver ref="form" v-slot="{ invalid }">
      <v-row>
        <v-col cols="12" md="6">
          <profile-admin-operating-city
            v-if="isAdmin"
            :profile="profile"
            @changeCity="onChangeCity"
          ></profile-admin-operating-city>
        </v-col>
        <v-col cols="12" md="6">
          <profile-admin-community
            v-if="isAdmin"
            :profile="profile"
            :baseCity="selectedCity"
          ></profile-admin-community>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4" md="3" lg="2">
          <ValidationProvider
            v-slot="{ errors }"
            name="TakeIn Boost"
            rules="required|min_value:0|max_value:100"
          >
            <v-text-field
              label="TakeIn Search Boost"
              v-model="profile.meta.takein_boost"
              hint="between 0-100, higher number the producer shows in top of result"
              persistent-hint
              type="number"
              :error-messages="errors"
            ></v-text-field>
          </ValidationProvider>
        </v-col>
      </v-row>
      <v-row justify="space-between">
        <v-col cols="4" md="2">
          <v-btn small color="info" :disabled="invalid" @click.prevent="save"
            >Save</v-btn
          >
        </v-col>
        <v-col cols="4" md="6">
          <SlimTakeInStatus :profile="profile" />
        </v-col>
      </v-row>
    </ValidationObserver>
  </v-sheet>
</template>

<script>
import { mapGetters } from 'vuex'
// import { ValidationObserver, ValidationProvider } from 'vee-validate'

import ProfileAdminOperatingCity from './ProfileAdminOperatingCity.vue'
import ProfileAdminCommunity from './ProfileAdminCommunity.vue'
import SlimTakeInStatus from './SlimTakeInStatus.vue'
export default {
  props: {
    profile: {
      type: Object,
      required: true
    }
  },
  components: {
    ProfileAdminOperatingCity,
    ProfileAdminCommunity,
    SlimTakeInStatus
    // ValidationObserver,
    // ValidationProvider
  },
  computed: {
    ...mapGetters({
      loading: 'loading',
      isAdmin: 'isAdmin'
    })
  },
  data() {
    return {
      selectedCity: null
    }
  },
  methods: {
    onChangeCity(cityInfo) {
      this.selectedCity = cityInfo
    },
    save() {
      this.$store.dispatch('updateProfile', {
        id: this.profile.id,
        meta: { takein_boost: parseInt(this.profile.meta.takein_boost || 0) }
      })
    }
  }
}
</script>
