<template>
  <v-container>
    <section v-if="!user">
      <v-row align="center" justify="center">
        <v-btn class="takein_logo mt-5" dark rounded href="/signup/chef">{{
          $t('Chef.chef_signup_sub')
        }}</v-btn>
      </v-row>
      <v-row align="center" justify="center" class="mt-5">
        <router-link to="/roi">ROI Calculator</router-link>
      </v-row>
    </section>
    <v-container v-if="profile">
      <v-row v-if="!loading && user && !newUser">
        <v-col cols="12" sm="12">
          <v-row>
            <v-col cols="12" sm="1"></v-col>
            <v-col cols="12" sm="10">
              <h4 class="text-center">Welcome to TakeIn</h4>
              <p class="text-center mt-4" v-if="newUser">
                {{ $t('Chef.chef_home_subtitle') }}
              </p>
            </v-col>
            <v-col cols="12" sm="1"></v-col>
          </v-row>

          <v-row
            v-if="
              true || (profile.settings && !profile.settings.delivery_subsidy)
            "
            justify="center"
          >
            <v-col cols="12" sm="10" class=" my-2">
              <span class="h5">
                <strong>Keys for Success:</strong> Add a link on your website to
                your TakeIn Portal and put your TakeIn link in social media
                bios. <br />Please use this link

                <a
                  :href="shareLink"
                  class="text-dark"
                  target="_blank"
                  rel="noopener noreferrer"
                  ref="mylink"
                >
                  {{ shareLink }}
                </a>
                <v-btn @click="copyLink" icon>
                  <v-icon>mdi-content-copy</v-icon>
                </v-btn>
              </span>
            </v-col>
          </v-row>
          <v-row
            v-if="
              false && profile.settings && !profile.settings.delivery_subsidy
            "
            justify="center"
          >
            <v-col cols="12" sm="1"></v-col>
            <v-col cols="12" sm="10" class=" my-5">
              <v-icon large color="red">mdi-alert</v-icon>
              <span class="h4">
                Important: Subsiding delivery is very <b>critical</b> to success
                of your business in TakeIn. Take me to the
                <router-link to="/settings/delivery-subsidy"
                  >settings</router-link
                >
              </span>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="1"></v-col>
            <v-col class="justify align" cols="12" sm="10">
              <v-alert
                outlined
                v-if="!bankAccount && approvalStatus === 'approved'"
                color="error"
                icon="warning"
                :value="true"
              >
                {{ $t('Common.bank_account_sub_title') }}
                <router-link :to="'/accounts/bank/' + profile.uid"
                  >bank account</router-link
                >
              </v-alert>
              <v-alert
                outlined
                v-if="profile.profileStatus === 'hide'"
                color="error"
                icon="warning"
                :value="true"
              >
                Your
                <router-link to="/my/profile">profile</router-link>is hidden!
              </v-alert>
            </v-col>
            <v-col cols="12" sm="1"></v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <section v-if="showProfileNowSection">
      <v-row justify="center" class="my-2">
        <v-col cols="12" sm="6">
          <v-card>
            <v-card-text class="d-flex justify-space-around align-center">
              <v-checkbox
                v-model="profileNow"
                label="Build my own profile now"
              ></v-checkbox>
              <v-btn color="primary" @click="onNext">Next</v-btn>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </section>
    <section v-else-if="newUser">
      <v-row justify="center" class="my-5">
        <v-col cols="12" sm="8">
          <new-user :emailVerified="user && user.emailVerified" :user="user" />
        </v-col>
      </v-row>
    </section>
    <section v-else-if="user && !isChef">
      <v-row align="center" justify="center">
        <consumer-forward />
      </v-row>
    </section>
    <section v-else>
      <profile-status v-if="showProgressTimeLine" />
    </section>
    <v-btn
      fab
      small
      color="primary"
      right
      top
      absolute
      v-if="user && !newUser"
      class="mt-9"
      @click.native="showProgress = !showProgress"
    >
      <v-icon>fab fa-wpforms</v-icon>
    </v-btn>
    <section v-if="!showProgressTimeLine && approvalStatus === 'approved'">
      <v-container v-bind="{ [`grid-list-10`]: true }">
        <v-row no-gutters>
          <v-col cols="12" md="6" class="mb-1">
            <v-card flat tile>
              <v-card-title primary-title class="justify-center"
                >View active orders. Accept or reject them</v-card-title
              >
              <v-card-actions class="justify-center">
                <v-btn
                  text
                  class="mb-3"
                  icon
                  id="activeOrders"
                  to="/order/list"
                >
                  <v-icon large color="takein_logo lighten-1"
                    >shopping_cart</v-icon
                  >
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col cols="12" md="6">
            <v-card flat tile>
              <v-card-title primary-title class="justify-center"
                >Manage your existing products or Dish List</v-card-title
              >
              <v-card-actions class="justify-center">
                <v-btn
                  raised
                  id="productList"
                  class="info mb-3"
                  to="/product/list"
                  >Product List</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
        <v-divider class="my-2"></v-divider>
        <v-row>
          <v-col class="mb-1" cols="12" md="6">
            <v-card flat tile>
              <v-card-title primary-title class="justify-center"
                >Invite your customers</v-card-title
              >
              <v-card-actions class="justify-center">
                <v-btn text class="mb-3" icon to="/invite">
                  <v-icon x-large color="takein_logo lighten-1">drafts</v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col cols="12" md="6">
            <v-card flat tile>
              <v-card-title primary-title class="justify-center"
                >Manage Your Menu</v-card-title
              >
              <v-card-actions class="justify-center">
                <v-btn text icon to="/product/menu">
                  <v-icon x-large color="info">menu_book</v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
        <v-row v-if="false">
          <v-col
            class="mb-1"
            cols="12"
            md="6"
            v-if="approvalStatus === 'approved'"
          >
            <v-card flat tile>
              <v-card-title primary-title class="justify-center"
                >Order Calendar</v-card-title
              >
              <v-card-actions class="justify-center">
                <v-btn text class="mb-3" icon to="/my/calendar">
                  <v-icon x-large color="takein_logo"
                    >far fa-calendar-alt</v-icon
                  >
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col cols="12" md="6">
            <v-card flat tile>
              <v-card-title primary-title class="justify-center"
                >My Working Schedule</v-card-title
              >
              <v-card-actions class="justify-center">
                <v-btn text class="mb-3" icon to="/schedule/calendar">
                  <v-icon x-large color="blue">event_available</v-icon>
                </v-btn>
              </v-card-actions>
              <!-- <v-card-title primary-title>Set Location</v-card-title>
              <v-card-actions>
                <v-btn text icon to="/address/current">
                  <v-icon x-large>my_location</v-icon>
                </v-btn>
              </v-card-actions>-->
            </v-card>
          </v-col>
        </v-row>
        <v-divider
          v-if="approvalStatus === 'approved'"
          class="my-2"
        ></v-divider>
        <v-row v-if="approvalStatus === 'approved'">
          <v-col class="x12 mb-1" md="6">
            <v-card flat tile>
              <v-card-title primary-title class="justify-center"
                >View or Manage profile</v-card-title
              >
              <v-card-actions class="justify-center">
                <v-btn
                  text
                  id="pastOrders"
                  icon
                  class="lighten-1 mb-3"
                  to="/my/profile"
                >
                  <v-icon x-large color="takein_logo">person</v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col cols="12" md="6">
            <v-card flat tile>
              <v-card-title primary-title class="justify-center"
                >Promotions and Coupons</v-card-title
              >
              <v-card-actions class="justify-center">
                <v-btn
                  text
                  icon
                  dark
                  id="live_broadcast"
                  class="mb-3"
                  to="/coupons/list"
                >
                  <img width="60" src="/svg/coupon.svg" />
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </section>
    <section>
      <v-container>
        <v-row v-if="user && isChef" justify="center" class="my-0">
          <v-col cols="12" md="6">
            <v-card class="pt-4">
              <v-img
                src="https://storage.googleapis.com/img.fod.live/static/app/food/pexels-photo-227432.jpeg"
                contain
                height="300px"
              ></v-img>

              <v-col>
                <v-card-title primary-title>
                  <div class="text-center">
                    <h3 class="headline mb-3">
                      {{ $t('Chef.invite_customers') }}
                    </h3>
                    <div class="word-breaking">
                      {{ $t('Chef.invite_customers_sub') }}
                    </div>
                  </div>
                </v-card-title>
              </v-col>

              <v-card-actions class="justify-center">
                <v-btn class="mb-3" dark color="takein_logo" to="/invite"
                  >Send Invitation</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>

        <v-row justify="center" class="my-3">
          <v-col cols="12" md="6">
            <v-card height="370px">
              <v-card-title primary-title class="layout justify-center">
                <div class="headline">{{ $t('Chef.company_info') }}</div>
              </v-card-title>
              <v-card-text v-html="$t('Chef.company_detail')"></v-card-text>
              <v-row justify="center">
                <v-col cols="12" sm="6">
                  <v-img
                    @click="goto('https://about.takein.com')"
                    class="my-3"
                    src="https://storage.googleapis.com/download.takein.com/apps/logo/takein_logo_sm.png"
                    contain
                  />
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col cols="12" md="6">
            <contact type="chef" />
          </v-col>
        </v-row>
      </v-container>
    </section>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
import ConsumerForward from '@/components/common/ConsumerForward'
import ProfileStatus from '@/components/common/ProfileStatus'
// import { profileMixin } from '@/mixins/profileMixin'
import { profileMixin } from 'fod-core'
const domain = process.env.VUE_APP_DOMAIN

export default {
  mixins: [profileMixin],

  components: {
    ConsumerForward,
    ProfileStatus,
    contact: () => import('fod-core/src/components/common/ContactTakeIn.vue'),

    NewUser: () => import('./user/NewUserWelcome')
  },
  data() {
    return {
      profileNow: null,
      showProfileNowSection: false,
      showProgress: false,
      applySubsidy: true
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
      loading: 'loading',
      profile: 'profile',
      newUser: 'newUser',
      approvalStatus: 'approvalStatus',
      isChef: 'isChef',
      bankAccount: 'bankAccount'
    }),
    showProgressTimeLine() {
      return this.showProgress || this.approvalStatus === 'pending'
    },
    shareLink() {
      return this.profile.handle
        ? `https://${domain}/producer/` + this.profile.handle
        : null
    }
  },
  watch: {
    approvalStatus() {
      if (this.approvalStatus && this.approvalStatus != 'approved') {
        this.$router.push('/profile/wizard/welcome')
      }
    }
  },
  mounted() {
    if (this.approvalStatus && this.approvalStatus != 'approved') {
      this.$router.push('/profile/wizard/welcome')
    }
    if (this.$route.query.sp) {
      this.showProgress = this.$route.query.sp
    }
    if (this.newUser && this.user && this.user.showOnboard) {
      this.showProfileNowSection = false
    }

    /*if (this.isChef) {
      this.$router.push('/product/home')
    }*/
  },
  methods: {
    copyLink() {
      navigator.clipboard.writeText(this.shareLink)

      //Url.select()
      document.execCommand('copy')
    },
    onNext() {
      if (this.profileNow) {
        this.showProfileNowSection = false
      } else {
        //this.$router.push('/user/scheduleonboarding')
        this.$router.push('/profile/wizard/welcome')
      }
    }
  }
}
</script>
<style scoped>
.word-breaking {
  word-break: normal !important;
}
</style>
