import firebase from '@/firebase-init'

export default {
  state: {
    followers: [],
    userFollowers: [],
    previousCustomers: [],
    userPreviousCustomers: []
  },
  mutations: {
    setFollowers(state, payload) {
      state.followers = payload
    },
    addFollower(state, payload) {
      state.followers.push(payload)
    },
    setPreviousCustomers(state, payload) {
      state.previousCustomers = payload
    }
  },
  actions: {
    loadPreviousCustomers({ commit, getters }, payload) {
      if (!payload.uid && !getters.user) {
        console.error('error, not user detected')
        return
      }
      commit('setLoading', true)
      commit('setPreviousCustomers', [])

      let uid = payload.uid || getters.user.id
      console.log(`getting list of followers for uid ${uid}`, payload)
      return firebase
        .firestore()
        .collection('orders')
        .doc(uid)
        .collection('seller-orders')
        .get()
        .then(querySnapshot => {
          const customers = []
          querySnapshot.forEach(d => {
            if (
              d.exists &&
              d.data().buyer &&
              d.data().buyer.email &&
              !customers.includes(d.data().buyer.email)
            ) {
              customers.push(d.data().buyer.email)
            }
          })
          console.log(`Retrieved ${customers.length} customers`)
          commit('setPreviousCustomers', customers)

          return
        })
        .catch(error => {
          commit('setLoading', false)
          commit('setError', error)
          console.log(error)
          return null
        })
    },

    loadFollowers({ commit, getters }, payload) {
      if (!payload.uid && !getters.user) {
        console.error('error, not user detected')
        return
      }
      commit('setLoading', true)
      commit('setPreviousCustomers', [])

      const uid = payload.uid || getters.user.id

      console.log(`getting list of previous customers for uid ${uid}`)
      return firebase
        .firestore()
        .collection('social')
        .doc('followers')
        .collection(uid)
        .get()
        .then(querySnapshot => {
          const ids = []
          //const followers = []
          querySnapshot.forEach(data => {
            if (data.exists) {
              ids.push(data.data())
            }
          })
          console.log(`Retrieved ${ids.length} followers`)
          for (let index = 0; index < ids.length; index++) {
            const id = ids[index]

            firebase
              .firestore()
              .doc('/profile/' + id.from)
              .get()
              .then(data => {
                if (data.exists) {
                  let follower = data.data()
                  follower.id = id.from

                  commit('addFollower', follower)
                  commit('setLoading', false)
                } else {
                  console.error('follower user does not exist', id)
                }
                if (index + 1 == ids.length) {
                  console.log('processed last id')
                  commit('setLoading', false)
                }
              })
          }
        })

        .catch(error => {
          commit('setLoading', false)
          commit('setError', error)
          console.log(error)
          return null
        })
    },
    loadFollowings(ctx, payload = {}) {
      console.log('Following Payload: ', payload)
      if (payload.uid) {
        return firebase
          .firestore()
          .collection('social')
          .doc('followings')
          .collection(payload.uid)
          .get()
          .then(querySnapshot => {
            const list = []
            querySnapshot.forEach(data => {
              if (data.exists) {
                const obj = data.data()
                obj.id = data.id
                list.push(obj)
              }
            })
            return list
          })
      }
    },
    isFollowing(ctx, payload = {}) {
      if (payload.uid && payload.pid) {
        return firebase
          .firestore()
          .collection('social')
          .doc('followers')
          .collection(payload.pid)
          .where('from', '==', payload.uid)

          .get()
          .then(querySnapshot => {
            const list = []
            querySnapshot.forEach(data => {
              if (data.exists) {
                const obj = data.data()
                obj.id = data.id
                list.push(obj)
              }
            })
            return list
          })
      }
    }
  },
  getters: {
    followers: state => {
      return state.followers
    },
    previousCustomers: state => {
      return state.previousCustomers
    }
  }
}
