<template>
  <v-container v-if="!loading">
    <v-card class="mx-auto my-auto mb-4" outlined>
      <v-form ref="form" v-model="valid">
        <v-row>
          <v-col cols="12" md="8">
            <v-row justify="start" class="mx-4 mt-4">
              <v-col cols="10" sm="10">
                <span class="display-1 font-weight-light"
                  >Create your dish!</span
                >
              </v-col>
            </v-row>

            <v-row justify="start" class="mx-4 mt-4">
              <v-col cols="12">
                <v-text-field
                  v-model="name"
                  label="Dish Name"
                  placeholder="Choose a compelling, creative name that will catch customers' attention and appetite."
                  solo
                  required
                  :rules="[rules.nameLength]"
                  :counter="nameLength"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row justify="start" class="mx-4 mt-2">
              <v-col cols="12">
                <v-textarea
                  solo
                  name="Food Description"
                  label="Food Description"
                  placeholder="Highlight your fresh, local ingredients and what makes this dish special. Focus on mouthwatering descriptors that emphasize flavor."
                  v-model="description"
                  :counter="descLength"
                ></v-textarea>
              </v-col>
            </v-row>

            <v-row justify="start" class="mx-4 mt-2">
              <v-col cols="12">
                <v-select
                  v-model="selectedMenus"
                  label="Add product to Menus (optional)."
                  :items="subMenus"
                  multiple
                  item-text="name"
                  item-value="id"
                  clearable
                  chips
                  :menu-props="{ offsetY: true }"
                  return-object
                >
                  <template slot="no-data">
                    <v-list-item>
                      <span class="subheading">No Menus</span>
                    </v-list-item>
                  </template>
                </v-select>
              </v-col>
            </v-row>

            <v-row justify="start" class="mx-4 mt-2">
              <v-col cols="6">
                <!-- price -->
                <v-currency-field
                  required
                  :label="$t('Chef.price_per_unit')"
                  :hint="$t('Chef.price_per_unit_hint')"
                  v-bind="currency_config"
                  v-model="price"
                  :rules="[rules.positive]"
                ></v-currency-field>
              </v-col>
              <v-col cols="6">
                <!-- amount left -->
                <v-checkbox
                  v-model="unlimitedInventory"
                  label="Unlimited Inventory"
                ></v-checkbox>
              </v-col>
            </v-row>

            <v-row v-if="!unlimitedInventory" justify="start" class="mx-4 mt-2">
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  required
                  name="left"
                  placeholder="Units"
                  :label="$t('Chef.inventory_available_now')"
                  :hint="$t('Chef.inventory_available_now')"
                  prefix="#"
                  mask="##"
                  :rules="[rules.positive]"
                  v-model="amountLeft"
                  type="number"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" md="6" v-if="id">
            <media-uploader @uploadMedia="uploadMedia" uploadType="media" />
          </v-col>

          <v-col cols="12" md="12" v-if="id">
            <v-container>
              <v-row>
                <template v-for="(image, key) in photos">
                  <v-col
                    v-if="media(image)"
                    cols="12"
                    sm="6"
                    md="6"
                    lg="3"
                    :key="key"
                  >
                    <v-card width="560" height="315">
                      <div
                        v-if="image.type === 'youtube'"
                        class="d-flex justify-center"
                      >
                        <iframe
                          width="560"
                          height="315"
                          :src="youtubeUrl(image.url)"
                          frameborder="0"
                          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                          allowfullscreen
                        ></iframe>
                      </div>
                      <div
                        v-if="
                          media(image)
                            .toLowerCase()
                            .indexOf('mp4') > 0
                        "
                        class="d-flex justify-center"
                      >
                        <video
                          playsinline
                          autoplay
                          controls
                          muted
                          ref="video"
                          class="video-card"
                        >
                          <source :src="media(image)" type="video/mp4" />
                        </video>
                      </div>
                      <div v-else class="d-flex justify-center">
                        <v-img
                          :src="media(image)"
                          contain
                          class="image-card"
                        ></v-img>
                      </div>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="red"
                          id="dishDeletePhoto"
                          @click="onDeletePhoto(image)"
                          class="white--text"
                          >Delete</v-btn
                        >
                      </v-card-actions>
                    </v-card>
                  </v-col>
                </template>
              </v-row>
              <v-row align="center" justify="center">
                <v-col cols="1">
                  <v-btn
                    icon
                    class="px-2"
                    @click="showAddYoutube = !showAddYoutube"
                  >
                    <v-icon color="red" large>fab fa-youtube</v-icon>
                  </v-btn>
                </v-col>

                <dish-photos
                  :id="id"
                  :uid="uid"
                  :query="name"
                  @onWebPhotoSelected="onWebPhotoSelected"
                />
              </v-row>
            </v-container>
          </v-col>
        </v-row>

        <v-row v-if="showAddYoutube">
          <v-col sm="6">
            <v-text-field
              label="Youtube Link"
              v-model="youtubeLink"
            ></v-text-field>
            <v-btn @click="uploadYoutube">Add</v-btn>
          </v-col>
        </v-row>

        <!-- <v-col cols="2" sm="2">
                <v-btn depressed text @click="cancel">
                  <span class="overline">BACK</span>
                </v-btn>
        </v-col>-->
        <v-row justify="start" class="ma-2">
          <v-col cols="3" sm="1">
            <v-btn :disabled="!valid" @click="save()" color="primary"
              >Save</v-btn
            >
          </v-col>
          <v-col cols="3" sm="1" v-if="id">
            <v-btn @click="sell()" color="success">Sell</v-btn>
          </v-col>
          <v-col cols="3" sm="3" v-if="id" class="ml-4">
            <v-btn @click="deleteDialog = true" text small>Delete</v-btn>
          </v-col>
          <v-col cols="2" sm="3" v-if="id">
            <v-btn @click="cancel" text>Back</v-btn>
          </v-col>

          <v-col cols="2" sm="4" v-if="!id">
            <v-btn @click="cancel" text>Cancel</v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card>

    <!-- image upload success dialogue -->
    <v-dialog v-model="uploadProgress" width="700px">
      <v-card>
        <v-card-title class="mx-2">
          <v-icon large color="success">check</v-icon>
          <h4 class="ml-1 fs-16">
            Image Successfully Uploaded! Please Allow Time for the Photo to
            Appear.
          </h4>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="uploadProgress = false">Ok</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- dish delete confirmation -->
    <v-dialog v-model="deleteDialog" width="400">
      <v-card>
        <v-card-title class="mx-2">
          <v-icon large color="grey">delete</v-icon>
          <h4 class="ml-1 mt-2 fs-16 font-weight-medium">Delete Product?</h4>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="deleteDialog = false">Cancel</v-btn>
          <v-btn color="red" dark @click="deleteProduct()">Ok</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import DishPhotos from './DishPhotos'
export default {
  components: {
    MediaUploader: () =>
      import('fod-core/src/components/common/MediaUploader.vue'),
    DishPhotos
  },
  data() {
    return {
      uid: null,
      id: null,
      name: '',
      price: 0,
      description: '',
      photos: [],
      amountLeft: 0,
      valid: false,
      nameLength: 50,
      descLength: 300,
      unlimitedInventory: true,
      subMenus: [],
      subMenuNames: [],
      selectedMenus: [],
      youtubeLink: null,
      showAddYoutube: false,
      uploadProgress: false,
      deleteDialog: false,
      currency_config: {
        decimal: '.',
        thousands: ',',
        prefix: '$ ',
        precision: 2,
        masked: false,
        allowBlank: false,
        min: 0.0,
        max: Number.MAX_SAFE_INTEGER
      },
      saved: false,
      rules: {
        positive: value => {
          let val = value ? value.toString() : ''
          val = val.replace(/,/g, '')
          return Number(val) > 0 || 'Value should be greater than zero.'
        },
        nameLength: value => {
          return (
            (value.length <= this.nameLength && value.length >= 5) ||
            'Name must be between 5 and ' + this.nameLength + 'characters.'
          )
        },
        descLength: value => {
          return value.length <= this.descLength || 'Characters exceeded.'
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      loading: 'loading',
      dish: 'dish',
      myProfile: 'profile',
      userProfile: 'userProfile',
      isAdmin: 'isAdmin'
    }),
    profile() {
      if (this.$route.params.uid) {
        return this.userProfile
      }
      return this.myProfile
    }
  },
  //   watch: {
  //     dish: function() {
  //       this.tags = this.dish ? this.dish.suggested_tags : []
  //     }
  //   },
  async mounted() {
    /*  if (!this.dish) {
      this.$store.dispatch('saveDish', null).then(id => {
        console.log('QUICK ADD ID:', id)
        this.id = id
      })
    } else {
      console.log('QUICK ADD ID:', this.dish.id)
      this.id = this.dish.id
    } */
    await this.$store.dispatch('checkAdmin')

    this.uid =
      this.isAdmin && this.$route.params.uid
        ? this.$route.params.uid
        : this.profile
        ? this.profile.id
        : null

    await this.$store.dispatch('loadUserProfile', { uid: this.uid })

    this.$store.dispatch('loadMenus', { uid: this.uid }).then(list => {
      this.subMenus = list.filter(menu => menu.type != 'parent')
    })
  },
  /* beforeDestroy() {
    if (!this.saved) {
      this.$store.dispatch('deleteDish', this.id)
    }
    this.$store.dispatch('setDish', null)
  }, */
  methods: {
    save() {
      const food = {
        id: this.id,
        price: parseFloat(this.price),
        name: this.name,
        diets: [],
        origins: [],
        ingredients: [],
        description: this.description,
        amountLeft: this.unlimitedInventory ? -1 : this.amountLeft,
        uid: this.uid
      }
      this.$store.dispatch('saveDish', { food: food }).then(id => {
        this.id = id
        this.saved = true

        const selectedMenuIds = this.selectedMenus.map(x => x.id)
        this.subMenus.forEach(menu => {
          // if one of the selected menu is this menu and it does not include the product
          if (selectedMenuIds.includes(menu.id) && !menu.items.includes(id)) {
            menu.items.push(id)
          }
          // if user removed the product from menu
          if (!selectedMenuIds.includes(menu.id) && menu.items.includes(id)) {
            let index = menu.items.indexOf(id)
            if (index > -1) {
              menu.items.splice(index, 1)
            }
          }
        })
        this.$store.dispatch('saveMenus', {
          menu: this.selectedMenus,
          uid: this.uid
        })
      })
    },
    sell() {
      const food = {
        id: this.id,
        uid: this.uid,
        visibility: 'public',
        submitted: true
      }
      this.$store.dispatch('saveDish', { food: food }).then(() => {
        this.saved = true
        this.$router.push('/product/list/' + this.uid)
      })
    },
    media(media) {
      return media.url || media.imageUrl
    },
    loadDishPhotos() {
      const that = this
      console.log('ID:', this.id)
      setTimeout(function() {
        that.$store
          .dispatch('loadDishList', { uid: this.uid })
          .then(dishlist => {
            console.log('ID:', this.dishlist)
            let dish = dishlist.filter(dish => dish.id === this.id)[0]
            console.log('DISH:', dish)
            if (dish) {
              this.photos = dish.photos || []
            }
          })
      }, 3500)
    },
    loadDish(dish) {
      console.log('DISH:', dish)
      this.photos = dish.photos
    },
    uploadMedia: function(file, img) {
      this.uploadProgress = true
      let id = this.id
      this.$store.dispatch('setLoading', true)
      this.$store.dispatch('uploadDishPhoto', {
        uid: this.uid,
        id: id,
        index: Math.random()
          .toString(36)
          .substring(8),
        image: img,
        file: file
      })
      const that = this
      setTimeout(function() {
        that.$store
          .dispatch('loadDishList', { uid: this.uid })
          .then(dishlist => {
            let matched = dishlist.filter(dish => dish.id === id)[0]
            if (matched) {
              that.loadDish(matched)
            }
          })
      }, 3500)
    },
    uploadYoutube() {
      console.log('calling upload youtube')
      this.$store.dispatch('uploadMediaUrl', {
        type: 'youtube',
        id: this.id,
        url: this.youtubeLink
      })
    },
    onWebPhotoSelected(url) {
      if (url) {
        this.photos.push({ imageUrl: url, type: 'external' })
        this.$store.dispatch('saveDish', {
          food: { uid: this.uid, id: this.id, photos: this.photos }
        })

        const that = this
        setTimeout(function() {
          that.$store
            .dispatch('loadDishList', { uid: this.uid })
            .then(dishlist => {
              let matched = dishlist.filter(dish => dish.id === this.id)[0]
              if (matched) {
                that.loadDish(matched)
              }
            })
        }, 3500)
      }
    },
    youtubeUrl(url) {
      if (!url) return null

      var regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/
      var match = url.match(regExp)
      if (match && match[2].length == 11) {
        return `https://www.youtube.com/embed/${match[2]}`
      }
      return null
    },
    onDeletePhoto(image) {
      this.photos = this.photos.filter(i => i !== image)
      console.log('delete photos', this.photos)
      let food = {
        id: this.id,
        photos: this.photos
      }
      this.$store.dispatch('deletePhoto', food)
    },
    cancel() {
      this.$router.push(`/product/list/${this.uid}`)
    },
    deleteProduct() {
      this.$store.dispatch('deleteDish', this.id)
      this.deleteDialog = false
      this.$router.push('/product/list')
    }
  }
}
</script>
