<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" class="pa-0">
        <v-sheet class="main_text--text shadow pa-4">
          <!-- Parent Menus -->
          <v-row class="mx-0 mb-4">
            <v-col>
              <v-sheet class="main_text--text shadow pa-4" v-if="!parentMenus">
                <v-row justify="center">
                  <v-progress-circular
                    indeterminate
                    color="takein"
                    :width="7"
                    :size="50"
                  ></v-progress-circular>
                </v-row>
              </v-sheet>
              <v-sheet v-else class="main_text--text shadow pa-4">
                <v-row align="center" class="pl-4">
                  <v-icon color="takein" class="subtitle1"
                    >mdi-food-variant</v-icon
                  >
                  <span class="title main_text--text ml-3">{{
                    $t('Common.parent_menus')
                  }}</span>
                  <v-spacer />
                  <v-btn
                    class="mx-4"
                    fab
                    dark
                    small
                    depressed
                    color="takein"
                    @click.native.stop="showNameDialog('parent')"
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </v-row>
                <v-divider />
                <v-row justify="center" class="mx-1">
                  <draggable
                    :list="parentMenus"
                    class="d-flex flex-row overflow-auto"
                    handle=".menu-handle"
                  >
                    <div v-for="(pMenu, key) in parentMenus" :key="key">
                      <v-card class="ma-4" height="360" width="240">
                        <v-card-title
                          :class="
                            (pMenu.isActive ? 'takein' : 'red') +
                              ' white--text pa-1'
                          "
                        >
                          <v-row>
                            <v-col
                              cols="12"
                              class="d-flex py-0 px-4 justify-space-between"
                            >
                              <div class="menu-handle">
                                <v-icon class="white--text"
                                  >mdi-cursor-move</v-icon
                                >
                              </div>
                              <div class="d-flex w-100 justify-space-between">
                                <v-edit-dialog
                                  @open="tmp = pMenu.name"
                                  @save="saveName(pMenu, tmp)"
                                  large
                                >
                                  <span class="pa-3 fs-16 white--text">
                                    {{ pMenu.name | truncate(50) }}
                                  </span>
                                  <div slot="input" class="mt-3">
                                    {{ $t('Common.update_menu_name') }}
                                  </div>
                                  <v-text-field
                                    slot="input"
                                    :label="$t('Common.edit')"
                                    v-model="tmp"
                                    single-line
                                    autofocus
                                  ></v-text-field>
                                </v-edit-dialog>
                                <v-menu offset-y>
                                  <template v-slot:activator="{ on }">
                                    <v-btn class="ma-0" dark icon v-on="on">
                                      <v-icon>more_vert</v-icon>
                                    </v-btn>
                                  </template>
                                  <v-list class="dropdown-list">
                                    <v-list-item
                                      :to="
                                        '/profile/wizard/menus/menus_sub/edit_menu/' +
                                          pMenu.id
                                      "
                                    >
                                      <v-icon class="mr-3">edit</v-icon>
                                      <span>{{ $t('Common.edit') }}</span>
                                    </v-list-item>
                                    <v-list-item
                                      @click="deleteMenuDialog(pMenu)"
                                    >
                                      <v-icon color="red" class="mr-3"
                                        >delete</v-icon
                                      >
                                      <span>Delete</span>
                                    </v-list-item>
                                  </v-list>
                                </v-menu>
                              </div>
                            </v-col>
                          </v-row>
                        </v-card-title>
                        <div class="sub-title">
                          <span class="d-flex align-center caption px-2">
                            Menus:
                            {{ pMenu.children ? pMenu.children.length : 0 }}
                          </span>
                        </div>
                        <v-card-text class="pa-1">
                          <vue-perfect-scrollbar
                            style="height:260px"
                            :settings="settings"
                          >
                            <v-list
                              v-if="pMenu.children && pMenu.children.length > 0"
                            >
                              <draggable
                                :list="pMenu.children"
                                group="childrenMenus"
                                @change="evt => addNewSubMenu(evt, pMenu)"
                                style="height:260px"
                              >
                                <v-list-item
                                  v-for="(menu, key) in menuInParent(pMenu)"
                                  :key="key"
                                >
                                  <template v-if="menu">
                                    <v-list-item-content>
                                      {{ menu.name | truncate(15) }}
                                    </v-list-item-content>
                                    <v-list-item-action>
                                      <v-btn
                                        small
                                        class="ma-0"
                                        icon
                                        @click.stop="
                                          removeMenu(menu.id, pMenu.id)
                                        "
                                      >
                                        <v-icon :small="true" color="secondary"
                                          >mdi-delete</v-icon
                                        >
                                      </v-btn>
                                    </v-list-item-action>
                                  </template>
                                </v-list-item>
                              </draggable>
                            </v-list>
                            <draggable
                              v-else
                              :list="['']"
                              group="childrenMenus"
                              style="height:260px"
                              @change="evt => addFirstNewSubMenu(evt, pMenu)"
                              >{{ $t('Common.drag_and_drop_menu') }}</draggable
                            >
                          </vue-perfect-scrollbar>
                        </v-card-text>
                      </v-card>
                    </div>
                  </draggable>
                </v-row>
              </v-sheet>
            </v-col>
          </v-row>

          <!-- Sub Menus -->
          <v-row class="mx-0 mb-4">
            <v-col>
              <v-sheet class="main_text--text shadow pa-4" v-if="!subMenus">
                <v-row justify="center">
                  <v-progress-circular
                    indeterminate
                    color="takein"
                    :width="7"
                    :size="50"
                  ></v-progress-circular>
                </v-row>
              </v-sheet>
              <v-sheet v-else class="main_text--text shadow pa-4">
                <v-row align="center" class="pl-4">
                  <v-icon color="takein" class="subtitle1"
                    >fas fa-utensils</v-icon
                  >
                  <span class="title main_text--text ml-3">{{
                    $t('Common.sub_menus')
                  }}</span>
                  <v-spacer />
                  <v-btn
                    class="mx-4"
                    fab
                    depressed
                    dark
                    small
                    color="takein"
                    @click.native.stop="showNameDialog('')"
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </v-row>
                <v-divider />
                <v-row justify="center" class="mx-1">
                  <draggable
                    :list="subMenuIds"
                    :group="{
                      name: 'childrenMenus',
                      pull: 'clone',
                      put: false
                    }"
                    class="d-flex flex-row overflow-auto"
                    handle=".menu-handle"
                    @change="changeSubMenuOrder"
                  >
                    <div v-for="(smID, key) in subMenuIds" :key="key">
                      <v-card class="ma-4" height="340" width="240">
                        <v-card-title
                          :class="
                            (getSubMenu(smID).isActive ? 'takein' : 'red') +
                              ' white--text pa-1 ma-0'
                          "
                        >
                          <v-row>
                            <v-col cols="12" class="d-flex px-4 py-0 ma-0">
                              <div class="menu-handle">
                                <v-icon class="white--text"
                                  >mdi-cursor-move</v-icon
                                >
                              </div>
                              <div class="d-flex w-100 justify-space-between">
                                <v-edit-dialog
                                  @open="tmp = getSubMenu(smID).name"
                                  @save="saveName(getSubMenu(smID), tmp)"
                                  large
                                >
                                  <span class="fs-16 white--text">
                                    {{ getSubMenu(smID).name | truncate(50) }}
                                  </span>
                                  <div slot="input" class="mt-3">
                                    {{ $t('Common.update_menu_name') }}
                                  </div>
                                  <v-text-field
                                    slot="input"
                                    :label="$t('Common.edit')"
                                    v-model="tmp"
                                    single-line
                                    autofocus
                                  ></v-text-field>
                                </v-edit-dialog>
                                <v-menu offset-y>
                                  <template v-slot:activator="{ on }">
                                    <v-btn class="ma-0" dark icon v-on="on">
                                      <v-icon>more_vert</v-icon>
                                    </v-btn>
                                  </template>
                                  <v-list class="dropdown-list">
                                    <v-list-item
                                      :to="
                                        '/profile/wizard/menus/menus_sub/edit_menu/' +
                                          smID
                                      "
                                    >
                                      <v-icon class="mr-3">edit</v-icon>
                                      <span>{{ $t('Common.edit') }}</span>
                                    </v-list-item>
                                    <v-list-item
                                      @click="
                                        deleteMenuDialog(getSubMenu(smID))
                                      "
                                    >
                                      <v-icon color="red" class="mr-3"
                                        >delete</v-icon
                                      >
                                      <span>Delete</span>
                                    </v-list-item>
                                  </v-list>
                                </v-menu>
                              </div>
                            </v-col>
                          </v-row>
                        </v-card-title>
                        <div class="sub-title">
                          <span class="d-flex align-center caption px-2">
                            Items:
                            {{
                              getSubMenu(smID).items
                                ? getSubMenu(smID).items.length
                                : 0
                            }}
                          </span>
                        </div>
                        <v-card-text class="pa-1">
                          <vue-perfect-scrollbar
                            style="height:260px"
                            :settings="settings"
                          >
                            <v-list
                              v-if="
                                getSubMenu(smID).items &&
                                  getSubMenu(smID).items.length > 0
                              "
                            >
                              <draggable
                                :list="getSubMenu(smID).items"
                                group="products"
                                @change="
                                  evt => addNewProduct(evt, getSubMenu(smID))
                                "
                                style="height:260px"
                              >
                                <v-list-item
                                  v-for="(product, key) in productInMenu(
                                    getSubMenu(smID)
                                  )"
                                  :key="key"
                                  class="pl-0 pr-1 d-flex"
                                >
                                  <template v-if="product">
                                    <v-list-item-avatar
                                      v-if="
                                        product.photos &&
                                          product.photos.length > 0
                                      "
                                      class="pa-1 ma-0"
                                    >
                                      <img
                                        :src="product.photos[0].imageUrl"
                                        class="image-round"
                                        elevation="2"
                                      />
                                    </v-list-item-avatar>
                                    <v-list-item-icon v-else class="ml-2 mr-2">
                                      <v-icon color="grey"
                                        >mdi-image-off</v-icon
                                      >
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                      {{ product.name | truncate(40) }}
                                    </v-list-item-content>
                                    <v-list-item-action class="d-flex flex-row">
                                      <v-btn
                                        small
                                        class="ma-0"
                                        icon
                                        @click="
                                          removeProduct(
                                            product.id,
                                            getSubMenu(smID)
                                          )
                                        "
                                      >
                                        <v-icon :small="true" color="secondary"
                                          >mdi-delete</v-icon
                                        >
                                      </v-btn>
                                    </v-list-item-action>
                                  </template>
                                </v-list-item>
                              </draggable>
                            </v-list>
                            <draggable
                              v-else
                              :list="['']"
                              group="products"
                              @change="
                                e => addFirstNewProduct(e, getSubMenu(smID))
                              "
                              style="height:260px"
                              >{{
                                $t('Common.drag_and_drop_product')
                              }}</draggable
                            >
                          </vue-perfect-scrollbar>
                        </v-card-text>
                      </v-card>
                    </div>
                  </draggable>
                </v-row>
              </v-sheet>
            </v-col>
          </v-row>

          <!-- Products -->
          <v-row class="mx-0">
            <v-col>
              <v-sheet class="main_text--text shadow pa-4">
                <v-row align="center" class="pl-4">
                  <v-icon color="takein" class="subtitle1"
                    >fas fa-hamburger</v-icon
                  >
                  <span class="title main_text--text mx-4">{{
                    $t('Common.products')
                  }}</span>
                  <v-text-field
                    color="takein"
                    append-icon="mdi-magnify"
                    :label="$t('Common.search')"
                    v-model="searchText"
                    dense
                    :hide-details="true"
                  ></v-text-field>
                  <v-checkbox
                    color="takein"
                    :hide-details="true"
                    v-model="availableMarket"
                    :label="$t('Common.available_market')"
                    dense
                    class="mt-1 mx-2"
                  ></v-checkbox>
                </v-row>
                <v-divider />
                <v-row justify="center" class="mx-1">
                  <draggable
                    :list="filteredProducts"
                    :group="{ name: 'products', pull: 'clone', put: false }"
                    class="d-flex flew-row overflow-auto"
                    handle=".product-handle"
                  >
                    <div
                      v-for="(productId, key) in filteredProducts"
                      :key="key"
                    >
                      <v-card class="ma-4" width="200">
                        <v-img
                          v-if="getProductImage(productId)"
                          class="image-rect"
                          :src="getProductImage(productId)"
                        >
                          <div
                            class="w-100 h-100 d-flex flex-column justify-space-between"
                          >
                            <div class="product-handle pa-1">
                              <v-icon class="white--text"
                                >mdi-cursor-move</v-icon
                              >
                            </div>
                            <div class="product-name px-2">
                              <p class="title mb-0 white--text text-center">
                                {{ getProductName(productId) | truncate(13) }}
                              </p>
                            </div>
                          </div>
                        </v-img>
                        <div v-else class="image-rect">
                          <div
                            class="w-100 h-100 d-flex flex-column justify-space-between"
                          >
                            <div class="product-handle pa-1">
                              <v-icon class="white--text"
                                >mdi-cursor-move</v-icon
                              >
                            </div>
                            <div class="d-flex justify-center">
                              <v-icon large color="grey">mdi-image-off</v-icon>
                            </div>
                            <div class="product-name px-2">
                              <p class="title mb-0 white--text text-center">
                                {{ getProductName(productId) | truncate(13) }}
                              </p>
                            </div>
                          </div>
                        </div>
                      </v-card>
                    </div>
                  </draggable>
                </v-row>
              </v-sheet>
            </v-col>
          </v-row>
        </v-sheet>
      </v-col>
    </v-row>
    <delete-confirmation-dialog
      ref="deleteConfirmationDialog"
      heading="Deleting Menu"
      message="Are you sure you want to delete this menu?"
      @onConfirm="onDeleteMenu"
    ></delete-confirmation-dialog>
    <v-dialog v-model="nameDialog" persistent max-width="320">
      <v-card>
        <v-card-title class="headline">
          {{
            menuType === 'parent'
              ? $t('Common.parent_menu_name')
              : $t('Common.sub_menu_name')
          }}
        </v-card-title>
        <v-card-text>
          <v-text-field
            :label="$t('Common.edit')"
            v-model="createMenuName"
            single-line
            autofocus
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="takein white--text" @click="addMenuPlaceholder"
            >OK</v-btn
          >
          <v-btn color="default black--text" @click="nameDialog = false"
            >Cancel</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import draggable from 'vuedraggable'
import { i18n } from 'fod-core'
import { mapGetters } from 'vuex'

export default {
  components: {
    draggable
  },
  data() {
    return {
      newMenuName: i18n.t('Common.sub_menu_name'),
      newParentMenuName: i18n.t('Common.parent_menu_name'),
      tmp: null,
      subMenus: null,
      parentMenus: null,
      deleteMenu: null,
      settings: {
        maxScrollbarLength: 160
      },
      searchText: '',
      availableMarket: false,
      dishList: null,
      nameDialog: false,
      menuType: '',
      createMenuName: ''
    }
  },
  computed: {
    ...mapGetters({
      profile: 'profile',
      menus: 'menus'
    }),
    productIds() {
      return this.products.map(d => d.id)
    },
    filteredProducts() {
      let list = []
      if (this.dishList && this.dishList.length > 0) {
        if (this.availableMarket)
          list = this.dishList.filter(
            dish =>
              dish.name &&
              dish.name.toLowerCase().includes(this.searchText.toLowerCase()) &&
              dish.visibility === 'public'
          )

        list = this.dishList.filter(
          dish =>
            dish.name &&
            dish.name.toLowerCase().includes(this.searchText.toLowerCase())
        )
      }
      return list.map(d => d.id)
    },
    subMenuIds() {
      if (this.subMenus) {
        return this.subMenus.map(s => s.id)
      }
    }
  },
  beforeMount() {
    if (!this.dishList) {
      this.$store
        .dispatch('loadDishList', { uid: this.profile.id })
        .then(list => {
          this.dishList = list
        })
    }
    this.loadMenus()
  },
  mounted() {
    // this.subMenuIds = this.subMenus.map(sm => sm.id)
  },
  methods: {
    showNameDialog(menuType) {
      this.createMenuName = ''
      this.nameDialog = true
      this.menuType = menuType
    },
    getProductImage(productId) {
      const product = this.dishList.filter(p => p.id === productId)[0]
      return product && product.photos && product.photos.length > 0
        ? product.photos[0].url || product.photos[0].imageUrl
        : null
    },
    productInMenu(sMenu) {
      let list = []
      if (!sMenu) {
        return
      }
      if (this.dishList) {
        sMenu.items.forEach(productId => {
          const product = this.dishList.filter(p => p.id === productId)
          if (product[0]) list.push(product[0])
        })
      }
      return list
    },
    saveName(menu, newName) {
      menu.name = newName
      this.$store.dispatch('setLoading', true)
      this.$store
        .dispatch('saveMenus', { menu: menu, uid: this.profile.uid })
        .then(() => {
          this.$store.dispatch('setLoading', false)
        })
    },
    removeProduct: function(id, menu) {
      if (!menu) {
        return
      }
      console.log(`Remove ${id} from  ${menu.id}`)
      menu.items = menu.items.filter(item => item !== id)
      this.changeVisibility(menu)
      this.$store.dispatch('saveMenus', { menu: menu, uid: this.profile.uid })
    },
    changeVisibility(menu) {
      let isVisible = false
      if (menu.type && menu.type === 'parent') {
        isVisible = menu.children && menu.children.length > 0
      } else {
        isVisible = menu.items && menu.items.length > 0
      }

      // sets menu to inactive if there are no items
      if (!isVisible) {
        menu.isActive = isVisible
        menu.isPublic = isVisible
        menu.followerOnly = isVisible
      }
    },
    changeSubMenuOrder: function(evt) {
      if (evt.moved) {
        let newSubMenus = []
        let endPosition = 0
        const allMenus = this.menus
        if (allMenus && allMenus.length > 0) {
          endPosition = Math.max(...allMenus.map(m => m.position))
          if (!endPosition) {
            endPosition = allMenus.length + 1
          }
        }
        for (let k = 0; k < this.subMenuIds.length; k++) {
          for (let i = 0; i < this.menus.length; i++) {
            if (this.menus[i].id === this.subMenuIds[k]) {
              newSubMenus.push({
                ...this.menus[i],
                position: endPosition + k + 1
              })
              break
            }
          }
        }
        this.menus = this.menus.filter(m => m.type === 'parent')
        this.menus = [...this.menus, ...newSubMenus]
        this.$store.dispatch('saveMenus', { menu: this.menus, uid: this.uid })
      }
    },
    getSubMenu(subMenuID) {
      const sMenu = this.subMenus.filter(s => s.id === subMenuID)[0]
      return sMenu ? sMenu : null
    },
    menuInParent(menu) {
      if (!menu) {
        return
      }
      let list = []
      menu.children.forEach(submenu => {
        if (this.menus) {
          let menu = this.menus.filter(s => s.id === submenu)
          list.push(menu[0])
        }
      })
      return list
    },
    deleteMenuDialog(menu) {
      this.deleteMenu = menu
      this.$refs.deleteConfirmationDialog.openDialog()
    },
    onDeleteMenu() {
      if (!this.deleteMenu) return
      if (this.deleteMenu.type && this.deleteMenu.type === 'parent') {
        this.parentMenus = this.parentMenus.filter(
          m => m.id !== this.deleteMenu.id
        )
      } else {
        this.subMenus = this.subMenus.filter(m => m.id !== this.deleteMenu.id)
      }
      this.$store.dispatch('deleteMenu', this.deleteMenu.id).then(() => {
        this.$refs.deleteConfirmationDialog.close()
      })
    },
    removeMenu(subId, parentId) {
      console.log(`Removed ${subId} from  ${parentId}`)

      let parentMenu = this.menus.filter(m => m.id == parentId)
      parentMenu = parentMenu.length > 0 ? parentMenu[0] : null
      if (!parentMenu) {
        return
      }

      const list = parentMenu.children.filter(item => item !== subId)
      parentMenu.children = list

      this.changeVisibility(parentMenu)
      this.$store
        .dispatch('saveMenus', { menu: parentMenu, uid: this.profile.uid })
        .then(() => {
          this.$store.dispatch('setLoading', false)
        })
    },
    addMenuPlaceholder() {
      this.nameDialog = false
      let lastParentMenuIndex = 0,
        lastSubMenuIndex = 0
      let parentMenus = this.menus.filter(m => m.type === 'parent'),
        allMenus = this.menus
      let newMenu = {
        isActive: false
      }
      if (this.menuType === 'parent') {
        if (parentMenus && parentMenus.length > 0) {
          lastParentMenuIndex =
            Math.max(...parentMenus.map(m => m.position)) + 1
          // if (!lastParentMenuIndex) {
          //   endPosition = parentMenus.length + 1
          // }
        }

        newMenu.type = 'parent'
        newMenu.name = this.createMenuName
        newMenu.position = lastParentMenuIndex
        newMenu.children = []
        newMenu.useBusinessHours = true
      } else {
        if (allMenus && allMenus.length > 0) {
          lastSubMenuIndex = Math.max(...allMenus.map(m => m.position)) + 1
          if (!lastSubMenuIndex) {
            lastSubMenuIndex = allMenus.length + 1
          }
        }
        newMenu.name = this.createMenuName
        newMenu.position = lastSubMenuIndex
        newMenu.items = []
        newMenu.useBusinessHours = true
      }
      this.menus.push(newMenu)
      this.$store.dispatch('setLoading', true)
      this.$store
        .dispatch('saveMenus', { menu: newMenu, uid: this.profile.uid })
        .then(() => {
          this.$store.dispatch('setLoading', false)
          this.loadMenus()
        })
    },
    loadMenus() {
      this.$store.dispatch('loadMenus', { uid: this.profile.id }).then(list => {
        if (list) {
          this.subMenus = list.filter(menu => menu.type != 'parent')
          this.parentMenus = list.filter(
            menu => menu.type && menu.type === 'parent'
          )
        } else {
          this.subMenus = []
          this.parentMenus = []
        }
      })
    },
    isParent(menu) {
      if (menu.type && menu.type === 'parent') {
        return true
      }
      return false
    },
    addFirstNewSubMenu(e, parentMenu) {
      parentMenu.children = [e.added.element]
      this.$store.dispatch('saveMenus', {
        menu: parentMenu,
        uid: this.profile.uid
      })
    },
    addNewSubMenu(e, parentMenu) {
      parentMenu.children = [...new Set(parentMenu.children)]
      this.$store.dispatch('saveMenus', {
        menu: parentMenu,
        uid: this.profile.uid
      })
    },
    addFirstNewProduct(e, menu) {
      menu.items = [e.added.element]
      this.$store.dispatch('saveMenus', {
        menu: menu,
        uid: this.profile.uid
      })
    },
    addNewProduct: function(evt, menu) {
      if (this.isParent(menu)) {
        menu.children = [...new Set(menu.children)]
      } else {
        menu.items = [...new Set(menu.items)]
      }
      this.$store.dispatch('saveMenus', {
        menu: menu,
        uid: this.profile.uid
      })
    },
    getProductName(productId) {
      let item
      if (this.dishList) {
        item = this.dishList.filter(p => p.id === productId)[0]
      }
      return item.name || ''
    }
  },

  subMenusInParent() {
    let list = []
    if (!this.menu) {
      return
    }
    this.menu.items.forEach(pid => {
      if (pid.length > 0) {
        let product = this.dishList.filter(p => p.id === pid)
        list.push(product[0])
      }
    })

    return list
  }
}
</script>
<style>
.shadow {
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
    0px 4px 8px rgba(0, 0, 0, 0.04);
}
.product-handle {
  background-color: rgba(0, 0, 0, 0.6);
  width: 32px;
  cursor: move;
  border-bottom-right-radius: 5px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2) !important;
}
.product-name {
  background-color: rgba(0, 0, 0, 0.6);
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2) !important;
}
.image-rect {
  overflow: hidden;
  width: 100%;
  height: 140px;
}
</style>
