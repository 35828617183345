import Vue from 'vue'
import Router from 'vue-router'
import firebase from '@/firebase-init'
import Home from '@/components/Home'
import About from '@/components/About'
import notFound from '@/components/404'
import Profile from '@/components/chef/profile/ChefProfile'
import ChefReview from '@/components/review/chef/ChefReview'
import DishReview from '@/components/review/dish/DishReview'
import CustomerReview from '@/components/review/customer/CustomerReview'
import BecomeChef from '@/components/chef/BecomeChef'

import ProfileWizard from '@/components/profilewizard/ProfileWizard'
import StepWelcome from '@/components/profilewizard/StepWelcome'
import StepProfile from '@/components/profilewizard/StepProfile'
import StepFinance from '@/components/profilewizard/StepFinance'
import DepositView from '@/components/profilewizard/StepFinance/DepositView'
import BankAccount from '@/components/profilewizard/StepFinance/BankAccount'
import StepSettings from '@/components/profilewizard/StepSettings'
import StepMenus from '@/components/profilewizard/StepMenus'
import ManageMenus from '@/components/profilewizard/StepMenus/ManageMenus.vue'
import ManageProducts from '@/components/profilewizard/StepMenus/ManageProducts.vue'
import NewProduct from '@/components/profilewizard/StepMenus/ManageProducts/NewProduct.vue'
import NewModifier from '@/components/profilewizard/StepMenus/ManageProducts/NewModifier.vue'
import ImportData from '@/components/profilewizard/StepMenus/ImportData.vue'
import ManageModifiers from '@/components/profilewizard/StepMenus/ManageProducts/ManageModifiers.vue'
import NewMenu from '@/components/profilewizard/StepMenus/ManageMenus/NewMenu.vue'
import EditMenu from '@/components/profilewizard/StepMenus/ManageMenus/EditMenu.vue'
import StepLive from '@/components/profilewizard/StepLive'

import ChefAlbum from '@/components/chef/profile/ChefAlbum'
import EditProduct from '@/components/cook/EditProduct'
import ProductQuickAdd from '@/components/cook/ProductQuickAdd'
import AddDish from '@/components/cook/AddDish'
import ProductList from '@/components/cook/ProductList'
import FoodPublicLabel from '@/components/cook/public/FoodPublicLabel'
import FollowerList from '@/components/social/FollowerList'
import GoLive from '@/components/live/GoLive'
import ViewLive from '@/components/live/ViewLive'
import Condiments from '@/components/cook/Condiments'

import OrderView from '@/components/order/OrderView'
import AccountView from '@/components/account/AccountView'
import Signin from '@/components/user/Signin'
import PhoneSignin from '@/components/user/PhoneSignin'
import Signup from '@/components/user/Signup'
import ScheduleOnboarding from '@/components/user/ScheduleOnboarding'
import ForgotPassword from '@/components/user/ForgotPassword'
import ChangePassword from '@/components/user/ChangePassword'

//import { NewMessage, ListMessages, PostDish } from 'fod-core'

import PostDish from 'fod-core/src/components/dish/PostDish.vue'
import ListMessages from 'fod-core/src/components/message/ListMessages.vue'
import NewMessage from 'fod-core/src/components/message/NewMessage.vue'
import Meta from 'vue-meta'
import { store } from './store'

Vue.use(Router)
Vue.use(Meta)

// Lets add children routes
// https://github.com/tamiat/tamiat/blob/feature/refactor/src/router/routes.js

let router = new Router({
  mode: 'history',
  //fallback: false,
  routes: [
    {
      path: '/',
      component: Home,
      meta: {
        title: 'TakeIn Business Portal'
      }
    },
    {
      path: '/notFound',
      component: notFound
    },
    {
      path: '/why-takein',
      name: 'My Dish Calendar',
      component: () => import('@/components/common/WhyTakeIn.vue'),
      meta: {
        title:
          'Why Takein: Food App Increase profit, Tips go to restaurant and Free Marketing tool for food business.'
      }
    },
    /*
    {
      path: '/foods',
      name: 'Foods',
      component: ExploreFoods
    },
    {
      path: '/chefs',
      name: 'Chefs',
      component: ExploreChefs
    },

    {
      path: '/dishes',
      name: 'Dishes',
      component: ExploreFoods
    },*/
    {
      path: '/profile/wizard',
      name: 'ProfileWizard',
      component: ProfileWizard,
      children: [
        {
          path: 'welcome',
          name: 'welcome',
          component: StepWelcome
        },
        {
          path: 'profile',
          name: 'profile',
          component: StepProfile
        },
        {
          path: 'finance',
          name: 'finance',
          component: StepFinance,
          children: [
            {
              path: 'deposit',
              name: 'deposit',
              component: DepositView
            },
            {
              path: 'bankaccount',
              name: 'bankaccount',
              component: BankAccount
            }
          ]
        },
        {
          path: 'ordersettings',
          name: 'ordersettings',
          component: StepSettings
        },
        {
          path: 'menus',
          name: 'menus',
          component: StepMenus,
          children: [
            {
              path: 'products_sub',
              name: 'products_sub',
              component: ManageProducts,
              children: [
                {
                  path: 'new_product',
                  name: 'new_product',
                  component: NewProduct
                },
                {
                  path: 'new_product/:id',
                  component: NewProduct
                },
                {
                  path: 'list_products',
                  name: 'list_products',
                  component: () =>
                    import(
                      '@/components/profilewizard/StepMenus/ManageProducts/ListProducts.vue'
                    )
                }
              ]
            },
            {
              path: 'menus_sub',
              name: 'menus_sub',
              component: ManageMenus,
              children: [
                {
                  path: 'new_menu',
                  name: 'new_menu',
                  component: NewMenu
                },
                {
                  path: 'edit_menu/:id',
                  component: EditMenu
                },
                {
                  path: 'list_menus',
                  name: 'list_menus',
                  component: () =>
                    import(
                      '@/components/profilewizard/StepMenus/ManageMenus/ListMenus.vue'
                    )
                }
              ]
            },
            {
              path: 'manage_modifiers/:id?',
              name: 'manage_modifiers',
              component: ManageModifiers
            },
            {
              path: 'new_modifier',
              name: 'new_modifier',
              component: NewModifier
            },
            {
              path: 'new_modifier/:mode/:id?',
              component: NewModifier
            },
            {
              path: 'import_data',
              component: ImportData
            }
          ]
        },
        {
          path: 'golive',
          name: 'golive',
          component: StepLive
        }
      ]
    },
    {
      path: '/about',
      name: 'About',
      component: About
    },
    {
      path: '/my/profile',
      name: 'UserProfile',
      component: Profile,
      meta: {
        requiresAuth: true,
        title: 'Chef Profile'
      }
    },
    {
      path: '/my/calendar',
      name: 'My Dish Calendar',
      component: () => import('@/components/calendar/DishCalendar'),
      meta: {
        requiresAuth: true,
        title: 'My Calendar'
      }
    },
    {
      path: '/chef/signup',
      component: BecomeChef,
      meta: {
        title: 'Signup'
      }
    },
    {
      path: '/user/scheduleonboarding',
      component: ScheduleOnboarding,
      meta: {
        title: 'Schedule Onboarding'
      }
    },
    {
      path: '/chef/album',
      component: ChefAlbum,
      meta: {
        requiresAuth: true,
        title: 'Upload Album'
      }
    },
    {
      path: '/review/list/:uid?',
      component: () => import('@/components/review/ReviewList'),
      meta: {
        requiresAuth: true,
        title: 'Reviews'
      }
    },
    {
      path: '/review/chef/:id/:order_id',
      component: ChefReview,
      meta: {
        requiresAuth: true,
        title: 'Chef Reviews'
      }
    },
    {
      path: '/review/customer/:id/:order_id',
      component: CustomerReview,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/followers/:id',
      component: FollowerList,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/followers',
      component: FollowerList,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/golive',
      component: GoLive,
      meta: {
        requiresAuth: true,
        title: 'Live Broadcast'
      }
    },
    {
      path: '/watch/:id',
      component: ViewLive
      /* meta: {
        requiresAuth: true
      }*/
    },
    {
      path: '/order/view/:order_id',
      component: OrderView,
      meta: {
        requiresAuth: true,
        title: 'View Order'
      }
    },
    {
      path: '/order/list/v2.0',
      component: () => import('fod-core/src/components/order/OrderList2.vue'),
      meta: {
        requiresAuth: true,
        title: 'Order List'
      }
    },

    {
      path: '/order/list/:id?',
      //component: OrderList,
      component: () => import('fod-core/src/components/order/OrderList.vue'),
      meta: {
        requiresAuth: true,
        title: 'Order List'
      }
    },
    {
      path: '/report',
      //component: OrderList,
      component: () => import('@/components/report/ReportHome'),
      meta: {
        requiresAuth: true,
        title: 'Reports'
      }
    },
    {
      path: '/order/report/daily_orders',
      //component: OrderList,
      component: () => import('@/components/order/DailyOrders'),
      meta: {
        requiresAuth: true,
        title: 'Daily Orders'
      }
    },
    {
      path: '/order/report/daily_customer',
      //component: OrderList,
      component: () => import('@/components/order/DailyCustomer'),
      meta: {
        requiresAuth: true,
        title: 'Daily Customer'
      }
    },
    {
      path: '/order/report/daily',
      //component: OrderList,
      component: () => import('@/components/order/DailyReport'),
      meta: {
        requiresAuth: true,
        title: 'Daily Report',
        permission: 'admin'
      }
    },
    {
      path: '/order/report/producers',
      //component: OrderList,
      component: () => import('@/components/order/OrderReport'),
      meta: {
        requiresAuth: true,
        title: 'Order Report',
        permission: 'admin'
      }
    },

    // REMOVE AFTER COMPLETION***************
    {
      path: '/order/view/v2.0/:uid/:order_id',
      component: () => import('@/components/order/OrderView2'),
      meta: {
        requiresAuth: true,
        title: 'Order View'
      }
    },
    {
      path: '/order/view/:uid/:order_id',
      component: () => import('@/components/order/OrderView'),
      meta: {
        requiresAuth: true,
        title: 'Order List'
      }
    },
    {
      path: '/order/lists/:uid',
      component: () => import('fod-core/src/components/order/OrderList2.vue'),
      meta: {
        requiresAuth: true,
        title: 'Order List',
        permission: 'admin'
      }
    },
    //************************************ */
    {
      path: '/review/dish/:food_id/:order_id',
      component: DishReview,
      meta: {
        requiresAuth: true,
        title: 'Dish View'
      }
    },

    {
      path: '/invite',
      component: () => import('fod-core/src/components/profile/Invite.vue'),
      meta: {
        requiresAuth: true,
        title: 'Invite Friends'
      }
    },
    {
      path: '/invite/list',
      component: () => import('fod-core/src/components/profile/InviteList.vue'),
      meta: {
        requiresAuth: true
      }
    },
    // when chef accpets invitation
    {
      path: '/invite/accept',
      component: () => import('@/components/user/AcceptInvite'),
      meta: {
        requiresAuth: true,
        signup: true,
        param: 'invitation_id'
      }
    },
    {
      path: '/signin',
      name: 'Signin',
      component: Signin,
      meta: {
        title: 'TakeIn Signin'
      }
    },
    {
      path: '/forgot-password',
      name: 'ForgotPassword',
      component: ForgotPassword
    },
    {
      path: '/change-password',
      name: 'ChangePassword',
      component: ChangePassword,
      meta: {
        requiresAuth: true,
        title: 'Change Password'
      }
    },
    /* {
      path: '/cook',
      component: CookHome
    },*/

    {
      path: '/product/list/:uid?',
      component: ProductList,
      meta: {
        requiresAuth: true,
        title: 'Chef Product List'
      }
    },

    //condiments
    {
      path: '/condiments',
      component: Condiments,
      meta: {
        requiresAuth: true,
        title: 'Condiments List'
      }
    },

    {
      path: '/product/inventory',
      component: () => import('@/components/cook/ProductInventory'),
      meta: {
        requiresAuth: true,
        title: 'Product Inventory'
      }
    },
    {
      path: '/product/export',
      component: () => import('@/components/cook/ExportModule'),
      meta: {
        requiresAuth: true,
        title: 'Product Export Import'
      }
    },
    {
      path: '/product/menu/:uid?',
      component: () => import('@/components/cook/ProductMenu'),
      meta: {
        requiresAuth: true,
        title: 'Product Menu'
      }
    },
    {
      path: '/product/last_call',
      component: () => import('@/components/cook/LastCall'),
      meta: {
        requiresAuth: true,
        title: 'Last Call'
      }
    },
    {
      path: '/product/category',
      component: () => import('@/components/cook/CategoryList'),
      meta: {
        requiresAuth: true,
        title: 'Product Category'
      }
    },
    {
      path: '/product/menu/:uid/editmenu/:id',
      component: () => import('@/components/cook/EditMenu'),
      meta: {
        requiresAuth: true,
        title: 'Edit Menu'
      }
    },
    {
      path: '/product/modifier/:id',
      component: () => import('@/components/cook/ProductModifier'),
      meta: {
        requiresAuth: true,
        title: 'Setup modifier'
      }
    },
    {
      path: '/product/managemodifiers',
      component: () => import('@/components/cook/ManageModifiers'),
      meta: {
        requiresAuth: true,
        title: 'Batch modifiers'
      }
    },
    {
      path: '/schedule/calendar',
      component: () => import('@/components/schedule/ChefSchedule'),
      meta: {
        requiresAuth: true,
        title: 'Product Inventory'
      }
    },
    {
      path: '/schedule/edit/:id',
      component: () => import('@/components/schedule/EditSchedule'),
      meta: {
        requiresAuth: true,
        title: 'Product Schedule'
      }
    },
    {
      path: '/product/:id/modifiers',
      component: () => import('@/components/cook/EditModifiers'),
      meta: {
        requiresAuth: true,
        title: 'Edit Modifiers'
      }
    },
    {
      path: '/profile/:id',
      name: 'PublicProfile',
      component: () =>
        import('fod-core/src/components/profile/PublicProfile.vue'),
      meta: {
        title: 'Profile View'
      }
    },
    {
      path: '/product/edit/:uid?/:id',
      alias: '/product/edit/:uid/:id',
      component: EditProduct,
      meta: {
        requiresAuth: true,
        title: 'Edit Product '
      }
    },
    {
      path: '/product/quickadd/:uid',
      component: ProductQuickAdd,
      meta: {
        requiresAuth: true,
        title: 'Quickly Add a Product'
      }
    },
    {
      path: '/address/:type/:id?',
      name: 'CurrentAddress',
      component: () =>
        import('fod-core/src/components/profile/ChangeAddress.vue'),
      meta: {
        title: 'Set address'
      }
    },
    {
      path: '/dish/label/:id',
      component: FoodPublicLabel,
      meta: {
        title: 'Product label'
      }
    },
    {
      path: '/dish/new',
      component: AddDish,
      meta: {
        //requiresAuth: true,
        title: 'Add new'
      }
    },

    {
      path: '/accounts',
      component: AccountView,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/account/bank',
      component: () => import('@/components/account/BankAccount'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/account/transactions',
      component: () => import('@/components/account/AccountTransactions'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/account/refunds',
      component: () => import('@/components/account/AccountRefunds'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/account/extra-charges',
      component: () => import('@/components/account/AccountExtraCharges'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/accounts/bank/:id?',
      component: () => import('@/components/account/BankAccount'),
      meta: {
        requiresAuth: true
      }
    },

    {
      path: '/accounts/connect-success',
      component: () => import('@/components/account/ConnectSuccess')
    },
    {
      path: '/accounts/connect-fail',
      component: () => import('@/components/account/ConnectFail')
    },
    {
      path: '/accounts/payment/:id?',
      component: () => import('@/components/account/PaymentAccount'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/settings/delivery-subsidy',
      component: () => import('@/components/setup/DeliverySubsidy'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/settings',
      component: () => import('@/components/setup/Settings'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/coupons/new',
      component: () => import('@/components/coupons/EditCoupons'),
      meta: {
        title: 'Generate new coupon',
        requiresAuth: true,
        param: 'wizard'
      }
    },
    {
      path: '/coupons/edit/:id',
      component: () => import('@/components/coupons/EditCoupons'),
      meta: {
        title: 'Generate new coupon',
        requiresAuth: true
      }
    },
    {
      path: '/coupons/share/:id',
      component: () => import('@/components/coupons/ShareCoupon'),
      meta: {
        title: 'Share coupon',
        requiresAuth: true
      }
    },
    {
      path: '/coupons/list',
      component: () => import('@/components/coupons/ListPromotions'),
      meta: {
        title: 'Coupon List',
        requiresAuth: true
      }
    },
    {
      path: '/coupons/list/:uid',
      component: () => import('@/components/coupons/ListPromotions'),
      meta: {
        title: 'Coupon List',
        requiresAuth: true
      }
    },
    {
      path: '/signup/:type?',
      name: 'Signup',
      component: Signup,
      meta: {
        title: 'Chef Signup'
      }
    },
    {
      path: '/phone-signin',
      name: 'PhoneSignin',
      component: PhoneSignin
    },
    {
      path: '/profile',
      name: 'Profile',
      component: Profile,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/admin/profile/:id',
      name: 'AdminProfile',
      component: Profile,
      meta: {
        requiresAuth: true,
        title: 'User Profile',
        permission: 'admin'
      }
    },
    {
      path: '/profile/address',
      name: 'ProfileAddress',
      component: () =>
        import('fod-core/src/components/profile/ProfileAddress.vue'),
      meta: {
        title: 'Profile address'
      }
    },
    {
      path: '/company/profile',
      name: 'Company Profile',
      component: () => import('@/components/company/CompanyProfile.vue'),
      meta: {
        title: 'Company Profile',
        requiresAuth: true
      }
    },
    {
      path: '/messages',
      name: 'Messages',
      meta: {
        title: 'Message',
        requiresAuth: true
      },
      component: ListMessages
    },
    {
      path: '/messages/:id',
      name: 'New Message',
      meta: {
        requiresAuth: true,
        title: 'New Message '
      },
      component: NewMessage
    },
    {
      path: '/post/add',
      component: PostDish,
      meta: {
        title: 'message.user',
        breadcrumb: 'Dish / Photo'
      }
    },
    {
      path: '/roi',
      component: () => import('fod-core/src/components/util/RoiCalculator.vue'),
      meta: {
        title: 'RIO Calculator'
      }
    },
    {
      path: '/pricing',
      component: () => import('fod-core/src/components/util/ChefPricing.vue'),
      meta: {
        title: 'TakeIn Pricing'
      }
    },

    {
      path: '/admin/home',
      component: () => import('@/components/admin/Dashboard.vue'),
      meta: {
        requiresAuth: true,
        title: 'TakeIn Pricing',
        permission: 'admin'
      }
    },
    {
      path: '/admin/manage_users',
      component: () => import('@/components/admin/ManageUsers.vue'),
      meta: {
        requiresAuth: true,
        title: 'TakeIn Pricing',
        permission: 'admin'
      }
    },
    {
      path: '/admin/settings',
      component: () => import('@/components/admin/Settings'),
      meta: {
        requiresAuth: true,
        permission: 'admin'
      }
    },
    {
      path: '/admin/support',
      component: () => import('@/components/admin/Support'),
      meta: {
        requiresAuth: true,
        permission: 'admin'
      }
    },
    {
      path: '/admin/support/list',
      component: () => import('@/components/admin/support/SupportList'),
      meta: {
        requiresAuth: true,
        permission: 'admin'
      }
    },
    {
      path: '/admin/cities/list',
      component: () => import('@/components/admin/cities/CityList'),
      meta: {
        requiresAuth: true,
        permission: 'admin'
      }
    },
    {
      path: '/admin/cities/edit/:id?',
      component: () => import('@/components/admin/cities/CityEdit'),
      meta: {
        requiresAuth: true,
        permission: 'admin'
      }
    },
    {
      path: '/admin/communities/list',
      component: () => import('@/components/admin/communities/CommunityList'),
      meta: {
        requiresAuth: true,
        permission: 'admin'
      }
    },
    {
      path: '/admin/pm/list',
      component: () => import('@/components/admin/pm/PmList'),
      meta: {
        requiresAuth: true,
        permission: 'admin'
      }
    },
    {
      path: '/admin/communities/edit/:id?',
      component: () => import('@/components/admin/communities/CommunityEdit'),
      meta: {
        requiresAuth: true,
        permission: 'admin'
      }
    },
    {
      path: '/admin/carousel/list',
      component: () => import('@/components/admin/carousel/CarouselList'),
      meta: {
        requiresAuth: true,
        permission: 'admin'
      }
    },
    {
      path: '/admin/carousel/edit/:id?',
      component: () => import('@/components/admin/carousel/EditCarousel'),
      meta: {
        requiresAuth: true,
        permission: 'admin'
      }
    },
    {
      path: '/admin/marketing/messages',
      component: () => import('@/components/admin/marketing/PushList'),
      meta: {
        requiresAuth: true,
        permission: 'admin'
      }
    },
    {
      path: '/admin/marketing/push/:id?',
      component: () => import('@/components/admin/marketing/EditPush'),
      meta: {
        requiresAuth: true,
        permission: 'admin'
      }
    },
    {
      path: '/admin/support/view/:id',
      component: () => import('@/components/admin/support/SupportView'),
      meta: {
        requiresAuth: true,
        permission: 'admin'
      }
    },
    {
      path: '/admin/env',
      component: () => import('@/components/admin/ModifyEnvironment'),
      meta: {
        requiresAuth: true,
        permission: 'admin'
      }
    },
    {
      path: '/admin/settings/categories',
      component: () => import('@/components/admin/Categories'),
      meta: {
        requiresAuth: true,
        permission: 'admin'
      }
    },
    {
      path: '/admin/update/list',
      component: () => import('@/components/admin/update/UpdateList'),
      meta: {
        requiresAuth: true,
        permission: 'admin'
      }
    },
    {
      path: '/admin/update/edit/:id?',
      component: () => import('@/components/admin/update/UpdateEdit'),
      meta: {
        requiresAuth: true,
        permission: 'admin'
      }
    }
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

// https://github.com/bradtraversy/vue_firestore_employeemanager/blob/auth/src/router/index.js
router.beforeEach(async (to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  //set language
  // if (store.state.language.language && store.state.language.language !== i18n.locale) {
  //   i18n.locale = store.state.language.language;
  //   next();
  // } else if (!store.state.language.language) {
  //   store.dispatch('language/setLanguage', navigator.languages)
  //     .then(() => {
  //       i18n.locale = store.state.language.language;
  //       next();
  //     });
  // } else {
  //   next();
  // }
  if (!to.matched.length) {
    next({
      path: '/notFound',
      query: {
        path: to.fullPath
      }
    })
  } else {
    next()
  }
  // Check for requiresAuth guard
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // Check if NO logged user
    if (!firebase.auth().currentUser) {
      // Go to login
      next({
        path: '/signin',
        query: {
          redirect: to.fullPath
        }
      })
    } else {
      // Proceed to route
      const user = store.getters.user

      if (user) {
        if (to.meta.permission) {
          console.log('Check permission:', to.meta.permission)
          switch (to.meta.permission) {
            case 'admin':
              if (store.getters.isAdmin) {
                next()
              } else {
                await store.dispatch('checkAdmin').then(isAdmin => {
                  if (isAdmin) {
                    next()
                  } else {
                    console.log('User is not admin:', user)
                    next({
                      path: '/signin',
                      query: {
                        redirect: to.fullPath
                      }
                    })
                  }
                })
              }
              break
          }
        }
      } else {
        next()
      }
    }
  } else if (to.matched.some(record => record.meta.requiresGuest)) {
    // Check if NO logged user
    if (firebase.auth().currentUser) {
      // Go to login
      next({
        path: '/',
        query: {
          redirect: to.fullPath
        }
      })
    } else {
      // Proceed to route
      next()
    }
  } else {
    // Proceed to route
    next()
  }
})
export default router
