<template>
  <v-row v-if="!loading">
    <v-col cols="12">
      <v-alert :value="true" dismissible color="info" icon="info" outlined>
        Your user does NOT have Chef permission. Would you like to visit
        <a :href="portalAddress + url"> TakeIn</a> Marketplace?
        <p class="info--text">
          Or Submit a
          <a class="nono" :href="'https://' + portalAddress + '/chef/apply'">
            <v-btn small color="success">Request</v-btn>
          </a>
          to change your profile to Chef status.
        </p>
      </v-alert>
    </v-col>
  </v-row>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    url: {
      default: '/foods'
    }
  },
  computed: {
    ...mapGetters({
      portalAddress: 'portalAddress',
      loading: 'loading'
    })
  }
}
</script>

<style scoped>
.nono {
  text-decoration: none;
}
</style>
