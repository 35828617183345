<template>
  <v-container v-if="profile">
    <simple-header :profile="profile" />
    <board-stepper ref="boardstepper" />
    <router-view></router-view>
  </v-container>
</template>
<script>
import SimpleHeader from './SimpleHeader'
import BoardStepper from './BoardStepper'
import { mapGetters } from 'vuex'

export default {
  components: {
    SimpleHeader,
    BoardStepper
  },
  computed: {
    ...mapGetters({
      profile: 'profile'
    })
  },

  mounted() {
    if (this.profile && this.profile.status === 'approved') {
      if (!this.$route.query.show) {
        this.$router.push('/')
      }
    }
  }
}
</script>
