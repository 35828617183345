<template>
  <div>
    <v-container fluid>
      <v-row>
        <v-col v-if="user && !user.emailVerified" cols="12" sm="8">
          <v-alert outlined color="error" icon="warning" :value="true">
            Your account is not verified yet. Please check your email and
            activate your account or update your
            <router-link to="/my/profile">profile</router-link>
          </v-alert>
        </v-col>
      </v-row>
      <template>
        <v-row no-gutters justify="end">
          <v-col cols="1" md="2" lg="1">
            <v-app-bar-nav-icon
              @click.stop="drawer = !drawer"
            ></v-app-bar-nav-icon>
          </v-col>
        </v-row>
        <missing-address :profile="profile"></missing-address>
        <v-container fluid class="grey lighten-4">
          <v-row>
            <v-col class="col" v-if="hasProducts">
              <v-speed-dial
                v-model="fab"
                class="mx-3"
                fixed
                bottom
                left
                direction="top"
                style="z-index: 99999"
              >
                <template v-slot:activator>
                  <v-btn v-model="fab" color="blue darken-2" dark fab>
                    <v-icon v-if="fab">mdi-close</v-icon>
                    <v-icon v-else>add</v-icon>
                  </v-btn>
                </template>

                <v-btn
                  fab
                  :to="'/product/edit/' + this.uid + '/new'"
                  dark
                  color="green"
                >
                  <v-icon>description</v-icon>
                </v-btn>

                <v-btn fab :to="'/product/quickadd/' + uid" dark color="indigo">
                  <v-icon>directions_run</v-icon>
                </v-btn>
              </v-speed-dial>
            </v-col>
          </v-row>
          <v-row align="center" v-if="hasProducts">
            <v-col cols="12" md="4">
              <h3>{{ $t('Chef.products_list_title') }}</h3>
            </v-col>

            <template v-if="hasProducts">
              <v-col cols="2" sm="2" md="2" lg="1">
                <router-link to="/product/managemodifiers"
                  >Modifiers</router-link
                >
              </v-col>
              <v-col cols="2" sm="2" md="2" lg="1">
                <router-link
                  :to="uid ? '/product/menu/' + uid : '/product/menu'"
                  >Menus</router-link
                >
              </v-col>

              <v-col cols="2" sm="2" md="2" lg="1">
                <router-link to="/product/inventory">Inventory</router-link>
              </v-col>
            </template>
            <v-col cols="4" sm="2" md="2">
              <router-link to="/product/export">Export/Import</router-link>
            </v-col>
          </v-row>
          <!-- if dishlist is empty or null, it hides name search and cat filter -->

          <search-product
            v-if="hasProducts"
            :menus="non_empty_menu"
            :dishList="dishList"
            @filterProduct="filterProduct"
          />

          <!-- <v-banner sticky single-line style="top: 120px">
          <template v-if="hasMenus">
            <v-tabs
              background-color="blue lighten-4"
              center-active
              light
              show-arrows
              class="d-flex justify-center"
            >
              <v-tab
                v-for="(menu, key) in availableMenus"
                :key="key"
                class="caption"
                @click.prevent="jumpToMenu(key + 1)"
              >
                <span class="red--text text-subtitle-1 font-weight-bold">{{
                  menu.name
                }}</span>
              </v-tab>
            </v-tabs>
          </template>
        </v-banner> -->
          <!-- menu tab -->
          <v-row v-if="!show_all_products" no-gutters>
            <v-col cols="12">
              <v-container
                fluid
                v-for="(menu, key) in availableMenus"
                :key="key"
              >
                <v-card-title
                  :id="'tab-' + (key + 1)"
                  primary-title
                  v-if="menu.items && menu.items.length > 0"
                >
                  <h4 class="mr-2">{{ menu.name }}</h4>
                  <v-divider></v-divider>
                </v-card-title>

                <v-row class="mr-4">
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                    lg="3"
                    v-for="dish in productsByMenu(menu.id)"
                    :key="dish.id"
                  >
                    <dish-detail
                      :dish="dish"
                      :profile="userProfile"
                    ></dish-detail>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
          </v-row>
          <!-- all products tab -->
          <v-row v-if="show_all_products">
            <v-col cols="11">
              <v-row
                v-if="hasProducts"
                class="mr-5"
              >
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                  lg="3"
                  v-for="dish in filteredList"
                  :key="dish.id"
                >
                  <dish-detail
                    :dish="dish"
                    :profile="userProfile"
                  ></dish-detail>
                </v-col>
              </v-row>
              <v-row v-else>
                <v-col cols="12" sm="6">
                  <h4>
                    <span v-html="$t('Chef.products_list_empty_title')"></span>
                  </h4>
                </v-col>
                <v-col class="justify" cols="12" sm="6">
                  <v-btn
                    :to="'/product/quickadd/' + uid"
                    dark
                    class="ma-3"
                    color="pink"
                  >
                    <v-icon>add</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <!-- right nav drawer -->
          <v-row v-if="hasMenus">
            <v-navigation-drawer
              fixed
              v-model="drawer"
              temporary
              right
              :width="maxNameLength + 'em'"
              class="mt-12"
            >
              <v-list dense>
                <v-list-item-group v-model="menuList" color="primary">
                  <v-list-item v-for="(menu, key) in availableMenus" :key="key">
                    <v-list-item-content @click.prevent="jumpToMenu(key + 1)">
                      <v-row no-gutters>
                        <v-col class="flex-grow-1 fs-14 word-break">
                          {{ menu.name }}</v-col
                        ></v-row
                      >
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
              <v-divider></v-divider>

              <v-list>
                <v-list-item-group>
                  <v-list-item>
                    <v-list-item-content @click="show_all_products = true"
                      >All</v-list-item-content
                    >
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-navigation-drawer>
          </v-row>
        </v-container>
      </template>
    </v-container>
    <v-footer inset width="auto" class="py-12" v-if="hasProducts">
      <v-col cols="1">
        <v-spacer></v-spacer>
        <v-btn fab dark color="primary" @click="onRefreshDishList">
          <v-icon dark>cached</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="1">
        <v-btn fab dark color="red" @click="toTop">
          <v-icon dark>keyboard_arrow_up</v-icon>
        </v-btn>
      </v-col>
    </v-footer>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import MissingAddress from './MissingAddress'
import DishDetail from './DishDetail'
import SearchProduct from './SearchProduct'
export default {
  components: {
    DishDetail,
    MissingAddress,
    SearchProduct
  },

  data() {
    return {
      drawer: false,
      food_menu_filter: [],
      filteredList: [],
      show_all_products: false,
      action: null,
      non_empty_menu: [],
      menuList: 0,
      availableInMarket: false,
      fab: false,
      uid: null
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
      myProfile: 'profile',
      dishList: 'dishList',
      menus: 'menus',
      isAdmin: 'isAdmin',
      userProfile: 'userProfile'
    }),
    availableMenus() {
      return this.menus
        ? this.menus.filter(menu => menu.items && menu.items.length > 0)
        : []
    },
    hasMenus() {
      return this.menus && this.menus.length > 0
    },
    profile() {
      if (this.$route.params.uid) {
        return this.userProfile
      }
      return this.myProfile
    },
    maxNameLength() {
      const defaultMax = 8
      if (!this.availableMenus || this.availableMenus.length === 0) {
        return defaultMax
      }

      const menu = this.availableMenus.reduce((max, menu) => {
        return menu.name.length > max.name.length ? menu : max
      })
      if (!menu) {
        return defaultMax
      }
      // console.log(defaultMax + menu.name.length * Math.log(menu.name.length))
      return menu.name.length > defaultMax + 6 ? defaultMax + 3 : defaultMax
    },
    hasProducts() {
      return this.filteredList && this.filteredList.length > 0;
    }
  },
  async mounted() {
    await this.$store.dispatch('checkAdmin')
    this.drawer = this.$vuetify.breakpoint.mdAndUp
    this.uid =
      this.isAdmin && this.$route.params.uid
        ? this.$route.params.uid
        : this.user
        ? this.user.id
        : null

    console.log(`Loading products of ${this.uid}`)
    await this.$store.dispatch('loadUserProfile', { uid: this.uid })
    await this.$store.dispatch('loadDishList', { uid: this.uid, summary: true })
    this.$store.dispatch('loadMenus', { uid: this.uid }).then(list => {
      let sub_menus = list ? list.filter(menu => menu.type != 'parent') : []
      if (sub_menus && sub_menus.length > 0 && this.dishList.length > 10) {
        // if there is at least one catgeory and there are more than 10 products
        this.show_all_products = false
      } else {
        this.show_all_products = true
      }
    })
    this.filteredList = this.dishList
    // filters through empty menus and adds to array for combobox
    let list = []

    if (this.menus) {
      this.menus.forEach(menu => {
        if (menu.items) {
          list.push(menu)
        }
      })
      this.non_empty_menu = list
    }
  },
  methods: {
    filterProduct(productList) {
      this.filteredList = productList
    },
    onRefreshDishList() {
      this.$store.dispatch('loadDishList', { uid: this.uid, force: true })
    },
    toTop() {
      this.$router.push({ hash: '' })
      this.$vuetify.goTo(0)
    },
    onDelete(id) {
      this.$store.dispatch('deleteFood', id)
    },
    productsByMenu(id) {
      let menu = this.menus.find(f => f.id === id)

      if (menu && menu.items) {
        let list = []
        menu.items.forEach(element => {
          let dish = this.filteredList
            ? this.filteredList.filter(d => d.id === element)
            : []
          if (dish && dish.length > 0) {
            list.push(dish[0])
          }
        })

        return list
      }
      return []
    },
    jumpToMenu(key) {
      this.show_all_products = false
      setTimeout(() => {
        if (key) this.$vuetify.goTo('#tab-' + key)
      }, 500)
    }
  }
}
</script>
<style scoped>
.word-break {
  word-break: break-all;
}
</style>
