<template>
  <v-container grid-list-xs>
    <v-row v-if="showNumberInput">
      <v-col cols="12" sm="4">
        <v-text-field
          name="Phone Number"
          v-model="phoneNumber"
          placeholder="+15554443333"
          label="Phone number"
          id="numberInputForm"
        ></v-text-field>
      </v-col>

      <v-col cols="12" sm="4">
        <v-btn
          id="get-sign-in-code"
          class="btn btn-block btn-lg success theme-accent"
          >{{ getSignInCodeButton.text }}
        </v-btn>
      </v-col>
    </v-row>

    <!-- SMS Verification Form -->
    <div v-if="showCodeInput">
      <v-row>
        <v-col cols="12" sm="4">
          <v-text-field
            v-model="verificationCode"
            label="Verification Code"
            id="verificationCode"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-btn color="secondary" @click="signIn">Verify</v-btn>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import firebase from 'firebase'

export default {
  name: 'SignIn',

  data() {
    return {
      // UI States
      showNumberInput: true,
      showCodeInput: false,
      phoneNumber: null,
      verificationCode: null,
      confirmationResult: null,
      // Buttons
      getSignInCodeButton: {
        text: 'Get sign in code'
      },
      signInButton: {
        text: 'Sign in'
      }
    }
  },

  mounted() {
    const self = this

    // Start Firebase invisible reCAPTCHA verifier
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      'get-sign-in-code',
      {
        size: 'invisible',
        callback: () => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          self.sendSMS()
        }
      }
    )

    window.recaptchaVerifier.render().then(widgetId => {
      window.recaptchaWidgetId = widgetId
    })
  },

  methods: {
    /**
     * Sends the user an SMS-verification code using Firebase auth
     *
     * @see https://firebase.google.com/docs/auth/web/phone-auth
     */
    sendSMS() {
      const self = this
      var appVerifier = window.recaptchaVerifier

      console.log('Sending', this.phoneNumber)
      console.log(appVerifier)
      firebase
        .auth()
        .signInWithPhoneNumber(this.phoneNumber, appVerifier)
        .then(function(confirmationResult) {
          // SMS sent. Prompt user to type the code from the message, then sign the
          // user in with confirmationResult.confirm(code).
          console.log(confirmationResult)
          self.showCodeInput = true
          self.showNumberInput = false
          window.confirmationResult = confirmationResult
          self.confirmationResult = confirmationResult
        })
        .catch(function(error) {
          // Error; SMS not sent
          // ...
          console.error(error)
        })

      self.getSignInCodeButton = {
        showSpinner: true,
        text: 'Sending SMS..',
        disabled: true
      }
    },

    /**
     * Authenticates the user with Firebase auth
     */
    signIn() {
      if (this.confirmationResult) {
        console.log('Signin with:', this.verificationCode)
        //this.$store.dispatch('signUserWithPhone', this.verificationCode)
        console.log(this.confirmationResult)
        this.$store.dispatch('signUserWithPhone', {
          confirmationResult: this.confirmationResult,
          verificationCode: this.verificationCode
        })
        this.$router.push('/')
      }
    }
  }
}
</script>
