<template>
  <v-container>
    <v-text-field
      v-model="email"
      :loading="isLoading"
      @blur="lookup"
      label="User Email"
      placeholder="Lookup by email"
      prepend-icon="mdi-email"
      return-object
    ></v-text-field>

    <template v-if="entries && entries.length > 0">
      <v-divider></v-divider>
      <user-profile :profile="entries[0]" :showButton="false" :showStats="false"
    /></template>
  </v-container>
</template>

<script>
  import UserProfile from "./UserProfile.vue";

  export default {
    components: {
      UserProfile,
    },
    data: () => ({
      entries: [],
      isLoading: false,
      email: null,
    }),

    computed: {
      fields() {
        if (!this.model) return [];
        return Object.keys(this.model).map((key) => {
          return {
            key,
            value: this.model[key] || "n/a",
          };
        });
      },
      items() {
        if (!this.entries) {
          return [];
        }
        return this.entries.map((entry) => {
          return Object.assign({}, entry, { entry });
        });
      },
    },

    methods: {
      async lookup(val) {
        // Items have already been requested
        if (this.isLoading) return;
        if (!this.email || this.email.length==0){
          return
        }
        
        console.log(`Lookup for ${this.email}`)
        this.isLoading = true;

        await this.$store
          .dispatch("userLookup", { email: this.email ? this.email : val })
          .then((res) => {
            this.entries = res  
            
            console.log(this.entries);
          })
          .catch((e) => console.error(e))
          .finally(() => (this.isLoading = false));
        if (this.entries && this.entries.length > 0) {
          this.$emit("selectedUser", this.entries[0]);
        }
      },
    },
  };
</script>
