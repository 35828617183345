<template>
  <div v-if="profile">
    <v-list-item class="sidebar-profile">
      <v-list-item-avatar class="hidden-sm-and-down" v-if="profile.avatar">
        <img
          :src="profile.avatar"
          alt="avatar"
          height="40"
          width="40"
          class="img-responsive"
        />
      </v-list-item-avatar>
      <v-list-item-content class="hidden-sm-and-down ml-2 ">
        <v-list-item-title
          ><span>{{ profile.name }}</span></v-list-item-title
        >
      </v-list-item-content>

      <v-menu
        bottom
        height="100"
        offset-y
        left
        content-class="userblock-dropdown"
        nudge-top="-10"
        nudge-right="0"
        transition="slide-y-transition"
        style="z-index: 999"
      >
        <template v-slot:activator="{on}">
          <v-btn dark icon v-on="on" class="ma-0">
            <v-icon>more_vert</v-icon>
          </v-btn>
        </template>
        <div class="dropdown-content">
          <div class="dropdown-top white--text primary">
            <span class="white--text fs-14 fw-bold d-block">{{
              profile.name
            }}</span>
            <span class="d-block fs-12 fw-light">{{ profile.title }}</span>

          </div>
          <v-list class="dropdown-list">
            <template v-for="userLink in userLinks" v-if="userLink.id !== 4">
              <v-list-item :to="getMenuLink(userLink.path)" :key="userLink.id">
                <v-icon class="mr-3 " :color="userLink.color">{{
                  userLink.icon
                }}</v-icon>
                <span>{{ $t(userLink.title) }}</span>

                <!-- message notification count -->
                <v-badge color="orange" overlap v-if="userLink.title == 'Common.inbox' && newMessageCount > 0">
                  <template v-slot:badge><div class="white--text">{{newMessageCount}}</div></template>
                  <v-icon color="grey">notifications</v-icon>
                </v-badge>
              </v-list-item>
            </template>
            <template v-else>
              <v-list-item @click="logoutUser" :key="userLink.id">
                <v-icon class="mr-3 " :color="userLink.color">{{
                  userLink.icon
                }}</v-icon>
                <span>{{ $t(userLink.title) }}</span>
              </v-list-item>
            </template>
          </v-list>
        </div>
      </v-menu>
    </v-list-item>
  </div>
</template>
<script>
//import { getCurrentAppLayout } from 'Helpers/helpers'
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      profile: 'profile',
      newMessageCount: 'newMessageCount',
    })
  },
  data() {
    return {
      userLinks: [
        {
          id: 1,
          title: 'Common.my_profile',
          icon: 'person',
          color: 'green',
          path: '/my/profile'
        },
        {
          id: 2,
          title: 'Common.inbox',
          icon: 'mail',
          path: '/messages'
        },
        {
          id: 3,
          title: 'Common.invitations',
          icon: 'fas fa-user-friends',
          color: 'cyan',
          path: '/invite/list'
        },
        {
          id: 4,
          color: 'red',
          title: 'Common.logout',
          icon: 'lock_open'
        }
      ]
    }
  },
  methods: {
    logoutUser() {
      this.$store.dispatch('logout')
      this.$router.go('/signin')
    },
    getMenuLink(path) {
      return path
    }
  }
}
</script>
