var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[(!_vm.hideAppbar)?_c('app-header'):_vm._e(),_c('v-content',{staticClass:"pb-3 mt-6"},[(_vm.loading && !_vm.newUser && !_vm.hideAppbar)?[_c('v-row',[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('v-progress-circular',{staticClass:"primary--text",attrs:{"indeterminate":"","width":7,"size":70}})],1)],1)]:_vm._e(),_c('v-row',{attrs:{"justify":"center","align":"center"}},[(_vm.newAppVersion)?_c('v-col',{staticClass:"mb-3 mt-1 ",attrs:{"cols":"11"}},[_c('v-banner',{attrs:{"sticky":"","elevation":"1","transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"actions",fn:function(ref){
var dismiss = ref.dismiss;
return [_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":_vm.refreshVersion}},[_vm._v("Refresh")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":dismiss}},[_vm._v("\n              Dismiss\n            ")])]}}],null,false,1950035998)},[_c('v-row',{staticClass:"py-1 ",attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"2"}},[_c('v-img',{attrs:{"width":"30","src":"https://storage.googleapis.com/download.takein.com/apps/logo/takein_sym_100.png"}})],1),_c('span',{staticClass:"body-2"},[_vm._v(" A new version of TakeIn is available")])],1)],1)],1):_vm._e(),(
          _vm.isChef &&
            _vm.systemUpdates &&
            _vm.systemUpdates.length > 0 &&
            !_vm.user.showOnboard &&
            !_vm.newUser &&
            !_vm.hideAppbar
        )?_c('v-col',{attrs:{"cols":"11"}},[_c('v-banner',{staticClass:"ma-1",attrs:{"elevation":"1","transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"actions",fn:function(ref){
        var dismiss = ref.dismiss;
return [_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":_vm.updateDismiss}},[_vm._v("Dismiss")])]}}],null,false,2279572438),model:{value:(_vm.updates_dismiss),callback:function ($$v) {_vm.updates_dismiss=$$v},expression:"updates_dismiss"}},[_vm._l((_vm.systemUpdates),function(update){return [_c('div',{key:update.id},[_c('v-subheader',{staticClass:"pa-0 subtitle-1",attrs:{"light":""}},[_vm._v("\n                "+_vm._s(update.title)+"\n              ")]),_c('span',{staticClass:"subtitle-2",domProps:{"innerHTML":_vm._s(_vm.markedDown(update))}})],1)]})],2)],1):_vm._e()],1),_c('router-view',{staticClass:"view one"}),_c('router-view',{staticClass:"view two",attrs:{"name":"a"}}),_c('router-view',{staticClass:"view three",attrs:{"name":"b"}}),_c('router-view',{staticClass:"view three",attrs:{"name":"chef"}}),(_vm.error)?_c('v-snackbar',{attrs:{"timeout":6000,"color":"red","multi-line":true,"vertical":false},on:{"input":_vm.done},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v("\n      "+_vm._s(_vm.error.message)+"\n      "),_c('v-btn',{attrs:{"dark":"","text":""},on:{"click":_vm.clearMessage}},[_vm._v("Close")])],1):_vm._e(),(_vm.message)?_c('v-snackbar',{attrs:{"timeout":20000,"color":_vm.message.color ? _vm.message.color : 'info',"multi-line":true,"vertical":false},on:{"input":_vm.done},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v("\n      "+_vm._s(_vm.message.title)+"\n      "),_c('br'),_vm._v("\n      "+_vm._s(_vm.message.body)+"\n      "),_c('v-btn',{attrs:{"dark":"","text":""},on:{"click":_vm.clearMessage}},[_vm._v("Close")])],1):_vm._e(),_c('app-fab')],2),_c('app-footer')],1)}
var staticRenderFns = []

export { render, staticRenderFns }