<template>
  <v-app>
    <app-header v-if="!hideAppbar" />

    <v-content class="pb-3 mt-6">
      <template v-if="loading && !newUser && !hideAppbar">
        <v-row>
          <v-col cols="12" class="text-center">
            <v-progress-circular
              indeterminate
              class="primary--text"
              :width="7"
              :size="70"
            ></v-progress-circular>
          </v-col>
        </v-row>
      </template>
      <v-row justify="center" align="center">
        <v-col cols="11" v-if="newAppVersion" class="mb-3 mt-1 ">
          <v-banner sticky elevation="1" transition="slide-y-transition">
            <v-row align="center" class="py-1 ">
              <v-col cols="2">
                <v-img
                  width="30"
                  src="https://storage.googleapis.com/download.takein.com/apps/logo/takein_sym_100.png"
                ></v-img>
              </v-col>
              <span class="body-2"> A new version of TakeIn is available</span>
            </v-row>

            <template v-slot:actions="{ dismiss }">
              <v-btn text color="primary" @click="refreshVersion"
                >Refresh</v-btn
              >

              <v-btn text color="primary" @click="dismiss">
                Dismiss
              </v-btn>
            </template>
          </v-banner>
        </v-col>
        <v-col
          cols="11"
          v-if="
            isChef &&
              systemUpdates &&
              systemUpdates.length > 0 &&
              !user.showOnboard &&
              !newUser &&
              !hideAppbar
          "
        >
          <v-banner
            v-model="updates_dismiss"
            elevation="1"
            transition="slide-y-transition"
            class="ma-1"
          >
            <template v-for="update in systemUpdates">
              <div :key="update.id">
                <v-subheader light class="pa-0 subtitle-1">
                  {{ update.title }}
                </v-subheader>
                <span class="subtitle-2" v-html="markedDown(update)"></span>
              </div>
            </template>
            <template v-slot:actions="{ dismiss }">
              <v-btn text color="primary" @click="updateDismiss">Dismiss</v-btn>
            </template>
          </v-banner>
        </v-col>
      </v-row>

      <router-view class="view one"></router-view>
      <router-view class="view two" name="a"></router-view>
      <router-view class="view three" name="b"></router-view>
      <router-view class="view three" name="chef"></router-view>

      <v-snackbar
        v-if="error"
        :timeout="6000"
        color="red"
        :multi-line="true"
        :vertical="false"
        @input="done"
        v-model="snackbar"
      >
        {{ error.message }}
        <v-btn dark text @click="clearMessage">Close</v-btn>
      </v-snackbar>

      <v-snackbar
        v-if="message"
        :timeout="20000"
        :color="message.color ? message.color : 'info'"
        :multi-line="true"
        :vertical="false"
        @input="done"
        v-model="snackbar"
      >
        {{ message.title }}
        <br />
        {{ message.body }}
        <v-btn dark text @click="clearMessage">Close</v-btn>
      </v-snackbar>

      <app-fab />
    </v-content>
    <app-footer />
  </v-app>
</template>

<script>
import AppHeader from './components/Header'
import AppFab from './components/AppFab'
import AppFooter from './components/Footer'
import marked from 'marked'
import moment from 'moment'

import { mapGetters } from 'vuex'

let audio = new Audio('/audio/the-little-dwarf.ogg')

export default {
  components: { AppHeader, AppFab, AppFooter },
  name: 'app',
  data() {
    return {
      updates_dismiss: true,
      snackbar: true,
      profileMessage: null,
      newOrderTimer: null
    }
  },
  computed: {
    ...mapGetters({
      loading: 'loading',
      user: 'user',
      error: 'error',
      newOrders: 'newOrders',
      message: 'message',
      isChef: 'isChef',
      newUser: 'newUser',
      newAppVersion: 'newAppVersion',
      systemUpdates: 'systemUpdates',
      approvalStatus: 'approvalStatus',
      myProfile: 'profile',
      userProfile: 'userProfile'
    }),
    hideAppbar() {
      return this.approvalStatus && this.approvalStatus != 'approved'
    },
    profile() {
      if (this.$route.params.uid) {
        return this.userProfile
      }
      return this.myProfile
    }
  },
  methods: {
    newOrderAlarm() {
      console.log('newOrderAlarm:', this.newOrders.length)
      const recent = moment
        .utc()
        .add(-10, 'm')
        .valueOf()

      if (this.newOrders) {
        let alarm = false
        this.newOrders.forEach(order => {
          order.alarm = order.alarm || 0
          if (order.alarm < 3 && order.submitDate > recent) {
            order.alarm++
            alarm = true
            console.log('Alarm order: ' + order.id)
          }
        })
        if (alarm) {
          if (
            this.profile &&
            this.profile.settings &&
            this.profile.settings.disable_order_sound
          ) {
            console.log('Order sounds is off')
            return
          }

          let promise = audio.play()

          if (promise !== undefined) {
            promise
              .then(() => console.log('Played'))
              .catch(e => console.error(e))
          }
        }
      }
    },
    clearMessage() {
      this.snackbar = false
      this.$store.dispatch('clearError')
    },
    markedDown(item) {
      return item.description ? marked(item.description) : ''
    },
    updateDismiss() {
      this.updates_dismiss = false
      this.$store.dispatch('updateDismiss')
    },
    done() {
      this.snackbar = false
      this.$store.dispatch('clearError')
    },
    async refreshVersion() {
      await this.$gtm.trackEvent({
        event: 'takein_event', // Event type [default = 'interaction'] (Optional)
        category: 'app',
        action: 'refresh_new_version',
        value: this.user ? this.user.id : null,
        method: 'refresh_new_version',
        payload: { method: 'refresh_new_version' }
      })
      this.$router.go(this.$router.currentRoute)
    }
  },
  mounted() {
    this.newOrderTimer = setInterval(this.newOrderAlarm, 30000)
  },
  beforeDestroy() {
    clearInterval(this.newOrderTimer)
  },
  watch: {
    error: function() {
      if (this.error && this.error.message) {
        this.snackbar = true
      }
    },
    newOrders: function(newList, oldList) {
      console.log('new orders:', newList.length, '==>', oldList)
    },
    message: function() {
      if (this.message && this.message.body) {
        this.snackbar = true
      }
    }
  }
}
</script>
<style>
@media print {
  .no-print,
  .noprint,
  .no-print * {
    display: none !important;
  }
}
</style>
<style scoped>
.v-footer--absolute,
.app--footer {
  z-index: 0;
}
</style>
