<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="8" md="6" offset-sm="2" offset-md="3">
        <v-card>
          <v-card-text>
            <v-container>
              <form @submit.prevent="onSignin">
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      name="email"
                      label="Email"
                      id="email"
                      v-model="email"
                      type="email"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      name="password"
                      label="Password"
                      id="password"
                      v-model="password"
                      type="password"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12">
                    <div class="text-center pa-2">
                      <v-btn
                        rounded
                        type="submit"
                        :disabled="loading"
                        :loading="loading"
                      >
                        Sign in
                        <v-icon right>lock_open</v-icon>
                        <span slot="loader" class="custom-loader">
                          <v-icon light>cached</v-icon>
                        </span>
                      </v-btn>
                      or
                      <router-link router to="/signup/chef">Signup</router-link>
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-list>
                    Or sign in with:

                    <v-row class="py-3">
                      <v-col class="6" md="4">
                        <v-btn
                          rounded
                          :disabled="loading"
                          :loading="loading"
                          @click.prevent="onSigninGoogle"
                          color="red"
                          class="white--text"
                        >
                          Google
                          <v-icon right dark>fab fa-google white-text</v-icon>
                        </v-btn>
                      </v-col>
                      <v-col class="6" md="4">
                        <v-btn
                          rounded
                          :disabled="loading"
                          :loading="loading"
                          @click.prevent="onSigninFacebook"
                          class="blue white--text"
                        >
                          Facebook
                          <v-icon right dark
                            >fab fa-facebook-f white-text</v-icon
                          >
                        </v-btn>
                      </v-col>
                      <v-col class="6" md="4">
                        <v-btn
                          rounded
                          :disabled="loading"
                          :loading="loading"
                          @click.prevent="onSigninPhone"
                          info
                          class="info white--text"
                        >
                          By Phone
                          <v-icon right dark>fas fa-phone</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>

                    <v-subheader v-if="false">
                      <v-btn
                        rounded
                        :disabled="loading"
                        :loading="loading"
                        @click.prevent="onSigninTwitter"
                        info
                        class="info white--text"
                      >
                        Twitter
                        <v-icon right dark>fab fa-twitter white-text</v-icon>
                      </v-btn>
                    </v-subheader>
                    <v-subheader> </v-subheader>
                  </v-list>
                </v-row>
                <v-card flat class="pa-2">
                  <router-link to="/forgot-password"
                    >Forgot password ?</router-link
                  >
                </v-card>
              </form>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      email: '',
      password: ''
    }
  },
  computed: {
    user() {
      return this.$store.getters.user
    },
    error() {
      return this.$store.getters.error
    },
    loading() {
      return this.$store.getters.loading
    }
  },
  watch: {
    user(value) {
      if (value !== null && value !== undefined) {
        let redirect = this.$route.query.redirect
        if (redirect) {
          this.$router.push(redirect)
        } else {
          this.$router.push('/')
        }
      }
    }
  },
  methods: {
    onSignin() {
      this.$store.dispatch('signUserIn', {
        email: this.email,
        password: this.password
      })
    },
    onSigninGoogle() {
      this.$store.dispatch('signUserInGoogle')
    },
    onSigninPhone() {
      this.$router.push('/phone-signin')
    },
    onSigninFacebook() {
      this.$store.dispatch('signUserInFacebook')
    },
    onSigninGithub() {
      this.$store.dispatch('signUserInGithub')
    },
    onSigninTwitter() {
      this.$store.dispatch('signUserInTwitter')
    },
    onDismissed() {
      this.$store.dispatch('clearError')
    }
  }
}
</script>
