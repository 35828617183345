<template>
  <v-combobox
    v-model="model"
    :filter="filter"
    :hide-no-data="!search"
    :items="items"
    :search-input.sync="search"
    hide-selected
    :label="$t('Common.diet_accommodations')"
    multiple
    small-chips
  >
    <template slot="no-data">
      <v-list-item>
        <span class="subheading">Create</span>
        <v-chip :color="`${colors[nonce - 1]} lighten-3`" label small>{{
          search
        }}</v-chip>
      </v-list-item>
    </template>
    <template
      v-if="item === Object(item)"
      slot="selection"
      slot-scope="{ item, parent, selected }"
    >
      <v-chip
        :color="`${item.color} lighten-3`"
        :input-value="selected"
        label
        small
      >
        <span class="pr-2">{{ item.text }}</span>
        <v-icon small @click="parent.selectItem(item)">close</v-icon>
      </v-chip>
    </template>
    <template slot="item" slot-scope="{ index, item, parent }">
      <v-list-item-content>
        <v-text-field
          v-if="editing === item"
          v-model="editing.text"
          autofocus
          flat
          background-color="transparent"
          hide-details
          solo
          @keyup.enter="edit(index, item)"
        ></v-text-field>
        <v-chip v-else :color="`${item.color} lighten-3`" dark label small>{{
          item.text
        }}</v-chip>
      </v-list-item-content>
      <v-spacer></v-spacer>
      <v-list-item-action v-if="item.editable" @click.stop>
        <v-btn icon @click.stop.prevent="edit(index, item)">
          <v-icon>{{ editing !== item ? 'edit' : 'check' }}</v-icon>
        </v-btn>
      </v-list-item-action>
    </template>
  </v-combobox>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    dietList: {
      type: Array,
      default: null,
      required: true
    }
  },
  data: () => ({
    activator: null,
    attach: null,

    colors: ['green', 'purple', 'indigo', 'cyan', 'teal', 'orange', 'red'],
    editing: null,
    index: -1,
    items: [{ header: 'Select an option or create one' }],
    nonce: 1,
    menu: false,
    model: [],
    x: 0,
    search: null,
    y: 0
  }),
  computed: {
    ...mapGetters({
      currentDiets: 'currentDiets'
    })
  },
  beforeMount() {
    if (this.dietList) {
      for (let index = 0; index < this.dietList.length; index++) {
        const element = this.dietList[index]
        let j = Math.floor(index % this.colors.length)
        let v = {
          text: element,
          editable: false,
          color: this.colors[j]
        }
        this.items.push(v)
      }
    }
    if (this.currentDiets) {
      for (let index = 0; index < this.currentDiets.length; index++) {
        const element = this.currentDiets[index]

        this.model.push({
          text: element,
          color: 'blue'
        })
      }
      console.log(`${this.currentDiets.length} currentDiets loaded`)
    } else {
      console.log('currentDiets is empty')
    }
  },
  watch: {
    model(val, prev) {
      if (val.length === prev.length) return

      this.model = val.map(v => {
        if (typeof v === 'string') {
          v = {
            text: v,
            editable: true,
            color: this.colors[this.nonce - 1]
          }

          this.items.push(v)

          this.nonce++
        }

        return v
      })
      let list = val.map(v => {
        if (typeof v !== 'string') {
          return v.text
        }
        return v
      })
      this.$store.dispatch('updateDiets', list)
    }
  },

  methods: {
    edit(index, item) {
      if (!this.editing) {
        this.editing = item
        this.index = index
      } else {
        this.editing = null
        this.index = -1
      }
    },
    filter(item, queryText, itemText) {
      if (item.header) return false

      const hasValue = val => (val != null ? val : '')

      const text = hasValue(itemText)
      const query = hasValue(queryText)

      return (
        text
          .toString()
          .toLowerCase()
          .indexOf(query.toString().toLowerCase()) > -1
      )
    }
  }
}
</script>
