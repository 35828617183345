<template>
  <!-- This component is similar to ChefAlbum except that it grabs user info from vuex
  For lifecycle purposes, I created a sub component for album. I kept the ChefAlbum page in case a dedicated page is still necessary-->
  <v-container>
    <template v-if="!loading">
      <v-row justify="space-around">
        <v-col cols="12">
          <v-card>
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                  lg="3"
                  v-for="image in album"
                  :key="image.id"
                >
                  <v-card max-width="560" height="315">
                    <div
                      v-if="youtubeUrl(image.imageUrl)"
                      class="d-flex justify-center"
                    >
                      <iframe
                        width="360"
                        height="275"
                        :src="youtubeUrl(image.url)"
                        frameborder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    </div>

                    <div v-else class="d-flex justify-center">
                      <template v-if="isVideo(image.imageUrl)">
                        <v-sheet max-height="275">
                          <video
                            playsinline
                            autoplay
                            muted
                            controls
                            ref="video"
                            class="video-card"
                          >
                            <source
                              :src="image.url || image.imageUrl"
                              type="video/mp4"
                            />
                          </video>
                        </v-sheet>
                      </template>
                      <template v-else>
                        <v-img
                          :src="image.url || image.imageUrl"
                          contain
                          class="image-card"
                        >
                          <v-container
                            class="img fill-height text-center image-hover"
                          >
                            <v-row align="center">
                              <v-col>
                                <strong>{{ image.description }}</strong>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-img>
                      </template>
                    </div>
                    <v-row>
                      <v-col cols="12" class="d-flex justify-center">
                        <template
                          v-show="!isVideo(image.url || image.imageUrl)"
                        >
                          <v-edit-dialog
                            @open="tmp = image.description"
                            @save="saveDescription(tmp, image.id)"
                            large
                          >
                            <v-btn text>
                              <v-icon>mdi-textbox</v-icon>
                            </v-btn>
                            <div slot="input" class="mt-3">
                              Add/Update Description
                            </div>
                            <v-text-field
                              slot="input"
                              label="Edit"
                              v-model="tmp"
                              single-line
                              autofocus
                            ></v-text-field>
                          </v-edit-dialog>
                        </template>
                        <v-btn text @click="removePhoto(image.id)">
                          <v-icon color="red">delete</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                    <v-spacer></v-spacer>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>

            <v-container>
              <v-row>
                <v-col cols="12" md="9" lg="7" class="mx-auto text-center pa-0">
                  <h4 class="headline">{{ $t('Common.add_photo_title') }}</h4>
                  <h5>{{ $t('Common.add_photo_sub') }}</h5>
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col cols="12" md="6">
                  <media-uploader
                    @uploadMedia="uploadMedia"
                    uploadType="media"
                    :hasDescription="true"
                  />
                </v-col>
              </v-row>
              <v-row v-if="showAddYoutube">
                <v-col cols="6">
                  <v-text-field
                    label="Youtube Link"
                    v-model="youtubeLink"
                  ></v-text-field>
                  <v-btn @click="uploadYoutube">Add</v-btn>
                </v-col>
              </v-row>
              <v-row justify="center" class="mt-0 mr-4">
                <v-col cols="1">
                  <v-btn
                    icon
                    class="px-2"
                    @click="showAddYoutube = !showAddYoutube"
                  >
                    <v-icon color="red" large>fab fa-youtube</v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="1">
                  <v-btn text @click.native="getPhotosFromUrl" slot="activator">
                    <v-icon>insert_link</v-icon>
                  </v-btn>

                  <v-dialog
                    v-model="metalink"
                    persistent
                    max-width="700px"
                    width="80%"
                  >
                    <v-card>
                      <v-card-title class="headline-3">
                        Photos from Internet. Just copy and paste the site
                        address:
                        <v-progress-circular
                          v-if="localLoading"
                          indeterminate
                          color="primary"
                        ></v-progress-circular>
                      </v-card-title>
                      <v-card-text>
                        <v-text-field
                          light
                          @change="getPhotosFromUrl"
                          v-model="urlText"
                          prepend-icon="cloud"
                          placeholder="https://www.instagram.com/p/B7y2iLDn86-/"
                        ></v-text-field>
                      </v-card-text>

                      <div v-if="images">
                        <div
                          class="images columns is-multiline"
                          v-if="images.length"
                        >
                          <div
                            class="column is-4"
                            v-for="image in images"
                            @click="onWebPhotoSelected(image.webformatURL)"
                            :key="image.id"
                          >
                            <img class="image" :src="image.webformatURL" />
                          </div>
                        </div>

                        <h5 v-if="!images.length && !isSearching">
                          No results found!
                        </h5>
                      </div>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="green darken-1"
                          text
                          @click.native="metalink = false"
                          >Close</v-btn
                        >
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
      </v-row>

      <v-row justify="space-around" class="my-3">
        <v-col cols="12" class="d-flex justify-center">
          <v-btn fab dark color="primary" @click="onRefreshAlbum">
            <v-icon dark>cached</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import axios from 'axios'
export default {
  props: ['profile'],
  data: () => ({
    user: null,
    hover: false,
    tmp: null,
    metalink: false,
    images: null,
    searchText: null,
    urlText: null,
    isSearching: false,
    localLoading: false,
    youtubeLink: null,
    showAddYoutube: false
  }),
  components: {
    MediaUploader: () =>
      import('fod-core/src/components/common/MediaUploader.vue')
  },
  computed: {
    ...mapGetters({
      loading: 'loading'
    }),
    album() {
      return this.$store.getters.album
    },
    loading() {
      return this.$store.getters.loading
    }
  },
  created() {
    this.user = this.profile
  },
  methods: {
    getPhotosFromUrl() {
      this.metalink = true
      this.images = null
      if (this.urlText && this.urlText.length > 0) {
        this.localLoading = true
        const targetUrl = `${process.env.VUE_APP_API}/scrapeUrl?url=${this.urlText}`
        console.log('Getting photos from:', targetUrl)
        //const targetUrl = `https://api.microlink.io/?url=${this.urlText}`
        axios
          .get(targetUrl)
          .then(res => {
            this.localLoading = false
            console.log(res.data.image)
            if (res.data.image) {
              this.images = [{ webformatURL: res.data.image }]
            }
          })
          .catch(e => {
            console.error(e)
            this.images = []
            this.localLoading = false
          })
      }
    },
    onWebPhotoSelected(url) {
      this.photoOptions = false
      this.metalink = false

      if (url) {
        console.log(url)
        this.$store.dispatch('albumUrlUpload', {
          uid: this.profile.id,
          imageUrl: url
        })
      }
      this.$store.dispatch('loadAlbum', this.profile.id)
    },
    isVideo(url) {
      return url && url.toLowerCase().includes('mp4')
    },
    saveDescription(new_description, image_id) {
      const new_album = this.album.map(d =>
        d.id === image_id ? { ...d, description: new_description } : d
      )
      this.$store.dispatch('albumDescriptionUpdate', {
        uid: this.profile.id,
        album: new_album
      })
      this.$store.dispatch('loadAlbum', this.user.id)
    },
    onRefreshAlbum() {
      this.$store.dispatch('loadAlbum', this.profile.id)
    },
    uploadYoutube() {
      console.log('calling upload youtube')
      this.$store.dispatch('youtubeLinkUpload', {
        uid: this.profile.id,
        webUrl: this.youtubeLink
      })
    },
    uploadMedia(file, img, description) {
      this.$store.dispatch('albumFileUpload', {
        userid: this.user.id,
        description: description,
        file: file,
        image: img
      })
      this.$store.dispatch('loadAlbum', this.profile.id)
    },
    removePhoto(id) {
      this.$store.dispatch('removePhoto', { photoId: id, uid: this.profile.id })
      this.$store.dispatch('loadAlbum', this.profile.id)
    },

    isYoutube(imageUrl) {
      return imageUrl && imageUrl.toLowerCase().indexOf('youtu') >= 0
    },
    youtubeUrl(url) {
      if (!url) return null

      var regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/
      var match = url.match(regExp)
      if (match && match[2].length == 11) {
        return `https://www.youtube.com/embed/${match[2]}`
      }
      return null
    }
  }
}
</script>

<style>
img {
  image-orientation: from-image;
}
.image-hover {
  background: white;
  opacity: 0;
}
.image-hover:hover {
  background: white;
  opacity: 0.8;
  color: black;
  cursor: pointer;
}
.image-hover:active {
  background: white;
  opacity: 0;
}
.icon-color {
  color: green;
}
.video-card {
  margin-top: 8px;
  width: 100%;
  height: 100%;
}
.image-card {
  margin-top: 8px;
  width: 100%;
  height: 100%;
}
</style>
