<template>
  <v-row>
    <v-col cols="12">
      <v-card>
        <v-card-text>
          <v-row class="mx-auto">
            <v-col cols="12">
              <h4 :class="this.$vuetify.breakpoint.xs ? 'text-center' : ''">
                {{ product.dish.name }}
              </h4>
            </v-col>
          </v-row>
          <v-row class="mx-auto">
            <v-col cols="12" v-if="firstPhoto">
              <div :class="this.$vuetify.breakpoint.xs ? 'text-center' : ''">
                <v-img :src="firstPhoto" alt="alt" width="150" />
              </div>
            </v-col>
          </v-row>
          <v-row class="mx-auto">
            <v-col cols="12" sm="6" align-self="center">
              <h3
                :class="
                  this.$vuetify.breakpoint.xs ? 'text-center' : '' + ' ma-0'
                "
              >
                Overall Food Rating
              </h3>
            </v-col>
            <v-col cols="12" sm="6">
              <div :class="this.$vuetify.breakpoint.xs ? 'text-center' : ''">
                <v-rating
                  v-model="ratings['overall']"
                  :rating="rating"
                  background-color="orange lighten-3"
                  color="orange"
                  large
                  :readonly="readonly"
                >
                </v-rating>
              </div>
            </v-col>
          </v-row>
          <v-row class="mx-auto">
            <v-col cols="12">
              <v-expansion-panels>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <h4 class="mb-0">More rating options</h4>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-row>
                      <v-col cols="12" sm="6" align-self="center">
                        <h4>Did quality meet you expectation?</h4>
                      </v-col>
                      <v-col cols="12" sm="6" align-self="center">
                        <div
                          :class="
                            this.$vuetify.breakpoint.xs ? 'text-center' : ''
                          "
                        >
                          <v-rating
                            v-model="ratings['quality']"
                            :rating="rating"
                            background-color="orange lighten-3"
                            color="orange"
                            large
                            :readonly="readonly"
                          >
                          </v-rating>
                        </div>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="6" align-self="center">
                        <h4>Did quantity meet you expectation?</h4>
                      </v-col>
                      <v-col cols="12" sm="6" align-self="center">
                        <div
                          :class="
                            this.$vuetify.breakpoint.xs ? 'text-center' : ''
                          "
                        >
                          <v-rating
                            v-model="ratings['quantity']"
                            :rating="rating"
                            background-color="orange lighten-3"
                            color="orange"
                            large
                            :readonly="readonly"
                          >
                          </v-rating>
                        </div>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" align-self="center">
                        <v-textarea
                          name="review"
                          placeholder="Few words about this product"
                          label="Public Review"
                          id="review"
                          :readonly="readonly"
                          v-model="ratings['publicComment']"
                        >
                        </v-textarea>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
export default {
  props: {
    product: {
      Type: Object,
    },
    data: {
      Type: Object,
    },
    readonly: { Type: Boolean, default: false },
  },
  computed: {
    firstPhoto() {
      return this.product.dish.photos && this.product.dish.photos.length > 0
        ? this.product.dish.photos[0].imageUrl
        : null;
    },
    hasReview() {
      return this.ratings;
    },
  },
  data() {
    return {
      rating: null,
      ratings: {},
    };
  },
  mounted() {
    this.ratings = this.data || {};
  },
  watch: {
    data: function() {
      // watching data and ratings has infinite loop
      if (
        this.data &&
        (!this.ratings || Object.entries(this.ratings).length == 0)
      ) {
        this.ratings = this.data;
      }
    },
    ratings: {
      handler: function() {
        this.$emit("onRatingUpdate", {
          ratings: this.ratings,
          id: this.product.dish.id,
        });
      },
      deep: true,
    },
  },
};
</script>
