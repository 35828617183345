<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="5">
        <v-text-field
          :disabled="sent"
          name="email"
          label="Enter your email address."
          hint="We will send you a link to reset your password."
          persistent-hint="true"
          id="email"
          v-model="email"
          type="email"
          required
          class="mb-1"
        ></v-text-field>
        <v-btn
          rounded
          color="info"
          type="submit"
          :disabled="!email || sent"
          @click.prevent="reset"
        >
          Email Reset Password
          <v-icon right>mail</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-row v-if="sent">
      <v-col class="pt-5" cols="12" sm="6">
        <h4>
          Check your email for a link to reset your password. If it doesn't
          appear within a few minutes, check your spam folder.
        </h4>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      email: null,
      sent: false
    }
  },
  computed: {
    ...mapGetters({})
  },
  methods: {
    reset() {
      console.log('Reset password sent to ', this.email)
      this.sent = true
      this.$store.dispatch('resetPassword', this.email)
    }
  }
}
</script>
