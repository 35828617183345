<template>
  <div>
    <v-row v-if="dish" align="center">
      <v-col cols="12" sm="4" v-if="false">
        <v-menu
          offset-x
          :close-on-content-click="false"
          :nudge-width="300"
          v-model="menu"
        >
          <template v-slot:activator="{ on }">
            <v-btn color="grey lighten-4" v-on="on">
              Cooking status
              <v-icon right :color="statusIconColor">{{ statusIcon }}</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-list>
              <v-card-title primary-title>
                Status:
                <v-alert
                  outlined
                  v-if="visibility === 'hide'"
                  color="error"
                  icon="warning"
                  :value="true"
                >
                  Product is visibility is
                  <b>hidden</b>.
                </v-alert>
              </v-card-title>
              <v-radio-group v-model="cookingStatus">
                <v-list-item>
                  <v-list-item-action>
                    <v-radio color="accent" value="prepping"></v-radio>
                  </v-list-item-action>
                  <v-list-item-title>
                    Prepping.
                    <small>Have haven't start cooking yet.</small>
                    <v-icon :color="_statusIconColor('prepping')">
                      {{ _statusIcon('prepping') }}
                    </v-icon>
                  </v-list-item-title>
                </v-list-item>

                <v-list-item>
                  <v-list-item-action>
                    <v-radio color="red" value="cooking"></v-radio>
                  </v-list-item-action>
                  <v-list-item-title>
                    Cooking.
                    <small
                      >Engage your fans via chat or a live broadcast.</small
                    >
                    <v-icon :color="_statusIconColor('cooking')">
                      {{ _statusIcon('cooking') }}
                    </v-icon>
                  </v-list-item-title>
                </v-list-item>

                <v-list-item>
                  <v-list-item-action>
                    <v-radio color="green" value="available"></v-radio>
                  </v-list-item-action>
                  <v-list-item-title>
                    Done.
                    <small>Product will be available in marketplace.</small>
                    <v-icon :color="_statusIconColor('available')">
                      {{ _statusIcon('available') }}
                    </v-icon>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item>
                  <v-list-item-action>
                    <v-radio color="lime" value="sold out"></v-radio>
                  </v-list-item-action>
                  <v-list-item-title>
                    Sold Out.
                    <small>Good job.</small>
                    <v-icon :color="_statusIconColor('sold out')">
                      {{ _statusIcon('sold out') }}
                    </v-icon>
                  </v-list-item-title>
                </v-list-item>
              </v-radio-group>
            </v-list>
            <v-alert type="info" outlined :value="showNotification"
              >Notification will be send to the buyer(s)</v-alert
            >

            <v-card-actions>
              <v-btn
                v-if="false"
                small
                color="red white--text"
                @click="liveBroadcast"
                >Live Broadcast</v-btn
              >
              <v-spacer></v-spacer>
              <v-btn text @click="menu = false">Cancel</v-btn>
              <v-btn color="primary" small @click="onSetStatus">Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </v-col>
      <v-col cols="6" md="5">
        This item is
        <span v-if="cookingVisibility !== 'public'"> NOT</span> public
        <v-icon v-if="cookingVisibility !== 'public'" color="red"
          >mdi-eye-off</v-icon
        >
        <v-icon v-else color="green">mdi-eye</v-icon>
      </v-col>
      <v-col cols="6">
        <span
          v-if="
            cookingVisibility === 'public' && chefApprovalStatus === 'pending'
          "
        >
          <v-icon color="red">warning</v-icon>
          <span v-html="$t('Chef.dish_order_visibility')"></span>
        </span>
        <v-switch
          :label="visibilityLabel"
          v-model="cookingVisibility"
          :color="visibilityColor"
          value="public"
          @change="showConfirm"
          :disabled="checkVisibility"
          hide-details
        ></v-switch>
      </v-col>
    </v-row>

    <v-dialog persistent max-width="340" v-model="confirmHide">
      <ProductVisibilityDialog
        @closeAction="closeAction"
        @confirmVisibility="confirmVisibility"
      />
    </v-dialog>
  </div>
</template>

<script>
//import { OrderMixin } from '@/mixins/orderMixin'
import { OrderMixin } from 'fod-core/src/mixins/orderMixin'
import { mapGetters } from 'vuex'
import ProductVisibilityDialog from './ProductVisibilityDialog.vue'

export default {
  props: ['id', 'status', 'visibility', 'orderCount'],
  mixins: [OrderMixin],
  components: {
    ProductVisibilityDialog
  },
  data() {
    return {
      confirmHide: null,
      cookingStatus: null,
      fav: true,
      menu: false,
      message: false,
      confirmDelete: false,
      hints: true,
      showNotification: false,
      cookingVisibility: null
    }
  },
  computed: {
    ...mapGetters({
      profile: 'profile',
      dish: 'dish',
      account: 'bankAccount',
      chefApprovalStatus: 'chefApprovalStatus'
    }),
    statusIcon() {
      return this._statusIcon()
    },
    checkVisibility() {
      return (
        this.profile.status !== 'approved' ||
        !this.account.scope ||
        !this.dish.name ||
        !this.dish.price
      )
    },
    statusIconColor() {
      return this._statusIconColor()
    },
    visibilityLabel() {
      return this.cookingVisibility !== 'public'
        ? 'It is NOT available in Marketplace'
        : 'It is Available in marketplace'
    },
    visibilityColor() {
      return this.cookingVisibility !== 'public' ? 'red' : 'green'
    }
  },
  watch: {
    cookingStatus: function(next, prev) {
      if (next === 'available' && prev === 'cooking' && this.orderCount > 0) {
        this.showNotification = true
      }
    }
  },
  mounted() {
    this.cookingStatus = this.status
    this.cookingVisibility = this.visibility
  },

  methods: {
    // gets variable changes from ProductVisibilityDialog and sets them here
    closeAction(ch, cv) {
      this.confirmHide = ch
      this.cookingVisibility = cv
    },
    confirmVisibility() {
      this.confirmHide = false
      this.$emit('onUpdateVisibility', 'hide')
    },
    showConfirm() {
      if (this.cookingVisibility !== 'public') {
        this.confirmHide = true
      } else {
        this.$emit('onUpdateVisibility', this.cookingVisibility)
      }
    },
    onSetStatus() {
      this.menu = false
      this.$emit('onUpdateStatus', this.cookingStatus)
    },
    liveBroadcast() {
      this.menu = false
      this.$router.push('/golive?dish=' + this.id)
    }
  }
}
</script>
