<template>
  <v-container grid-list-xs v-if="!loading && session && chef">
    <template v-if="streamAvailable">
      <v-row class="fill-height" align="space-around">
        <v-col cols="11" sm="8">
          <div id="session" @error="errorHandler">
            <div
              id="subscribers"
              v-for="stream in streams"
              :key="stream.streamId"
            >
              <subscriber
                @error="errorHandler"
                :stream="stream"
                :opts="viewOptions"
                :session="session"
              ></subscriber>
            </div>
          </div>
        </v-col>
      </v-row>
    </template>
    <template v-else>
      <v-row>
        <v-col cols="11">
          <v-alert
            type="info"
            :value="true"
            icon="fas fa-spinner fa-spin"
            outlined
          >
            Stream is not available yet. Video will be shown as soon as steam
            available
          </v-alert>
        </v-col>
      </v-row>
    </template>
    <v-row>
      <v-col cols="12" sm="6">
        <chat :profile="chef" @sendMessage="sendMessage" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import fod_config from '@/config/config'
import OT from '@opentok/client'
import Subscriber from './Subscriber.vue'
import { mapGetters } from 'vuex'
import Chat from './Chat.vue'

const errorHandler = err => {
  console.error(err.message)
}

export default {
  name: 'watch',
  data: () => ({
    chefId: null,
    roomId: null,
    dishId: null,
    streams: [],
    viewOptions: null,
    session: null
  }),
  components: { Subscriber, Chat },
  computed: {
    ...mapGetters({
      dish: 'dish',
      chef: 'chef',
      streamSession: 'streamSession',
      streamingStatus: 'streamingStatus',
      loading: 'loading'
    }),
    streamAvailable() {
      return this.streams.length > 0
    }
  },
  created() {
    this.chefId = this.$route.params.id
    let dish_id = this.$route.query.dish
    this.roomId = this.chefId || this.$route.query.room

    this.apiKey = fod_config.STREAM_API_KEY
    this.$store.dispatch('getStreamSession', this.roomId).then(sessionData => {
      console.log('Subscriber starting a session from: ', sessionData)
      this.session = OT.initSession(this.apiKey, this.streamSession.sessionId)
      this.session.on('streamCreated', event => {
        console.log(`Stream Created ${this.roomId}`)

        // TODO: this should be removed if there are multiple streams
        this.streams = []
        this.streams.push(event.stream)
      })
      this.session.connect(this.streamSession.token, err => {
        if (err) {
          errorHandler(err)
        } else {
          console.log('Stream Connected ', this.roomId)
        }
      })
      this.session.on('streamDestroyed', event => {
        console.log('Stream Destroyed ', this.roomId)
        const idx = this.streams.indexOf(event.stream)
        if (idx > -1) {
          this.streams.splice(idx, 1)
        }
      })
    })

    this.$store.dispatch('loadChefById', this.chefId)
  },
  mounted() {
    this.viewOptions = {
      width: '100%',
      height: 450
    }
  },

  beforeDestroy() {
    console.log('Disconnecting')
    this.session.disconnect()
  },

  methods: {
    errorHandler,
    sendMessage(message) {
      this.$store.dispatch('sendMessage', {
        session: this.session,
        message: message
      })
    }
  }
}
</script>
