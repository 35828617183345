<template>
  <v-container class="justify" fluid v-if="!loading">
    <div v-if="profile">
      <template>
        <approval-message />
        <v-app-bar
          v-if="isAdmin"
          absolute
          color="info--text white"
          flat
          scroll-target="#scrolling-techniques-7"
        >
          <v-toolbar-title>{{ profileName }}</v-toolbar-title>
          <template v-if="profile.type === 'chef'">
            <v-btn :to="uid ? '/product/list/' + uid : '/product/list'" text
              >Products</v-btn
            >
            <v-btn text :to="uid ? '/order/lists/' + uid : '/order/lists'"
              >Orders</v-btn
            >
            <v-btn text :to="uid ? '/coupons/list/' + uid : '/coupons/list'"
              >Promotions</v-btn
            >
            <v-btn text :to="uid ? '/review/list/' + uid : '/review/list'"
              >Reviews</v-btn
            >
          </template>
          <v-spacer></v-spacer>

          <AccountApproval :action="action" :uid="uid" />

          <v-menu bottom origin="center center">
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on">
                <v-icon>more_vert</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="showDeleteAccount = true">
                <v-list-item-title>
                  Delete Account
                  <v-icon color="red">delete</v-icon>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-app-bar>
        <v-sheet id="scrolling-techniques-7">
          <v-row class="mb-2">
            <v-col cols="12" sm="7">
              <h4>Profile {{ profileName }}</h4>
            </v-col>
            <v-spacer></v-spacer>
            <v-menu bottom origin="center center">
              <template v-slot:activator="{ on }">
                <v-btn icon v-on="on">
                  <v-icon>more_vert</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="showDeleteAccount = true">
                  <v-list-item-title>
                    Delete Account
                    <v-icon color="red">delete</v-icon>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-row>

          <v-row>
            <v-col cols="12">
              <v-tabs
                slider-color="yellow"
                v-model="tabs"
                color="blue"
                class="my-4"
                background-color="blue-grey darken-3"
                grow
                show-arrows
              >
                <v-tab href="#tab-basic" class="white--text">My Profile</v-tab>
                <v-tab href="#tab-address" class="white--text">
                  Address
                  <span v-if="!hasAddress">
                    <v-icon right color="yellow accent-1">error</v-icon>
                  </span>
                </v-tab>

                <v-tab href="#tab-payment" v-if="isChef" class="white--text">
                  Payment Info
                  <span v-if="!account">
                    <v-icon right color="yellow accent-1">error</v-icon>
                  </span>
                </v-tab>
                <v-tab href="#tab-work" v-if="false" class="white--text"
                  >Work experience</v-tab
                >
                <v-tab
                  @click="getPictures()"
                  href="#tab-album"
                  v-if="isChef"
                  class="white--text"
                  >Photo Album</v-tab
                >
                <v-tab href="#tab-admin" v-if="isAdmin" class="red--text"
                  >Admin Only</v-tab
                >

                <v-tab-item value="tab-basic">
                  <profile-basic
                    :tabs="tabs"
                    ref="profileBasic"
                    :uid="uid"
                  ></profile-basic>
                </v-tab-item>
                <v-tab-item value="tab-address">
                  <profile-address
                    :tabs="tabs"
                    ref="profileAddress"
                    :profile="profile"
                  ></profile-address>
                </v-tab-item>
                <v-tab-item value="tab-license" v-if="false">
                  <profile-permits
                    :profile="profile"
                    ref="profilePermits"
                  ></profile-permits>
                </v-tab-item>
                <v-tab-item value="tab-payment">
                  <account-view
                    ref="accountView"
                    :id="profile.id"
                  ></account-view>
                </v-tab-item>
                <v-tab-item value="tab-work" v-if="false">
                  <work-experience
                    ref="workExperience"
                    :profile="profile"
                  ></work-experience>
                </v-tab-item>
                <v-tab-item value="tab-album">
                  <sub-album ref="subAlbum" :profile="profile"></sub-album>
                </v-tab-item>
                <v-tab-item value="tab-admin">
                  <sub-admin :profile="profile"></sub-admin>
                </v-tab-item>
              </v-tabs>
            </v-col>
          </v-row>
        </v-sheet>
      </template>
      <v-dialog v-model="showDeleteAccount" width="500px">
        <v-card>
          <v-card-title class="grey lighten-4 py-4">
            <v-icon color="red">block</v-icon>Your account and all data be will
            deleted and this is not reversible!
          </v-card-title>
          <v-card-actions>
            <v-btn color="secondary" @click="goodbye">Delete</v-btn>
            <v-spacer></v-spacer>

            <v-btn text color="primary" @click="showDeleteAccount = false"
              >Cancel</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-container>
</template>
<script>
import ProfileBasic from './ProfileBasic'

import ProfilePermits from './SubPermits'
import SubWorkExperience from './SubWorkExperience'
import SubAlbum from './SubAlbum'
import SubAdmin from './SubAdmin'
import AccountView from '@/components/account/AccountView'
import ApprovalMessage from './ApprovalMessage'
import ConsumerForward from '@/components/common/ConsumerForward'
import { mapGetters } from 'vuex'
//import { profileMixin } from '@/mixins/profileMixin'
import { profileMixin } from 'fod-core'

export default {
  mixins: [profileMixin],

  components: {
    'profile-basic': ProfileBasic,
    'profile-permits': ProfilePermits,
    'work-experience': SubWorkExperience,
    'account-view': AccountView,
    'sub-album': SubAlbum,
    'sub-admin': SubAdmin,

    ProfileAddress: () =>
      import('fod-core/src/components/profile/ProfileAddress.vue'),
    AccountApproval: () => import('./AccountApproval.vue'),
    ApprovalMessage,
    ConsumerForward
  },
  data() {
    return {
      uid: null,
      tabs: null,
      action: null,
      showDeleteAccount: false
    }
  },

  computed: {
    ...mapGetters({
      myProfile: 'profile',
      userProfile: 'userProfile',
      hasAddress: 'hasAddress',
      account: 'bankAccount',

      isAdmin: 'isAdmin',
      loading: 'loading'
    }),
    profileName() {
      if (this.profile) {
        let name = this.profile.name
        if (this.profile.user) {
          name += ' (username: ' + this.profile.user + ')'
        }

        return name
      }
      return ''
    },
    profile() {
      if (this.$route.params.id) {
        return this.userProfile
      }
      return this.myProfile
    },
    isChef() {
      return this.profile.type === 'chef'
    }
  },
  watch: {
    $route() {
      this.getPictures()
      console.log('changed path!', this.profile.id)
    }
  },
  mounted() {
    if (this.$route.params.id) {
      this.uid = this.$route.params.id
      this.$store.dispatch('loadUserProfile', { uid: this.$route.params.id })
    }
    let tab = this.$route.query.tab
    this.action = this.$route.query.action
    if (tab) {
      this.tabs = tab
    }
  },
  methods: {
    goodbye() {
      this.$store.dispatch('deleteAccount')
      this.showDeleteAccount = false
      this.$router.push('/signup/chef')
    },
    // this is required so that the photos are populated when the tab is open
    async getPictures() {
      await this.$store.dispatch('loadAlbum', this.profile.id)
    }
  }
}
</script>
