<template>
  <v-container>
    <v-row>
      <v-btn dark color="accent" class="my-3" @click="addPermit">
        Add
        <v-icon dark right>add</v-icon>
      </v-btn>
    </v-row>
    <v-row v-if="!permits || permits.length == 0">
      <v-col class="ms7" cols="12">
        <v-alert outlined color="blue accent-2" icon="warning" :value="true">
          <v-row>
            <v-col cols="12" sm="7">
              <h5>{{ $t('Chef.add_permit_help') }}</h5>
            </v-col>
          </v-row>
        </v-alert>
      </v-col>
    </v-row>
    <template v-for="(permit, key) in permits">
      <v-row :key="key">
        <v-col cols="12" v-if="key > 0">
          <v-divider></v-divider>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="3">
          <v-select
            label="Vendor Type"
            :items="permitList"
            v-model="permits[key].type.text"
            @change="onPermitChange"
            item-text="name"
            item-value="code"
            persistent-hint
            single-line
          >
            <template slot="item" slot-scope="data">{{
              data.item.name
            }}</template>
          </v-select>

          <!-- <v-select
            v-model="permits[key].type.text"
            @change="onPermitChange"
            v-bind:items="[
              'CFO',
              'Food Truck',
              'Catering',
              'General License',
              'Certificates and Award'
            ]"
            label="Type"
          ></v-select>-->
        </v-col>
        <v-col class="mx-1" cols="12" sm="4" md="2" lg="2">
          <v-text-field
            name="permit"
            placeholder="Permit Name"
            :label="'Permit ' + (key + 1)"
            v-model="permits[key].permit"
          ></v-text-field>
        </v-col>
        <v-col cols="6" sm="4" md="2">
          <v-text-field
            name="authority"
            class="small"
            placeholder="County Environmental Health Department"
            label="Issued In (County)"
            v-model="permits[key].authority"
          ></v-text-field>
        </v-col>
        <v-col cols="5" sm="2" class="mx-1" lg="1">
          <v-autocomplete
            v-bind:items="states"
            v-model="permits[key].state"
            label="State"
          ></v-autocomplete>
        </v-col>

        <!-- issued date input -->
        <v-col cols="6" sm="2" lg="1">
          <v-menu
            :close-on-content-click="true"
            v-model="menu[key]"
            :ref="menu[key]"
            transition="scale-transition"
            offset-y
            :nudge-right="40"
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-on="on"
                class="small"
                label="Issue Date"
                v-model="permits[key].issueDate"
                prepend-icon="event"
                readonly
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="permits[key].issueDate"
              no-title
              type="month"
              :max="maxDate"
            ></v-date-picker>
          </v-menu>
        </v-col>

        <!-- expiration date input -->
        <v-col cols="6" sm="2" lg="1">
          <v-menu
            :close-on-content-click="true"
            v-model="expMenu[key]"
            :ref="expMenu[key]"
            transition="scale-transition"
            offset-y
            :nudge-right="40"
            max-width="290px"
            min-width="290px"
            v-if="permits[key].issueDate"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-on="on"
                class="small"
                label="Expiration Date"
                v-model="permits[key].expDate"
                prepend-icon="event"
                readonly
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="permits[key].expDate"
              no-title
              type="month"
              :min="permits[key].issueDate"
            ></v-date-picker>
          </v-menu>
        </v-col>

        <v-col cols="6" sm="2" align-self="center">
          <a
            v-if="permits[key].document"
            target="_blank"
            :href="permits[key].document"
          >
            <v-icon color="cyan">attachment</v-icon>
          </a>

          <file-upload
            :type="'profile'"
            v-if="!permits[key].document && permits[key].permit"
            :sub_type="'permit'"
            :id="profile.id"
            :index="key"
            :message="'The file will be visible to public users.'"
            @onFilePathUpdate="onFilePathUpdate"
          />
          <v-btn
            icon
            dark
            small
            color="red"
            v-if="!permits[key].draft"
            @click="removePermit(key)"
          >
            <v-icon dark>remove</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </template>

    <v-row v-if="!wizard" class="pt-1">
      <form @submit.prevent="onUpdatePermit">
        <v-btn primary type="submit">Save</v-btn>
      </form>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
import { states } from '@/data/static'
import moment from 'moment'
import FileUpload from './FileUpload'

export default {
  props: ['wizard', 'profile'],
  components: {
    FileUpload
  },
  data() {
    return {
      permits: [],
      menu: [],
      expMenu: [],
      states: states,
      //
      permitList: [
        {
          name: 'Microenterprise Home Kitchen (MEHKO)',
          code: 'MEHKO'
        },
        {
          name: 'Cottage Food Operator (CFO)',
          code: 'CFO'
        },
        {
          name: 'Food Truck (MFF)',
          code: 'MFF'
        },
        {
          name: 'Caterer',
          code: 'CAT'
        },
        {
          name: 'Restaurant',
          code: 'RES'
        },
        {
          name: 'Other',
          code: 'OTHER'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      // profile: 'profile',
      loading: 'loading'
    }),
    maxDate() {
      return moment().format('Y-M')
    }
  },
  mounted() {
    this.permits = this.profile.permits || []
  },
  watch: {
    permits: function() {
      if (this.wizard) {
        let payload = { type: 'permits', permits: this.permits }
        this.$emit('onUpdateWork', payload)
      }
    },
    $route() {
      this.permits = this.profile.permits || []
    }
  },
  methods: {
    addPermit() {
      let permit = {
        permit: '',
        authority: '',
        state: '',
        type: {},
        issueDate: null,
        expDate: null,
        draft: true,
        id: this.permits ? this.permits.length : 0
      }
      this.permits.push(permit)
      this.expMenu.push(false)
      this.menu.push(false)
    },
    removePermit(expIndex) {
      this.permits.splice(expIndex, 1)
    },
    onFilePathUpdate(payload) {
      this.permits[payload.index].document = payload.path
      this.$store.dispatch('savePermit', {
        profile: this.profile,
        permitData: {
          permits: this.permits
        }
      })
    },
    onUpdatePermit() {
      let foundCFO = null

      // remove empty permit
      let missed = this.permits.filter(p => !p.permit || !p.type.text)
      this.permits = this.permits.filter(p => p.permit && p.type.text)

      if (
        this.permits &&
        this.permits.find(p => p.type && p.type.text === 'CFO')
      ) {
        foundCFO = true
      }
      this.permits.map(p => delete p.draft)
      this.$store.dispatch('savePermit', {
        profile: this.profile,
        permitData: {
          cfo: foundCFO,
          permits: this.permits
        }
      })
      if (missed.length > 0) {
        this.$store.dispatch('setMessage', { title: 'Missing permit info' })
      }
    },
    onPermitChange(data) {
      console.log('Permit is selected', data)
    }
  }
}
</script>
<style scoped>
.small {
  font-size: 1em;
}
</style>
