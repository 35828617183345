<template>
  <v-container>
    <v-row justify="center">
      <v-progress-circular
        indeterminate
        color="takein"
        :width="7"
        :size="50"
        v-if="!dataLoaded"
      ></v-progress-circular>
    </v-row>
    <v-row justify="center">
      <v-col cols="12" class="pa-0">
        <v-sheet class="main_text--text shadow">
          <v-stepper v-model="hstep" vertical non-linear class="pa-1">
            <v-stepper-step
              editable
              color="takein"
              :complete="hstep > 1"
              step="1"
            >
              <span
                class="text-uppercase text-underline subtitle-2 main_text--text"
                >{{ $t('Common.product_information') }}</span
              >
            </v-stepper-step>
            <v-stepper-content step="1">
              <v-sheet class="pa-0">
                <product-information
                  @nextStep="nextStep"
                  :uid="profile.id"
                  :menus="menus"
                  v-if="dataLoaded"
                  :product="product"
                />
              </v-sheet>
            </v-stepper-content>
            <v-stepper-step
              :editable="!!product && !!product.id"
              color="takein"
              :complete="hstep > 2"
              step="2"
            >
              <span
                class="text-uppercase text-underline subtitle-2 main_text--text"
                >{{ $t('Common.product_details') }}</span
              >
            </v-stepper-step>

            <v-stepper-content step="2">
              <v-sheet class="d-flex flex-column pa-4 shadow mb-8">
                <product-details
                  :product="product"
                  :uid="profile.uid"
                  :menus="menus"
                  :dishList="dishList"
                  v-if="dataLoaded"
                  ref="productDetails"
                />
                <v-row>
                  <v-col cols="12" class="mt-7">
                    <v-row justify="center">
                      <v-btn
                        color="takein"
                        :ripple="false"
                        depressed
                        class="mr-2 mb-2 white--text"
                        @click="save()"
                        :disabled="!dishIsValid"
                        >{{ $t('Common.save') }}</v-btn
                      >
                      <v-btn
                        color="takein"
                        :ripple="false"
                        depressed
                        class="mr-2 mb-2 white--text"
                        :disabled="!dishIsValid"
                        @click="addNewProduct()"
                        >{{ $t('Common.save_and_add_new_product') }}</v-btn
                      >
                      <v-btn
                        dark
                        color="grey"
                        :ripple="false"
                        depressed
                        class="mr-2 mb-2"
                      >
                        <v-icon size="16" left>mdi-close</v-icon>
                        {{ $t('Common.cancel') }}
                      </v-btn>
                      <v-btn
                        color="error"
                        dark
                        :ripple="false"
                        depressed
                        class="mr-2 mb-2"
                      >
                        <v-icon size="16" left>mdi-delete</v-icon>
                        {{ $t('Common.delete') }}
                      </v-btn>
                      <v-btn
                        color="takein"
                        dark
                        :ripple="false"
                        depressed
                        class="mr-2 mb-2"
                        @click="toModifiers()"
                        >{{ $t('Common.modifiers') }}</v-btn
                      >
                    </v-row>
                  </v-col>
                </v-row>
              </v-sheet>
              <v-row>
                <v-spacer />
                <v-col class="d-flex justify-center">
                  <v-btn
                    color="takein"
                    dark
                    :ripple="false"
                    depressed
                    rounded
                    class="mr-2 mb-2"
                    to="/profile/wizard/menus/menus_sub/new_menu"
                  >
                    {{ $t('Common.next') }}
                    <v-icon size="16" right>mdi-chevron-right</v-icon>
                  </v-btn>
                </v-col>
                <v-spacer />
              </v-row>
            </v-stepper-content>
          </v-stepper>
        </v-sheet>
      </v-col>
    </v-row>
    <v-snackbar
      v-model="snackbar"
      :bottom="true"
      :color="snackType"
      :timeout="snackTimeout"
    >
      {{ snackMessage }}
      <v-btn color="white" text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {
    ProductInformation: () => import('./ProductInformation'),
    ProductDetails: () => import('./ProductDetails')
  },
  data() {
    return {
      snackTimeout: 2500,
      snackbar: false,
      snackMessage: '',
      snackType: 'success',
      product: null,
      hstep: 1,
      menus: null,
      id: null
    }
  },
  beforeMount() {
    this.$store.dispatch('loadMenus', { uid: this.profile.id }).then(list => {
      this.menus = list.filter(menu => menu.type != 'parent')
    })
    if (!this.dishList) {
      this.$store.dispatch('loadDishList', { uid: this.profile.id })
    }
  },
  mounted() {
    this.$parent.$parent.$parent.$parent.showWizard = false

    if (this.$route.params.id) {
      this.$store
        .dispatch('getDishById', { id: this.$route.params.id })
        .then(data => {
          this.product = data
        })
    } else {
      this.product = {}
    }
    if (this.dish) {
      this.$store.dispatch('setDish', null)
    }
  },
  destroyed() {
    this.$parent.$parent.$parent.$parent.showWizard = true
  },
  computed: {
    ...mapGetters({
      profile: 'profile',
      dishList: 'dishList',
      dish: 'dish'
    }),
    dataLoaded() {
      if (!this.$route.params.id) return this.menus && this.dishList
      return this.menus && this.dishList && this.product
    },
    dishIsValid() {
      if (!this.product) return false
      return this.product.name && this.product.price && this.product.amountLeft
    },
    loading() {
      return this.$store.getters.loading
    }
  },

  methods: {
    nextStep(value) {
      this.product = value
      this.hstep = 2
    },
    save() {
      this.$refs.productDetails.save()
      this.snackMessage = 'Product saved successfully.'
      this.snackType = 'success'
      this.snackbar = true
    },
    toModifiers() {
      this.$store.dispatch('setDish', this.product).then(() => {
        this.$router.replace(
          `/profile/wizard/menus/manage_modifiers/${this.product.id}`
        )
      })
    },
    addNewProduct() {
      this.$refs.productDetails.save()
      this.snackMessage = 'Product saved successfully.'
      this.snackType = 'success'
      this.snackbar = true
      this.product = null
      this.hstep = 1
      //this.product = {}
      this.$router.replace('/profile/wizard/menus/products_sub/new_product')
    }
  }
}
</script>
<style>
.v-stepper__header {
  box-shadow: none;
  justify-content: flex-start !important;
}
.v-stepper__content {
  padding: 16px 40px 16px 23px;
}
.v-stepper__wrapper {
  padding: 4px;
}
.shadow {
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
    0px 4px 8px rgba(0, 0, 0, 0.04);
}
</style>
