<template>
  <v-container>
    <v-row v-if="!account">
      <v-col class="text-right">
        <v-btn depressed :ripple="false" to="/profile/wizard/finance">
          {{ $t('Common.back') }}
        </v-btn>
      </v-col>

      <v-col cols="12 my-2">
        <h6>{{ $t('Common.connect_bank_your_account') }}</h6>
      </v-col>
    </v-row>

    <template v-if="!loading">
      <template v-if="account">
        <v-card height="100">
          <v-card-title>
            <v-row justify="space-between" class="px-4">
              <div>
                {{ $t('Common.account_type') }}:
                <b>{{ accountType }}</b>
              </div>

              <v-btn color="error" small @click="deleteAccount">
                {{ $t('Common.delete_account') }}
              </v-btn>
            </v-row>
          </v-card-title>
          <v-card-title>
            <v-row>
              <template v-if="accountType === 'Standard'">
                <v-list-item-title>
                  {{ $t('Common.managed_by') }}
                  <a
                    target="_blank"
                    href="https://connect.stripe.com/account/applications"
                  >
                    <v-icon color="blue lighten-2" left>fab fa-stripe</v-icon>
                  </a>
                </v-list-item-title>
              </template>
            </v-row>
          </v-card-title>
        </v-card>
      </template>
      <template>
        <v-row justify="space-between" class="my-4">
          <div>
            {{ $t('Common.setup_your_bank_using') }}
            <a target="_blank" :href="callBackUrl">
              <v-btn color="blue darken-2 mx-2">
                <v-icon class="pl-3 white--text" large left>fab fa-stripe</v-icon>
              </v-btn>
            </a>
          </div>
          <!-- <v-col cols="12" v-if="false">
            <a target="_blank" :href="callBackUrlForCode">
              {{ $t('Common.link') }}
            </a>
            {{ $t('Common.bank_exist_description') }}
          </v-col> -->
        </v-row>
      </template>
    </template>
    <template v-else>
      <v-col cols="12">
        <v-alert outlined color="error" icon="warning" :value="true">
          {{ $t('Common.not_verified_bank') }}
        </v-alert>
      </v-col>
    </template>
    <v-row class="px-4">
      <v-row justify="end">
        <v-col cols="4" md="2">
          <a href="https://stripe.com" target="_blank">
            <img height="30" src="/powered_by_stripe.png" />
          </a>
        </v-col>
      </v-row>
    </v-row>
    <v-row class="px-4" justify="center">
      <v-progress-circular
        v-if="localLoading"
        indeterminate
        color="primary"
      ></v-progress-circular>
    </v-row>
    <delete-confirmation-dialog
      ref="deleteConfirmationDialog"
      :heading="$t('Common.delete_bank_account')"
      :message="$t('Common.delete_bank_message')"
      @onConfirm="onDeleteAccount"
    ></delete-confirmation-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import JsonCSV from 'vue-json-csv'
import moment from 'moment'

export default {
  components: {
    'download-csv': JsonCSV
  },
  data() {
    return {
      localLoading: false,
      test_data: [
        { name: 'john', date: 123 },
        { name: 'ay', date: 543 }
      ]
    }
  },
  computed: {
    ...mapGetters({
      loading: 'loading',
      profile: 'profile',
      account: 'bankAccount'
    }),
    callBackUrl() {
      const domain = process.env.VUE_APP_DOMAIN
      const client_id = process.env.VUE_APP_STRIPE_CLIENT_ID
      return `https://connect.stripe.com/express/oauth/authorize?redirect_uri=https://biz.${domain}/callback/account&client_id=${client_id}&state=${this.profile.id}`
    },
    callBackUrlForCode() {
      const client_id = process.env.VUE_APP_STRIPE_CLIENT_ID
      return `https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${client_id}&scope=read_write&state=${this.profile.id}`
    },
    accountType() {
      if (this.account) {
        switch (this.account.scope) {
          case 'express':
            return 'Express'
          case 'read_write':
            return 'Standard'

          default:
            return 'Custom'
        }
      }
    },
    filename() {
      return (
        moment().format('MM_DD_YYYY') +
        '_' +
        this.profile.name +
        '_financial_data.csv'
      )
    }
  },
  methods: {
    deleteAccount(id) {
      this.id = id
      this.$refs.deleteConfirmationDialog.openDialog()
    },
    onDeleteAccount() {
      const that = this
      this.localLoading = true
      this.$refs.deleteConfirmationDialog.close()
      this.$store
        .dispatch('deleteBankAccount', {
          uid: this.profile.id,
          id: this.account.stripe_user_id
        })
        .then(() => {
          that.$store.dispatch('setBankAccount', null).then(() => {
            this.localLoading = false
            that.$router.history.push('/profile/wizard/finance')
          })
        })
    }
  }
}
</script>
