<template>
  <v-container>
    <v-row v-if="profile" justify="center">
      <v-col cols="12" sm="10" xl="8">
        <v-sheet class="pa-8 main_text--text shadow mb-8">
          <h4 class="font-weight-bold text-uppercase">
            {{ $t('Common.step') }} {{ $t('Common.three') }} -
            {{ $t('Common.order_settings') }}
          </h4>
          <v-row justify="center" class="pl-4 pr-4 py-2">
            <v-col>
              <small
                class="main_text--text"
                v-html="$t('Common.order_settings_page_description')"
              />
            </v-col>
          </v-row>
          <v-sheet class="pa-8 main_text--text shadow">
            <v-row justify="center" class="pl-4 pr-4 py-2">
              <v-col cols="12" sm="6">
                <v-text-field
                  color="takein"
                  dense
                  :hide-details="true"
                  :label="$t('Common.minimum_order_price')"
                  v-model="minimumOrderAmount"
                  prefix="$"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  color="takein"
                  dense
                  :hide-details="true"
                  :label="$t('Common.default_lead_time')"
                  v-model="defaultLeadTime"
                  suffix="minutes"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row justify="center" class="pl-4 pr-4 py-2">
              <v-col cols="12">
                <span class="main_text--text caption">
                  {{ $t('Common.call_robot_description') }}
                </span>
                <v-switch
                  color="takein"
                  v-model="robotCall"
                  :hide-details="true"
                  :label="$t('Common.call_robot')"
                ></v-switch>
                <i class="small" v-if="robotCall">
                  {{ $t('Common.call_robot_warning') }}
                  {{ profile.businessPhone }}
                </i>
              </v-col>
              <v-col cols="12" sm="6"></v-col>
            </v-row>

            <v-row justify="center" class="pl-4 pr-4 py-2">
              <v-col>
                <v-switch
                  v-model="disable_preorder"
                  color="takein"
                  dense
                  :hide-details="true"
                  :label="$t('Common.disable_preorder')"
                ></v-switch>
                <i class="small" v-if="disable_preorder"
                  >Allow Customers to place pre-orders</i
                >
              </v-col>
            </v-row>
            <v-row justify="center" class="pl-4 pr-4 py-2">
              <v-col>
                <v-btn
                  color="takein"
                  dark
                  :ripple="false"
                  depressed
                  class="mr-2"
                  @click="savePriceSettings"
                  >{{ $t('Common.save') }}</v-btn
                >
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="d-flex justify-center py-2">
                <v-btn
                  color="takein"
                  depressed
                  rounded
                  :ripple="false"
                  class="white--text"
                  @click="saveAll"
                >
                  {{ $t('Common.next') }}
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-sheet>
        </v-sheet>
      </v-col>
    </v-row>
    <v-snackbar
      v-model="snackbar"
      :bottom="true"
      :color="snackType"
      :timeout="snackTimeout"
    >
      {{ snackMessage }}
      <v-btn color="white" text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
  </v-container>
</template>
<script>
import Vue from 'vue'
import currency from 'v-currency-field'
import { mapGetters } from 'vuex'
Vue.use(currency)

export default {
  computed: {
    ...mapGetters({
      profile: 'profile'
    })
  },
  components: {},
  mounted() {
    if (!this.profile) {
      return
    }
    console.log('when mounedted!!!')
    this.loadOrderSettings()
  },
  data() {
    return {
      snackTimeout: 2500,
      snackbar: false,
      snackMessage: '',
      snackType: 'success',
      minimumOrderAmount: 5,
      defaultLeadTime: 10,
      robotCall: true,
      disable_preorder: true,
      producerDeliveryPct: 0,
      producerPickupPct: 0,
      errors: {},

      currency_config: {
        decimal: '.',
        thousands: ',',
        prefix: '$ ',
        precision: 2,
        masked: false,
        allowBlank: false,
        min: 0.0,
        max: Number.MAX_SAFE_INTEGER
      }
    }
  },
  methods: {
    async loadOrderSettings() {
      const fees = await this.$store.dispatch('getOrderFees')

      this.minimumOrderAmount =
        this.profile.settings && this.profile.settings.minimumOrderAmount
          ? this.profile.settings.minimumOrderAmount
          : this.minimumOrderAmount
      this.defaultLeadTime =
        this.profile.settings && this.profile.settings.defaultLeadTime
          ? this.profile.settings.defaultLeadTime
          : this.defaultLeadTime
      this.robotCall =
        this.profile.settings && this.profile.settings.robotCall !== null
          ? this.profile.settings.robotCall
          : this.robotCall

      this.disable_preorder = !(
        (this.profile.settings && this.profile.settings.allow_preorder) ||
        (this.profile.settings &&
          this.profile.settings.allow_preorder === undefined)
      )

      this.producerDeliveryPct =
        this.profile.settings && this.profile.settings.producerDeliveryPct
          ? this.profile.settings.producerDeliveryPct
          : fees.producerDeliveryPct

      this.producerPickupPct =
        this.profile.settings && this.profile.settings.producerPickupPct
          ? this.profile.settings.producerPickupPct
          : fees.producerPickupPct
    },
    savePriceSettings() {
      this.$store
        .dispatch('saveSettings', {
          minimumOrderAmount: this.minimumOrderAmount,
          robotCall: this.robotCall,
          defaultLeadTime: parseInt(this.defaultLeadTime),
          allow_preorder: !this.disable_preorder,
          producerPickupPct: this.producerPickupPct,
          producerDeliveryPct: this.producerDeliveryPct
        })
        .then(() => {
          this.$store.dispatch('getOrderFees')
          this.snackMessage = 'Order settings saved successfully.'
          this.snackType = 'success'
          this.snackbar = true
        })
    },
    changePickupServiceFee(value) {
      console.log(`Pickup Service fee changed:${value}`)

      this.producerPickupPct = value
    },
    changeDeliveryServiceFee(value) {
      console.log(`Delivery Service fee changed:${value}`)
      this.producerDeliveryPct = value
    },
    saveAll() {
      this.savePriceSettings()
      this.$router.push('/profile/wizard/menus/products_sub')
      // setTimeout(() => {
      //   this.$router.push('/profile/wizard/menus/products_sub')
      // }, this.snackTimeout)

      // setTimeout(() => {
      //   this.$router.push('/profile/wizard/menus')
      // }, 1500)
    }
  }
}
</script>
<style>
.shadow {
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
    0px 4px 8px rgba(0, 0, 0, 0.04);
}
</style>
