import firebase from '@/firebase-init'
import moment from 'moment'

//https://core.telegram.org/bots/payments
export default {
  state: {
    sources: null,
    charges: null,
    bankAccount: null,
    settings: null,
    stripeCustomerInitialized: false,
    paymentHistory: null
  },
  mutations: {
    updateStripeCustomerInitialized(state, payload) {
      state.stripeCustomerInitialized = payload
    },
    updateSources(state, payload) {
      state.sources = payload
    },
    updateCharges(state, payload) {
      state.charges = payload
    },
    updateBankAccount(state, payload) {
      state.bankAccount = payload
    },
    settings(state, payload) {
      state.settings = payload
    },
    setPaymentHistory(state, payload) {
      state.paymentHistory = payload
    }
  },
  actions: {
    loadTransfers(context, payload) {
      if (!payload.uid) {
        console.error('error, not user detected')
        return
      }

      let ref = firebase
        .firestore()
        .collection(`/profile/${payload.uid}/payments`)

      if (payload.dates && payload.dates.length > 0) {
        const start = moment(payload.dates[0]).add(-1, 'd')
        let end = moment(payload.dates[0]).add(1, 'd')
        if (payload.dates.length === 2) {
          end = moment(payload.dates[1]).add(1, 'd')
        }
        // ref = ref.where('charge.chargeResponse.created', '>=', start.unix())
        // ref = ref.where('charge.chargeResponse.created', '<=', end.unix())
        // console.log(`Getting Transactions for: ${start.unix()} - ${end.unix()}`)
        ref = ref.where('createdTime', '>=', start.valueOf())
        ref = ref.where('createdTime', '<=', end.valueOf())
        console.log(
          `Getting Transactions for: ${start.valueOf()} - ${end.valueOf()}`
        )
      }
      return ref.get().then(snapshot => {
        let items = snapshot.docs.map(doc => {
          return { ...doc.data() }
        })

        return items
        //return items.sort(function (a, b) { return a.submitDate < b.submitDate ? 1 : -1 });
      })
    },
    loadRefunds(context, payload) {
      if (!payload.uid) {
        console.warn('Missing uid')
        return []
      }
      let ref = firebase
        .firestore()
        .collection(`/profile/${payload.uid}/refund`)

      return ref.get().then(snapshot => {
        let items = snapshot.docs.map(doc => {
          return { id: doc.id, ...doc.data() }
        })
        return items
        //return items.sort(function (a, b) { return a.submitDate < b.submitDate ? 1 : -1 });
      })
    },
    loadExtraCharges(context, payload) {
      if (!payload.uid) {
        console.warn('Missing uid')
        return []
      }

      let ref = firebase
        .firestore()
        .collection(`/profile/${payload.uid}/extraCharge`)

      return ref.get().then(snapshot => {
        let items = snapshot.docs.map(doc => {
          return { id: doc.id, ...doc.data() }
        })
        console.log('loadExtraCharges: ', payload, items)
        return items
      })
    },

    deleteBankAccount(context, payload) {
      context.commit('setLoading', true)
      return firebase
        .firestore()
        .collection('profile')
        .doc(payload.uid)
        .update({
          account: {
            action: 'delete',
            id: payload.id,
            updatedTime: moment.utc().valueOf()
          }
        })
        .then(() => {
          context.commit('setMessage', {
            title: 'Account Delete Request Submitted',
            body: `Submitted a request to delete the account, please wait few seconds.`
          })
          context.commit('updateBankAccount', null)
          context.commit('setLoading', false)
        })
        .catch(error => {
          context.commit('setLoading', false)
          context.commit('setError', error)
          console.error(error)
        })
    },
    loadSettings({ commit }) {
      return firebase
        .firestore()
        .collection('admin')
        .doc('settings')
        .get()
        .then(snapshot => {
          commit('settings', snapshot.data())
          return snapshot.data()
        })
    },
    activateAccount({ commit, getters, dispatch }) {
      if (!getters.user) {
        console.error('error, not user detected')
        return
      }
      let uid = getters.user.id
      console.log('Activating account')
      return firebase
        .firestore()
        .collection('profile')
        .doc(uid)
        .collection('account')
        .doc('stripe')
        .set({ email: getters.user.email })
        .then(() => {
          commit('setLoading', false)
          dispatch('checkAccount')
        })
    },
    checkAccount({ commit, getters, dispatch }, payload) {
      if (!getters.user) {
        console.error('error, not user detected')
        return
      }
      let uid = getters.user.id
      console.log('Checking account uid', uid)

      return firebase
        .firestore()
        .collection('profile')
        .doc(uid)
        .collection('account')
        .doc('stripe')
        .get()
        .then(
          snapshot => {
            if (snapshot.exists) {
              console.log(
                'updateStripeCustomerInitialized has value',
                uid,
                snapshot.data(),
                payload
              )
              dispatch('getSources')

              commit(
                'updateStripeCustomerInitialized',
                snapshot.data() !== null
              )
            }
          },
          () => {
            console.log('updateStripeCustomerInitialized has not value', uid)
            commit('updateStripeCustomerInitialized', false)
          }
        )
    },
    getSources({ commit, getters }) {
      if (!getters.user) {
        console.error('error, not user detected')
        return
      }
      commit('setLoading', true)
      let uid = getters.user.id
      console.log('Getting source account')

      return firebase
        .firestore()
        .collection('profile')
        .doc(uid)
        .collection('sources')
        .get()
        .then(querySnapshot => {
          let source = []
          querySnapshot.forEach(data => {
            const obj = data.data()
            obj._id = data.id
            source.push(obj)
          })
          commit('updateSources', source)
          //dispatch('getSources')
          commit('setLoading', false)
        })
        .catch(error => {
          commit('setError', error)
          commit('setLoading', false)
        })
    },
    deleteSource({ commit, getters, dispatch }, id) {
      if (!getters.user) {
        console.error('error, not user detected')
        return
      }
      commit('setLoading', true)
      let uid = getters.user.id
      console.log('Getting source account')

      return firebase
        .firestore()
        .collection('profile')
        .doc(uid)
        .collection('sources')
        .doc(id)
        .delete()
        .then(() => {
          dispatch('getSources')
          commit('setLoading', false)
        })
        .catch(error => {
          commit('setError', error)
          commit('setLoading', false)
        })
    },
    getCharges({ commit, getters }) {
      if (!getters.user) {
        console.error('error, not user detected')
        return
      }
      commit('setLoading', true)
      let uid = getters.user.id
      console.log('Getting source account')

      return firebase
        .firestore()
        .collection('profile')
        .doc(uid)
        .collection('charges')
        .get()
        .then(querySnapshot => {
          let source = []
          querySnapshot.forEach(data => {
            source.push(data.data())
          })
          commit('updateCharges', source)
          commit('setLoading', false)
        })
        .catch(error => {
          commit('setError', error)
          commit('setLoading', false)
        })
    },
    addSource({ commit, getters, dispatch }, payload) {
      if (!getters.user) {
        console.error('error, not user detected')
        return
      }
      commit('setLoading', true)
      let uid = getters.user.id
      console.log('Adding source account')

      return firebase
        .firestore()
        .collection('profile')
        .doc(uid)
        .collection('sources')
        .add({ token: payload.id })
        .then(d => {
          commit('setLoading', false)
          dispatch('setMessage', {
            title: 'In Progress',
            body: 'Please wait few second and refresh the page.'
          })
          return d
        })
        .catch(error => {
          commit('setError', error)
          return error
        })
      /*
      firebase
        .database()
        .ref(`/stripe_customers/${uid}/sources`)
        .push({ token: payload.id })
        .then(() => {
          commit('setLoading', false)
        })*/
    },
    submitCharge({ commit, getters }, payload) {
      if (!getters.user) {
        console.error('error, not user detected')
        return
      }
      commit('setLoading', true)
      //let uid = getters.user.id
      payload.updatedAt = new Date().getTime()
      console.warn('Charge will be done in server side', payload)

      /*  firebase
        .firestore()
        .collection('profile')
        .doc(uid)
        .collection('charges')
        .add(payload)
        .then(() => {
          //dispatch('loadProfile')
          commit('setLoading', false)
        })
        .catch(error => {
          commit('setLoading', false)
          commit('setError', error)
          console.log(error)
        })*/

      /* firebase.database().ref(`/stripe_customers/${uid}/charges`).push(payload).then(() => {
        setInterval(() => { commit('setLoading', false) }, 1000 * 2)
      })*/
    },
    setBankAccount({ commit }, bank) {
      commit('updateBankAccount', bank)
    },
    //use Load
    loadBankAccount({ commit, getters }) {
      if (!getters.user) {
        console.error('error, not user detected')
        return
      }
      commit('setLoading', true)
      let uid = getters.user.id
      console.log('Getting account for ', uid)

      return firebase
        .firestore()
        .collection('admin')
        .doc('accounts')
        .collection('chefs')
        .doc(uid)
        .get()
        .then(data => {
          let bank = null
          if (data.exists) {
            if (data.data().bank) {
              bank = data.data().bank
              bank.id = data.id
              commit('updateBankAccount', bank)
            }
          }
          commit('setLoading', false)
          return bank
        })
        .catch(error => {
          commit('setError', error)
          commit('setLoading', false)
        })
    },
    getPaymentHistory({ commit, getters }) {
      if (!getters.user) {
        console.error('error, not user detected')
        return
      }
      commit('setLoading', true)
      const uid = getters.user.id
      console.log('Grabbing Payment History')
      return firebase
        .firestore()
        .collection('profile')
        .doc(uid)
        .collection('payments')
        .orderBy('created')
        .get()
        .then(snapshot => {
          let history = []
          snapshot.forEach(data => {
            if (data.data().charge) {
              let payload = {
                orderId: data.data().orderId,
                amount: data.data().charge.amount,
                refundAmount: data.data().charge.amount_refunded,
                orderDate: data.data().charge.created,
                status: data.data().charge.status
              }
              history.push(payload)
            }
          })
          // list comes sorted from oldest to newest; need to reverse so that most recent is first
          commit('setPaymentHistory', history.reverse())
          commit('setLoading', false)
        })
        .catch(error => {
          commit('setError', error)
          commit('setLoading', false)
        })
    }
  },
  getters: {
    bankAccount: state => {
      return state.bankAccount
    },
    sources: state => {
      return state.sources
    },
    charges: state => {
      return state.charges
    },
    settings: state => {
      return state.settings
    },
    stripeCustomerInitialized: state => {
      return state.stripeCustomerInitialized
    },
    paymentHistory: state => {
      return state.paymentHistory
    }
  }
}
