 /* eslint-disable */
import firebase from '@/firebase-init'
import moment from 'moment'

const profileMixin = {

  methods: {
    sendVerificationEmail() {
      let user = this.user
      this.showRefresh = true

      let fb = firebase
        .auth()
        .currentUser.sendEmailVerification()
        .then(function() {
          console.log('Verification Email Sent ', user.email)
          return true
        })
        .catch(function(error) {
          console.log('emailSent error ', error)
          return false
        })
      fb.then(t => {
        this.$store.dispatch('setMessage', {
          color: 'info',
          title: 'Verification',
          body: 'A verification message send, please check your email'
        })
        if (t) {
          this.showVerification = false
          this.showRefresh = true
        }
      })
    },
    isCFO(profile) {
      if (profile && profile.permits) {
        // If we want to show label for non - CFO chef's then we need to remove this filter
        let cfo = profile.permits.find(
          p => p.type && p.type.text === 'CFO'
        )
        return cfo != null
      }
      return false
    },
    isRestaurant(profile) {

      if (profile && profile.permits) {
        // If we want to show label for non - CFO chef's then we need to remove this filter
        let perm = profile.permits.find(
          p => p.type && p.type.text === 'RES'
        )

        return perm != null
      }
      return false
    },
    isOpenHour(profile) {

      if (profile.businessHours){
        const now = moment()
        const weekday = now.format('dddd')
        let isOpen = false
        profile.businessHours.forEach(bh => {
          if (bh.business_days && bh.fromHour && bh.endHour && bh.business_days.indexOf(weekday)>=0 ){
            isOpen = moment(now, 'HH:mm a').isBetween(moment(bh.fromHour, 'HH:mm a'), moment(bh.endHour, 'HH:mm a'))
            return
          }

        });
        return isOpen
      }
    }
  }
}

export default profileMixin
