<template>
  <v-container v-show="newUser">
    <form>
      <v-row>
        <v-col cols="12">
          <h3 class="text-center">
            Schedule onboarding with TakeIn representative
          </h3>
        </v-col>
      </v-row>

      <v-row justify="center" class="mb-4">
        <v-col cols="12" sm="6">
          <v-card>
            <v-card-text>
              <v-row justify="center">
                <v-col cols="12">
                  <h3>Questions</h3>
                </v-col>
              </v-row>
              <v-expansion-panels multiple>
                <v-expansion-panel>
                  <v-expansion-panel-header
                    :class="radiosRole ? 'title info--text' : 'title '"
                    >Your Role</v-expansion-panel-header
                  >
                  <v-expansion-panel-content>
                    <v-radio-group v-model="radiosRole" :mandatory="false">
                      <v-radio label="Owner" value="owner"></v-radio>
                      <v-radio
                        label="General Manager"
                        value="general"
                      ></v-radio>
                      <v-radio label="Other" value="other"></v-radio>
                      <v-text-field
                        v-if="radiosRole === 'other'"
                        label="Role"
                        v-model="otherRole"
                      ></v-text-field>
                    </v-radio-group>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header
                    :class="locations ? 'title info--text' : 'title '"
                    >How many locations do you have?</v-expansion-panel-header
                  >
                  <v-expansion-panel-content>
                    <v-text-field
                      label="number of locations"
                      v-model="locations"
                    ></v-text-field>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header
                    :class="
                      chkUbereats ||
                      chkPostmates ||
                      chkDoordash ||
                      chkGrubhub ||
                      chkCaviar ||
                      noApp ||
                      chkOthersApp
                        ? 'title info--text'
                        : 'title '
                    "
                  >
                    What delivery app are you using now?
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-checkbox
                      v-model="chkPostmates"
                      label="Postmates"
                      :disabled="noApp"
                      hide-details
                    ></v-checkbox>
                    <v-checkbox
                      v-model="chkUbereats"
                      label="Ubereats"
                      :disabled="noApp"
                      hide-details
                    ></v-checkbox>
                    <v-checkbox
                      v-model="chkDoordash"
                      label="Doordash"
                      :disabled="noApp"
                      hide-details
                    ></v-checkbox>
                    <v-checkbox
                      v-model="chkGrubhub"
                      label="Grubhub"
                      :disabled="noApp"
                      hide-details
                    ></v-checkbox>
                    <v-checkbox
                      v-model="chkCaviar"
                      :disabled="noApp"
                      label="Caviar"
                      hide-details
                    ></v-checkbox>
                    <v-checkbox
                      v-model="noApp"
                      label="None"
                      hide-details
                    ></v-checkbox>
                    <v-checkbox
                      :disabled="noApp"
                      v-model="chkOthersApp"
                      label="Other"
                      hide-details
                    ></v-checkbox>
                    <v-text-field
                      v-if="chkOthersApp"
                      v-model="otherApp"
                      label="Delivery App Name"
                    ></v-text-field>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header
                    :class="
                      radiosPOS || chkTablet ? 'title info--text' : 'title '
                    "
                    >Point of Sales?</v-expansion-panel-header
                  >
                  <v-expansion-panel-content>
                    <v-radio-group v-model="radiosPOS" :mandatory="false">
                      <v-radio label="Toasts" value="toasts"></v-radio>
                      <v-radio label="Micros" value="micros"></v-radio>
                      <v-radio label="Aloha" value="aloha"></v-radio>
                      <v-radio label="Other" value="other"></v-radio>
                      <v-radio label="None" value="none"></v-radio>
                    </v-radio-group>
                    <v-divider></v-divider>
                    <v-checkbox
                      v-model="chkTablet"
                      label="Tablet"
                      hide-details
                    ></v-checkbox>
                    <v-checkbox
                      v-model="chkPOS"
                      label="POS integration"
                      hide-details
                    ></v-checkbox>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel v-if="false">
                  <v-expansion-panel-header
                    :class="chkTablet || chkPOS ? 'title info--text' : 'title '"
                  >
                    Do you use a tablet or POS integration?
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-checkbox
                      v-model="chkTablet"
                      label="Tablet"
                      hide-details
                    ></v-checkbox>
                    <v-checkbox
                      v-model="chkPOS"
                      label="POS integration"
                      hide-details
                    ></v-checkbox>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                  <v-expansion-panel-header class="title"
                    >Menu or Food Photos Available?</v-expansion-panel-header
                  >
                  <v-expansion-panel-content>
                    <v-text-field
                      label="Where is your menu available?"
                      placeholder="Website, Print, Other"
                      v-model="menuLocation"
                    ></v-text-field>
                    <v-text-field
                      label="Website address"
                      placeholder="Website url"
                      v-model="website"
                    ></v-text-field>
                    <v-checkbox
                      v-model="chkPhotoAvailable"
                      label="Photos are available?"
                      hide-details
                    ></v-checkbox>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header
                    :class="chkInterested ? 'title info--text' : 'title '"
                  >
                    Are you interested in a free training on how to use TakeIn’s
                    features most effectively?
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-checkbox
                      v-model="chkInterested"
                      label="Yes"
                      hide-details
                    ></v-checkbox>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header class="title">
                    Please leave any Comments or Questions
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-textarea
                      solo
                      name="input-7-4"
                      v-model="comments"
                      label="Comments or Questions"
                    ></v-textarea>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <v-row justify="center">
                <v-col cols="12" class="d-flex justify-center">
                  <v-btn
                    color="primary"
                    type="submit"
                    @click.prevent="onSubmit"
                    :disabled="showThankYou"
                    >Submit</v-btn
                  >
                </v-col>
              </v-row>
              <hr />
              <v-row justify="center" v-if="showThankYou">
                <v-col cols="12" class="ma-2 subtitle-1  font-weight-thin">
                  Thank you. A TakeIn representative will contact you soon by
                  email. Please have your menu, photos, and deposit account
                  information available at that time.
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </form>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      //newUser: true,
      showThankYou: false,
      radiosRole: null,
      otherRole: null,
      radiosPOS: null,
      chkPostmates: null,
      chkUbereats: null,
      chkDoordash: null,
      chkGrubhub: null,
      chkCaviar: null,
      chkOthersApp: null,
      noApp: null,
      otherApp: null,
      chkTablet: null,
      chkPOS: null,
      chkPhotoAvailable: false,
      chkInterested: false,
      locations: null,
      menuLocation: null,
      website: null,

      comments: null
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
      newUser: 'newUser'
    })
  },
  methods: {
    onSubmit() {
      this.showThankYou = true

      let boardData = {
        uid: this.user.id,
        role: this.radiosRole || null,
        locations: this.locations || null,
        pos: this.radiosPOS || null,
        apps: [
          this.chkPostmates ? 'Postmates' : '',
          this.chkUbereats ? 'Ubereats' : '',
          this.chkDoordash ? 'Doordash' : '',
          this.chkGrubhub ? 'Grubhub' : '',
          this.chkCaviar ? 'Caviar' : '',
          this.chkOthersApp ? 'Other' : '',
          this.noApp ? 'none' : null
        ].filter(e => !!e),
        equipment: [
          this.chkTablet ? 'Tablet' : '',
          this.chkPOS ? 'POS integration' : ''
        ].filter(e => !!e),

        menuLocation: this.menuLocation || null,
        website: this.website || null,

        interested: this.chkInterested || false,
        comments: this.comments || null,
        otherRole: this.otherRole || null,
        otherApp: this.otherApp || null
      }
      console.log(boardData, 'board')
      this.$store.dispatch('saveOnboarding', boardData)
    }
  }
}
</script>
