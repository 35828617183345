<template>
  <v-row justify="center">
    <v-col
      :cols="$vuetify.breakpoint.lgAndDown ? 10 : 8"
      class="d-flex justify-center pa-0"
      v-resize="onResize"
      ref="svgStepperContainer"
    >
      <svg :width="width" :height="height" class="svgStepper"></svg>
    </v-col>
  </v-row>
</template>

<script>
import resize from 'vue-resize-directive'
import * as d3 from 'd3'
import { i18n } from 'fod-core'
import StepWelcome from './StepWelcome'
import StepProfile from './StepProfile'
import StepFinance from './StepFinance'
import StepSettings from './StepSettings'
import StepMenus from './StepMenus'
import StepLive from './StepLive'
export default {
  directives: {
    resize
  },
  components: {
    StepWelcome,
    StepProfile,
    StepFinance,
    StepSettings,
    StepMenus,
    StepLive
  },
  data: () => ({
    step: 0,
    menuSubStep: 0,
    width: 0,
    height: 140,
    radius: 20,
    stepInfo: [
      {
        name: 'Common.welcome',
        path: 'welcome',
        icon: '\uf118',
        step: 0
      },
      {
        name: 'Common.profile',
        path: 'profile',
        icon: '\uf2c2',
        step: 1
      },
      {
        name: 'Common.menus',
        path: 'menus',
        icon: '\uf2e7',
        step: 2
      },
      {
        name: 'Common.finance',
        path: 'finance',
        icon: '\uf3d1',
        step: 3
      },
      {
        name: 'Common.go_live',
        path: 'golive',
        icon: '\uf519',
        step: 4
      }
    ],
    subStepInfo: [
      {
        name: 'Common.products',
        path: 'products_sub',
        icon: '\uf805',
        step: 0
      },
      {
        name: 'Common.menus',
        path: 'menus_sub',
        icon: '\uf0c9',
        step: 1
      }
    ]
  }),
  watch: {
    width: function(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.drawSteps()
      }
    },
    step: function(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.drawSteps()
      }
    },
    menuSubStep: function(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.drawSteps()
      }
    },
    $route: function(to) {
      let matched = this.stepInfo.filter(e => e.path === to.name)[0]

      if (matched) {
        this.step = matched.step
      } else if (to.name === 'deposit' || to.name === 'bankaccount') {
        this.step = 4
      } else {
        this.step = 2 //set menus step

        const subMatched = this.subStepInfo.filter(e =>
          to.path.includes(e.path)
        )[0]

        if (subMatched) {
          this.menuSubStep = subMatched.step
        }
      }
    }
  },

  mounted() {
    const matched = this.stepInfo.filter(
      e => e.path === this.$router.history.current.name
    )[0]
    if (matched) {
      this.step = matched.step
    } else {
      this.step = 2 //set menus step
      const subMatched = this.subStepInfo.filter(
        e => e.path === this.$router.history.current.name
      )[0]
      if (subMatched) {
        this.menuSubStep = subMatched.step
      }
    }
    this.onResize()
    this.drawSteps()
  },

  methods: {
    onResize() {
      this.width = this.$refs.svgStepperContainer.clientWidth
    },
    drawSteps() {
      const that = this
      const margin = this.$vuetify.breakpoint.smAndDown ? 10 : 40
      const v_width = this.width - 2 * (margin + this.radius)
      d3.select('.svgStepper')
        .selectAll('*')
        .remove()

      //signs
      const checked = '✓',
        warning = '⚠'
      //Container for the gradients
      const defs = d3.select('.svgStepper').append('defs')

      //Filter for the outside glow
      const filter = defs.append('filter').attr('id', 'glow')
      filter
        .append('feGaussianBlur')
        .attr('stdDeviation', '1.7')
        .attr('result', 'coloredBlur')
      const feMerge = filter.append('feMerge')
      feMerge.append('feMergeNode').attr('in', 'coloredBlur')
      feMerge.append('feMergeNode').attr('in', 'SourceGraphic')
      this.width = this.$refs.svgStepperContainer.clientWidth
      this.stepInfo = this.stepInfo.map((si, k) => ({
        ...si,
        cx: margin + this.radius + (v_width / (this.stepInfo.length - 1)) * k,
        cy: this.height * 0.3
      }))
      const stepG = d3.select('.svgStepper').append('g')
      const circlesG = stepG.append('g').attr('class', 'circles')

      const circleG = circlesG
        .selectAll('circle')
        .data(this.stepInfo)
        .enter()
        .append('g')
        .attr('transform', d => `translate(${d.cx}, ${d.cy})`)
      circleG
        .append('circle')
        .attr('r', this.radius)
        .style('stroke', d => (this.step >= d.step ? '#00B729' : '#eaeaea'))
        .style('stroke-width', d => (this.step >= d.step ? 4 : 1.5))
        .style('fill', d =>
          this.step >= d.step
            ? this.step === d.step
              ? 'white'
              : '#00B729'
            : '#eaeaea'
        )
        .style('filter', 'url(#glow)')
        .on('mouseover', function() {
          d3.select(this)
            .attr('cursor', 'pointer')
            .attr('opacity', 0.8)
        })
        .on('mouseout', function() {
          d3.select(this)
            .attr('cursor', 'none')
            .attr('opacity', 1)
        })
        .on('click', function(d) {
          if (d.step === 2) {
            // menus
            that.$router.push('/profile/wizard/' + d.path + '/products_sub')
          } else {
            that.$router.push('/profile/wizard/' + d.path)
          }
        })

      circleG
        .append('text')
        .attr('text-anchor', 'middle')
        .attr('y', -this.radius * 1.5)
        .style('fill', d => (this.step >= d.step ? '#00B729' : '#464d69'))
        .style('font-size', Math.max(10, this.width * 0.015))
        .text(d =>
          this.step >= d.step ? checked + ' ' + i18n.t(d.name) : i18n.t(d.name)
        )
      circleG
        .append('text')
        .attr('class', 'far fas')
        .attr('y', 8)
        .attr('text-anchor', 'middle')
        .style('pointer-events', 'none')
        .style('fill', d => (this.step === d.step ? '#00B729' : 'white'))
        .style('font-size', 22)
        .text(d => d.icon)

      const linesG = stepG.append('g').attr('class', 'lines')

      for (let i = 0; i < this.stepInfo.length - 1; i++) {
        linesG
          .append('line')
          .attr('class', 'bg-line')
          .attr('x1', this.stepInfo[i].cx)
          .attr('y1', this.stepInfo[i].cy)
          .attr('x2', this.stepInfo[i + 1].cx)
          .attr('y2', this.stepInfo[i + 1].cy)
          .style('stroke', '#eaeaea')
          .style('stroke-width', 6)

        linesG
          .append('line')
          .attr('class', 'effect-line main-line' + (i + 1))
          .attr('x1', this.stepInfo[i].cx)
          .attr('y1', this.stepInfo[i].cy)
          .attr('x2', this.stepInfo[i + 1].cx)
          .attr('y2', this.stepInfo[i + 1].cy)
          .style('stroke', () =>
            this.stepInfo[i + 1].step <= this.step ? '#00B729' : 'lightgrey'
          )
          .style('stroke-width', 2)
      }

      linesG.selectAll('.effect-line').each(function(d, i) {
        if (i + 1 === that.step) {
          d3.select(this)
            .attr('x1', that.stepInfo[i].cx)
            .attr('y1', that.stepInfo[i].cy)
            .attr('x2', that.stepInfo[i].cx)
            .attr('y2', that.stepInfo[i].cy)
            .transition()
            .duration(500)
            .attr('x2', that.stepInfo[i + 1].cx)
            .attr('y2', that.stepInfo[i + 1].cy)
            .style('stroke', '#00B729')
        }
      })
      circlesG.raise()
      if (this.step === 2) {
        // step = menus
        //additional circles
        const additionalCirclesG = stepG
          .append('g')
          .attr('class', 'additional-circles')
        const additionalLinesG = stepG
          .append('g')
          .attr('class', 'additional-lines')
        const menuStepInfo = this.stepInfo.filter(si => si.step === 2)[0]
        const subPositions = [
          {
            cx: menuStepInfo.cx - 65,
            cy: menuStepInfo.cy + 65
          },
          {
            cx: menuStepInfo.cx + 65,
            cy: menuStepInfo.cy + 65
          }
        ]
        this.subStepInfo = this.subStepInfo.map((sub, k) => ({
          ...sub,
          cx: subPositions[k].cx,
          cy: subPositions[k].cy
        }))

        const lineInfo = [
          {
            step: 0,
            x1: menuStepInfo.cx,
            y1: menuStepInfo.cy,
            x2: this.subStepInfo[0].cx,
            y2: this.subStepInfo[0].cy
          },
          {
            step: 1,
            x1: menuStepInfo.cx,
            y1: menuStepInfo.cy,
            x2: this.subStepInfo[1].cx,
            y2: this.subStepInfo[1].cy
          }
        ]
        const addCircleG = additionalCirclesG
          .selectAll('circle')
          .data(this.subStepInfo)
          .enter()
          .append('g')
          .attr(
            'transform',
            `translate(${menuStepInfo.cx}, ${menuStepInfo.cy})`
          )
          .style('opacity', 0)

        addCircleG
          .append('circle')
          .attr('r', this.radius * 0.8)
          .style('stroke', d =>
            this.menuSubStep === d.step ? '#00B729' : '#eaeaea'
          )
          .style('stroke-width', d => (this.menuSubStep === d.step ? 4 : 1.5))
          .style('fill', d =>
            this.menuSubStep === d.step ? 'white' : '#eaeaea'
          )
          .style('filter', 'url(#glow)')
          .on('mouseover', function() {
            d3.select(this)
              .attr('cursor', 'pointer')
              .attr('opacity', 0.8)
          })
          .on('mouseout', function() {
            d3.select(this)
              .attr('cursor', 'none')
              .attr('opacity', 1)
          })
          .on('click', function(d) {
            that.$router.push(
              '/profile/wizard/' + menuStepInfo.path + '/' + d.path
            )
          })
        addCircleG
          .append('text')
          .attr('text-anchor', 'middle')
          .attr('y', this.radius * 1.6)
          .style('fill', d =>
            this.menuSubStep === d.step ? '#00B729' : '#464d69'
          )
          .style('font-size', Math.max(10, this.width * 0.015))
          .text(d => i18n.t(d.name))
        addCircleG
          .append('text')
          .attr('class', 'far fas')
          .attr('y', 6)
          .attr('text-anchor', 'middle')
          .style('pointer-events', 'none')
          .style('fill', d =>
            this.menuSubStep === d.step ? '#00B729' : 'white'
          )
          .style('font-size', 18)
          .text(d => d.icon)

        addCircleG
          // .transition()
          // .duration(200)
          .style('opacity', 1)
          .attr('transform', d => `translate(${d.cx}, ${d.cy})`)

        additionalLinesG
          .selectAll('line')
          .data(lineInfo)
          .enter()
          .append('line')
          .attr('x1', d => d.x1)
          .attr('y1', d => d.y1)
          .attr('x2', d => d.x1)
          .attr('y2', d => d.y1)
          .style('stroke', d =>
            this.menuSubStep === d.step ? '#00B729' : '#eaeaea'
          )
          .style('stroke-width', 2)
        additionalLinesG
          .selectAll('line')
          // .transition()
          // .duration(200)
          .attr('x1', d => d.x1)
          .attr('y1', d => d.y1)
          .attr('x2', d => d.x2)
          .attr('y2', d => d.y2)
        additionalLinesG.lower()
        addCircleG.raise()
      }
    }
  }
}
</script>
