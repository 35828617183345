<template>
  <v-container v-if="!loading && food">
    <v-row>
      <v-col cols="12">
        <h4>You are reviewing {{ food.name }}</h4>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="8">
        <v-card>
          <v-img v-if="food.photos" :src="imageSrc(food)" height="300px">
          </v-img>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="mt-5">
      <v-col cols="12" sm="6">
        <h3>Overall Rating</h3>
        <star-rating
          v-model="rating"
          :read-only="showThankYou"
          :rating="rating"
        ></star-rating>
      </v-col>
      <v-col cols="12" sm="6" v-if="updatedTime">
        Review Date: <span>{{ updatedTime | moment('from') }}</span>
      </v-col>
    </v-row>
    <v-row class="mt-3">
      <v-col cols="12" sm="7">
        <v-text-field
          multi-line
          name="review"
          rows="2"
          placeholder="Few words about this dish"
          label="Public Review"
          id="review"
          :readonly="showThankYou"
          v-model="publicComment"
        >
        </v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-btn
          dark
          color="info darken-3"
          :disabled="showThankYou"
          @click.stop="submitReview"
          >Submit</v-btn
        >
      </v-col>
    </v-row>
    <v-row v-if="showThankYou">
      <h3>Thank you for your review</h3>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      publicComment: null,
      rating: null,
      updatedTime: null,
      showThankYou: false
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
      food: 'food',
      review: 'review',
      loading: 'loading'
    }),
    imageUrl() {
      return this.food && this.food.photos && this.food.photos.length > 0
        ? this.food.photos[0].url || this.food.photos[0].imageUrl
        : null
    }
  },
  mounted() {
    let foodId = this.$route.params.food_id
    let orderId = this.$route.params.order_id
    this.$store.dispatch('findById', foodId)
    this.$store.dispatch('loadDishReview', { foodId, orderId }).then(data => {
      if (data) {
        this.rating = data.rating
        this.publicComment = data.publicComment
        if (data.updatedTime) {
          this.updatedTime = new Date(data.updatedTime)
        }
      }
    })
  },
  methods: {
    submitReview() {
      let review = {
        foodId: this.$route.params.food_id,
        orderId: this.$route.params.order_id,
        publicComment: this.publicComment,
        rating: this.rating
      }
      this.$store.dispatch('saveDishReview', review).then(() => {
        this.showThankYou = true
      })
      console.log('Submitting review', review)
    },
    imageSrc(dish) {
      if (dish.photos) {
        if (dish.photos.length > 0 && dish.photos[0] !== undefined) {
          return dish.photos[0].url || dish.photos[0].imageUrl
        }
        if (dish.photos.length > 0 && dish.photos[1] !== undefined) {
          return dish.photos[1].url
        }
      }
      return null
    }
  }
}
</script>
