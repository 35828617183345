<template>
  <v-container>
    <v-row justify="center" v-if="submitted">
      <v-col cols="12" sm="10" xl="8">
        <h3>{{ $t('Common.approval_submit_message') }}</h3>
        {{ approval.updatedTime | formatDateTime }}
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12" sm="10" xl="8">
        <v-sheet class="pa-8 main_text--text shadow mb-4">
          <v-row>
            <v-col>
              <div v-html="$t('Common.congratulations')"></div>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-radio-group v-model="approval.orderTablet" :mandatory="false">
                <v-radio
                  :label="$t('Common.use_tablet')"
                  value="false"
                ></v-radio>
                <v-radio
                  :label="$t('Common.order_tablet')"
                  value="true"
                ></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-checkbox
                color="takein"
                :hide-details="true"
                v-model="approval.complimentaryVideoShoot"
                :label="$t('Common.request_complimentary_video_shoot')"
                dense
                class="mt-4"
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-checkbox
                color="takein"
                :hide-details="true"
                v-model="approval.complimentaryPaidMarketing"
                :label="$t('Common.request_complimentary_paid_marketing')"
                dense
                class="mt-4"
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-row justify="center">
            <div v-html="$t('Common.please_review_restaurant')" class="py-4" />
          </v-row>
          <v-row justify="center">
            <v-col class="mb-4">
              <v-img
                contain
                height="200"
                src="https://storage.googleapis.com/img.fod.live/static/app/chef/thank-you-1428147_1920_xs.png"
              />
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col cols="8" class="d-flex justify-center">
              <v-btn
                color="takein"
                rounded
                @click.once="submit"
                :ripple="false"
                class="white--text"
                >{{ $t('Common.submit_approval') }}</v-btn
              >
            </v-col>
          </v-row>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  data() {
    return {
      submitted: false,
      approval: {
        orderTablet: false,
        complimentaryVideoShoot: false,
        complimentaryPaidMarketing: false
      }
    }
  },
  async mounted() {
    const data = await this.$store.dispatch('getOnboarding')
    this.approval = data || {}
    this.submitted = !!this.approval.updatedTime
  },
  methods: {
    submit() {
      this.submitted = true
      this.$store.dispatch('saveOnboarding', this.approval).then(() => {
        this.$store.dispatch('setMessage', {
          title: 'Submitted',
          body: 'Your apporval reuqest successfuly submitted'
        })
      })
    }
  }
}
</script>
<style>
.shadow {
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
    0px 4px 8px rgba(0, 0, 0, 0.04);
}
.v-label {
  color: #464d69 !important;
  font-size: 14px;
}
</style>
