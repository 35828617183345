<template>
  <v-select
    v-model="selectedItems"
    :label="typeLabel"
    :items="totalList"
    multiple
    item-text="name"
    :hint="hint"
    persistent-hint
    clearable
    chips
    :disabled="noItems"
    return-object
    color="takein"
  >
    <!-- <template slot="no-data">
      <v-list-item>
        <span class="subheading">
          {{ $t('Common.no_suggested_items') }}
        </span>
      </v-list-item>
    </template> -->

    <!-- <template v-slot:selection="{ item }">
      <v-flex xs2>
        <v-avatar v-if="item.image" size="25px">
          <img :src="item.image" />
        </v-avatar>
      </v-flex>
      <v-flex class="ml-1">{{ item.name }}</v-flex>
    </template>

    <template v-slot:item="{ item }">
      <v-list-item>
        <v-list-item-avatar v-if="item.image">
          <img :src="item.image" />
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title v-html="item.name"></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template> -->
  </v-select>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  props: ['type', 'dish', 'menus', 'dishList', 'hint'],
  data: () => ({
    colors: ['green', 'purple', 'indigo', 'cyan', 'teal', 'orange', 'red'],
    selectedItems: [],
    typeLabel: null,
    totalList: []
  }),
  computed: {
    ...mapGetters({
      suggestedProducts: 'suggestedProducts',
      suggestedMenus: 'suggestedMenus'
    }),
    noItems() {
      return (
        (!this.dishList || this.dishList.length == 0) &&
        (!this.menus || this.menus.length == 0)
      )
    }
  },
  beforeMount() {
    let suggestedList = []
    if (this.type === 'suggested_products' && this.dishList) {
      this.typeLabel = 'Add suggested dish items'
      // need to eventually check if consumer is a follower
      this.totalList = this.dishList.filter(
        item => item.id != this.dish.id && item.visibility === 'public'
      )
      suggestedList = this.suggestedProducts
    } else if (this.type === 'suggested_menus') {
      this.typeLabel = 'Add suggested menus'
      this.totalList = this.menus.filter(
        item =>
          (!item.type || item.type != 'parent') &&
          item.isActive &&
          item.isPublic
      )
      suggestedList = this.suggestedMenus
    }

    if (this.noItems) {
      this.typeLabel = this.$t('Common.no_suggested_items')
    }

    // totalList = either all dish list or all menus
    // suggestedList = list of suggested dishes or products ids
    if (suggestedList) {
      for (let i = 0; i < suggestedList.length; i++) {
        const element = this.totalList.find(
          item => item.id === suggestedList[i]
        )
        if (element) {
          // let j = Math.floor(i % this.colors.length)
          let v = {
            id: element.id,
            name: element.name
            // image: element.photos ? element.photos[0].imageUrl : null,
            // color: this.colors[j]
          }

          this.selectedItems.push(v)
        }
      }
    }

    // this.$store.dispatch('loadSuggested', {'id': this.dish_id, 'type': this.type})
  },
  watch: {
    selectedItems(val) {
      const newList = val.map(a => a.id)
      if (this.type === 'suggested_products') {
        this.$store.dispatch('updateSuggestedProducts', newList)
      } else if (this.type === 'suggested_menus') {
        this.$store.dispatch('updateSuggestedMenus', newList)
      }
    }
  },

  methods: {}
}
</script>
