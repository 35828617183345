<template>
  <v-container>
    <v-row v-if="error" justify="center">
      <v-col cols="9" sm="7" class="offset-sm3">
        <app-alert @dismissed="onDismissed" :text="error.message"></app-alert>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col cols="12" sm="9" class="offset-sm3">
        <v-card>
          <v-card-title primary-title>
            <h3>{{ $t('Chef.chef_signup_title') }}</h3>

            <v-btn
              color="info"
              class="hidden-sm-and-up"
              text
              block
              @click="$vuetify.goTo('#how_it_works')"
            >
              How it works?
              <v-icon right>help</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <form @submit.prevent="onSignup" class="pa-4">
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    name="name"
                    :label="$t('Common.your_name')"
                    :hint="$t('Chef.chef_signup_name_hint')"
                    id="name"
                    v-model="name"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6" class="pr-4">
                  <v-text-field
                    name="email"
                    label="Email"
                    id="email"
                    v-model="email"
                    type="email"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    name="phone"
                    :label="$t('Common.phone')"
                    id="phone"
                    v-model="phone"
                    mask="phone"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6" class="pr-4">
                  <v-text-field
                    name="password"
                    label="Password"
                    hint="At least 6 characters"
                    min="6"
                    id="password"
                    v-model="password"
                    type="password"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-text-field
                    name="confirmPassword"
                    label="Confirm Password"
                    id="confirmPassword"
                    v-model="confirmPassword"
                    validate-on-blur
                    type="password"
                    :rules="[matchPassword]"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="my-3">
                <v-col
                  cols="12"
                  v-html="$t('Common.signup_agree_terms')"
                ></v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <div class="text-center">
                    <v-btn
                      x-large
                      type="submit"
                      :disabled="!comparePasswords"
                      :loading="loading"
                      class="takein_logo white--text mr-3"
                    >
                      Sign up
                      <span slot="loader" class="custom-loader">
                        <v-icon light>cached</v-icon>
                      </span>
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
              <v-row class="mt-8 mb-2">
                <v-col cols="12">{{ $t('Common.signup_options') }}</v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-btn
                    rounded
                    :disabled="loading"
                    :loading="loading"
                    @click.prevent="onSigninGoogle"
                    color="info white--text"
                    class="white--text"
                  >
                    Google
                    <v-icon right dark>fab fa-google white-text</v-icon>
                  </v-btn>

                  <v-btn
                    rounded
                    :disabled="loading"
                    :loading="loading"
                    @click.prevent="onSigninFacebook"
                    class="blue white--text mx-8"
                  >
                    Facebook
                    <v-icon right dark>fab fa-facebook-f</v-icon>
                  </v-btn>
                  <router-link router to="/signin">Sign In</router-link>
                  ({{ $t('Chef.if_has_chef_account') }})
                </v-col>
              </v-row>
            </form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="pt-3 mb-8" justify="center">
      <v-col cols="12" sm="9" class="offset-sm3" id="how_it_works">
        <how-it-works />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import HowItWorks from './HowItWorks'
export default {
  components: {
    HowItWorks
  },
  data() {
    return {
      name: null,
      phone: null,
      email: '',
      password: '',
      confirmPassword: '',
      matchPassword: v =>
        (this.password && v === this.password) || "The passwords don't match"
    }
  },
  mounted() {
    console.log('Signup type:', this.$route.params.type)
    this.$gtm.trackEvent({
      event: 'takein_event', // Event type [default = 'interaction'] (Optional)
      category: 'sign_up',
      action: 'signup_view',
      label: 'signup_view',
      method: 'signup_view',
      payload: { method: 'signup_view', source: 'fb2' }
    })
  },
  computed: {
    comparePasswords() {
      return this.password === this.confirmPassword
    },
    user() {
      return this.$store.getters.user
    },
    error() {
      return this.$store.getters.error
    },
    loading() {
      return this.$store.getters.loading
    }
  },
  watch: {
    user(value) {
      if (value !== null && value !== undefined) {
        this.$router.push('/')
      }
    }
  },
  methods: {
    onSignup() {
      // sets profile status to public from sign up so that chef's profile shows up on maps
      this.$gtm.trackEvent({
        event: 'takein_event', // Event type [default = 'interaction'] (Optional)
        category: 'sign_up',
        action: 'signup',
        label: 'signup',
        method: 'signup_email',
        payload: { method: 'signup_email', source: 'fb2' }
      })
      this.$store.dispatch('signUserUp', {
        name: this.name,
        email: this.email,
        phone: this.phone,
        password: this.password,
        showOnboard:
          String(this.$router.currentRoute.query.show_onboard) == 'true' ||
          null,
        type: 'chef',
        profileStatus: 'public'
      })
    },
    onSigninGoogle() {
      this.$gtm.trackEvent({
        event: 'takein_event', // Event type [default = 'interaction'] (Optional)
        category: 'sign_up',
        action: 'signup',
        label: 'signup',
        method: 'signup_google',
        payload: { method: 'signup_google', source: 'fb2' }
      })
      this.$store.dispatch('signUserInGoogle', {
        type: 'chef'
      })
    },
    onSigninFacebook() {
      this.$gtm.trackEvent({
        event: 'takein_event', // Event type [default = 'interaction'] (Optional)
        category: 'sign_up',
        action: 'signup',
        label: 'signup',
        method: 'signup_facebook',
        payload: { method: 'signup_facebook', source: 'fb2' }
      })
      this.$store.dispatch('signUserInFacebook', {
        type: 'chef'
      })
    },
    onSigninTwitter() {
      this.$gtm.trackEvent({
        event: 'takein_event', // Event type [default = 'interaction'] (Optional)
        category: 'sign_up',
        action: 'signup',
        label: 'signup',
        method: 'signup_twitter',
        payload: { method: 'signup_twitter', source: 'fb2' }
      })
      this.$store.dispatch('signUserInTwitter', {
        type: 'chef'
      })
    },
    onDismissed() {
      this.$store.dispatch('clearError')
    }
  }
}
</script>
