<template>
  <v-row>
    <v-col cols="12">
      {{ $t('Common.get_public_photos') }}
      <v-btn text @click.native="getPhotos" slot="activator" v-if="false">
        <v-icon>photo_album</v-icon>
      </v-btn>
      <v-btn text @click.native="getPhotosFromUrl" slot="activator">
        <v-icon>insert_link</v-icon>
      </v-btn>
    </v-col>
    <v-col cols="12">
      <v-dialog v-model="photoOptions" persistent max-width="800px">
        <v-card>
          <v-card-title class="headline-2">
            Photos provided by Pexels
            <img
              width="100px"
              src="http://images.pexels.com/lib/api/pexels-white.png"
            />
            <v-progress-circular
              v-if="localLoading"
              indeterminate
              color="primary"
            ></v-progress-circular>
          </v-card-title>
          <v-card-text>
            <v-text-field
              light
              solo
              @keyup.enter="getPhotos"
              v-model="searchText"
              append-outer-icon="search"
              @click:append-outer="getPhotos"
              placeholder="Search Photos"
            ></v-text-field>
          </v-card-text>

          <div v-if="images">
            <div class="images columns is-multiline" v-if="images.length">
              <v-row>
                <v-col
                  sm="6"
                  md="4"
                  lg="3"
                  v-for="image in images"
                  :key="image.id"
                >
                  <v-img
                    contain
                    v-if="image.src"
                    @click="selectedUrl(image.src.medium)"
                    class="image"
                    :src="image.src.medium"
                  />
                </v-col>
              </v-row>
            </div>

            <h5 v-if="!images.length && !isSearching">No results found!</h5>
          </div>

          <v-card-actions>
            <v-row justify="center" align="center">
              <v-col cols="10">
                <v-pagination
                  v-model="page"
                  class="my-4"
                  :length="total"
                ></v-pagination>
              </v-col>
              <v-col cols="2">
                <v-spacer></v-spacer>
                <v-btn
                  color="grey darken-1"
                  text
                  @click.native="photoOptions = false"
                  >Close</v-btn
                >
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="metalink" persistent max-width="700px" width="80%">
        <v-card>
          <v-card-title class="headline-3">
            Photos from Internet. Just copy and paste the site address:
            <v-progress-circular
              v-if="localLoading"
              indeterminate
              color="primary"
            ></v-progress-circular>
          </v-card-title>
          <v-card-text>
            <v-text-field
              light
              @change="getPhotosFromUrl"
              v-model="urlText"
              prepend-icon="cloud"
              append-outer-icon="search"
              @click:append-outer="getPhotosFromUrl"
              placeholder="https://www.instagram.com/p/B7y2iLDn86-/"
            ></v-text-field>
          </v-card-text>

          <div v-if="images">
            <div class="images columns is-multiline" v-if="images.length">
              <div
                class="column is-4"
                v-for="image in images"
                @click="selectedUrl(image.webformatURL)"
                :key="image.id"
              >
                <img class="image" :src="image.webformatURL" />
              </div>
            </div>

            <h5 v-if="!images.length && !isSearching">No results found!</h5>
          </div>

          <v-card-actions>
            <v-btn
              :disabled="!imageUrl"
              color="info"
              small
              @click.prevent="selected"
              >Copy Image</v-btn
            >
            <v-btn :disabled="!imageUrl" small @click.prevent="selectedUrl"
              >Copy URL</v-btn
            >
            <v-spacer></v-spacer>

            <v-btn color="grey darken-1" text @click.native="metalink = false"
              >Close</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import axios from 'axios'

export default {
  props: {
    query: {
      type: String
    },
    uid: {
      type: String
    },
    id: {
      type: String
    }
  },
  data() {
    return {
      image: null,
      imageUrl: null,
      page: 1,
      per_page: 20,
      total: 1,
      photoOptions: false,
      metalink: false,
      apiUrl: 'https://api.pexels.com',
      images: null,
      searchText: null,
      urlText: null,
      isSearching: false,
      localLoading: false
    }
  },
  watch: {
    page: function() {
      this.getPhotos()
    }
  },

  methods: {
    selectedUrl(url) {
      console.log(this.imageUrl)
      this.photoOptions = false
      this.metalink = false
      this.$emit('onWebPhotoSelected', url ? url : this.imageUrl)
    },
    async selected() {
      this.photoOptions = false
      this.metalink = false
      this.image = await this.downloadImage(this.imageUrl)
      console.log(this.image.width)
      const index = Math.random()
        .toString(36)
        .substring(8)
      this.$store
        .dispatch('uploadDishPhoto', {
          id: this.id,
          uid: this.uid,
          index: index,
          src: this.image.src,
          image: this.image,
          fileName: `${index}.${this.image.ext || '.jpg'}`
        })
        .then(e => {
          this.$store.dispatch('setLoading', false)
          this.$store.dispatch('setMessage', {
            title: 'Image Copied',
            body: 'Please refresh your screen'
          })
        })
    },
    async getPhotosFromUrl() {
      this.metalink = true
      this.images = null
      if (this.urlText && this.urlText.length > 0) {
        this.localLoading = true
        const targetUrl = `${process.env.VUE_APP_API}/scrapeUrl?url=${this.urlText}`
        console.log('Getting photos from:', targetUrl)
        //const targetUrl = `https://api.microlink.io/?url=${this.urlText}`
        const url = await axios
          .get(targetUrl)
          .then(res => {
            this.localLoading = false
            console.log(res.data.image)
            if (res.data.image) {
              this.images = [{ webformatURL: res.data.image }]
              return res.data.image
            }
          })
          .catch(e => {
            console.error(e)
            this.images = []
            this.localLoading = false
          })

        if (url) {
          this.imageUrl = url
          // this.image = await this.downloadImage(url)
        }
      }
    },
    async downloadImage(url) {
      var base64 = await axios
        .get(url, {
          responseType: 'arraybuffer'
        })
        .then(response =>
          Buffer.from(response.data, 'binary').toString('base64')
        )
      var img = new Image()
      img.ext = img.ext = url
        .split('?')[0]
        .split('.')
        .pop()
      img.src = 'data:image/jpeg;base64, ' + base64
      return img
    },
    getPhotos() {
      console.log('Getting photos for:' + this.searchText)
      if (!this.searchText) {
        this.searchText = this.query
      }
      this.photoOptions = true

      if (this.searchText) {
        this.images = []
        this.isSearching = true
        const searchQuery = encodeURIComponent(this.searchText)
        this.localLoading = true
        axios
          .get(
            `${this.apiUrl}/v1/search?query=${searchQuery}&page=${this.page}&per_page=${this.per_page}`,
            {
              headers: {
                Authorization:
                  '563492ad6f91700001000001d233c6591d204806b456a09b0baa911c'
              }
            }
          )
          .then(res => {
            this.localLoading = false
            this.total = res.data.total_results / this.per_page
            this.total = parseInt(this.total)
            if (res.data.total_results != 0) {
              this.images = res.data.photos
              this.isSearching = false
            } else {
              this.isSearching = false
            }
          })
          .catch(e => {
            console.error(e)
            this.images = []
            this.localLoading = false
          })
      }
    }
  }
}
</script>
<style scoped>
.image {
  height: 50vh;
}
</style>
