<template>
  <v-container>
    <h3 class="title">Product Review</h3>
    <product-review
      v-for="(product, index) in products"
      :key="index"
      :data="getReviewData(product.dish.id)"
      :product="product"
      :readonly="readonly"
      @onRatingUpdate="onRatingUpdate"
    />
  </v-container>
</template>
<script>
import ProductReview from "./ProductReview";
export default {
  components: { ProductReview },
  props: {
    products: {
      Type: Array,
    },
    data: {
      Type: Array,
    },
    readonly: { Type: Boolean, default: false },
  },
  methods: {
    getReviewData(id) {
      return this.data ? this.data[id] : null;
    },
    onRatingUpdate(payload) {
      console.log("onRatingUpdate ", payload);
      let review = {};
      review[payload.id] = payload.ratings;
      this.$emit("onRatingUpdate", review);
    },
  },
};
</script>
