<template>
  <v-container>
    <v-card>
      <v-card-title>
        <v-row justify="center" class="mx-2">
          <span>Generating one time use coupon: {{ title }}</span>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row align="center">
            <v-col cols="6" sm="4">
              <v-radio-group v-model="amount_type" row>
                <v-radio label="$" value="$" class="fsx-12"></v-radio>
                <v-radio label="%" value="%" class="fsx-12"></v-radio>
              </v-radio-group>
            </v-col>

            <v-col cols="4" sm="3" lg="3">
              <v-text-field
                :prefix="amount_type === '$' ? '$' : ''"
                :suffix="amount_type === '%' ? '%' : ''"
                :label="
                  amount_type === '$'
                    ? 'Amount of each coupon'
                    : 'Percent discount'
                "
                type="number"
                v-model="amount"
                :rules="[rules.checkAmount]"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="6" sm="4">
              <h3 class="info--text font-weight-black	">{{ code }}</h3>
            </v-col>
            <v-col cols="6" sm="4">
              Expires in one month
            </v-col>
          </v-row>
        </v-container></v-card-text
      >
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="grey darken-1" text @click="onCancel">Close</v-btn>
        <v-btn
          color="blue darken-1"
          dark
          :loading="loading"
          @click="onUseCoupon"
          >Use</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import coupon from 'coupon-code'
import moment from 'moment'

export default {
  props: {
    title: {
      type: String
    }
  },
  data() {
    return {
      code: null,
      amount: 10,
      amount_type: '%',
      loading: false,
      rules: {
        checkAmount: value => {
          if (value) {
            if (value <= 0) {
              return 'Wrong value'
            }
            if (this.amount_type === '%') {
              if (value > 100) {
                return 'Wrong value'
              }
            }
          }
          return true
        }
      }
    }
  },
  mounted() {
    this.code = coupon.generate({ parts: 1, partLen: 4 })
  },
  methods: {
    async onUseCoupon() {
      this.loading = true
      const end_date = moment()
        .add(1, 'month')
        .format('YYYY-MM-DD')
      const list = [this.code]
      const payload = {
        title: this.title,
        amount: parseFloat(this.amount),
        promo_category: 'single_use',
        promo_type: { name: 'Price Discount', value: 'price' },
        max_redemptions: 1,
        total: 1,
        code: this.code,
        all_products: true,
        amount_type: this.amount_type,
        list,
        end_date
      }
      console.log(payload)

      this.loading = false
      payload.code = this.code
      this.$emit('onUseCoupon', payload)
    },
    onCancel() {
      this.$emit('onCancel')
    }
  }
}
</script>

<style lang="scss" scoped></style>
