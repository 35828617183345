<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" lg="10">
        <v-sheet class="pa-4 main_text--text shadow">
          <h4 class="font-weight-bold text-uppercase" v-if="$parent.showWizard">
            {{ $t('Common.step') }} {{ $t('Common.two') }} -
            {{ $t('Common.products') }} & {{ $t('Common.menus') }}
          </h4>
          <router-view></router-view>
        </v-sheet>
      </v-col>
      <v-btn
        fixed
        dark
        fab
        bottom
        right
        color="success"
        style="margin-right: 64px"
        @click="$parent.showWizard = !$parent.showWizard"
      >
        <v-icon>{{
          $parent.showWizard
            ? 'mdi-chevron-triple-up'
            : 'mdi-chevron-triple-down'
        }}</v-icon>
      </v-btn>
    </v-row>
  </v-container>
</template>
<script>
export default {
  components: {},
  data() {
    return {}
  }
}
</script>
<style>
.v-label {
  font-size: 12px;
}
.shadow {
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
    0px 4px 8px rgba(0, 0, 0, 0.04);
}
</style>
