//import moment from 'moment'
export var OrderMixin = {
  data() {
    return {
      deliveryDate: null,
      deliveryTime: null,
      deliveryMethod: null,
      dateModal: false,
      timeModal: false,
      notes: null,
      order: null,
      delivery: null,
      //totalPrice: null,
      surcharge: null,
      source: null,
      orderAmount: 1,
      payment: null,
    };
  },
  computed: {
    totalPrice() {
      return (
        this.orderAmount * this.unitPrice + this.surcharge + this.platformFee
      );
    },
    platformFee() {
      if (this.settings && this.settings.service_fee) {
        return this.orderAmount * this.unitPrice * this.settings.service_fee;
      }
      return 0;
    },
    isBuyer() {
      return this.user.id === this.myOrder.buyerUserId;
    },
    canRefund() {
      if (this.myOrder && this.myOrder.status) {
        switch (this.myOrder.status) {
          case "accepted":
          case "accepting":
          case "delivered":
          case "delivering":
          case "processing":
          case "picked_up":
          case "confirmed":
          case "picking_up":
            return true;
        }
      }
      return false;
    },
    maxRefund() {
      return this.isAdmin
        ? this.myOrder.payment.all_fees.total
        : this.myOrder.payment.all_fees.producer_total;
    },
  },
  watch: {
    orderAmount: function() {
      //this.totalPrice = this.orderAmount * this.price + this.surcharge
    },
  },
  mounted() {
    let foodId = this.$route.params.food_id;

    if (foodId) {
      this.$store.dispatch("findById", foodId);
      this.$store.dispatch("checkAccount");
      this.$store.dispatch("loadSettings");
    } else {
      console.warn("foodId is missing: ", foodId);
    }
  },

  methods: {
    onUpdateAddress(delivery) {
      console.log("delivery", delivery);
      this.delivery = delivery;
    },
    onAcceptSurcharge(payload) {
      console.log("Applying surchage:", payload);
      if (payload.acceptTerms) {
        //this.totalPrice = this.orderAmount * this.price + payload.surcharge
        this.surcharge = payload.surcharge;
      }
    },
    icon(status) {
      if (status) {
        switch (status) {
          case "archived":
            return { icon: "view_list", color: "info", action: "redo" };
          case "submitting":
            return { icon: "more_horiz", color: "indigo", action: "forward" };
          case "submitted":
            return { icon: "alarm_on", color: "info", action: "edit" };
          case "rejected":
          case "declined":
            return { icon: "warning", color: "error", action: "chat" };
          case "cancelled":
            return { icon: "cancel", color: "error", action: "chat" };
          case "no_pickup":
          case "no_delivery":
          case "error":
          case "failed":
            return { icon: "error", color: "red", action: "edit" };
          case "processing":
            return {
              icon: "fas fa-spinner fa-spin",
              color: "green",
              action: "forward",
            };
          case "payment_accepted":
          case "confirmed":
            return { icon: "check", color: "green", action: "edit" };
          case "accepting":
          case "ready":
          case "rejecting":
          case "cancelling":
            return {
              icon: "swap_horiz",
              color: "error",
              action: "edit",
            };
          case "accepted":
            return { icon: "done", color: "green", action: "edit" };
          case "new":
            return { icon: "card_giftcard", color: "primary", action: "edit" };
          case "delivered":
            return {
              icon: "folder",
              color: "primary",
              action: "forward",
            };
          case "delivering":
            return {
              icon: "folder",
              color: "secondary",
              action: "forward",
            };
          case "picking_up":
            return {
              icon: "fas fa-sync fa-car",
              color: "secondary",
              action: "forward",
            };
          default:
            console.error("un-handled status", status);
        }
      }
      return { icon: "view_list", color: "info", action: "redo" };
    },
    title(status) {
      if (status) {
        switch (status) {
          case "payment_accepted":
            return "Payment Accepted";
          case "picking_up":
            return "Picked Up";
          case "not_delivered":
            return "Not delivered";

          case "picked_up":
            return "Picked Up";
          case "no_delivery":
            return "No Delivery";
          case "no_pickup":
            return "No Pick Up";
        }
        return status.replace("_", " ");
      }
      return status;
    },
    saveOrderDateTime(payload) {
      this.deliveryDate = payload.deliveryDate;
      this.deliveryTime = payload.deliveryTime;
    },

    _statusIcon(status) {
      if (!status) {
        status = this.cookingStatus;
      }
      switch (status) {
        case "prepping":
          return "fas fa-cart-plus";
        case "cooking":
          return " fab fa-gripfire";
        case "sold out":
          return " far fa-heart";
        default:
          return "far fa-smile";
      }
    },
    _statusIconColor(status) {
      if (!status) {
        status = this.cookingStatus;
      }
      switch (status) {
        case "prepping":
          return "accent";
        case "cooking":
          return "red";
        case "sold out":
          return "teal accent-4";
        default:
          return "green";
      }
    },
    allowArchive(status) {
      return ["cancelled", "picking_up", "delivered"].includes(status);
    },
  },
};
