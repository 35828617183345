<template>
  <v-row
    class="fill-height"
    align="start"
    justify="space-between"
    v-if="isChef"
  >
    <v-col cols="9">
      <v-timeline align-top>
        <v-timeline-item
          color="green lighten-1 white--text"
          icon="mdi-buffer"
          fill-dot
          v-if="user && !user.emailVerified"
        >
          <v-card color="white--text green lighten-1">
            <v-card-title class="green lighten-1">
              <h3>Email Address</h3>
              <v-spacer></v-spacer>
              <v-icon dark v-if="user.emailVerified" size="32">
                checked
              </v-icon>
              <v-icon v-else size="32"> error_outline </v-icon>
            </v-card-title>

            <v-card-text class="white text--primary">
              <p>
                Your email ({{ user.email }}) is not verified yet! Send a new
                verification code to your email address.
              </p>
              <v-btn
                color="green lighten-1"
                class="mx-0"
                @click="sendVerificationEmail"
                outlined
              >
                Resend
              </v-btn>
            </v-card-text>
          </v-card>
        </v-timeline-item>
        <v-timeline-item
          color="indigo"
          icon="mdi-buffer"
          v-if="!hasAddress || !hasPermit"
          fill-dot
        >
          <v-card color="white--text indigo">
            <v-card-title>
              <h3>{{ $t('Chef.chef_onboard_primary_address') }}</h3>
              <v-spacer></v-spacer>
              <v-icon dark v-if="hasAddress" class="ml-3" size="32">
                done
              </v-icon>
              <v-icon v-else size="32"> error_outline </v-icon>
            </v-card-title>

            <v-card-text class="white text--primary">
              <p>{{ $t('Chef.chef_onboard_primary_address_sub') }}</p>
              <v-btn
                color="indigo lighten-1"
                to="my/profile?tab=tab-address"
                class="mx-0 mr-2"
                v-if="!hasAddress"
                outlined
              >
                address
              </v-btn>

              <v-btn
                color="indigo lighten-1"
                to="my/profile?tab=tab-license"
                class="mx-0"
                outlined
                v-if="!hasPermit"
              >
                Permits
              </v-btn>
            </v-card-text>
          </v-card>
        </v-timeline-item>

        <v-timeline-item
          color="blue lighten-1"
          icon="mdi-buffer"
          v-if="!hasProduct"
          fill-dot
        >
          <v-card color="blue lighten-1 white--text">
            <v-card-title class="blue lighten-1">
              <h3 class="white--text">
                {{ $t('Chef.chef_onboard_food_list') }}
              </h3>
            </v-card-title>
            <v-card-text class="white text--primary">
              <p>{{ $t('Chef.chef_onboard_food_list_sub') }}</p>
              <v-btn
                color="accent lighten-1"
                to="product/edit/new"
                class="mx-0 mr-3"
                v-if="!hasProduct"
                outlined
              >
                Product
              </v-btn>
              <v-btn
                color="blue lighten-1"
                to="product/menu"
                class="mx-0"
                v-if="!hasMenu && !cfo"
                outlined
              >
                Menus
              </v-btn>
            </v-card-text>
          </v-card>
        </v-timeline-item>
        <v-timeline-item
          :color="approvalColor"
          icon="mdi-buffer"
          fill-dot
          v-if="chefApprovalStatus != 'approved'"
        >
          <v-card :color="approvalColor" class="white--text">
            <v-card-title>
              <h3>{{ $t('Chef.chef_onboard_approval_status') }}</h3>
              <v-spacer></v-spacer>
              <v-icon
                dark
                v-if="chefApprovalStatus === 'approved'"
                class="ml-3"
                size="32"
              >
                done
              </v-icon>
              <v-icon dark v-else class="ml-3" size="32"> far fa-clock </v-icon>
            </v-card-title>
            <v-card-text class="white text--primary">
              <p v-if="chefApprovalStatus === 'approved'">
                Your account is approved
              </p>
              <p v-else>
                {{ $t('Chef.chef_onboard_approval_status_sub') }}
                <router-link to="product/list">dish list</router-link>
              </p>
            </v-card-text>
          </v-card>
        </v-timeline-item>
        <v-timeline-item
          color="cyan "
          icon="mdi-buffer"
          v-if="!account"
          fill-dot
        >
          <v-card color="cyan white--text">
            <v-card-title>
              <h3>{{ $t('Chef.chef_onboard_bank') }}</h3>

              <v-spacer></v-spacer>
              <v-icon dark class="ml-3" size="32"> account_balance </v-icon>
            </v-card-title>
            <v-card-text class="white text--primary">
              <p>{{ $t('Chef.chef_onboard_bank_sub') }}</p>
              <v-btn
                color="cyan lighten-1"
                to="accounts/bank"
                class="mx-0"
                outlined
              >
                Setup
              </v-btn>
            </v-card-text>
          </v-card>
        </v-timeline-item>

        <v-timeline-item color="green lighten-1" icon="mdi-buffer" fill-dot>
          <v-card color="green white--text">
            <v-card-title>
              <h3 class="white--text">{{ $t('Chef.chef_onboard_promote') }}</h3>

              <v-spacer></v-spacer>
              <v-icon dark class="ml-3" size="32">
                fas fa-hand-holding-usd
              </v-icon>
            </v-card-title>
            <v-card-text class="white text--primary">
              <p>{{ $t('Chef.chef_onboard_promote_sub') }}</p>
              <v-btn
                color="cyan lighten-1"
                to="coupons/list"
                class="mx-0"
                outlined
              >
                Setup
              </v-btn>
            </v-card-text>
          </v-card>
        </v-timeline-item>
      </v-timeline>
    </v-col>
    <v-col cols="2" sm="2" v-if="user">
      <section v-if="user.emailVerified">
        <v-icon color="green" class="mr-2">check</v-icon>
        <small>Your email is verified.</small>
      </section>
      <section v-if="user.emailVerified && !profile.avatar">
        <v-divider></v-divider>
        <v-icon color="red" class="mr-2">clear</v-icon>
        <small
          >Profile <router-link to="/my/avatar">picture </router-link> is
          missing.</small
        >
      </section>
      <section v-if="hasAddress">
        <v-divider></v-divider>
        <v-icon color="green" class="mr-2">check</v-icon>
        <small>Your address is set</small>
      </section>
      <section v-if="hasPermit">
        <v-divider></v-divider>
        <v-icon color="green" class="mr-2">check</v-icon>
        <small>Your permit is set</small>
      </section>

      <section v-if="hasProduct">
        <v-divider></v-divider>
        <v-icon color="green" class="mr-2">check</v-icon>
        <small>You have {{ dishList.length }} product</small>
      </section>
      <section v-if="!cfo && !hasMenu && hasProduct">
        <v-divider></v-divider>
        <v-icon color="red" class="mr-2">clear</v-icon>
        <small>Add <router-link to="/product/menu">menu</router-link></small>
      </section>

      <section v-if="chefApprovalStatus === 'approved'">
        <v-divider></v-divider>
        <v-icon color="green" class="mr-2">check</v-icon>
        <small>Your account is approved</small>
      </section>
      <section v-if="account">
        <v-divider></v-divider>
        <v-icon color="green" class="mr-2">check</v-icon>
        <small>Your bank account is linked</small>
      </section>
    </v-col>
  </v-row>
</template>
<script>
import { mapGetters } from 'vuex'
//import { profileMixin } from '@/mixins/profileMixin'
import { profileMixin } from 'fod-core'

export default {
  mixins: [profileMixin],
  data() {
    return {
      menus: {}
    }
  },
  computed: {
    ...mapGetters({
      location: 'userLocation',
      loading: 'loading',
      user: 'user',
      isChef: 'isChef',
      hasAddress: 'hasAddress',
      chefApprovalStatus: 'chefApprovalStatus',
      profile: 'profile',
      dishList: 'dishList',
      account: 'bankAccount'
    }),
    approvalColor() {
      return this.chefApprovalStatus === 'approved'
        ? 'green lighten-1'
        : 'red darken-1'
    },
    hasProductAndMenu() {
      return this.hasProduct && this.hasMenu
    },
    hasMenu() {
      return this.menus && Object.keys(this.menus).length > 0
    },
    hasProduct() {
      return this.dishList && this.dishList.length > 0
    },
    hasPermit() {
      return this.profile.permits && this.profile.permits.length > 0
    },
    cfo() {
      if (this.profile && this.profile.permits) {
        // If we want to show label for non - CFO chef's then we need to remove this filter
        let cfo = this.profile.permits.find(
          p => p.type && p.type.text === 'CFO'
        )
        return cfo
      }
      return null
    }
  },
  mounted() {}
}
</script>
