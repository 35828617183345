//import firebase from 'firebase'
//import fs from 'firebase/firestore'

import firebase from 'firebase'
import 'firebase/firestore'

console.log('Loading firebase for ', process.env.VUE_APP_DOMAIN)
var config = {
  apiKey: process.env.VUE_APP_API_KEY,
  authDomain: `chef.${process.env.VUE_APP_DOMAIN}`,
  databaseURL: `https://${process.env.VUE_APP_PROJECT_ID}.firebaseio.com`,
  projectId: process.env.VUE_APP_PROJECT_ID,
  storageBucket: `${process.env.VUE_APP_PROJECT_ID}.appspot.com`,
  messagingSenderId: process.env.VUE_APP_FCM_SENDER_ID,
  appId: process.env.VUE_APP_APP_ID,
  measurementId: process.env.VUE_APP_MEASUREMENT_ID
}

firebase.initializeApp(config)
const firestore = firebase.firestore()
firestore.settings({
  ignoreUndefinedProperties: true
})
const analytics = firebase.analytics()
//console.log(firestore)
//const settings = { timestampsInSnapshots: true }
//console.log(fs)
//firestore.settings(settings)

export default firebase
export { firestore, analytics }
