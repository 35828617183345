import firebase from "@/firebase-init";

export default {
  state: {},
  mutations: {},
  actions: {
    upload(context, payload) {
      console.log("Uploading photos for:", payload);
      context.commit("setLoading", true);
      let uid = context.getters.user.id;
      const filename = payload.file.name;
      const ext = filename.slice(filename.lastIndexOf("."));

      let path = `${payload.target}/${payload.id}/${payload.imageType}/${payload.id}${ext}`;
      console.log("Uploading to:", path);
      return firebase
        .storage()
        .ref(path)
        .put(payload.file.file)
        .then((fileData) => {
          return fileData.ref.getDownloadURL().then((fileUrl) => {
            let up = {};
            up[payload.imageType] = fileUrl;
            console.log("Target url is:", up);
            return firebase
              .firestore()
              .collection(payload.target)
              .doc(payload.id)
              .update(up);
          });
        })
        .then(() => {
          context.commit("setLoading", false);
        })
        .catch((error) => {
          console.log("Failed to Uploaded ", path);
          context.commit("setLoading", false);
          context.commit("setError", error);
          console.error("error: ", error);
        });
    },
  },
  getters: {},
};
