import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#1976D2',
        takein_logo: '#00B729',
        takein: '#00B729',
        secondary: '#E65100',
        accent: '#E040FB',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        main_text: '#464d69'
      }
    }
  }
})
