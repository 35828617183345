<template>
  <v-container grid-list-xs v-if="!loading && profile">
    <v-row>
      <v-col cols="12"> <h3>Live Broadcast</h3> </v-col>
    </v-row>
    <v-row class="fill-height" align="space-around" v-if="streamOn">
      <v-col cols="12" sm="8">
        <div id="session" @error="errorHandler">
          <publisher
            :session="session"
            :opts="viewOptions"
            @publisherCreated="publisherCreated"
            @publisherConnected="publisherConnected"
            @error="errorHandler"
          ></publisher>
        </div>
      </v-col>
      <v-col cols="1" offset="1">
        <v-badge>
          <span slot="badge">{{ viewerCount }}</span>
          <v-icon>fas fa-eye</v-icon>
        </v-badge>
      </v-col>
    </v-row>
    <v-row v-if="streamOn">
      <v-col cols="11" sm="6">
        <v-btn class="accent lighten-1 white--text" @click="stopStreaming"
          >Pause</v-btn
        >
      </v-col>
      <v-col cols="11" sm="6">
        <v-btn class="red lighten-1 white--text" @click="endStreaming"
          >End Live Stream</v-btn
        >
      </v-col>
    </v-row>
    <template v-if="!streamOn">
      <v-row>
        <v-col cols="12" sm="8">
          <v-radio-group v-model="privacy" row>
            Options:
            <v-radio label="Public" color="green" value="public"></v-radio>

            <v-radio label="Private" color="red" value="private"></v-radio>
          </v-radio-group>
          <v-switch
            label="Send push notification to your followers"
            v-model="notify"
          ></v-switch>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="11" sm="6">
          <v-textarea
            outlined
            label="Brief Description"
            v-model="description"
            name="description"
            rows="2"
          ></v-textarea>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="11" sm="6">
          <v-btn class="green lighten-1 white--text" @click="goLive"
            >GO LIVE</v-btn
          >
        </v-col>
      </v-row>
    </template>
    <v-row v-if="currentStreamingId">
      <v-col cols="12" sm="6">
        <chat
          :profile="profile"
          :chatParticipants="chatParticipants"
          :incomingMessage="incomingMessage"
          @sendMessage="sendMessage"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import fod_config from '@/config/config'
import OT from '@opentok/client'
import Publisher from './Publisher.vue'
import { mapGetters } from 'vuex'
import Chat from './Chat.vue'

const errorHandler = err => {
  console.error(err.message)
}

export default {
  name: 'session',
  data: () => ({
    roomId: null,
    connectionCount: 0,
    dishId: null,
    description: null,
    streams: [],
    streamOn: false,
    publisher: null,
    session: null,
    viewOptions: null,
    notify: null,
    privacy: null,
    newStream: true,
    incomingMessage: []
  }),
  components: { Publisher, Chat },
  computed: {
    ...mapGetters({
      user: 'user',
      dish: 'dish',
      profile: 'profile',
      streamSession: 'streamSession',
      streamingStatus: 'streamingStatus',
      currentStreamingId: 'currentStreamingId',
      chatParticipants: 'chatParticipants',
      loading: 'loading'
    }),
    viewerCount() {
      return this.connectionCount > 0 ? this.connectionCount : 0
    }
  },
  created() {
    this.roomId = this.$route.query.room || this.user.id
    let dish_id = this.$route.query.dish

    if (dish_id) {
      this.dishId = dish_id
    }
    this.viewOptions = {
      width: '100%',
      height: 450
    }
    this.apiKey = fod_config.STREAM_API_KEY
    this.$store.dispatch('getStreamSession', this.roomId).then(sessionData => {
      console.log('Starting a session from ', sessionData)
      if (!sessionData) {
        this.$store.dispatch('setError', {
          message: 'Unable to start Live Broadcast!'
        })
        return
      }
      this.session = OT.initSession(this.apiKey, this.streamSession.sessionId)
      this.session.connect(this.streamSession.token, err => {
        if (err) {
          errorHandler(err)
        } else {
          console.log('Stream Connected Token')
        }
      })
      this.session.on('streamCreated', event => {
        this.streams.push(event.stream)
      })
      this.session.on('connectionCreated', event => {
        this.connectionCount++
        if (
          event.connection.connectionId != this.session.connection.connectionId
        ) {
          console.log(
            'Another client connected. ' + this.connectionCount + ' total.'
          )
        }
      })
      this.session.on('connectionDestroyed', () => {
        if (this.connectionCount > 0) {
          this.connectionCount--
        }
        console.log(
          'A client disconnected. ' + this.connectionCount + ' total.'
        )
      })
      this.session.on('streamDestroyed', event => {
        const idx = this.streams.indexOf(event.stream)
        if (idx > -1) {
          this.streams.splice(idx, 1)
        }
      })
      var msgHistory = document.querySelector('#history')
      var session = this.session
      this.incomingMessage = []
      var incomingMessage = this.incomingMessage
      var store = this.$store
      this.session.on('signal:msg', function(event) {
        var payload = event.data
        if (!payload) {
          return
        }
        var caller =
          event.from.connectionId === session.connection.connectionId
            ? 'mine'
            : 'theirs'

        if (event.from.connectionId != session.connection.connectionId) {
          let data = {
            author: payload.name,
            type: 'text',
            data: { text: event.data.message.text }
          }
          console.log('message arrived:', payload)

          incomingMessage.length = 0
          incomingMessage.push(data)
          store.dispatch('updateChatParticipants', payload)
        }
      })
    })
  },
  beforeDestroy() {
    if (this.session) {
      this.session.unpublish(this.publisher)
      this.$store.dispatch('stopStreaming', { id: this.currentStreamingId })
    }
    if (this.publisher) {
      this.publisher.destroy()
    }
  },

  methods: {
    errorHandler,
    goLive() {
      if (this.streamSession) {
        //this.session = OT.initSession(this.apiKey, this.streamSession.sessionId);
        if (this.newStream) {
          this.$store.dispatch('startStreaming', {
            notify: this.notify,
            privacy: this.privacy,
            description: this.description,
            dishId: this.dishId
          })
          this.newStream = false
        }
        this.streamOn = true
      } else {
        console.warn('StreamSession is not available')
      }
    },
    stopStreaming() {
      console.log('stopping stream')
      this.session.unpublish(this.publisher)
      this.publisher.destroy()
      this.streamOn = false
      this.$store.dispatch('setMessage', {
        color: 'info',
        title: 'Streaming stopped',
        body: 'Stream temporarily stopped, you can start and GO LIVE.'
      })
    },
    endStreaming() {
      console.log('end live stream')
      this.$store.dispatch('setMessage', {
        color: 'accent',
        title: 'Thank you. The streaming is ended',
        body: 'You needed the streaming, all the viewers will be disconnected .'
      })
      this.session.unpublish(this.publisher)
      this.publisher.destroy()
      this.$store.dispatch('stopStreaming', { id: this.currentStreamingId })
      this.newStream = true
      this.streamOn = false
    },
    publisherCreated(payload) {
      this.publisher = payload
    },

    publisherConnected(payload) {
      this.publisher = payload
    },
    sendMessage(message) {
      let data = {
        profileType: 'chef',
        message: message,
        userId: this.user.id,
        name: this.profile.name,
        imageUrl: this.profile.avatar
      }
      this.$store.dispatch('sendMessage', { session: this.session, data: data })
    }
  }
}
</script>
