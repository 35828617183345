<template>
  <v-container v-if="profile">
    <v-row class="mb-2">
      <v-col class="text-right">
        <v-btn depressed :ripple="false" to="/profile/wizard/finance">{{
          $t('Common.back')
        }}</v-btn>
      </v-col>
    </v-row>

    <v-list two-line subheader>
      <v-list-item v-if="isChef" to="/profile/wizard/finance/bankaccount">
        <v-list-item-action>
          <v-icon color="primary" class="mb-4">account_balance</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title class="account_title">
            <v-btn color="primary" id="bank_buttons">{{
              $t('Common.manage_bank_account')
            }}</v-btn>
          </v-list-item-title>
          <v-list-item-subtitle>{{
            $t('Common.manage_bank_account_descrption')
          }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters({
      isChef: 'isChef',
      profile: 'profile'
    })
  }
}
</script>
<style scoped>
.account_title {
  height: 50px;
}
#bank_buttons {
  color: white;
  width: 260px;
  padding-left: 5px;
}
</style>
