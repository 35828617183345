/**
 * Base Profile
 */
import firebase from '@/firebase-init'

export default {
  state: {
    promotion :null
  },
  mutations: {

  },
  actions: {
    _getCoupon: async (context, payload) => {

      context.commit('setLoading', true)
      console.log('Loading coupon, ', payload)
      return await firebase
        .firestore()
        .collection(`campaign/${payload.producer_id}/coupons`)
        .doc(payload.coupon_id)
        .get()
        .then((querySnapshot) => {

          if (querySnapshot.exists){
            let coupon = querySnapshot.data()
            console.log('Retrieved coupon:',coupon )
            context.commit('setLoading', false)

            return coupon
          }
          context.commit('setLoading', false)
          return {status:'error'}
        })
        .catch(error => {
          //context.commit('setError', error)
          context.commit('setLoading', false)
          console.error(error)
          return {status:'error'}
        })
    },
    _getPromotion: async (context, payload) => {
      context.commit('setLoading', true)
      console.log('Loading promotion, ', payload)
      return await firebase
        .firestore()
        .collection(`campaign/${payload.producer_id}/promotions`)
        .doc(payload.promotion_id)
        .get()
        .then((querySnapshot) => {

          if (querySnapshot.exists){
            let promo = querySnapshot.data()
            context.commit('setLoading', false)
            context.commit('setPromotion', promo)
            console.log('Retrieved promotion1: ', promo)
            return promo
          }
          context.commit('setLoading', false)
          return {status:'error'}
        })
        .catch(error => {
          //context.commit('setError', error)
          context.commit('setLoading', false)

          console.error(error)
          return {status:'error'}
        })
    }
  },
  getters: {


  }
}
