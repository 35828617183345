<template>
  <v-container v-if="uid && userProfile">
    <v-row class="px-2">
      {{ $t('Common.import_page_title') }}
    </v-row>
    <v-row>
      <import-products :uid="uid" :userProfile="userProfile" />
    </v-row>
    <v-row>
      <import-modifiers :uid="uid" :userProfile="userProfile" />
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters({
      user: 'user',
      myProfile: 'profile',
      loading: 'loading',
      isAdmin: 'isAdmin',
      userProfile: 'userProfile'
    })
  },
  components: {
    ImportProducts: () => import('@/components/cook/ImportProducts'),
    ImportModifiers: () => import('@/components/cook/ImportModifiers')
  },
  data() {
    return {
      uid: null
    }
  },
  async mounted() {
    this.uid =
      this.isAdmin && this.$route.params.uid
        ? this.$route.params.uid
        : this.user
        ? this.user.id
        : null
    await this.$store.dispatch('loadUserProfile', { uid: this.uid })
  }
}
</script>
<style></style>
