<template>
  <v-card>
    <v-card-title primary-title>
      <h4>{{ $t('Chef.how_it_works') }}</h4>
    </v-card-title>
    <v-container fluid>
      <v-row>
        <v-col cols="12" sm="6">
          <v-card>
            <v-card-title primary-title class="how_to_title">
              <v-icon color="purple accent-2" x-large
                >mdi-numeric-1-box-outline</v-icon
              >

              {{ $t('Chef.chef_howitworks_1_title') }}
            </v-card-title>
            <v-card-text> {{ $t('Chef.chef_howitworks_1_sub') }} </v-card-text>
            <v-container>
              <v-img
                src="https://storage.googleapis.com/download.takein.com/apps/images/woman-1979272_1920_sm.jpg"
              />
            </v-container>
          </v-card>
        </v-col>

        <v-col cols="12" sm="6">
          <div class="hidden-sm-and-down  how_to_set"></div>
          <v-card>
            <v-icon color="blue accent-2" x-large
              >mdi-numeric-2-box-outline</v-icon
            >
            <v-card-title primary-title class="how_to_title">
              {{ $t('Chef.chef_howitworks_2_title') }}
            </v-card-title>
            <v-card-text> {{ $t('Chef.chef_howitworks_2_sub') }} </v-card-text>
            <v-container>
              <v-img
                src="https://storage.googleapis.com/img.fod.live/static/app/chef/pexels-photo-xs.jpeg"
              />
            </v-container>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6">
          <v-card>
            <v-icon color="green" x-large>mdi-numeric-3-box-outline</v-icon>
            <v-card-title primary-title class="how_to_title">
              {{ $t('Chef.chef_howitworks_3_title') }}
            </v-card-title>
            <v-card-text> {{ $t('Chef.chef_howitworks_3_sub') }} </v-card-text>
            <v-container>
              <v-img
                src="https://storage.googleapis.com/img.fod.live/static/app/chef/fountain-pen-1053697_1920_xs.jpg"
              />
            </v-container>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6">
          <div class="hidden-sm-and-down  how_to_set"></div>
          <v-card>
            <v-icon color="red" x-large>mdi-numeric-4-box-outline</v-icon>
            <v-card-title primary-title class="how_to_title">
              {{ $t('Chef.chef_howitworks_4_title') }}
            </v-card-title>
            <v-card-text> {{ $t('Chef.chef_howitworks_4_sub') }} </v-card-text>
            <v-container>
              <v-img
                src="https://storage.googleapis.com/img.fod.live/static/app/chef/dollar-15328_1920_xs.jpg"
              />
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
export default {}
</script>

<style scoped>
.how_to_title {
  font-size: 1.2em;
}
.how_to_set {
  margin-top: 6em;
}
.badge_size {
  margin: 0 0.6em;
}
</style>
