<template>
  <v-container class="offset-sm3">
    <v-list two-line subheader>
      <v-subheader>Account Setting</v-subheader>
      <v-list-item avatar class="mt-5" :to="'/account/transactions'">
        <v-list-item-action>
          <v-icon color="success" class="mb-4">credit_card</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title class="account_title">
            <v-btn color="success" id="bank_buttons"
              >Account Transactions</v-btn
            >
          </v-list-item-title>

          <v-list-item-subtitle
            >Add / update your Credit Card information</v-list-item-subtitle
          >
        </v-list-item-content>
      </v-list-item>
      <v-list-item avatar class="mt-5" :to="'/account/refunds'">
        <v-list-item-action>
          <v-icon color="accent" class="mb-4">mdi-cash-100</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title class="account_title">
            <v-btn color="accent" id="bank_buttons">Refunds</v-btn>
          </v-list-item-title>

          <v-list-item-subtitle>Issued refunds</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item avatar class="mt-5" :to="'/account/extra-charges'">
        <v-list-item-action>
          <v-icon color="cyan" class="mb-4">mdi-cash-100</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title class="account_title">
            <v-btn color="cyan" id="bank_buttons">Extra Charges</v-btn>
          </v-list-item-title>

          <v-list-item-subtitle>Applied Extra Charges </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        v-if="isChef"
        :to="id ? '/accounts/bank/' + id : '/account/bank'"
      >
        <v-list-item-action>
          <v-icon color="primary" class="mb-4">account_balance</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title class="account_title">
            <v-btn color="primary" id="bank_buttons"
              >Manage Bank Accounts</v-btn
            >
          </v-list-item-title>
          <v-list-item-subtitle
            >You will get paid by TakeIn Platform</v-list-item-subtitle
          >
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-row>
      <v-col cols="12" sm="6"></v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6"></v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: ['id'],
  computed: {
    ...mapGetters({
      isChef: 'isChef'
    })
  }
}
</script>
<style scoped>
.account_title {
  height: 50px;
}
#bank_buttons {
  color: white;
  width: 260px;
  padding-left: 5px;
}
</style>
