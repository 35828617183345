<template>
  <div>
    <v-card>
      <v-card-text>
        <v-row align="center">
          Send a message
          <v-btn text small color="info" link @click="sayThankYou"
            >Say Thank You</v-btn
          >
          <v-checkbox
            label="Coupon"
            v-model="sendPromo"
            hide-details
            @change="showCouponDialog"
            hint="Generate one time protion code"
            light
            dense
            class="shrink mr-2 mt-0"
          ></v-checkbox>
        </v-row>
      </v-card-text>

      <v-textarea
        v-model="message"
        placeholder="Type a message"
        flat
        rows="5"
        background-color="transparent"
        hide-details
        solo
      ></v-textarea>
      <v-card-actions>
        <v-btn small :disabled="!message" @click.once="send">Send</v-btn>
      </v-card-actions>
    </v-card>
    <v-dialog v-model="couponDialog" persistent max-width="600px">
      <single-coupon
        @onUseCoupon="onUseCoupon"
        @onCancel="couponDialog = false"
        :title="couponTitle"
      />
    </v-dialog>
  </div>
</template>

<script>
import SingleCoupon from '../coupons/SingleCoupon'
import { mapGetters } from 'vuex'

export default {
  components: {
    SingleCoupon
  },
  props: {
    order: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      message: null,
      sendPromo: false,
      couponDialog: false,
      coupon: {}
    }
  },
  computed: {
    ...mapGetters({
      user: 'user'
    }),
    couponTitle() {
      return `order (${this.order.id.substr(0, 4)}) for (${
        this.order.buyer.name
      })`
    },
    couponAmount() {
      return this.coupon && this.coupon.amount_type === '%'
        ? `${this.coupon.amount}%`
        : `$${this.coupon.amount}`
    }
  },
  methods: {
    sayThankYou() {
      this.message = `Dear ${this.order.buyer.name}, We hope you enjoyed your meal. We enjoyed serving you and hope to see you again.`
      this.message += this.coupon.code
        ? ` To say thank you we offer you a coupon ${this.coupon.code} for a ${this.couponAmount} discount on your next meal on TakeIn. See you soon!`
        : ''
      console.log('Thank you: ', this.message)
    },
    showCouponDialog() {
      //TODO:
      this.couponDialog = true
      if (!this.coupon.code) {
        this.couponDialog = true
      }
    },
    onUseCoupon(payload) {
      this.couponDialog = false
      this.coupon = payload

      this.sayThankYou()
    },
    async send() {
      if (this.message && this.message.length > 0) {
        if (this.coupon.code) {
          console.log('Saving coupn ', this.coupon)
          await this.$store.dispatch('savePromotion', this.coupon)
        }
      }

      const thread_id = await this.$store
        .dispatch('getThread', { recipient: this.order.buyerUserId })
        .then(thread_id => {
          console.log('thread_id', thread_id)
          return thread_id
        })
      await this.$store.dispatch('sendMessage', {
        message: { body: this.message },
        body: this.message,
        thread_id: thread_id
      })
      this.message = null
      this.$store.dispatch('setMessage', {
        color: 'info',
        title: 'Sent',
        body: 'Your Thank You Message Successfully sent.'
      })
    }
  }
}
</script>
