<template>
  <v-container :fluid="$vuetify.breakpoint.smAndDown" v-if="!loading">
    <v-row align="center" class="mt-5" v-if="isNew">
      <v-col cols="12" sm="6"
        ><div class="title">Send a new Message</div>
      </v-col>
      <v-col cols="12" sm="6" v-if="!selectedProfile">
        <UserLookup @selectedUser="selectedUser" />
      </v-col>
      <v-col cols="12" sm="6" v-else>
        <v-card>
          <v-card-text
            >Sending message to {{ selectedProfile.name }}</v-card-text
          >
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" v-if="!messageLoading">
        <template v-for="(message, index) in messages">
          <v-container :key="index">
            <v-row v-if="member_info">
              <v-col
                :offset-sm="fromYou(message, member_info) ? 3 : 0"
                cols="12"
                sm="2"
              >
                <v-avatar size="50" v-if="!fromYou(message, member_info)">
                  <v-img :src="getAvatar(member_info, message.from)" />
                </v-avatar>

                <div v-if="fromYou(message, member_info)">
                  <h4>You</h4>
                </div>
                <div v-else>
                  {{
                    member_info[message.from]
                      ? member_info[message.from].name
                      : member_info[message.uid]
                      ? member_info[message.uid].name
                      : ""
                  }}
                </div>

                <br />
                <small>{{ message.timestamp | moment("from") }}</small>
              </v-col>

              <v-col cols="12" sm="6">
                <pre>{{ message.body }}</pre>
              </v-col>
            </v-row>
            <v-divider :key="index + 'a'"></v-divider>
          </v-container>
        </template>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="8">
        <v-textarea
          outlined
          label="Message"
          v-model="message"
          counter
          required
          name="message"
          rows="4"
        ></v-textarea>
      </v-col>
    </v-row>

    <v-row class="mb-6">
      <v-col cols="12" sm="4">
        <v-btn color="success" :disabled="!message" @click="send">Send</v-btn>
        <v-btn icon @click="$router.go(-1)" class="ml-2">
          <v-icon>arrow_back</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import UserLookup from "../profile/UserLookup";
import { mapGetters } from "vuex";

export default {
  components: {
    UserLookup,
  },
  data() {
    return {
      uid: null,
      localLoading: false,
      thread_id: null,
      message: null,
      userNotFound: false,
      unsubscribe: null,
      isNew: false,
      selectedProfile: null,
    };
  },
  computed: {
    ...mapGetters({
      loading: "loading",
      profile: "profile",
      users: "users",
      messageLoading: "messageLoading",
      messages: "messages",
      member_info: "member_info",
    }),
  },
  mounted() {
    this.thread_id = this.$route.params.id;
    this.uid = this.$route.query.uid;
    this.isNew = this.thread_id === "new";
    this.loadByUid();

    if (this.thread_id != "new") {
      this.$store.dispatch("getMemberInfo", { thread_id: this.thread_id });

      this.$store
        .dispatch("loadMessages", { thread_id: this.thread_id })
        .then(() => {
          this.$store
            .dispatch("markAsRead", { thread_id: this.thread_id })
            .then((s) => {
              this.unsubscribe = s;
            });
        });
    } else {
      this.$store.commit("setMessages", []);
    }
  },
  beforeDestroy() {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  },
  methods: {
    loadByUid() {
      if (this.uid) {
        this.$store
          .dispatch("loadUserProfile", {
            uid: this.uid,
          })
          .then((profile) => {
            console.log("Profile loaded", profile);

            if (profile) {
              //this.$emit("selectedUser", profile);
              //this.entries = [profile];
              this.selectedUser(profile);
            }
          });
      }
    },
    send() {
      this.$store
        .dispatch("sendMessage", {
          body: this.message,
          thread_id: this.thread_id,
        })
        .then(() => {
          // reset text box
          this.message = null;
          // get user info if missing
          if (!this.member_info || Object.keys(this.member_info).length === 0) {
            this.$store.dispatch("getMemberInfo", {
              thread_id: this.thread_id,
            });
          }
          // reload the messages when sent
          this.$store.dispatch("loadMessages", { thread_id: this.thread_id });
        });
    },
    fromYou(message, member_info) {
      return member_info[message.from]
        ? member_info[message.from].email === this.profile.email
        : member_info[message.uid]
        ? member_info[message.uid].email === this.profile.email
        : false;
    },
    getAvatar(member_info, id) {
      return member_info && member_info[id] && member_info[id].avatar
        ? member_info[id].avatar
        : "/svg/default.svg";
    },

    getName(id) {
      return this.member_info &&
        this.member_info.length > 0 &&
        this.member_info[id]
        ? this.member_info[id].name
        : null;
    },
    selectedUser(user) {
      this.selectedProfile = user;
      this.$store
        .dispatch("getThread", { recipient: user.id })
        .then((thread_id) => {
          this.thread_id = thread_id;
          this.$store.dispatch("getMemberInfo", {
            thread_id: this.thread_id,
          });
        });

      this.$store.dispatch("setMessage", {
        color: "info",
        title: "Selected",
        body: `Message will send to ${user.name}`,
      });
    },
  },
};
</script>
<style scoped>
pre {
  white-space: pre-wrap;
  line-height: 30px;
  position: relative;
  top: -15px;
}
</style>
