<template>
  <v-container>
    <template v-if="!loading">
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                  lg="3"
                  v-for="image in album"
                  :key="image.id"
                >
                  <v-card>
                    <template v-if="isVideo(image.imageUrl)">
                      <video
                        playsinline
                        autoplay
                        muted
                        controls
                        ref="video"
                        class="video-card"
                      >
                        <source
                          :src="image.url || image.imageUrl"
                          type="video/mp4"
                        />
                      </video>
                    </template>
                    <template v-else>
                      <v-img
                        :src="image.url || image.imageUrl"
                        class="image-card"
                      >
                        <v-container
                          class="img fill-height text-center image-hover"
                        >
                          <v-row align="center">
                            <v-col>
                              <strong>{{ image.description }}</strong>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-img>
                    </template>
                    <v-row>
                      <v-col cols="12" class="d-flex justify-center">
                        <template
                          v-show="!isVideo(image.url || image.imageUrl)"
                        >
                          <v-edit-dialog
                            @open="tmp = image.description"
                            @save="saveDescription(tmp, image.id)"
                            large
                          >
                            <v-btn text>
                              <v-icon>mdi-textbox</v-icon>
                            </v-btn>
                            <div slot="input" class="mt-3">
                              Add/Update Description
                            </div>
                            <v-text-field
                              slot="input"
                              label="Edit"
                              v-model="tmp"
                              single-line
                              autofocus
                            ></v-text-field>
                          </v-edit-dialog>
                        </template>
                        <v-btn text @click="removePhoto(image.id)">
                          <v-icon>delete</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                    <v-spacer></v-spacer>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>

            <v-container>
              <v-row>
                <v-col cols="12" md="9" lg="7" class="mx-auto text-center pa-0">
                  <h4 class="headline">{{ $t('Common.add_photo_title') }}</h4>
                  <h5>{{ $t('Common.add_photo_sub') }}</h5>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" sm="6">
                  <media-uploader
                    @uploadMedia="uploadMedia"
                    uploadType="media"
                    :hasDescription="true"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
      </v-row>

      <v-row justify="space-around">
        <v-col cols="12" class="d-flex justify-center">
          <v-btn fab dark color="primary" class="my-6" @click="onRefreshAlbum">
            <v-icon dark>cached</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>
<script>
export default {
  data: () => ({
    user: null,
    hover: false,
    tmp: null
  }),
  components: {
    MediaUploader: () =>
      import('fod-core/src/components/common/MediaUploader.vue')
  },
  computed: {
    album() {
      return this.$store.getters.album
    },
    loading() {
      return this.$store.getters.loading
    }
  },
  created() {
    this.user = this.$store.getters.user
    this.$store.dispatch('loadAlbum', this.user.id)
  },
  methods: {
    isVideo(url) {
      return url.toLowerCase().includes('mp4')
    },
    onRefreshAlbum() {
      this.$store.dispatch('loadAlbum', this.user.id)
    },
    // uploadMedia(file, description) {
    //   this.$store.dispatch('albumFileUpload', {
    //     description: description,
    //     image: file
    //   })
    //   this.$store.dispatch('loadAlbum', this.user.id)
    // },

    uploadMedia(file, img, description) {
      this.$store.dispatch('albumFileUpload', {
        userid: this.user.id,
        description: description,
        file: file,
        image: img
      })
      this.$store.dispatch('loadAlbum', this.user.id)
    },
    saveDescription(new_description, image_id) {
      const new_album = this.album.map(d =>
        d.id === image_id ? { ...d, description: new_description } : d
      )
      this.$store.dispatch('albumDescriptionUpdate', {
        uid: this.user.id,
        album: new_album
      })
      this.$store.dispatch('loadAlbum', this.user.id)
    },
    removePhoto(id) {
      this.$store.dispatch('removePhoto', id)
      this.$store.dispatch('loadAlbum', this.user.id)
    }
  }
}
</script>
<style>
img {
  image-orientation: from-image;
}
.image-hover {
  background: white;
  opacity: 0;
}
.image-hover:hover {
  background: white;
  opacity: 0.8;
  color: black;
  cursor: pointer;
}
.image-hover:active {
  background: white;
  opacity: 0;
}
.icon-color {
  color: green;
}
.video-card {
  margin-top: 8px;
  width: 100%;
  height: 100%;
}
.image-card {
  margin-top: 8px;
  width: 100%;
  height: 100%;
}
</style>
