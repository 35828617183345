import firebase from '@/firebase-init'

export default {
  state: {
    company: {}
  },
  mutations: {
    setCompany(state, payload) {
      state.company = payload
    }
  },
  actions: {
    saveCompany(context, payload) {
      console.log('Saving company:', payload)
      context.commit('setLoading', true)

      let uid = context.getters.user.id
      payload.uid = uid
      //let user = firebase.auth().currentUser
      if (payload.id) {
        firebase
          .firestore()
          .collection('company')
          .doc(payload.id)
          .update(payload)
          .then(() => {
            context.commit('setLoading', false)
          })
          .catch(error => {
            context.commit('setLoading', false)
            context.commit('setError', error)
            console.log(error)
          })
        console.log('Updating company', uid)
      } else {
        // in future we can use .add
        // One user can create many co
        payload.status = 'new'
        console.log('Save new company', uid)
        firebase
          .firestore()
          .collection('company')
          .doc('request')
          .collection('new')
          .doc(uid)
          .set(payload)
          .then(d => {
            context.commit('setLoading', false)
          })
          .catch(error => {
            context.commit('setLoading', false)
            context.commit('setError', error)
            console.log(error)
          })
        console.log('Updating company', uid)
      }
    },
    getCompany(context, payload) {
      context.commit('setLoading', true)

      let uid = context.getters.user.id
      console.log('get company ', uid)
      firebase
        .firestore()
        .collection('company')
        .where('uid', '==', uid)
        .onSnapshot(snapshot => {
          snapshot.forEach(doc => {
            if (doc.exists) {
              let c = doc.data()
              c.id = doc.id
              context.commit('setCompany', c)
              context.commit('setLoading', false)
            }
          })
          context.commit('setLoading', false)
        })
    }
    /*  companyInviteMember(context, payload) {
      let uid = context.getters.user.id
      payload.uid = uid
      console.log('inviting company ', payload)
      firebase
        .firestore()
        .collection('company')
        .doc(payload.cid)
        .get()
        .then(snapshot => {
          if (snapshot.empty) {
            console.log('No matching documents: ', payload)
            return true
          }
          snapshot.ref.update({
            invites: firebase.firestore.FieldValue.arrayUnion(payload)
          })
          firebase
            .firestore()
            .collection('company')
            .doc(payload.cid)
            .collection('invites')
            .doc(payload.email)
            .set(payload)
          return false
        })
    }*/
  },
  getters: {
    company(state) {
      return state.company
    }
  }
}
