<template>
  <v-container>
    <div v-if="!loading && profile">
      <ValidationObserver ref="form" v-slot="{ invalid }">
        <form @submit.prevent="onUpdateProfile">
          <v-row align="center">
            <v-col cols="12" sm="4">
              <ValidationProvider
                v-slot="{ errors }"
                name="Name"
                rules="required"
                immediate
              >
                <v-text-field
                  name="name"
                  ref="name"
                  id="name"
                  :error-messages="errors"
                  :label="$t('Common.profile_name')"
                  :placeholder="$t('Chef.chef_name_placeholder')"
                  class="pr-1"
                  :hint="$t('Chef.chef_name_hint')"
                  v-model="name"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col cols="12" sm="3" v-if="!wizard">
              <ValidationProvider
                v-slot="{ errors }"
                name="Handle"
                rules="required|min:3|max:50"
              >
                <v-text-field
                  :label="$t('Common.handle')"
                  prepend-inner-icon="alternate_email"
                  append-outer-icon="fas fa-user-check"
                  @click:prepend-inner="fillHandle"
                  @click:append-outer="checkHandleExists"
                  :error="handleExists"
                  :error-messages="errors.length ? errors : handleMessages"
                  :hint="$t('Chef.chef_handle_hint')"
                  @change="checkHandleExists"
                  :placeholder="tagLabel"
                  v-model.trim.lazy="handle"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col cols="12" sm="5" v-if="profile.handle">
              {{ shareLink }}
              <a :href="shareLink" target="_blank">view public profile</a>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="8" v-if="isChef">
              <ValidationProvider
                v-slot="{ errors }"
                name="Tagline"
                rules="required|min:5|max:47"
                immediate
              >
                <v-text-field
                  name="title"
                  counter="47"
                  maxlength="47"
                  :placeholder="$t('Chef.chef_title_placeholder')"
                  :hint="$t('Chef.chef_title_hint')"
                  :label="$t('Chef.chef_title')"
                  :error-messages="errors"
                  id="title"
                  v-model="title"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="8">
              <div v-if="isEditable">
                <v-textarea
                  name="description"
                  rows="8"
                  counter="1250"
                  maxlength="1250"
                  :placeholder="$t('Chef.chef_description_hint')"
                  :label="$t('Chef.profile_description')"
                  id="description"
                  v-model="description"
                  @blur="blurProfileDescription"
                ></v-textarea>
              </div>
              <div v-else>
                <p
                  class="mb-1 fs-12"
                  v-html="$t('Chef.profile_description')"
                ></p>
                <div
                  class="fs-14 border overflow-y-auto word-breaking"
                  v-html="compiledMarkdown"
                  @click="isEditable = true"
                  style="height: 160px"
                ></div>
              </div>
            </v-col>
          </v-row>

          <v-row align="center">
            <v-col cols="12" sm="6" md="5">
              <v-snackbar v-model="showPrivacyHint" color="info">{{
                $t('Chef.chef_privacy_hint1')
              }}</v-snackbar>
              <v-text-field
                name="phone"
                label="Primary Phone"
                id="phone"
                prepend-icon="phone"
                v-model="phone"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field
                name="email"
                :label="emailLabel"
                id="email"
                :rules="[rules.email]"
                prepend-icon="email"
                v-model="email"
                :readonly="user.emailVerified"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3" v-if="!isAdmin">
              <v-icon class="green--text" v-if="user.emailVerified"
                >check</v-icon
              >
              <template v-if="!showRefresh">
                <span v-if="!user.emailVerified">
                  <v-btn
                    text
                    v-if="!wizard"
                    small
                    @click="sendVerificationEmail"
                    class="pl-5 ml-2"
                    color="info"
                  >
                    Send verification
                    <v-icon right>fas fa-envelope</v-icon>
                  </v-btn>
                </span>
              </template>
              <template v-else>
                Please refresh after verified your email
                <v-btn text icon @click.prevent="refresh">
                  <v-icon large color="accent">refresh</v-icon>
                </v-btn>
              </template>
            </v-col>
            <v-col cols="12" sm="3" v-else>
              Force to verify
              <v-btn text icon @click.prevent="verifyEmailByAdmin">
                <v-icon large color="accent">check</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="5">
              <v-text-field
                label="Business Name"
                prepend-icon="account_balance"
                v-model="businessName"
              ></v-text-field>
            </v-col>
            <v-col cols="10" sm="5">
              <v-text-field
                name="website"
                label="Web site"
                id="website"
                prepend-icon="public"
                v-model="website"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row v-if="!wizard" align="center">
            <v-row v-if="false" class="cols">
              <v-switch
                v-model="hasBusiness"
                :label="$t('Chef.do_you_have_business')"
              ></v-switch>
            </v-row>
            <v-col cols="2">
              <router-link v-if="false" to="/company/profile"
                >Business Setup</router-link
              >
            </v-col>
          </v-row>

          <template v-if="isChef">
            <v-row v-if="isChef">
              <v-col cols="10" sm="4">
                <v-text-field
                  label="Business Phone"
                  prepend-icon="phone"
                  @blur="setBusinessPhone"
                  v-model="businessPhone"
                  :hint="$t('Chef.chef_privacy_hint1')"
                ></v-text-field>
              </v-col>
              <v-col cols="2" sm="1" v-if="businessPhone">
                <v-text-field
                  label="Extension "
                  v-model="orderPhoneExtension"
                  hint="Answering machine extion of orders."
                ></v-text-field>
              </v-col>
              <v-col cols="10" sm="5">
                <v-text-field
                  label="Business Email"
                  prepend-icon="email"
                  v-model="businessEmail"
                ></v-text-field>
              </v-col>
            </v-row>
          </template>
          <v-row>
            <v-col cols="12" sm="6" md="5" v-if="isChef">
              <v-combobox
                prepend-icon="mdi-shape"
                multiple
                placeholder="List of items you sell"
                :label="$t('Common.food_category')"
                :items="categoryList"
                v-model="categories"
                item-text="name"
                item-value="name"
              ></v-combobox>
            </v-col>
            <v-col cols="12" sm="6" md="5" v-if="isChef">
              <food-origin :cuisineList="cuisineList" />
            </v-col>
          </v-row>
          <v-row v-if="!wizard">
            <v-col cols="12" sm="8" class="px-1">
              <v-expansion-panels>
                <v-expansion-panel expand v-model="social_panel">
                  <v-expansion-panel-header
                    >Social Settings</v-expansion-panel-header
                  >
                  <v-expansion-panel-content>
                    <v-card class="pa-3">
                      <v-card-text class="d-flex align-center">
                        <v-btn color="success" text @click="viewFollowers"
                          >View</v-btn
                        >
                        <h5 class="ma-0">your followers</h5>
                      </v-card-text>
                    </v-card>
                    <v-card class="px-2">
                      <v-text-field
                        name="facebook"
                        label="Facebook"
                        prefix="https://www.facebook.com/"
                        id="facebook"
                        prepend-icon="fab fa-facebook"
                        v-model="facebook"
                      ></v-text-field>
                    </v-card>
                    <v-card class="px-2">
                      <v-text-field
                        name="twitter"
                        label="Twitter"
                        prefix="https://twitter.com/"
                        id="twitter"
                        prepend-icon="fab fa-twitter"
                        v-model="twitter"
                      ></v-text-field>
                    </v-card>
                    <v-card class="px-2">
                      <v-text-field
                        name="instagram"
                        label="Instagram"
                        id="instagram"
                        prefix="https://www.instagram.com/"
                        prepend-icon="fab fa-instagram"
                        v-model="instagram"
                      ></v-text-field>
                    </v-card>
                    <v-card class="px-2">
                      <v-text-field
                        name="linkedin"
                        label="LinkedIn"
                        id="linkedin"
                        prefix="https://www.linkedin.com/in/"
                        prepend-icon="fab fa-linkedin"
                        v-model="linkedin"
                      ></v-text-field>
                    </v-card>
                    <v-card class="px-2">
                      <v-text-field
                        name="pinterest"
                        label="Pinterest"
                        prefix="https://www.pinterest.com/"
                        id="pinterest"
                        prepend-icon="fab fa-pinterest"
                        v-model="pinterest"
                      ></v-text-field>
                    </v-card>
                    <v-card class="px-2">
                      <v-text-field
                        name="yelp"
                        label="Yelp"
                        prefix="https://www.yelp.com/biz/"
                        id="yelp"
                        prepend-icon="fab fa-yelp"
                        v-model="yelp"
                      ></v-text-field>
                    </v-card>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>

          <template v-if="!wizard">
            <v-row v-if="false">
              <v-col cols="12">
                <v-icon>mdi-image</v-icon>
                <span class="ml-3 mr-3 grey--text text--lighten-0"
                  >Business Logo</span
                >
              </v-col>
              <v-col cols="12" sm="6" lg="4">
                <v-row v-show="profile.businessLogo">
                  <v-col cols="12" class="d-flex justify-center">
                    <div class="logo-image">
                      <v-img
                        :src="profile.businessLogo || ''"
                        width="100%"
                        height="100%"
                        alt
                      />
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="d-flex justify-center">
                    <v-dialog v-model="showLogoChangeDlg" fullscreen>
                      <template v-slot:activator="{ on }">
                        <v-btn color="primary" v-on="on">
                          {{
                            profile.businessLogo ? 'Change Logo' : 'Add Logo'
                          }}
                          <v-icon>mdi-image-edit</v-icon>
                        </v-btn>
                      </template>
                      <v-card>
                        <v-card-title
                          class="headline grey lighten-2"
                          primary-title
                          >Change Business Logo</v-card-title
                        >
                        <v-card-text class="d-flex">
                          <v-row>
                            <v-col cols="12" sm="6">
                              <media-uploader @uploadMedia="uploadLogo" />
                            </v-col>
                          </v-row>
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="primary"
                            @click="showLogoChangeDlg = false"
                            >Back</v-btn
                          >
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-icon>mdi-account-circle</v-icon>
                <span class="ml-3 mr-3 grey--text text--lighten-0"
                  >Business Logo or Profile Picture</span
                >
              </v-col>
              <v-col cols="12" sm="6" lg="4">
                <v-row v-show="profile.avatar">
                  <v-col cols="12" class="d-flex justify-center">
                    <div class="avatar-round">
                      <v-img
                        contain
                        :src="profile.avatar"
                        width="100%"
                        height="100%"
                        alt
                      />
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="d-flex justify-center">
                    <v-dialog v-model="showAvatarChangeDlg" fullscreen>
                      <template v-slot:activator="{ on }">
                        <v-btn color="primary" v-on="on">
                          {{
                            profile.avatar ? 'Change Picture' : 'Add Picture'
                          }}
                          <v-icon>mdi-account-edit</v-icon>
                        </v-btn>
                      </template>
                      <v-card>
                        <v-card-title
                          class="headline grey lighten-2"
                          primary-title
                          >Change Profile Picture</v-card-title
                        >
                        <v-card-text class="d-flex">
                          <v-row justify="center">
                            <v-col cols="12" sm="6">
                              <media-uploader
                                @uploadMedia="uploadAvatar"
                                :ratio="1 / 1"
                                imageOnly
                                circle
                              />
                            </v-col>
                          </v-row>
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="primary"
                            @click="showAvatarChangeDlg = false"
                            >Back</v-btn
                          >
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" sm="6" lg="4" class="d-flex justify-center">
                <v-card class="w-100">
                  <v-container>
                    <template v-if="profile.banner16by9">
                      <v-img
                        contain
                        :src="profile.banner16by9"
                        height="250"
                        alt
                      />
                    </template>
                    <template v-else>
                      <v-img contain height="250px">
                        <h4 class="grey--text font-weight-thin font-italic">
                          Upload Producer Banner
                        </h4>
                        <h5 class="grey--text font-weight-thin font-italic">
                          16:9 Aspect Ratio
                        </h5>
                      </v-img>
                    </template>
                  </v-container>
                  <v-card-actions class="d-flex justify-center">
                    <v-dialog v-model="showBannerChangeDlg16_9" fullscreen>
                      <template v-slot:activator="{ on }">
                        <v-btn color="secondary" v-on="on">
                          {{
                            profile.banner16by9
                              ? 'Change Picture'
                              : 'Add Picture'
                          }}
                          <v-icon>mdi-image-edit</v-icon>
                        </v-btn>
                      </template>
                      <v-card>
                        <v-card-title
                          class="headline grey lighten-2"
                          primary-title
                        >
                          {{ profile.banner16by9 ? 'Change ' : 'Add ' }}Banner
                          Picture(16:9 Aspect Ratio)
                        </v-card-title>
                        <v-card-text class="text-center">
                          <v-row justify="center">
                            <v-col cols="12" sm="6">
                              <media-uploader
                                @uploadMedia="uploadBanner16_9"
                                imageOnly
                              />
                            </v-col>
                          </v-row>
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="primary"
                            @click="showBannerChangeDlg16_9 = false"
                            >Back</v-btn
                          >
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-card-actions>
                </v-card>
              </v-col>
              <v-col cols="12" sm="6" lg="3" class="d-flex justify-center">
                <v-card class="w-100">
                  <v-container>
                    <template v-if="profile.banner1by1">
                      <v-img
                        contain
                        :src="profile.banner1by1"
                        height="250"
                        alt
                      />
                    </template>
                    <template v-else>
                      <v-img contain height="250px">
                        <h4 class="grey--text font-weight-thin font-italic">
                          Upload Producer Banner
                        </h4>
                        <h5 class="grey--text font-weight-thin font-italic">
                          1:1 Aspect Ratio
                        </h5>
                      </v-img>
                    </template>
                  </v-container>
                  <v-card-actions class="d-flex justify-center">
                    <v-dialog v-model="showBannerChangeDlg1_1" fullscreen>
                      <template v-slot:activator="{ on }">
                        <v-btn color="secondary" v-on="on">
                          {{
                            profile.banner1by1
                              ? 'Change Picture'
                              : 'Add Picture'
                          }}
                          <v-icon>mdi-image-edit</v-icon>
                        </v-btn>
                      </template>
                      <v-card>
                        <v-card-title
                          class="headline grey lighten-2"
                          primary-title
                        >
                          {{ profile.banner1by1 ? 'Change ' : 'Add ' }}Banner
                          Picture(1:1 Aspect Ratio)
                        </v-card-title>
                        <v-card-text class="d-flex">
                          <v-row justify="center">
                            <v-col cols="12" sm="6">
                              <media-uploader
                                @uploadMedia="uploadBanner1_1"
                                imageOnly
                                :ratio="1 / 1"
                              />
                            </v-col>
                          </v-row>
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="primary"
                            @click="showBannerChangeDlg1_1 = false"
                            >Back</v-btn
                          >
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>

            <business-hours
              v-if="isChef"
              @onBusinessHoursUpdate="onBusinessHoursUpdate"
              @onBusinessClose="onBusinessClose"
              :businessHours="profile.businessHours"
              :isBusinessClose="profile.isBusinessClose"
              :allow_preorder="
                profile.settings ? profile.settings.allow_preorder : false
              "
            />
            <v-row>
              <v-col cols="12" sm="7">
                <v-switch
                  v-model="profileStatus"
                  dense
                  value="hide"
                  @change="showHideProfile"
                  :label="
                    ` ${
                      profileStatus === 'hide'
                        ? `Un-hide your profile.`
                        : `Hide your profile. Your business will not be shown in TakeIn`
                    }`
                  "
                  color="red"
                ></v-switch>
              </v-col>
            </v-row>

            <v-row class="pt-2 mb-3">
              <v-col cols="8" class="d-flex">
                <v-btn class="primary mr-3" :disabled="invalid" type="submit"
                  >Save</v-btn
                >
                <router-link to="/">
                  <v-btn class="mr-3">Close</v-btn>
                </router-link>
              </v-col>
            </v-row>
          </template>
        </form>
      </ValidationObserver>
    </div>
  </v-container>
</template>
<script>
/* eslint-disable */
import { globalMixin } from 'fod-core'
import { mapGetters } from 'vuex'
import marked from 'marked'
import moment from 'moment'
import { parsePhoneNumberFromString } from 'libphonenumber-js'

import { profileMixin } from 'fod-core'
const domain = process.env.VUE_APP_DOMAIN

export default {
  props: ['wizard', 'tabs', 'uid'],
  mixins: [profileMixin, globalMixin],
  components: {
    MediaUploader: () =>
      import('fod-core/src/components/common/MediaUploader.vue'),
    BusinessHours: () => import('@/components/company/BusinessHours.vue'),
    FoodOrigin: () => import('@/components/cook/FoodOrigin.vue')

    // Cropper: () => import('fod-core/src/components/common/Cropper.vue')
  },

  data() {
    return {
      uploadProgress: false,
      showLogoChangeDlg: false,
      showAvatarChangeDlg: false,
      showBannerChangeDlg16_9: false,
      showBannerChangeDlg1_1: false,
      name: '',
      handle: null,
      title: null,
      businessName: '',
      businessPhone: null,
      orderPhoneExtension: null,
      businessEmail: null,
      businessHours: null,
      description: '',
      email: '',
      phone: '',
      website: '',
      twitter: '',
      facebook: '',
      linkedin: '',
      instagram: '',
      yelp: '',
      pinterest: '',
      profileStatus: 'public',
      handleExists: false,
      handleMessages: [],
      hasBusiness: true,
      showRefresh: false,
      status: null,
      categories: [],
      showPrivacyHint: false,
      social_panel: [],
      isEditable: false,
      prevProfile: null,
      curProfile: null,
      onEditing: false,
      rules: {
        required: value => !!value || 'Required.',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
        }
      }
    }
  },
  watch: {
    tabs: function() {
      if (this.tabs == 'tab-basic') {
        this.$refs.name.focus()
      }
    },
    $route: function(to, from) {
      this.loadProfile()
    }
  },
  mounted() {
    this.loadProfile()
  },
  computed: {
    ...mapGetters({
      location: 'userLocation',
      loading: 'loading',
      user: 'user',
      cuisineList: 'cuisineList',
      isAdmin: 'isAdmin',
      chefApprovalStatus: 'chefApprovalStatus',
      myProfile: 'profile',
      userProfile: 'userProfile',
      foodCategories: 'foodCategories',
      currentOrigins: 'currentOrigins'
    }),

    emailLabel() {
      return this.user.emailVerified ? 'email' : 'Email is NOT verified.'
    },
    shareLink() {
      return this.profile.handle
        ? `https://${domain}/producer/` + this.profile.handle
        : null
    },
    getTagFromName() {
      return this.name
        .trim()
        .replace(/\s+/g, '-')
        .toLowerCase()
    },
    tagLabel() {
      let tag = this.getTagFromName

      return `${tag} `
    },
    hasSocial() {
      let all = `${this.twitter || ''}${this.facebook || ''}${this.linkedin ||
        ''}${this.instagram || ''}${this.yelp || ''}${this.pinterest || ''}`
      return all.length > 0
    },

    compiledMarkdown() {
      return this.description
        ? marked(this.description, { sanitize: true })
        : ''
    },
    profile() {
      if (this.$route.params.id) {
        return this.userProfile
      }
      return this.myProfile
    },
    isChef() {
      return this.profile.type === 'chef'
    },
    categoryList() {
      if (this.foodCategories) {
        return this.foodCategories.map(c => c.name)
      }
    }
  },
  updated: function() {
    if (this.wizard) {
      let profile = {
        type: 'profile',
        name: this.name,
        hasBusiness: this.hasBusiness,
        businessName: this.businessName,
        businessEmail: this.businessEmail,
        businessPhone: this.businessPhone,
        orderPhoneExtension: this.orderPhoneExtension,
        categories: this.categories,
        email: this.email,
        phone: this.phone,
        website: this.website,
        description: this.description,
        title: this.title,
        handle: this.handle,
        profileStatus: this.profileStatus || 'public'
      }
      this.$emit('onUpdateWork', profile)
    }
  },
  methods: {
    uploadLogo(file) {
      this.keepUpdateProfile()
      this.$store.dispatch('uploadBusinessLogo', {
        uid: this.uid,
        file: file
      })
    },
    async keepUpdateProfile() {
      if (this.businessPhone) {
        const p = parsePhoneNumberFromString(this.businessPhone, 'US')
        if (p && p.isValid()) {
          this.businessPhone = p.nationalNumber
        }
      }
      if (this.phone) {
        const p = parsePhoneNumberFromString(this.phone, 'US')
        if (p && p.isValid()) {
          this.phone = p.nationalNumber
        }
      }
      let profile = {
        id: this.uid,
        name: this.name,
        handle: this.handle,
        title: this.title,
        businessName: this.businessName,
        businessEmail: this.businessEmail,
        businessPhone: this.businessPhone,
        businessHours: this.businessHours,
        hasBusiness: this.hasBusiness,
        orderPhoneExtension: this.orderPhoneExtension,
        profileStatus: this.profileStatus || 'public',
        email: this.email,
        categories: this.categories,
        //status: this.status,

        phone: this.phone,
        website: this.website,
        facebook: this.facebook,
        pinterest: this.pinterest,
        instagram: this.instagram,
        linkedin: this.linkedin,
        yelp: this.yelp,
        twitter: this.twitter,
        description: this.description,
        type: this.profile.type
      }
      await this.$store.dispatch('updateProfile', profile)
      if (!this.uid) return
      await this.$store.dispatch('loadUserProfile', { uid: this.uid })
    },
    viewFollowers() {
      this.keepUpdateProfile()
      this.$router.push('/followers/' + this.uid)
    },
    uploadAvatar(file, img) {
      this.keepUpdateProfile()
      this.$store.dispatch('uploadMedia', {
        uid: this.uid,
        file: file,
        img: img,
        type: 'avatar'
      })
    },
    uploadBanner16_9(file, img, desc) {
      this.keepUpdateProfile()
      this.$store.dispatch('uploadMedia', {
        uid: this.uid,
        file: file,
        img: img,
        type: 'banner16by9'
      })
    },
    uploadBanner1_1(file, img) {
      this.keepUpdateProfile()
      this.$store.dispatch('uploadMedia', {
        uid: this.uid,
        file: file,
        img: img,
        type: 'banner1by1'
      })
    },
    loadProfile() {
      let profile = this.profile
      console.log(profile, 'profile###')
      if (profile != null) {
        this.name = profile.name
        this.handle = profile.handle
        this.title = profile.title
        this.description = profile.description
        this.businessName = profile.businessName
        this.hasBusiness = true //profile.hasBusiness
        this.businessEmail = profile.businessEmail
        this.businessPhone = profile.businessPhone
        this.businessHours = profile.businessHours
        this.orderPhoneExtension = profile.orderPhoneExtension
        this.email = profile.email
        this.status = profile.status
        this.categories = profile.categories

        this.profileStatus = profile.profileStatus
        this.website = profile.website
        this.facebook = profile.facebook
        this.twitter = profile.twitter
        this.instagram = profile.instagram
        this.linkedin = profile.linkedin
        this.yelp = profile.yelp
        this.pinterest = profile.pinterest
        this.cuisine = profile.cuisine
        this.phone = profile.phone
        if (!this.handle){
          this.fillHandle()
        }
      }

      this.$store.dispatch('updateOrigins', profile.cuisine || [])

      if (this.businessPhone) {
        const p = parsePhoneNumberFromString(this.businessPhone, 'US')
        if (p && p.isValid()) {
          this.businessPhone = p.format('NATIONAL')
        }
      }
      if (this.phone) {
        const p = parsePhoneNumberFromString(this.phone, 'US')
        if (p && p.isValid()) {
          this.phone = p.format('NATIONAL')
        }
      }

      if (this.hasSocial) {
        this.social_panel.push(true)
      }
    },
    async onUpdateProfile() {
      let profile = {
        id: this.uid,
        name: this.name,
        handle: this.handle,
        title: this.title,
        businessName: this.businessName,
        businessEmail: this.businessEmail,
        businessPhone: this.businessPhone,
        businessHours: this.businessHours,
        orderPhoneExtension: this.orderPhoneExtension,
        hasBusiness: this.hasBusiness,
        profileStatus: this.profileStatus || 'public',
        email: this.email,
        categories: this.categories,
        //status: this.status,

        phone: this.phone,
        website: this.website,
        facebook: this.facebook,
        pinterest: this.pinterest,
        instagram: this.instagram,
        linkedin: this.linkedin,
        yelp: this.yelp,
        twitter: this.twitter,
        description: this.description,
        type: this.profile.type,
        cuisine: this.currentOrigins
      }
      this.$store.dispatch('saveProfile', profile)
      this.$store.dispatch('setLoading', true)

      //this.$store.dispatch('loadProfile')
    },

    showHideProfile() {
      if (this.profileStatus === 'hide') {
        if (confirm('You are about to hide your profile, Are you sure?')) {
          console.log('Setting profile status to :', this.profileStatus)

          this.$store.dispatch('updateProfile', {
            id: this.uid,
            profileStatus: this.profileStatus || 'public'
          })
        } else {
          this.profileStatus = 'public'
        }
      }
    },
    refresh() {
      console.log('Refreshing profile')
      this.loadProfile()
      //this.$store.dispatch('reloadUser')
      //this.$router.push('/my/profile?load')
      this.$router.go(this.$router.currentRoute)
    },
    fillHandle() {
      if (!this.handle || this.handle.length == 0) {
        this.handle = this.getTagFromName
      }
      return true
    },
    onBusinessHoursUpdate(payload) {
      console.log(payload, 'payload---')
      this.businessHours = payload
    },
    onBusinessClose(payload) {
      let profile = {
        id: this.uid,
        isBusinessClose: payload.isBusinessClose
      }
      this.$store.dispatch('updateProfile', profile)
    },

    checkHandleExists() {
      if (this.handle && this.handle.length > 1) {
        //this.handle = this.getTagFromName

        console.log(`Checking is handle ${this.handle} exists`)
        this.$store.dispatch('checkHandle', this.handle).then(data => {
          if (data) {
            this.handleExists = true
            this.handleMessages = [
              "This handle it's not available or has been used by others"
            ]
          } else {
            this.handleExists = false
            this.handleMessages = []
          }
          console.log('Handle check result', data)
        })
      }
      return true
    },
    blurProfileDescription() {
      this.isEditable = false
    },
    setBusinessPhone() {
      const p = parsePhoneNumberFromString(this.businessPhone, 'US')
      if (p && p.isValid()) {
        this.businessPhone = p.nationalNumber
      }
    },
    verifyEmailByAdmin() {
      this.$store.dispatch('verifyEmailByAdmin', { uid: this.$route.params.id })
    }
  }
}
</script>
<style scoped>
.avatar-round {
  border: gainsboro 5px solid;
  border-radius: 50%;
  overflow: hidden;
  width: 250px;
  height: 250px;
}
.logo-image {
  border: gainsboro 5px solid;
  border-radius: 5px;
  overflow: hidden;
  width: 300px;
  height: 168px;
}
.word-breaking {
  word-break: normal !important;
}
</style>
