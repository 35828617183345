<template>
  <div>
    <v-row>
      <v-col cols="12">
        <h5 class="font-weight-bold">Operating City</h5>
        <v-select
          :items="citiesList"
          v-model="code"
          @change="onChangeCity"
          item-text="city_name"
          item-value="city_code"
          persistent-hint
          single-line
        >
          <template slot="item" slot-scope="data">{{
            data.item.city_name
          }}</template>
        </v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="text-center">
        <v-btn color="primary" @click="saveOperatingCity"
          >Save Operating City</v-btn
        >
      </v-col></v-row
    >
  </div>
</template>

<script>
import firebase from '@/firebase-init'

export default {
  props: {
    profile: {
      type: Object,
      required: true,
      default: null
    }
  },
  data() {
    return {
      code: null,
      citiesList: [],
      selectedCity: null
    }
  },
  mounted() {
    this.code = this.profile.operating_city
      ? this.profile.operating_city.city_code
      : null
    firebase
      .firestore()
      .collection('admin')
      .doc('cities')
      .collection('operatingCities')
      .get()
      .then(dataSnapshot => {
        if (!dataSnapshot.empty) {
          dataSnapshot.forEach(data => {
            if (data.exists) {
              const city = data.data()
              city.id = data.id
              this.citiesList.push(city)
            }
          })
        }
        if (!this.citiesList.length) return
        if (this.code) {
          this.selectedCity = this.citiesList.find(
            c => c.city_code === this.code
          )
        } else {
          this.selectedCity = this.citiesList[0]
        }
        this.$emit('changeCity', this.selectedCity)
      })
      .catch(error => {
        console.error(error)
      })
  },
  methods: {
    onChangeCity() {
      this.selectedCity = this.citiesList.find(c => c.city_code === this.code)
      this.$emit('changeCity', this.selectedCity)
    },
    saveOperatingCity() {
      if (this.profile && this.selectedCity) {
        firebase
          .firestore()
          .collection('profile')
          .doc(this.profile.id)
          .update({
            operating_city: {
              city_name: this.selectedCity.city_name,
              city_code: this.selectedCity.city_code,
              id: this.selectedCity.id
            }
          })
          .then(() => {
            this.$store.dispatch('setMessage', {
              title: 'Saved',
              body: 'Operating City saved'
            })
          })
          .catch(e => console.error(e))
      }
    }
  }
}
</script>
