<template>
  <v-navigation-drawer
    temporary
    class="no-print"
    :right="true"
    v-model="rightDrawer"
    fixed
    app
  >
    <v-toolbar flat dark class="primary">
      <v-toolbar-title>Notifications</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click.stop="rightDrawer = false">
        <v-icon>close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-list subheader dense>
      <v-subheader>All notifications</v-subheader>
      <v-list-item v-if="!deliver_state">
        <v-list-item-action>
          <v-icon color="red">report</v-icon>
        </v-list-item-action>
        <v-list-item-title>
          One of the two options above MUST be turned on.
        </v-list-item-title>
      </v-list-item>
      <v-list-item @click.native="showOrders" v-if="order_count > 0">
        <v-list-item-action>
          <v-icon>shopping_cart</v-icon>
        </v-list-item-action>
        <v-list-item-title> {{ order_count }} active orders </v-list-item-title>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item v-if="notification_count > 0">
        <v-list-item-action> <v-icon>data_usage</v-icon> </v-list-item-action>
        <v-list-item-title>
          {{ notification_count }} new notifications
        </v-list-item-title>
      </v-list-item>
      <v-list-item v-if="chefApprovalStatus !== 'approved'">
        <v-list-item-action>
          <v-icon color="red">report</v-icon>
        </v-list-item-action>
        <v-list-item-title @click="$router.push('/?sp=true')">
          Account approval is pending
        </v-list-item-title>
      </v-list-item>
      <v-list-item @click="$router.push('/my/profile')" v-if="!validProfile">
        <v-list-item-action>
          <v-icon color="red">error</v-icon>
        </v-list-item-action>
        <v-list-item-title> Profile is not complete yet. </v-list-item-title>
      </v-list-item>
      <v-subheader @click="$router.push('/?sp=true')"
        >Account Status</v-subheader
      >
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  props: {
    open: {
      Type: Boolean
    },
    order_count: {
      Type: Number
    },
    notification_count: {
      Type: Number
    },
    invitation_count: {
      Type: Number
    },
    validProfile: {
      Type: Boolean
    },
    chefApprovalStatus: {
      type: String
    },
    deliver_state: {
      type: Boolean
    }
  },
  data() {
    return {
      rightDrawer: false
    }
  },
  watch: {
    open: function() {
      this.rightDrawer = this.open
    }
  },
  methods: {
    showOrders() {
      console.log('showOrders')
      this.$router.push('/order/list')
    }
  }
}
</script>
