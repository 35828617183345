import Geohash from 'latlon-geohash'
import firebase from '@/firebase-init'

var checkLoading = function(state) {
  let now = new Date().getTime()
  let started = new Date(state.loading_start_time)
  started.setSeconds(started.getSeconds() + 30)

  if (now > started && state.loading) {
    clearInterval(state.loading_timer)
    state.loading = false
    state.loading_timer = null
    console.log('Stopping loading timer', state.loading)
  }
}
export default {
  state: {
    loading: false,
    loading_start_time: null,
    error: null,
    message: null,
    searchLocation: null
    //portalAddress: null
  },
  mutations: {
    setLoading(state, payload) {
      state.loading = payload
      if (payload) {
        state.loading_start_time = new Date().getTime()
        let timer = setInterval(checkLoading.bind(null, state), 5000)
        state.loading_timer = timer
      } else {
        if (state.loading_timer && state.loading_timer > 0) {
          clearInterval(state.loading_timer)
          state.loading_timer = 0
        }
      }
    },
    setError(state, payload) {
      state.error = payload
    },
    setMessage(state, payload) {
      state.message = payload
    },
    setSearchLocation(state, payload) {
      state.searchLocation = payload
    },
    clearError(state) {
      state.error = null
    }
    // setPortalAddress(state, payload) {
    //   state.portalAddress = payload
    // }
  },
  actions: {
    clearError({ commit }) {
      commit('clearError')
    },
    setError({ commit }, payload) {
      commit('setError', payload)
    },
    setMessage({ commit }, payload) {
      commit('setMessage', payload)
    },
    setLoading({ commit }, payload) {
      commit('setLoading', payload)
    },
    // setPortalAddress({ commit }, payload) {
    //   const domain = process.env.VUE_APP_DOMAIN
    //   if (!payload) {
    //     commit('setPortalAddress', domain)
    //   } else {
    //     commit('setPortalAddress', payload)
    //   }
    // },
    setSearchLocation({ commit, getters, dispatch }, location) {
      if (location && location.latitude) {
        location.lowGeohash = Geohash.encode(
          location.latitude,
          location.longitude,
          7
        )
        location.ighGeohash = Geohash.encode(
          location.latitude,
          location.longitude
        )
      }
      if (!getters.userLocation) {
        //
      }
      dispatch('setUserLocation', location)

      commit('setSearchLocation', location)
    },
    async uploadFile({ getters, dispatch }, payload) {
      payload.id = payload.id || getters.user.id
      let path = `${payload.type}/${payload.id}/${payload.key}`
      if (payload.sub_type) {
        path = `${payload.type}/${payload.id}/${payload.sub_type}/${payload.key}`
      }

      payload.path = path
      console.log('uploading', payload)

      return dispatch('uploadStorage', payload)
    },
    async uploadStorage({ commit }, payload) {
      console.log('Uploading to:', payload.path)
      return await firebase
        .storage()
        .ref(payload.path)
        .put(payload.file)
        .then(fileData => {
          return fileData.ref.getDownloadURL().then(imageUrl => {
            return imageUrl
          })
        })
    },
    sendNotification(context, payload) {
      console.log(payload)
      return firebase
        .firestore()
        .collection('notification')
        .add(payload)
    }
  },
  getters: {
    loading(state) {
      return state.loading
    },
    error(state) {
      return state.error
    },
    message(state) {
      return state.message
    },
    searchLocation: state => state.searchLocation,
    portalAddress: () => process.env.VUE_APP_DOMAIN
  }
}
