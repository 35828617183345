/**
 * Base Profile
 */
import firebase from "@/firebase-init";
import moment from "moment";

export default {
  state: {},
  mutations: {},
  actions: {
    async userLookup(ctx, payload) {
      if (payload && payload.email && payload.email.length > 0) {
        return await firebase
          .firestore()
          .collection("profile")
          .where("email", "==", payload.email.toLowerCase())
          .get()
          .then((res) => {
            if (!res.docs || res.docs.length==0){
              ctx.commit('setError', {message: `${this.email} not found`})
              return
          }
          
          const entries = res.docs.map((profile) => { 
            const d = {...profile.data()}
            d.id = profile.id
            return d});
          return entries
          })
        
          
          
      } else {
        console.warn("Missing payload", payload);
      }
    },
    saveAddresses({ commit, getters }, payload) {
      commit("setLoading", true);
      let key = payload.profile.id || getters.user.id;
      let userLocation = payload.profile.user_location
        ? payload.profile.user_location.geopoint
        : getters.userLocation;

      if (payload.addresses) {
        payload.addresses.forEach((address) => {
          if (address.latitude && address.longitude) {
            let location = new firebase.firestore.GeoPoint(
              address.latitude,
              address.longitude
            );
            address.location = location;
          } else {
            if (userLocation) {
              let location = new firebase.firestore.GeoPoint(
                userLocation.latitude,
                userLocation.longitude
              );
              address.location = location;
              address.userLocation = userLocation;
            }
          }
        });
        let profile;
        if (payload.profile.id === getters.user.id) {
          profile = getters.profile;
          profile.addresses = payload.addresses;
          commit("setProfile", profile);
        } else {
          profile = payload.profile;
          profile.addresses = payload.addresses;
          commit("setUserProfile", profile);
        }
      }

      firebase
        .firestore()
        .collection("profile")
        .doc(key)
        .update({ addresses: payload.addresses })
        .then(() => {
          commit("setLoading", false);
          console.log("addresses successfully saved");
        })
        .catch((error) => {
          commit("setLoading", false);
          commit("setError", error);
          console.error(error);
        });
    },
    submitSupport(context, payload) {
      payload.created = moment()
        .utc()
        .valueOf();
      firebase
        .firestore()
        .collection("support")
        .doc("product")
        .collection("technical")
        .add(payload)
        .then(() => {
          console.log("addresses successfully saved");
        })
        .catch((error) => {
          console.error(error);
        });
    },
    sendVerificationEmail({ commit, getters }, payload) {
      const id = getters.user.id;
      const ref = firebase
        .firestore()
        .collection("profile")
        .doc(id);
      ref
        .get()
        .then((snapshot) => {
          console.log("Sending another verificaiton email");
          let emailCount = snapshot.data().verificationEmailCount;
          if (!emailCount) {
            emailCount = 0;
          }
          if (emailCount == 5) {
            return;
          }
          ref.update({ verificationEmailCount: emailCount + 1 });
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
  getters: {
    /*defaultAddress(state) {
      if (!state.profile) {
        return null
      }
      return state.profile.addresses && state.profile.addresses.length > 0
        ? state.profile.addresses[0]
        : state.profile.address
    }*/
  },
};
