// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import firebase, { analytics } from './firebase-init'
import '@firebase/messaging'
import App from './App'
import vuetify from '@/plugins/vuetify'

import '@/assets/scss/_style.scss'
import { store } from './store'
import router from './router'
import VueRx from 'vue-rx'

import * as VueGoogleMaps from 'vue2-google-maps'
import './assets/css/style.css'
import '@mdi/font/css/materialdesignicons.css'
import moment from 'moment'
import Vue2Filters from 'vue2-filters'
import StarRating from 'vue-star-rating'
import VueMoment from 'vue-moment'
import VueLocalStorage from 'vue-localstorage'
// import Pace from 'pace-progress'
import VueMeta from 'vue-meta'
import * as Sentry from '@sentry/browser'
import { Vue as VueIntegration, CaptureConsole } from '@sentry/integrations'
import PrismicVue from 'prismic-vue'
import linkResolver from './prismic/link-resolver'
import htmlSerializer from './prismic/html-serializer'
// import { ValidationProvider } from 'vee-validate/dist/vee-validate.full.esm'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

import VueGtm from 'vue-gtm'

import './registerServiceWorker'
import FlagIcon from 'vue-flag-icon'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import VueI18n from 'vue-i18n'
import { i18n, globalMixin } from 'fod-core'
// Mixins Only for Producers
import { mainMixin } from '@/mixins/mainMixin'
const AlertComp = () => import('./components/common/Alert.vue')

import { extend } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules.js'
import { messages } from 'vee-validate/dist/locale/en.json'

Object.keys(rules).forEach(rule => {
  extend(rule, {
    ...rules[rule], // copies rule configuration
    message: messages[rule] // assign message
  })
})

extend('fromTo', {
  params: ['from', 'to'],
  validate(value, { from, to }) {
    return moment(from, 'HH:mm a').isBefore(moment(to, 'HH:mm a'))
  },
  message: 'The {from} should be before {to}'
})
Vue.config.productionTip = false

Vue.use(Vue2Filters)
Vue.use(VueMoment)
Vue.use(VueRx)
Vue.component('star-rating', StarRating)
Vue.component('app-alert', AlertComp)
Vue.component('vue-perfect-scrollbar', VuePerfectScrollbar)
Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)

Vue.use(FlagIcon)
Vue.use(VueLocalStorage)

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAWvOJtQ0I3EJA8wlLdjuBaC4KVqdjtDVY',
    libraries: ['places']
  }
})

Vue.component('deleteConfirmationDialog', () =>
  import('fod-core/src/components/common/DeleteConfirmationDialog')
)
Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
})
Vue.use(VueI18n)

//console.log('VUE_APP_TITLE:', process.env.VUE_APP_TITLE)
//console.log('VUE_APP_TITLE_COLOR:', process.env.VUE_APP_TITLE_COLOR)

//console.log('firebaseInit', firebaseInit)
Vue.filter('formatDate', function(value) {
  if (value) {
    return moment(value).format('MM/DD/YYYY hh:mm a')
  }
})
Vue.filter('formatEpoch', function(value) {
  if (value) {
    return moment.unix(value).format('MM/DD/YYYY hh:mm a')
  }
})
Vue.filter('fromNow', function(value) {
  if (value) {
    return moment(value).fromNow()
  }
})
Vue.filter('showPics', function(value) {
  if (!Vue.config.devtools) {
    return value
  }
  return value
})

//console.log('firebaseInit', firebaseInit)
Vue.filter('formatDate', function(value) {
  if (value) {
    return moment(value).format('MM/DD/YYYY')
  }
})
Vue.filter('formatTimeOnly', function(value) {
  if (value) {
    return moment(value).format('hh:mm a')
  }
})
Vue.filter('formatDateTime', function(value) {
  if (value) {
    return moment(value).format('MM/DD/YYYY hh:mm a')
  }
})
Vue.filter('formatTimeDate', function(value) {
  if (value) {
    return moment(value).format('hh:mm a MM/DD/YYYY')
  }
})

const env = process.env.VUE_APP_ENV

window.prismic = {
  endpoint: `https://takein${
    env === 'development' ? 'dev' : ''
  }.prismic.io/api/v2`
}
Vue.use(PrismicVue, {
  endpoint: window.prismic.endpoint,
  linkResolver,
  htmlSerializer
})

Vue.mixin(globalMixin)
Vue.mixin(mainMixin)
Vue.use(VueGtm, {
  id: process.env.VUE_APP_ID,
  loadScript: true,
  enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
  debug: true, // Whether or not display console logs debugs (optional)
  vueRouter: router // Pass the router instance to automatically sync with router (optional)
  // ignoredViews: ['homepage'] // If router, you can exclude some routes name (case insensitive) (optional)
})

Sentry.init({
  dsn: process.env.VUE_APP_DSN,
  enabled: process.env.VUE_APP_ENV === 'production',
  release: process.env.VUE_APP_VERSION,
  environment: process.env.VUE_APP_ENV,
  maxBreadcrumbs: 50,
  integrations: [
    new VueIntegration({ Vue, attachProps: true, logErrors: true }),
    new CaptureConsole({
      levels: ['error']
    })
  ]
})

/* eslint-disable no-new */
let audio = new Audio('/audio/the-little-dwarf.ogg')
new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App),
  localStorage: {
    userlocation: {
      type: Object,
      default: {}
    }
  },
  created() {
    const dispatch = this.$store.dispatch
    dispatch('setPageSize', 6)
    //dispatch('setPortalAddress')
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        Sentry.setUser({ email: user.email, id: user.uid })

        this.$store.dispatch('autoSignIn', user)
        this.$store.dispatch('loadProfile')
        this.$store.dispatch('checkAdmin')
        this.$store.dispatch('newMessageCount')
        this.$store.dispatch('getSystemUpdates', { recent: true })
        dispatch('checkVersion')
      } else {
        analytics.setUserProperties({ type: 'guest' })
      }
    })
    const messaging = firebase.messaging()

    messaging.onMessage(function(payload) {
      if (
        payload.data &&
        payload.data.type === 'order' &&
        payload.data.status === 'new'
      ) {
        let promise = audio.play()

        if (promise !== undefined) {
          promise.then(() => console.log('Played')).catch(e => console.error(e))
        }
      }
      console.log('Message received. ', payload)
      if (payload.data && payload.data.CONFIG_STATE === 'STALE') {
        dispatch('checkVersion')
      } else {
        dispatch('setMessage', payload.notification)
      }
    })
  }
}).$mount('#app')
