<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" sm="10" xl="8">
        <v-sheet class="pa-8 main_text--text shadow">
          <h4 class="font-weight-bold text-uppercase">
            {{ $t('Common.step') }} {{ $t('Common.two') }} -
            {{ $t('Common.finance') }}
          </h4>
          <v-stepper v-model="vstep" vertical non-linear>
            <v-stepper-step
              editable
              :complete="vstep > 1"
              step="1"
              color="takein"
              class="main_text--text"
            >
              <span
                class="text-uppercase text-underline subtitle-2 main_text--text"
                >{{ $t('Common.add_deposit_account') }}</span
              >
            </v-stepper-step>

            <v-stepper-content step="1">
              <v-sheet class="d-flex flex-column pa-4 shadow mb-8 mt-4">
                <v-row justify="center" class="pl-4 pr-4 py-2">
                  <v-col>
                    <small class="main_text--text">{{
                      $t('Common.deposit_account_subheader')
                    }}</small>
                  </v-col>
                </v-row>
                <v-row justify="center" class="pl-4 pr-4 py-2">
                  <v-col>
                    <v-btn
                      v-if="addMode"
                      depressed
                      :ripple="false"
                      color="primary"
                      to="/profile/wizard/finance/deposit"
                      @click="addMode = false"
                    >
                      <v-icon small left>fas fa-money-check-alt</v-icon>
                      {{ $t('Common.add_deposit_account2') }}
                    </v-btn>
                    <router-view></router-view>
                  </v-col>
                </v-row>
                <v-row justify="center" class="pl-4 pr-4 py-2">
                  <v-col>
                    <small class="main_text--text">{{
                      $t('Common.deposit_account_description')
                    }}</small>
                  </v-col>
                </v-row>
              </v-sheet>

              <v-btn
                color="takein"
                dark
                :ripple="false"
                depressed
                class="mr-4"
                @click="vstep = 2"
                >{{ $t('Common.continue') }}</v-btn
              >
              <!-- <v-btn depressed :ripple="false">{{ $t('Common.cancel') }}</v-btn> -->
            </v-stepper-content>
            <v-stepper-step
              editable
              step="2"
              color="takein"
              class="main_text--text"
            >
              <span
                class="text-uppercase text-underline subtitle-2 main_text--text"
                >{{ $t('Common.choose_commission_struction') }}</span
              >
            </v-stepper-step>

            <v-stepper-content step="2">
              <v-sheet class="d-flex flex-column pa-4 shadow mb-8 mt-4">
                <v-row justify="center" class="pl-4 pr-4 py-2">
                  <v-col>
                    <small class="main_text--text">{{
                      $t('Common.commission_struction_subheader')
                    }}</small>
                  </v-col>
                </v-row>
                <v-row
                  v-if="serviceFees"
                  justify="center"
                  class="pl-4 pr-4 pb-2"
                >
                  <v-col cols="12" sm="6">
                    <service-fee
                      :serviceType="
                        `Service Fee for Pickups is ${serviceFees.pickupPct}%`
                      "
                      :maxValue="serviceFees.pickupPct"
                      :current="producerPickupPct"
                      @changeValue="changePickupServiceFee"
                    />
                    <!-- <donut-chart :totalFee="5" :kindOf="'pickup'" /> -->
                  </v-col>
                  <v-col cols="12" sm="6">
                    <service-fee
                      :serviceType="
                        `Service Fee for Delivery is ${serviceFees.deliveryPct}%`
                      "
                      :maxValue="serviceFees.deliveryPct"
                      :current="producerDeliveryPct"
                      @changeValue="changeDeliveryServiceFee"
                    />
                  </v-col>
                </v-row>
                <v-row justify="center" class="pl-4 pr-4 py-2">
                  <v-col>
                    <small class="main_text--text">{{
                      $t('Common.commission_struction_description')
                    }}</small>
                  </v-col>
                </v-row>
              </v-sheet>
              <v-btn
                color="takein"
                dark
                :ripple="false"
                depressed
                class="mr-4"
                @click="savePriceSettings"
                >{{ $t('Common.save') }}</v-btn
              >
              <!-- <v-btn depressed :ripple="false">{{ $t('Common.cancel') }}</v-btn> -->
            </v-stepper-content>
          </v-stepper>
          <v-row>
            <v-col cols="12" class="d-flex justify-center py-2">
              <v-btn
                color="takein"
                depressed
                rounded
                :ripple="false"
                @click="saveAll"
                class="white--text"
              >
                {{ $t('Common.next') }}
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-sheet>
      </v-col>
    </v-row>
    <v-snackbar
      v-model="snackbar"
      :bottom="true"
      :color="snackType"
      :timeout="snackTimeout"
    >
      {{ snackMessage }}
      <v-btn color="white" text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters({
      serviceFees: 'serviceFees',
      profile: 'profile',
      account: 'bankAccount'
    })
  },
  components: {
    DepositView: () =>
      import('@/components/profilewizard/StepFinance/DepositView'),
    BalanceChart: () =>
      import('@/components/profilewizard/StepFinance/BalanceChart.vue'),
    ServiceFee: () => import('./StepSettings/ServiceFee.vue')
  },
  watch: {
    $route(path) {
      if (path.name === 'finance') {
        if (!this.account) {
          this.addMode = true
        } else {
          this.addMode = false
          this.$router.history.push('/profile/wizard/golive')
        }
      }
    }
  },

  async mounted() {
    if (!this.profile) {
      return
    }
    await this.$store.dispatch('loadBankAccount')
    if (!this.account) {
      this.addMode = true
    } else {
      this.addMode = false
      this.$router.history.push('/profile/wizard/golive')
    }
    this.loadOrderSettings()
  },
  data() {
    return {
      snackTimeout: 2500,
      snackbar: false,
      snackMessage: '',
      snackType: 'success',
      vstep: 1,
      addMode: true,
      minimumOrderAmount: 0,
      defaultLeadTime: 10,
      robotCall: false,
      disable_preorder: false,
      producerDeliveryPct: 0,
      producerPickupPct: 0,
      errors: {},

      currency_config: {
        decimal: '.',
        thousands: ',',
        prefix: '$ ',
        precision: 2,
        masked: false,
        allowBlank: false,
        min: 0.0,
        max: Number.MAX_SAFE_INTEGER
      }
    }
  },
  methods: {
    async loadOrderSettings() {
      const fees = await this.$store.dispatch('getOrderFees')
      console.log('All fee settings: ', fees)

      this.minimumOrderAmount =
        this.profile.settings && this.profile.settings.minimumOrderAmount
          ? this.profile.settings.minimumOrderAmount
          : this.minimumOrderAmount
      this.defaultLeadTime =
        this.profile.settings && this.profile.settings.defaultLeadTime
          ? this.profile.settings.defaultLeadTime
          : this.defaultLeadTime
      this.robotCall =
        this.profile.settings && this.profile.settings.robotCall
          ? this.profile.settings.robotCall
          : this.robotCall

      this.disable_preorder = !(
        (this.profile.settings && this.profile.settings.allow_preorder) ||
        (this.profile.settings &&
          this.profile.settings.allow_preorder === undefined)
      )

      this.producerDeliveryPct =
        this.profile.settings && this.profile.settings.producerDeliveryPct
          ? this.profile.settings.producerDeliveryPct
          : 0
      this.producerPickupPct =
        this.profile.settings && this.profile.settings.producerPickupPct
          ? this.profile.settings.producerPickupPct
          : 0
    },
    savePriceSettings() {
      this.$store
        .dispatch('saveSettings', {
          minimumOrderAmount: this.minimumOrderAmount,
          robotCall: this.robotCall || null,
          defaultLeadTime: parseInt(this.defaultLeadTime),
          allow_preorder: !this.disable_preorder,
          producerPickupPct: this.producerPickupPct,
          producerDeliveryPct: this.producerDeliveryPct
        })
        .then(() => {
          this.snackMessage = 'Finance settings saved successfully.'
          this.snackType = 'success'
          this.snackbar = true

          this.$store.dispatch('getOrderFees')
          // this.$store.dispatch('setMessage', {
          //   title: 'Finance settings saved successfully.',
          //   body: ''
          // })
        })
    },
    changePickupServiceFee(value) {
      console.log(`Pickup Service fee changed:${value}`)

      this.producerPickupPct = value
    },
    changeDeliveryServiceFee(value) {
      console.log(`Delivery Service fee changed:${value}`)
      this.producerDeliveryPct = value
    },
    saveAll() {
      this.$store
        .dispatch('saveSettings', {
          minimumOrderAmount: this.minimumOrderAmount,
          robotCall: this.robotCall || null,
          defaultLeadTime: parseInt(this.defaultLeadTime),
          allow_preorder: !this.disable_preorder,
          producerPickupPct: this.producerPickupPct,
          producerDeliveryPct: this.producerDeliveryPct
        })
        .then(() => {
          this.$store.dispatch('getOrderFees')
          this.snackMessage = 'Finance settings saved successfully.'
          this.snackType = 'success'
          this.snackbar = true
          this.$router.push('/profile/wizard/golive')
          // setTimeout(() => {
          //   this.$router.push('/profile/wizard/ordersettings')
          // }, this.snackTimeout)
          // this.$store
          //   .dispatch('setMessage', {
          //     title: 'Finance settings saved successfully.',
          //     body: ''
          //   })
          //   .then(() => {
          //     this.$router.push('/profile/wizard/ordersettings')
          //   })
        })
    }
  }
}
</script>
<style>
.shadow {
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
    0px 4px 8px rgba(0, 0, 0, 0.04);
}
</style>
