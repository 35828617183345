<template>
  <v-row justify="center">
    <v-col cols="12" sm="10" xl="8">
      <v-row align="center">
        <v-col cols="12" sm="4" class="pa-0">
          <v-img
            src="https://storage.googleapis.com/download.takein.com/apps/logo/takein_logo_sm.png"
            width="220px"
          />
        </v-col>
        <v-col cols="12" sm="5">
          <h4 v-if="profile" class="title">
            {{ $t('Common.welcome') }} - {{ profile.name }}
          </h4>
        </v-col>

        <!-- <v-icon dark class="ml-3" size="32">far fa-clock </v-icon> -->
        <v-col cols="12" sm="3">
          <v-btn
            text
            @click="onLogout"
            small
            :ripple="false"
            color="primary"
            class="mr-2 text-transform-none"
          >
            {{ $t('Common.logout') }}
            <v-icon>exit_to_app</v-icon>
          </v-btn>
          <v-btn
            text
            small
            :ripple="false"
            color="primary"
            class="text-transform-none"
          >
            {{ $t('Common.help') }}
            <v-icon>mdi-help-circle-outline</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
export default {
  props: ['profile'],
  data: () => ({}),
  methods: {
    onLogout() {
      this.$store.dispatch('logout')
      this.$router.push('/signin')
    }
  }
}
</script>
<style>
.col {
  padding: 0;
}
.v-btn {
  text-transform: none !important;
}
</style>
