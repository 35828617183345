<template>
  <v-container>
    <h2>About TakeIn Business Portal</h2>
    <v-row>
      <v-col cols="12" sm="6">
        <v-card>
          <v-card-text>
            <v-row>
              <v-col cols="12"> server: {{ env }} </v-col>
            </v-row>
            <v-row>
              <v-col cols="12"> version: {{ version }} </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">git version: {{ gitVerion }} </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">git hash: {{ gitCommitHash }} </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  computed: {
    version() {
      return process.env.VUE_APP_VERSION
    },
    env() {
      return process.env.VUE_APP_ENV
    },
    gitVerion() {
      return VERSION
    },
    gitCommitHash() {
      return COMMITHASH
    }
  }
}
</script>
