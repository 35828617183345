<template>
  <v-container fluid v-if="!loading">
    <missing-address :profile="profile"></missing-address>
    <router-view></router-view>
    <v-row v-if="isAdmin && dish.chef">
      <v-col cols="12">
        <v-sheet>
          From:
          <router-link :to="'/admin/profile/' + dish.chef.id">{{
            dish.chef.name
          }}</router-link>
        </v-sheet>
      </v-col>
    </v-row>

    <dish-help :isNew="hasEmptyDishList" :cookStep="cookStep" />
    <v-row justify="center">
      <v-col cols="12" sm="12">
        <v-stepper v-model="cookStep" vertical>
          <v-stepper-step
            step="1"
            editable
            :complete="cookStep > 1"
            :rules="[rules.step1]"
            >{{ $t('Chef.food_info') }}
          </v-stepper-step>

          <v-stepper-content step="1">
            <product-status
              :status="status"
              :id="id"
              :orderCount="orderCount"
              :visibility="visibility"
              @onUpdateStatus="onUpdateStatus"
              @onUpdateVisibility="onUpdateVisibility"
            />
            <v-row align="center">
              <v-col cols="12" sm="5">
                <v-text-field
                  name="name"
                  :placeholder="$t('Common.food_name_hint')"
                  :label="$t('Common.food_name')"
                  :rules="[checkName]"
                  id="name"
                  v-model="name"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="5" v-if="id">
                <router-link :to="'/product/' + this.id + '/modifiers'"
                  >Manage Modifiers</router-link
                ></v-col
              >
            </v-row>
            <v-row>
              <v-col cols="12" sm="12">
                <div v-if="isEditable">
                  <v-textarea
                    :name="$t('Common.food_detail')"
                    rows="5"
                    :placeholder="$t('Common.food_description_hint')"
                    :label="$t('Common.food_description')"
                    id="description"
                    v-model="description"
                    @blur="blurFoodDescription"
                    maxlength="1000"
                  ></v-textarea>
                </div>
                <div v-else>
                  <p
                    class="mb-1 fs-12"
                    v-html="$t('Common.food_description')"
                  ></p>
                  <div
                    class="fs-14 border overflow-y-auto word-breaking"
                    v-html="compiledMarkdown"
                    @click="isEditable = true"
                    style="height: 100px"
                  ></div>
                </div>
              </v-col>
            </v-row>
            <v-row v-if="false">
              <v-col cols="12">
                <ingredient-list
                  v-if="ingredients"
                  @onUpdate="onUpdateIngredients"
                  :list="ingredients"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="4" class="pr-3">
                <v-combobox
                  :label="$t('Common.food_category')"
                  :items="categoryList"
                  v-model="categories"
                  item-text="name"
                  item-value="name"
                ></v-combobox>
              </v-col>
              <v-col
                class="pr-3"
                cols="12"
                md="4"
                v-if="dietList && dietList.length > 0"
              >
                <diet-options :dietList="dietList" />
              </v-col>
              <v-col
                cols="12"
                md="4"
                v-if="cuisineList && cuisineList.length > 0"
              >
                <food-origin :cuisineList="cuisineList" />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" sm="4" class="pr-3">
                <v-text-field
                  name="netWeight"
                  :label="$t('Common.food_weight')"
                  type="number"
                  min="0"
                  v-model.number="netWeight"
                  id="netWeight"
                ></v-text-field>
              </v-col>
              <v-col cols="6" sm="4">
                <v-select
                  label="Unit of Measurement"
                  v-model.number="weightUnit"
                  :items="['oz', 'lbs', 'g', 'kg']"
                ></v-select>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="12" sm="1" v-if="visibility === 'public'"></v-col>
            </v-row>

            <v-row>
              <v-col cols="6" sm="4" md="3" lg="2">
                <!-- POPULAR BUTTON -->
                <span v-if="dish && popular">
                  <v-btn
                    text
                    color="grey"
                    @click.prevent="saveDesignation('popular', false)"
                    >Remove from Popular</v-btn
                  >
                </span>
                <span v-else>
                  <v-btn
                    color="teal white--text"
                    v-if="visibility === 'public'"
                    :disabled="!photos || photos.length == 0"
                    class="mr-1"
                    small
                    @click.prevent="saveDesignation('popular', true)"
                  >
                    Set as Popular
                    <v-icon right>star</v-icon>
                  </v-btn>
                  <small v-if="!photos || photos.length == 0"
                    >Missing photo</small
                  >
                </span>
              </v-col>
              <v-col cols="6" sm="4" md="3" lg="2">
                <!-- TAKEIN EXCLUSIVE SECTION -->
                <span v-if="dish && takein_exclusive">
                  <v-btn
                    text
                    class="mr-1"
                    small
                    color="grey"
                    @click.prevent="saveDesignation('takein_exclusive', false)"
                    >Remove from TakeIn Exclusive</v-btn
                  >
                </span>
                <span v-else>
                  <v-btn
                    color="green  white--text"
                    v-if="visibility === 'public'"
                    :disabled="!photos || photos.length == 0"
                    class="mr-1"
                    small
                    @click.prevent="saveDesignation('takein_exclusive', true)"
                  >
                    TakeIn Exclusive
                    <v-icon right>fastfood</v-icon>
                  </v-btn>
                </span>
              </v-col>
              <v-col cols="6" sm="4" md="3" lg="2">
                <!-- NEW SECTION -->
                <span v-if="dish && new_category">
                  <v-btn
                    text
                    color="grey"
                    class="mr-1"
                    small
                    @click.prevent="saveDesignation('new', false)"
                    >Remove from New</v-btn
                  >
                </span>
                <span v-else>
                  <v-btn
                    color="light-green"
                    v-if="visibility === 'public'"
                    dark
                    class="mr-1"
                    small
                    @click.prevent="saveDesignation('new', true)"
                  >
                    Set as New
                    <v-icon right>new_releases</v-icon>
                  </v-btn>
                </span>
              </v-col>
            </v-row>

            <v-row align="center">
              <v-col cols="8" sm="4" lg="2">
                <v-btn
                  color="primary"
                  :disabled="!isNameValid"
                  id="dishSaveDetail"
                  @click="saveAndGetFoodId"
                  class="mr-1"
                  >Save</v-btn
                >
                <v-btn
                  small
                  color="gray"
                  text
                  id="dishSaveDetailCancel"
                  :to="'/product/list/' + uid"
                  class="mr-3"
                  >Cancel</v-btn
                >
              </v-col>

              <template v-if="visibility === 'public'">
                <v-col
                  cols="6"
                  sm="2"
                  md="1"
                  v-if="isNew && followersCount > 0"
                >
                  <v-btn icon>
                    <v-icon large color="success" @click="notifyNewDish"
                      >notifications_none</v-icon
                    >
                  </v-btn>
                </v-col>
                <v-col cols="6" sm="4" md="1">
                  <v-menu
                    open-on-hover
                    top
                    offset-y
                    v-if="visibility === 'public'"
                  >
                    <template v-slot:activator="{ on }">
                      <v-btn color="secondary" text id="dishShare" v-on="on">
                        <v-icon>share</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <vue-goodshare-facebook
                        data-size="large"
                        title_social="Facebook"
                        has_counter
                        :page_url="shareLink"
                        has_icon
                      ></vue-goodshare-facebook>
                      <vue-goodshare-pinterest
                        title_social="Pinterest"
                        has_counter
                        :page_image="firstPhoto"
                        :page_description="dish.name"
                        :page_url="shareLink"
                        has_icon
                      ></vue-goodshare-pinterest>

                      <vue-goodshare-twitter
                        title_social="Twitter"
                        :page_url="shareLink"
                        :page_title="shareDesc"
                        has_counter
                        has_icon
                      ></vue-goodshare-twitter>
                    </v-list>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="6">
                  <span v-if="$vuetify.breakpoint.smAndUp">
                    {{ shareLink }}</span
                  >
                </v-col>
              </template>

              <v-badge v-if="false && orderCount" class="mr-6" overlap v>
                <template v-slot:badge>
                  <span class="white--text">{{ orderCount }}</span>
                </template>
                <v-icon color="info lighten-1" @click.prevent="viewDishOrders"
                  >shopping_cart</v-icon
                >
              </v-badge>
            </v-row>
          </v-stepper-content>
          <v-stepper-step
            step="2"
            :editable="id != null"
            :complete="cookStep > 2"
            :rules="[rules.priceStep]"
            >{{ $t('Chef.price_title') }}</v-stepper-step
          >
          <v-stepper-content step="2" v-if="id" :rules="[rules.priceStep]">
            <product-price
              :uid="uid"
              :unitPrice="price"
              :status="status"
              :promotion="promotion"
              :amountLeft="amountLeft"
              :leadTime="leadTime"
              :preOrderDeadline="preOrderDeadline"
              :deadlineUnit="deadlineUnit"
              :hideNotAvailable="hideNotAvailable"
              :minimumOrder="minimumOrder"
              @onUpdatePrice="onUpdatePrice"
              @savePrice="savePrice"
            />
            <!-- <v-btn small
                   color="primary"
                   id="dishSavePrice"
                   :disabled="!valid"
                   @click="savePrice">Save Price</v-btn>
            <v-btn small
                   color="gray"
                   text
                   id="dishSavePriceCancel"
            to="/product/list">Cancel</v-btn>-->
          </v-stepper-content>
          <!-- took out :rules="[rules.photoStep]" -->
          <v-stepper-step
            step="3"
            :editable="id != null"
            :complete="cookStep > 3 && photos.length > 0"
          >
            {{ $t('Chef.photos_title') }}
            <small class="font-weight-bold">Helps buyers to decide</small>
          </v-stepper-step>
          <!-- took out :rules="[rules.photoStep]" -->
          <v-stepper-content step="3">
            <v-row justify="center" no-gutters>
              <v-col v-if="id" cols="12" md="6">
                {{ $t('Common.upload_food_photo') }}
                <media-uploader @uploadMedia="uploadMedia" uploadType="media" />
              </v-col>
            </v-row>

            <v-row>
              <v-container>
                <draggable
                  :list="photos"
                  class="d-flex flew-row overflow-auto"
                  @change="orderChange"
                  handle=".card-handle"
                >
                  <div v-for="(image, key) in photos" :key="key">
                    <v-card
                      v-if="media(image)"
                      width="360"
                      height="240"
                      class="ma-4 pa-0 photo-card"
                      :key="key"
                      @mouseover="mouseOverOnCard(key)"
                      @mouseleave="mouseLeaveOnCard(key)"
                    >
                      <div
                        v-if="image.type === 'youtube'"
                        class="d-flex justify-center"
                      >
                        <iframe
                          width="360"
                          height="240"
                          :src="youtubeUrl(image.url)"
                          frameborder="0"
                          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                          allowfullscreen
                        ></iframe>
                      </div>
                      <div
                        v-if="
                          media(image)
                            .toLowerCase()
                            .indexOf('mp4') > 0
                        "
                      >
                        <video
                          playsinline
                          autoplay
                          controls
                          muted
                          ref="video"
                          class="video-card"
                        >
                          <source :src="media(image)" type="video/mp4" />
                        </video>
                      </div>
                      <div v-else>
                        <v-img :src="media(image)" class="image-card"></v-img>
                      </div>
                      <div v-if="card_hovered[key]" class="card-handle">
                        <v-btn
                          color="white"
                          outlined
                          id="dishDeletePhoto"
                          @click="onDeletePhoto(image)"
                          class="red--text"
                        >
                          <b>Delete</b>
                        </v-btn>
                      </div>
                    </v-card>
                  </div>
                </draggable>
              </v-container>
            </v-row>
            <v-row>
              <v-col cols="6" lg="4"
                >YouTube link
                <v-btn
                  icon
                  class="px-2"
                  @click="showAddYoutube = !showAddYoutube"
                >
                  <v-icon color="red" large>fab fa-youtube</v-icon>
                </v-btn>
              </v-col>
              <v-col cols="6" lg="4">
                <dish-photos
                  :id="id"
                  :uid="uid"
                  :query="name"
                  @onWebPhotoSelected="onWebPhotoSelected"
                />
              </v-col>
            </v-row>
            <template v-if="dish">
              <v-row v-if="showAddYoutube" align="center">
                <v-col cols="7" md="4">
                  <v-text-field
                    label="Youtube Link"
                    v-model="youtubeLink"
                  ></v-text-field>
                </v-col>
                <v-col cols="5">
                  <v-btn small @click="uploadYoutube" color="info" class="mx-2"
                    >Add</v-btn
                  >
                  <v-btn small @click="showAddYoutube = false">Cancel</v-btn>
                </v-col>
              </v-row>
              <v-row justify="start" align="center" v-if="false">
                <v-col cols="12" sm="6" md="4">
                  <!-- <v-chip :key="tag"
                        close
                  v-for="tag in dish.tags">{{tag}}</v-chip>-->

                  <!-- SUGGESTED TAGS COMBOBOX -->
                  <v-combobox
                    v-model="tags"
                    :items="dish.tags"
                    label="Add tags to help find your products."
                    chips
                    :delimiters="[',', ';']"
                    clearable
                    multiple
                  >
                    <template slot="selection" slot-scope="data">
                      <v-chip
                        :selected="data.selected"
                        close
                        @input="remove(data.item)"
                      >
                        <strong>{{ data.item }}</strong
                        >&nbsp;
                      </v-chip>
                    </template>
                  </v-combobox>
                </v-col>
                <v-col cols="12" sm="6" md="4"> </v-col>
              </v-row>

              <v-row justify="space-between" class="my-2" no-gutters>
                <v-col cols="12" sm="5" md="4">
                  <!-- OTHER DISH SUGGESTIONS -->
                  <suggested-items
                    :dishList="dishList"
                    :dish="dish"
                    type="suggested_products"
                    :hint="$t('Common.suggest_dishitem_hint')"
                  />
                </v-col>

                <v-col cols="12" sm="5" md="4">
                  <!-- OTHER MENU SUGGESTIONS -->
                  <suggested-items
                    :menus="menus"
                    :dish="dish"
                    type="suggested_menus"
                    :hint="$t('Common.suggest_menu_hint')"
                  />
                </v-col>
                <v-col cols="12" md="3" v-if="dish.menus">
                  <v-sheet width="100%">
                    <span class="caption d-flex ">Menus</span>
                    <template v-for="menu in Object.keys(dish.menus)">
                      <v-chip
                        class="mr-2"
                        color="success"
                        :key="menu"
                        outlined
                        small
                        :to="`/product/menu/${uid}/editmenu/${menu}`"
                      >
                        <span class="body-2">{{ dish.menus[menu].name }}</span>
                      </v-chip>
                    </template>
                  </v-sheet>
                </v-col>
              </v-row>
            </template>
            <v-btn
              color="primary"
              id="dishContiue"
              @click="onSubmitFood"
              class="mr-3 mb-3"
              >Save</v-btn
            >
            <v-btn
              small
              color="gray"
              class="mb-3"
              text
              id="dishCancelPhoto"
              :to="'/product/list/' + uid"
              >Cancel</v-btn
            >
          </v-stepper-content>
        </v-stepper>
      </v-col>
      <v-row>
        <v-col cols="12" v-if="id">
          <v-btn
            small
            fab
            dark
            fixed
            top="top"
            right="right"
            class="setting-fab"
            color="red"
            @click="openFoodAttributes"
          >
            <v-icon>apps</v-icon>
          </v-btn>
          <v-navigation-drawer
            class="setting-drawer"
            temporary
            right
            v-model="rightDrawer"
            hide-overlay
            width="270"
            fixed
          >
            <v-list class="dropdown-list">
              <v-list-item @click="printLabel">
                <v-list-item-title>
                  <v-icon class="mr-3">print</v-icon>
                  <span>Print Label</span>
                </v-list-item-title>
              </v-list-item>
              <v-list-item :to="'/product/' + this.id + '/modifiers'">
                <v-list-item-title>
                  <v-icon class="mr-3">fas fa-cogs</v-icon>
                  <span>{{ $t('Common.modifiers') }}</span>
                </v-list-item-title>
              </v-list-item>
              <v-list-item v-if="visibility === 'public'">
                <v-list-item-title>
                  <a target="_blank" :href="shareLink">
                    <v-icon class="mr-3">link</v-icon>
                  </a>
                  <span>View in TakeIn</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item @click="confirmDelete = true">
                <v-list-item-title>
                  <v-icon color="red" class="mr-3">delete</v-icon>
                  <span>Delete</span>
                </v-list-item-title>
              </v-list-item>
              <v-divider />
              <v-list-item>
                <v-list-item-title class="caption">
                  <v-icon color="red" small class="mr-2">shopping_cart</v-icon>
                  Total orders: {{ orderCount }}
                  <br />
                  <v-icon color="red" small class="mr-2">far fa-heart</v-icon>
                  Likes:
                  {{ favoriteCount }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
            <!-- <product-attributes @saveAttributes="saveAttributes" :id="id" /> -->
          </v-navigation-drawer>
        </v-col>
      </v-row>
    </v-row>

    <!-- when designation is full -->
    <v-row>
      <v-snackbar :timeout="6000" color="red" v-model="designationSnackbar">
        {{ designationMsg }}
        <v-btn color="white" text @click="designationSnackbar = false"
          >Ok</v-btn
        >
      </v-snackbar>
    </v-row>

    <v-dialog v-model="confirmDelete" v-if="id" persistent max-width="350">
      <v-card>
        <v-card-title class="headline">Delete Dish?</v-card-title>
        <v-card-text>
          The dish will be removed from your dish list permanently.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            dark
            text
            @click.native="confirmDelete = false"
            >Disagree</v-btn
          >
          <v-btn
            color="green darken-1"
            text
            id="dishDeleteConfirm"
            @click="onDelete(id)"
            >Agree</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="deleteError" v-if="id" persistent max-width="400">
      <v-card>
        <v-card-title class="headline">Cannot Delete Dish</v-card-title>
        <v-card-text>{{ deleteErrorText }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" flat @click.native="deleteError = false"
            >Ok</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- dialog for invalid picture upload (gif first) -->
    <v-dialog v-model="gifDialog" width="500px">
      <v-card>
        <v-card-title class="py-4">
          <v-icon color="grey">error</v-icon>Must have an Uploaded Picture
          First!
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="gifDialog = false">Ok</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- image upload success dialogue -->
    <v-dialog v-model="uploadProgress" width="700px">
      <v-card>
        <v-card-title class="mx-2">
          <v-icon large color="success">check</v-icon>
          <h4 class="ml-1 fs-16">
            Image Successfully Uploaded! Please Allow Time for the Photo to
            Appear.
          </h4>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="uploadProgress = false">Ok</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <delete-confirmation-dialog
      ref="notifyNewConfirmationDialog"
      heading="Notify new dish to your followers"
      message="Are you sure you want to send notification to your followers?"
      @onConfirm="onNotifyNewDish"
    ></delete-confirmation-dialog>
    <pagination-alert
      ref="paginationAlert"
      heading="Warning"
      message="Are you sure you want to leave this page without saving contents?"
    ></pagination-alert>
  </v-container>
</template>

<script>
import VueGoodshareFacebook from 'vue-goodshare/src/providers/Facebook.vue'
import VueGoodsharePinterest from 'vue-goodshare/src/providers/Pinterest.vue'
import VueGoodshareReddit from 'vue-goodshare/src/providers/Reddit.vue'
import VueGoodshareTwitter from 'vue-goodshare/src/providers/Twitter.vue'
import IngredientList from '../cook/IngredientList'
import ProductPrice from './ProductPrice'
import ProductStatus from './ProductStatus'
import DishPhotos from './DishPhotos'
import FoodOrigin from './FoodOrigin'
import DietOptions from './DietOptions'
import MissingAddress from './MissingAddress'
import SuggestedItems from './SuggestedItems'
import moment from 'moment'

import { globalMixin } from 'fod-core'

import { mapGetters } from 'vuex'
import marked from 'marked'
import draggable from 'vuedraggable'
const domain = process.env.VUE_APP_DOMAIN
const now = moment.utc()
export default {
  mixins: [globalMixin],

  components: {
    draggable,
    MediaUploader: () =>
      import('fod-core/src/components/common/MediaUploader.vue'),
    PaginationAlert: () =>
      import('fod-core/src/components/common/PaginationAlert.vue'),
    VueGoodshareFacebook,
    VueGoodsharePinterest,
    VueGoodshareReddit,
    DishHelp: () => import('./DishHelp'),
    //'food-label': () => System.import('./FoodLabel')
    VueGoodshareTwitter,
    IngredientList,
    ProductPrice,
    ProductStatus,
    DishPhotos,
    MissingAddress,
    FoodOrigin,
    DietOptions,
    SuggestedItems
  },
  data() {
    return {
      card_hovered: [],
      uid: null,
      uploadProgress: false,
      dish: null,
      cookStep: 1,
      fromDishList: false,
      id: null,
      name: '',
      tags: [],
      price: 0,
      status: 'draft',
      visibility: null,
      description: '',
      photos: [],
      hideWarn: true,
      localPhotos: [],
      ingredients: null,
      categories: null,
      netWeight: 0,
      weightUnit: 'oz',
      amountLeft: null,
      minimumOrder: null,
      confirmDelete: false,
      rightDrawer: false,
      valid: true,
      leadTime: null,
      preOrderDeadline: null,
      deadlineUnit: 'Day',
      promotion: null,
      isNameValid: false,
      designationSnackbar: false,
      designationMsg: null,
      deleteError: false,
      deleteErrorText: '',
      isEditable: false,
      gifDialog: false,
      youtubeLink: null,

      popular: false,
      takein_exclusive: false,
      new_category: false,
      popular_num: 0,
      takein_exclusive_num: 0,
      new_category_num: 0,
      hideNotAvailable: null,
      sub_menus: [],
      suggested_menus: [],
      suggested_products: [],
      showAddYoutube: false,
      rules: {
        required: value => !!value || 'Required.',
        step1: () => !!this.name || this.cookStep <= 1,
        // dont think this is require if images are not required
        // photoStep: () => {
        //   return this.photos != null && this.photos.length > 0
        // },
        priceStep: () => {
          return (
            (this.price != null && this.price > 0) ||
            (this.amountLeft != null &&
              (this.amountLeft > 0 || this.amountLeft == -1))
          )
        },
        finalStep: () => !isNaN(this.price)
      }
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
      loading: 'loading',
      dishList: 'dishList',
      dietList: 'diets',
      cuisineList: 'cuisineList',
      dish2: 'dish',
      myProfile: 'profile',
      foodCategories: 'foodCategories',
      currentDiets: 'currentDiets',
      currentOrigins: 'currentOrigins',
      chefApprovalStatus: 'chefApprovalStatus',
      activeOrders: 'activeOrders',
      menus: 'menus',
      suggestedProducts: 'suggestedProducts',
      suggestedMenus: 'suggestedMenus',
      isAdmin: 'isAdmin',
      userProfile: 'userProfile'
    }),
    compiledMarkdown() {
      return this.description
        ? marked(this.description, { sanitize: true })
        : ''
    },
    categoryList() {
      if (this.foodCategories) {
        return this.foodCategories.map(c => c.name)
      }
    },
    orderCount() {
      return this.dish && this.dish.meta ? this.dish.meta.orderCount || 0 : 0
    },
    favoriteCount() {
      return this.dish && this.dish.meta ? this.dish.meta.favoriteCount || 0 : 0
    },
    firstPhoto() {
      return this.photos && this.photos.length > 0
        ? this.photos[0].url || this.photos[0].imageUrl
        : null
    },

    shareDesc() {
      return 'Check out ' + this.dish.name + ' from TakenIn!'
    },
    shareLink() {
      return this.dish && this.dish.slug
        ? `https://${domain}/product/` + this.dish.slug
        : null
    },
    hasEmptyDishList() {
      return this.dishList == null && !!this.isNew
    },
    isNew() {
      return (
        this.dish &&
        this.dish.createdTime &&
        moment(this.dish.createdTime)
          .add(7, 'd')
          .isAfter(now)
      )
    },
    followersCount() {
      return this.profile &&
        this.profile.meta &&
        this.profile.meta.stats &&
        this.profile.meta.stats.followers
        ? this.profile.meta.stats.followers
        : 0
    },
    profile() {
      if (this.$route.params.uid) {
        return this.userProfile
      }
      return this.myProfile
    }
  },

  async mounted() {
    this.id = this.$route.params.id

    await this.loadProduct()
  },
  beforeDestroy() {
    this.$store.dispatch('setDish', null)
    this.$store.dispatch('updateDiets', [])
    this.$store.dispatch('updateOrigins', [])
    this.$store.dispatch('updateSuggestedProducts', [])
    this.$store.dispatch('updateSuggestedMenus', [])
  },

  watch: {
    cookStep: function() {
      console.log('Current Cook Step:', this.cookStep, this.id)
      // a new food id is missing, lets get that from store
      if (!this.id) {
        let food = this.$store.getters.dish
        console.log('store food', food)
        if (food && food.id) {
          this.id = this.$store.getters.food.id
        }
      }
    },
    dish2: function() {
      if (this.dish2) {
        this.loadDish(this.dish2)
      }
    }
  },
  methods: {
    async loadProduct() {
      if (this.dish) {
        console.info('Product is already loaded')
        return
      }
      await this.$store.dispatch('checkAdmin')

      this.uid =
        this.isAdmin && this.$route.params.uid
          ? this.$route.params.uid
          : this.myProfile
          ? this.myProfile.id
          : null
      if (!this.uid) {
        console.warn(`uid is missing to load product: ${this.id}`)
        return
      }
      await this.$store.dispatch('loadUserProfile', { uid: this.uid })
      this.$store.dispatch('updateDiets', [])
      this.$store.dispatch('updateOrigins', [])
      this.$store.dispatch('updateSuggestedProducts', [])
      this.$store.dispatch('updateSuggestedMenus', [])

      if (this.id && this.id !== 'new') {
        await this.$store.dispatch('getDishById', { id: this.id })
      } else {
        this.ingredients = []
      }
      if (!this.dishList) {
        // will be used to show help
        this.$store.dispatch('loadDishList', { uid: this.uid })
      }
      //this.$store.dispatch('loadDishList')
      /*if (dishId && dishId !== 'new') {
      if (this.dishList) {
        this.loadDishById(dishId)
      } else {
        this.$store
          .dispatch('findDishById', dishId)
          .then(data => this.loadDish(data))
      }
    } else {
      this.ingredients = []
    }*/
      this.$store.dispatch('loadUserIngredients')
      console.log('Setting cookStep to ', this.cookStep)

      if (this.dishList) {
        this.dishList.forEach(dish => {
          if (dish.popular) {
            this.popular_num += 1
          }
          if (dish.takein_exclusive) {
            this.takein_exclusive_num += 1
          }
          if (dish.new) {
            this.new_category_num += 1
          }
        })
      }
      // load default info if it doesn't exist
      if (!this.foodCategories) {
        await this.$store.dispatch('loadFoodTypes')
      }

      if (!this.menus) {
        this.$store.dispatch('loadMenus', { uid: this.uid })
      }
    },
    orderChange(evt) {
      console.log(evt.moved, 'order changed!')
      let updated = this.photos
        .filter(p => p.position !== evt.moved.oldIndex)
        .map(p =>
          p.position === evt.moved.newIndex
            ? { ...p, position: evt.moved.oldIndex }
            : p
        )
      console.log(updated, 'removed!')
      const newIndexedPhoto = {
        ...evt.moved.element,
        position: evt.moved.newIndex
      }
      updated = [...updated, newIndexedPhoto].sort(
        (a, b) => a.position - b.position
      )
      this.photos = updated
      console.log(this.photos, 'after changed')
    },
    mouseOverOnCard(k) {
      this.card_hovered = this.card_hovered.map((d, p) => (p === k ? true : d))
    },
    mouseLeaveOnCard(k) {
      this.card_hovered = this.card_hovered.map((d, p) => (p === k ? false : d))
    },
    uploadMedia: function(file, img) {
      this.uploadProgress = true
      let id = this.id || ''
      this.$store.dispatch('setLoading', true)
      this.$store.dispatch('uploadDishPhoto', {
        id: id,
        uid: this.uid,
        index: Math.random()
          .toString(36)
          .substring(8),
        image: img,
        file: file,
        position: this.photos.length
      })
      const that = this
      setTimeout(function() {
        that.$store
          .dispatch('loadDishList', { uid: this.uid })
          .then(dishlist => {
            let matched = dishlist.filter(dish => dish.id === id)[0]
            if (matched) {
              that.loadDish(matched)
            }
          })
      }, 3500)
    },
    alert(message) {
      alert(message)
    },
    blurFoodDescription() {
      this.isEditable = false
    },
    media(media) {
      return media.url || media.imageUrl
    },
    checkName() {
      if (!this.name) {
        this.isNameValid = false
        return 'Name is required'
      }
      if (this.name && this.name.trim().length < 5) {
        this.isNameValid = false
        return 'Name must be more than 5 characters'
      }
      if (!this.id && this.dishList) {
        if (this.dishList.find(d => d.name == this.name)) {
          this.isNameValid = false
          return 'Dish name already exists'
        }
      }
      this.isNameValid = true
      return true
    },
    uploadYoutube() {
      console.log('calling upload youtube')
      this.$store.dispatch('uploadMediaUrl', {
        type: 'youtube',
        id: this.id,
        url: this.youtubeLink
      })
    },
    loadDish(dish) {
      this.dish = dish
      this.id = dish.id
      this.name = dish.name
      this.tags = dish.tags
      this.status = dish.status
      this.visibility = dish.visibility
      this.price = dish.price
      this.description = dish.description
      this.netWeight = dish.netWeight
      this.weightUnit = dish.weightUnit || 'oz'
      this.amountLeft = dish.amountLeft
      this.minimumOrder = dish.minimumOrder
      this.promotion = dish.promotion
      //this.diets = dish.diets || []
      this.categories = dish.categories || []
      this.ingredients = dish.ingredients || []
      this.photos = dish.photos || []
      if (this.photos.length > 0) {
        if (this.photos[0].position) {
          this.photos = this.photos.sort((a, b) => a.position - b.position)
        } else {
          this.photos = this.photos.map((p, k) => ({ ...p, position: k }))
        }
      }
      this.card_hovered = this.photos.map(() => false)
      this.preOrderDeadline = dish.preOrderDeadline
      this.deadlineUnit = dish.deadlineUnit

      this.localPhotos = []
      this.popular = dish.popular || false
      this.takein_exclusive = dish.takein_exclusive || false
      this.new_category = dish.new || false
      this.hideNotAvailable = dish.hideNotAvailable || null
      this.suggested_products = dish.suggested_products || null
      this.suggested_menus = dish.suggested_menus || null

      this.leadTime = dish.leadTime

      if (
        !this.leadTime &&
        this.profile.settings &&
        this.profile.settings.defaultLeadTime
      ) {
        this.leadTime = this.profile.settings.defaultLeadTime
      }
      this.leadTime = this.leadTime || 10
      /*if (dish.suggested_tags) {
        this.tags = this.tags || []
        dish.suggested_tags.forEach(element => {
          this.tags.push(element)
        })
      }*/

      this.$store.dispatch('setDish', dish)
    },
    loadDishById(foodId) {
      if (this.dishList) {
        let dishes = this.dishList.filter(dish => dish.id === foodId)
        if (dishes && dishes.length > 0) {
          let dish = dishes[0]
          this.loadDish(dish)
        }
      } else {
        console.warn('Odd, the dish list empty')
      }
    },
    uploadAndContinue() {
      // if (!this.photos || this.photos.length === 0) {
      //   alert("You haven't upload the photo yet!")
      //   return
      // }

      let food = {
        id: this.id,
        uid: this.uid,
        tags: this.tags || [],
        photos: this.photos
      }
      this.$store.dispatch('saveDish', {
        food: food
      })
      this.cookStep = 4
    },
    // return true if category max has not been met
    _canHaveDesignation(designation, flag) {
      // if user is removing/adding the item from category
      let count = flag ? 1 : -1

      // only allow for 3 popular, 3 takein exclusive, and 1 new
      if (designation === 'popular') {
        this.popular_num += count
        return this.popular_num <= 3
      }
      if (designation === 'takein_exclusive') {
        this.takein_exclusive_num += count
        return this.takein_exclusive_num <= 3
      }
      if (designation === 'new') {
        this.new_category_num += count
        return this.new_category_num <= 1
      }
      return true
    },
    saveDesignation(designation, flag) {
      // numberOfDesignations returns true if there is enough room for the designation
      if (this._canHaveDesignation(designation, flag)) {
        switch (designation) {
          case 'popular':
            this.popular = flag
            break
          case 'takein_exclusive':
            this.takein_exclusive = flag
            break
          case 'new':
            this.new_category = flag
            break
        }
      } else {
        switch (designation) {
          case 'popular':
            this.designationMsg =
              'You cannot have more than 3 popular products!'
            break
          case 'takein_exclusive':
            this.designationMsg =
              'You cannot have more than 3 TakeIn Exclusive products!'
            break
          case 'new':
            this.designationMsg = 'You cannot have more than 1 new product!'
            break
          default:
            this.designationMsg = 'Unknown Error'
            break
        }
        this.designationSnackbar = true
      }
    },
    saveAndGetFoodId() {
      let food = {
        id: this.id,
        uid: this.uid,
        name: this.name,
        description: this.description,
        netWeight: parseFloat(this.netWeight),
        weightUnit: this.weightUnit || 'oz',
        diets: this.currentDiets,
        origins: this.currentOrigins,
        categories: this.categories,
        visibility: this.visibility,
        status: this.status,
        ingredients: this.ingredients,
        updatedTime: new Date().getTime(),
        popular: this.popular,
        takein_exclusive: this.takein_exclusive,
        new: this.new_category,
        suggested_products: this.suggestedProducts || null,
        suggested_menus: this.suggestedMenus || null
      }
      this.$store.dispatch('saveDish', { food: food }).then(id => {
        this.cookStep++
        if (id) {
          this.id = id
          //this.$router.replace(id)
        }
      })
    },
    async onSubmitFood() {
      let food = {
        id: this.id,
        uid: this.uid,
        price: parseFloat(this.price),
        status: this.status,
        visibility: this.visibility,
        name: this.name,
        description: this.description,
        netWeight: this.netWeight,
        weightUnit: this.weightUnit,
        diets: this.currentDiets,
        origins: this.currentOrigins,
        amountLeft: parseInt(this.amountLeft),
        //minimumOrder: parseInt(this.minimumOrder),
        preOrderDeadline: this.preOrderDeadline,
        promotion: this.promotion || null,
        deadlineUnit: this.deadlineUnit || null,
        leadTime: this.leadTime,
        categories: this.categories,
        ingredients: this.ingredients,
        tags: this.tags || [],
        updatedTime: new Date().getTime(),
        popular: this.popular,
        takein_exclusive: this.takein_exclusive,
        new: this.new_category,
        // submitted shows all the basic info has been set. W/o it, prodcuer won't be able to set the dish as public
        submitted: true,
        hideNotAvailable: this.hideNotAvailable || null,
        suggested_products: this.suggestedProducts || null,
        suggested_menus: this.suggestedMenus || null
      }

      this.$store.dispatch('saveDish', {
        food: food
      })
      this.$store.dispatch('setMessage', { title: 'Saved', body: '' })
      //this.$router.push(`/product/list/${this.uid}`)
    },
    savePrice() {
      let food = {
        id: this.id,
        price: parseFloat(this.price),
        amountLeft: parseInt(this.amountLeft),
        minimumOrder: parseInt(this.minimumOrder),
        preOrderDeadline: parseInt(this.preOrderDeadline),
        deadlineUnit: this.deadlineUnit || null,
        leadTime: parseInt(this.leadTime),
        promotion: this.promotion || null,
        updatedTime: new Date().getTime(),
        hideNotAvailable: this.hideNotAvailable || null,
        uid: this.uid
      }
      this.$store.dispatch('saveDish', {
        food: food
      })
      this.cookStep = 3
    },
    // checks if producer can be deleted
    async productCanBeDeleted(id) {
      // check if active order has product
      await this.$store.dispatch('loadOrders')
      for (let order of this.activeOrders) {
        if (order.products.some(product => product.dish.id == id)) {
          this.deleteErrorText =
            'There are active orders for this product. You may remove from Marketplace but not delete until order is fulfilled.'
          this.deleteError = true
          return false
        }
      }
      // check if dish is still in marketplace
      if (this.visibility === 'public') {
        this.deleteErrorText =
          'Dish must be taken off the marketplace before deletion.'
        this.deleteError = true
        return false
      }
      return true
    },
    onDelete(id) {
      if (this.productCanBeDeleted(id)) {
        // if all the conditions are met for dish to be deleted
        this.$store.dispatch('deleteDish', id)
        this.confirmDelete = false
        this.$router.push('/product/list')
      } else {
        this.confirmDelete = false
      }
    },
    onDeletePhoto(image) {
      this.photos = this.photos.filter(i => i !== image)
      console.log('delete photos', this.photos)
      let food = {
        id: this.id,
        photos: this.photos
      }
      this.$store.dispatch('deletePhoto', food)
    },
    printLabel() {
      console.log('Print Label', this.id)
      this.$router.push('/dish/label/' + this.id)
    },

    onUpdateIngredients(ingredients) {
      this.ingredients = ingredients
    },
    onUpdatePrice(data) {
      this.price = data.unitPrice
      this.amountLeft = data.amountLeft
      this.minimumOrder = data.minimumOrder
      this.leadTime = data.leadTime
      this.preOrderDeadline = data.preOrderDeadline
      this.deadlineUnit = data.deadlineUnit
      this.promotion = data.promotion
      this.hideNotAvailable = data.hideNotAvailable || null
    },
    onUpdateStatus(data) {
      this.status = data
      if (data === 'available') {
        if (!this.amountLeft || this.amountLeft == 0) {
          this.$store.dispatch('setMessage', {
            title: 'Total Serving',
            body: 'Please update Total Serving. Currently it is 0'
          })
        }
      } else if (data === 'sold out' && this.amountLeft) {
        if (this.amountLeft > 0) {
          this.$store.dispatch('setMessage', {
            title: 'Amount Left',
            body: `There is ${this.amountLeft} still left. Please set it to zero before continuing.`
          })
        }
        if (this.amountLeft == -1) {
          this.$store.dispatch('setMessage', {
            title: 'Amount Left',
            body: `The inventory is set to unlimited. Please set it to zero before continuing.`
          })
        }
      }

      let food = {
        id: this.id,
        uid: this.uid,
        status: this.status
      }
      this.$store.dispatch('saveDishStatus', {
        food: food
      })
    },
    onUpdateVisibility(data = 'public') {
      this.visibility = data

      let food = {
        id: this.id,
        uid: this.uid,
        visibility: this.visibility || 'public'
      }
      this.$store.dispatch('saveDish', { food })
    },
    onWebPhotoSelected(url) {
      if (url) {
        this.photos.push({ imageUrl: url, type: 'external' })
        this.$store.dispatch('saveDish', {
          food: { uid: this.uid, id: this.id, photos: this.photos }
        })
      }
    },
    openFoodAttributes() {
      this.$vuetify.goTo(0)
      this.rightDrawer = !this.rightDrawer
    },
    saveAttributes() {
      this.rightDrawer = false
    },
    viewDishOrders() {
      this.$router.push('/order/list?dish_id=' + this.dish.id)
    },
    youtubeUrl(url) {
      if (!url) return null

      var regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
      var match = url.match(regExp)
      if (match && match[2].length == 11) {
        return `https://www.youtube.com/embed/${match[2]}`
      }
      return null
    },
    notifyNewDish() {
      this.$refs.notifyNewConfirmationDialog.openDialog()
    },
    onNotifyNewDish() {
      this.$refs.notifyNewConfirmationDialog.close()
      this.$store.dispatch('sendNotification', {
        id: this.id,
        status: 'new',
        type: 'new_dish',
        uid: this.user.id,
        createdTime: now.valueOf()
      })
      this.$store.dispatch('setMessage', { title: 'Notification is scheduled' })
    }
  }
}
</script>
<style scoped>
.v-avatar {
  display: flex;
  width: 100%;
  height: 300px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border: 5px solid gainsboro;
  background-color: #eeeeee;
  overflow: hidden;
}
.v-avatar-empty {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 300px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border: 5px solid gainsboro;
  background-color: #eeeeee;
  overflow: hidden;
}
.image-card {
  width: 100%;
  height: 240px;
  border-radius: 3px;
}
.video-card {
  width: 100%;
  min-height: 240px;
  padding: 0;
  border-radius: 3px;
}
.file-input {
  display: none;
}
.clipper-fixed {
  width: 100%;
  height: 300px;
}
.preview-image {
  border: gainsboro 5px solid;
  border-radius: 5px;
  overflow: hidden;
  width: 300px;
  height: 168px;
}
.setting-fab {
  top: 50% !important;
  right: 0;
  border-radius: 0;
}
.setting-fab .page-wrapper {
  min-height: calc(100vh - 64px - 50px - 81px);
}

.word-breaking {
  word-break: normal !important;
}
.card-handle {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 3px;
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: move;
}
</style>
