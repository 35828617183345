<template>
  <div>
    <v-row>
      <v-col cols="12">
        <h5 class="font-weight-bold">Communities</h5>
        <v-select
          :items="baseCommunities"
          v-model="comunityIDs"
          item-text="name"
          item-value="id"
          multiple
        >
          <template v-slot:no-data
            ><v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  No Available Communities
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item></template
          >
        </v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="text-center">
        <v-btn color="secondary" @click="saveCommunities"
          >Save Communities</v-btn
        >
      </v-col></v-row
    >
  </div>
</template>

<script>
import firebase from '@/firebase-init'
export default {
  props: {
    profile: {
      type: Object,
      required: true,
      default: null
    },
    baseCity: {
      type: Object,
      default: null
    }
  },
  watch: {
    baseCity(nv, ov) {
      if (!ov || this.defaultCityCodes.includes(nv.city_code)) {
        this.comunityIDs = this.defaultIDs
      } else {
        this.comunityIDs = []
      }
      if (nv) {
        this.baseCommunities = this.communities.filter(
          com => com.city_code === nv.city_code
        )
      } else {
        this.baseCommunities = []
      }
    }
  },
  data() {
    return {
      communities: [],
      baseCommunities: [],
      comunityIDs: [],
      defaultIDs: [],
      defaultCityCodes: []
    }
  },
  mounted() {
    this.defaultIDs = this.profile.communities ? this.profile.communities : []
    this.comunityIDs = this.defaultIDs
    firebase
      .firestore()
      .collection('community')
      .get()
      .then(dataSnapshot => {
        if (!dataSnapshot.empty) {
          dataSnapshot.forEach(data => {
            if (data.exists) {
              const community = data.data()
              community.id = data.id
              this.communities.push(community)
            }
          })
          if (this.baseCity) {
            this.baseCommunities = this.communities.filter(
              com => com.city_id === this.baseCity.id
            )
          }
          this.defaultCityCodes = this.communities
            .filter(c => this.defaultIDs.includes(c.id))
            .map(c => c.city_code)
        }
      })
      .catch(error => {
        console.error(error)
      })
  },
  methods: {
    saveCommunities() {
      if (this.profile) {
        this.defaultCityCodes = [this.baseCity.city_code]
        firebase
          .firestore()
          .collection('profile')
          .doc(this.profile.id)
          .update({
            communities: this.comunityIDs
          })
          .then(() => {
            this.$store.dispatch('setMessage', {
              title: 'Saved',
              body: 'Communities saved'
            })
            this.defaultIDs = this.comunityIDs
          })
          .catch(e => console.error(e))
      }
    }
  }
}
</script>
