<template>
  <v-container v-if="!hasAddress">
    <v-row justify="center">
      <v-col cols="12">
        <v-icon color="red" class="mr-3">error</v-icon>

        <i18n path="Chef.missing_address" tag="span">
          <span place="link">
            <router-link to="/my/profile?tab=tab-address">{{
              $t('Common.add')
            }}</router-link>
          </span>
        </i18n>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    profile: { Type: Object }
  },
  computed: {
    hasAddress() {
      if (
        this.profile &&
        this.profile.addresses &&
        this.profile.addresses.length > 0
      ) {
        let pickup = this.profile.addresses.filter(a => a.type === 'pickup')
        let current = this.profile.addresses.filter(a => a.type === 'current')

        console.log('p', this.hasLocation(pickup))
        console.log('c', this.hasLocation(current))
        return this.hasLocation(pickup) ? true : this.hasLocation(current)
      }
      return false
    }
  },
  methods: {
    hasLocation(address) {
      return (
        address &&
        address.length > 0 &&
        address[0].location &&
        address[0].location.latitude
        // && address[0].location._lat
      )
    }
  }
}
</script>
