<template>
  <v-container v-if="!loading && dish && profile">
    <div v-if="dish.label">
      <v-row>
        <v-col cols="12">
          <template v-if="labelIsImage">
            <img :src="dish.label" />
          </template>
          <template v-else>
            <embed
              :src="dish.label"
              v-if="labelIsPdf"
              width="800px"
              height="2100px"
            />

            <br />View or download

            <a :href="dish.label" target="_blank">Custom Label </a>
          </template>
        </v-col>
      </v-row>
    </div>
    <div v-else>
      <v-row>
        <v-col cols="10" sm="6" class="ml-1 box">
          <v-container v-if="!loading">
            <v-row>
              <v-col cols="12" class="printLabel text-center">
                <h3 v-if="cfo || mehko" class="home_made_title">
                  MADE IN A HOME KITCHEN
                </h3>
                <br />
                <span class="body-1">
                  <h2>{{ dish.name }}</h2>
                  <div v-if="includeLogo">
                    <br />

                    <img
                      :src="profile.businessLogo"
                      v-if="profile.businessLogo"
                      class="businessLogo"
                    />
                    <p v-if="!profile.businessLogo">
                      Please update your profile and add a Logo Address
                    </p>
                  </div>

                  <br />
                  <h4>{{ name }}</h4>

                  <br />{{ address }} <br />
                  <br />{{ authority }} <br />
                  Registration/Permit Number:<br />
                  PR# {{ permit }}
                </span>
              </v-col>
            </v-row>
            <v-divider class=" mr-3"></v-divider>
            <v-row>
              <v-col cols="12" class="printLabel">
                <div class="body-1 mb-3 mr-4 ">
                  <h4>Ingredients:</h4>
                  <span
                    v-for="(element, index) in subsubIngredientList"
                    :key="index"
                  >
                    {{ element | capitalize }}
                    <span v-if="index + 1 < subsubIngredientList.length"
                      >,
                    </span>
                  </span>
                  <br />
                  <br />
                  <h4 v-if="contains.length > 0">Contains:</h4>
                  <v-icon v-if="contains.length > 0" color="error"
                    >warning</v-icon
                  >
                  <span v-for="(element, i) in contains" :key="i + 100">
                    {{ element }} <span v-if="i + 1 < contains.length">, </span>
                  </span>

                  <br />
                  <br />

                  <h4>
                    Net Wt.: {{ dish.netWeight }}
                    {{ dish.weightUnit || 'ounces' }}
                  </h4>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-img
                  class="transparent"
                  src="https://storage.googleapis.com/download.takein.com/apps/logo/takein_logo_sm.png"
                  contain
                  height="50px"
                >
                </v-img>
              </v-col>
            </v-row>
            <v-row v-if="includeLink">
              <v-col cols="12">
                <qr-code :size="125" :text="dishLink"></qr-code>Link:
                <a :href="dishLink">{{ dishLink }}</a></v-col
              >
            </v-row>
          </v-container>
        </v-col>
      </v-row>
      <v-row class="no-print">
        <v-col cols="10" sm="3">
          <v-switch
            v-model="includeLink"
            :label="`Show link and Barcode`"
            color="green"
          ></v-switch>
        </v-col>

        <v-col cols="10" sm="4" v-if="profile.businessLogo">
          <v-switch
            v-model="includeLogo"
            :label="`Include Logo`"
            color="purple"
          ></v-switch>
        </v-col>
      </v-row>
    </div>
    <v-row>
      <v-col cols="6" v-if="labelIsImage">
        <div class="no-print">
          <v-btn color="primary" @click.prevent="print" class="mx-0"
            >Print
            <v-icon right>print</v-icon>
          </v-btn>
        </div>
      </v-col>
      <v-col cols="6" class="no-print">
        <v-btn color="info" class="mx-0" :to="'/dish/' + dish.id">Cancel</v-btn>
      </v-col>
    </v-row>
    <div v-if="isChef" class="my-8">
      <v-row v-if="dish.label">
        <v-col cols="12" sm="6">
          Delete custom label? The system generated label will be used instead.
          <v-btn class="error" @click.prevent="deleteLabel">Delete</v-btn>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col cols="12">
          Upload your custom label instead? <br />
          <file-upload
            v-model="files"
            :drop="true"
            extensions="gif,jpg,jpeg,png,webp,pdf,doc,docx"
            accept="image/png,image/gif,image/jpeg,image/webp,application/pdf,application/x-pdf,application/msword,application/vnd.google-apps.document,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          >
            <v-icon large color="primary">cloud</v-icon>
          </file-upload>
          <v-btn color="success" @click.prevent="upload" v-if="files.length > 0"
            >Upload</v-btn
          >
        </v-col>
        <v-snackbar :timeout="6000" color="success" v-model="uploadProgress">
          File upload may take few seconds, please wait and revisit this page.
        </v-snackbar>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import Vue from 'vue'
import VueQRCodeComponent from 'vue-qrcode-component'
Vue.component('qr-code', VueQRCodeComponent)

// Register the Vue component
// Vue.component('qr-code', VueQRCodeComponent)
import { mapGetters } from 'vuex'
import { capitalize } from 'vue2-filters/src/string'
import FileUpload from 'vue-upload-component/dist/vue-upload-component.part.js'

const allergens = [
  /milk/,
  /egg/,
  /eggs/,
  /fish/,
  /shellfish/,
  /^nut$/,
  /nuts/,
  /wheat/,
  /wheats/,
  /almond/,
  /almonds/,
  /walnuts/,
  /walnut/,
  /pecans/,
  /pecan/,
  /peanuts/,
  /peanut/,
  /soybeans/,
  /soybean/
]
const domain = process.env.VUE_APP_DOMAIN
export default {
  components: {
    FileUpload
  },
  data() {
    return {
      dish: null,
      profile: null,
      includeLogo: false,
      includeLink: false,
      uploadProgress: false,
      files: []
    }
  },
  computed: {
    ...mapGetters({
      subIngredients: 'subIngredients',
      loading: 'loading',
      isChef: 'isChef',
      chefApprovalStatus: 'chefApprovalStatus'
    }),
    cfo() {
      if (this.profile && this.profile.permits) {
        // If we want to show label for non - CFO chef's then we need to remove this filter
        let cfo = this.profile.permits.find(
          p => p.type && p.type.text === 'CFO'
        )
        return cfo
      }
      return null
    },
    mehko() {
      // get mehko info
      if (this.profile && this.profile.permits) {
        let mehko = this.profile.permits.find(
          p => p.type && p.type.text === 'MEHKO'
        )
        return mehko
      }
      return null
    },
    dishLink() {
      return `https://${domain}/dish/${this.dish.id}`
    },
    permit() {
      if (this.cfo) {
        return this.cfo.permit
      }
      if (this.mehko) {
        return this.mehko.permit
      }
      return null
    },
    authority() {
      if (this.cfo) {
        return this.cfo.authority
      }
      if (this.mehko) {
        return this.mehko.authority
      }
      return null
    },
    contains() {
      return this.dish.ingredients.filter(v =>
        allergens.some(rx => rx.test(v.toLowerCase()))
      )
    },
    subsubIngredientList() {
      let list = this.dish.ingredients.map(l => {
        let sub = this.subIngredients(l.toLowerCase())
        if (sub) {
          let subStr = sub.toString().replace(/,/g, ', ')
          return `${l} (${subStr})`
        }
        return capitalize(l)
      })
      return list
    },
    name() {
      return this.profile.businessName
        ? this.profile.businessName
        : this.profile.name
    },
    address() {
      if (this.profile && this.profileDefaultAddress) {
        let address = this.profileDefaultAddress
        return `${address.address1} ${address.city} ${address.state} ${address.zipcode}`
      }
      return null
    },
    labelIsImage() {
      return (
        this.dish &&
        this.dish.label &&
        this.dish.label.match(/.(jpg|jpeg|png|gif)/i)
      )
    },
    labelIsPdf() {
      return this.dish && this.dish.label && this.dish.label.match(/.(pdf)/i)
    },
    labelIsDoc() {
      return (
        this.dish && this.dish.label && this.dish.label.match(/.(doc|docx)/i)
      )
    }
  },
  mounted() {
    let dishId = this.$route.params.id

    if (dishId) {
      this.$store.dispatch('findDishById', dishId).then(data => {
        this.dish = data
        console.log(this.dish, 'dish!!!')
        this.$store.dispatch('loadChefById', data.chef.id).then(ch => {
          if (ch) {
            this.profile = ch
          } else {
            console.error('Can not load the chef', data.chef.id)
          }
        })
      })
    } else {
      this.$store.dispatch('setError', {
        message: 'Can not find food info' + dishId
      })
    }
  },
  methods: {
    print() {
      this.$nextTick(() => {
        window.print()
      })
    },
    upload() {
      this.uploadProgress = true

      this.$store
        .dispatch('uploadDishLabel', {
          dish: this.dish,
          file: this.files[0].file
        })
        .then(() => {
          this.files = []
          this.$router.go(this.$router.currentRoute)
        })
    },
    deleteLabel() {
      this.$store
        .dispatch('deleteDishLabel', {
          id: this.dish.id
        })
        .then(() => {})
    }
  }
}
</script>

<style scoped>
@media print {
  body {
    background: white;
  }

  .printLabel {
    background: transparent;
  }
  .no-print,
  .no-print * {
    display: none !important;
  }
}
.box {
  box-sizing: content-box;

  border: 2px dotted black;
}
.businessLogo {
  height: 7vh;
}
.transparent {
  opacity: 0.35;
  border-color: transparent !important;
}
</style>
