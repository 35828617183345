<template>
  <v-container>
    <v-row justify="center" v-if="$parent.$parent.$parent.showWizard">
      <v-col cols="12" class="pa-0">
        <small
          class="main_text--text"
          v-html="$t('Common.menus_product_page_description')"
        />
      </v-col>
    </v-row>
    <v-alert text color="info">
      <v-col cols="12" class="pa-0">
        <v-icon color="black accent-2" large>mdi-numeric-1-box-outline</v-icon>

        Add your products,dishes,drinks,salads, etc
      </v-col>
      <v-col cols="12" class="pa-0">
        <v-icon color="black accent-2" large>mdi-numeric-2-box-outline</v-icon>
        Setup your modifiers such as Size, Toppings, Spice level</v-col
      >
      <v-divider class="my-4 info" style="opacity: 0.22"></v-divider>

      <v-col cols="12" class="pa-0">
        <v-icon color="black accent-2" large>mdi-numeric-3-box-outline</v-icon>
        Set your Regular Menus and add your products to each menu
      </v-col>
      <v-col cols="12" class="pa-0">
        <v-icon color="black accent-2" large>mdi-numeric-4-box-outline</v-icon>
        Set your Parent Menus and add your regular menus into it
      </v-col>
    </v-alert>
    <v-row align="center">
      <v-col cols="6" sm="4" md="3">
        <v-btn
          color="takein"
          dark
          :ripple="false"
          rounded
          depressed
          to="/profile/wizard/menus/products_sub/new_product"
        >
          <v-icon left>mdi-plus</v-icon>
          {{ $t('Common.add_products') }}
        </v-btn>
      </v-col>
      <v-col cols="6" sm="5" lg="4" class="mx-2">
        <v-row align="center" v-if="false">
          <v-checkbox
            color="primary"
            :label="$t('Common.importable')"
            v-model="importable"
          />
          <v-btn
            text
            v-if="importable"
            to="/profile/wizard/menus/import_data"
            class="ml-4"
          >
            <v-icon left color="primary">mdi-file-import</v-icon>
            {{ $t('Common.import') }}
          </v-btn>
        </v-row>
      </v-col>
      <v-col cols="6" sm="5" lg="4" class="mx-2">
        <v-btn text to="/profile/wizard/menus/products_sub/list_products">
          <v-icon left color="grey lighten-1">fastfood</v-icon>
          {{ $t('Common.products') }}
        </v-btn>
        <v-btn @click.prevent="viewModifiers" text>
          <v-icon left color="grey lighten-1">menu_book</v-icon>
          {{ $t('Common.modifiers') }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <router-view></router-view>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  data() {
    return {
      importable: false
    }
  },
  mounted() {
    this.$router.push('/profile/wizard/menus/products_sub/list_products')
  },
  methods: {
    viewModifiers() {
      this.$store.dispatch('setDish', null)
      this.$router.push('/profile/wizard/menus/manage_modifiers')
    }
  }
}
</script>
<style></style>
