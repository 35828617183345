<template>
  <div>
    <v-navigation-drawer
      fixed
      clipped
      temporary
      app
      width="210"
      class="no-print"
      v-model="drawer"
    >
      <v-list dense v-if="userIsAuthenticated">
        <div v-if="userIsAuthenticated">
          <template v-for="(item, i) in menuItems">
            <v-row v-if="item.heading" align="center" :key="i">
              <v-col cols="6">
                <v-subheader v-if="item.heading">{{
                  item.heading
                }}</v-subheader>
              </v-col>
              <v-col cols="6" class="text-center">
                <a href="#!" class="body-2 black--text">EDIT</a>
              </v-col>
            </v-row>
            <v-divider
              v-else-if="item.divider"
              :key="i"
              dark
              class="my-3"
            ></v-divider>
            <v-list-group
              v-else-if="item.children"
              v-model="item.model"
              :key="item.text"
              :prepend-icon="item.model ? item.icon : item['icon-alt']"
              append-icon
            >
              <v-list-item slot="activator">
                <v-list-item-content>
                  <v-list-item-title
                    v-if="item.text_key"
                    v-html="$t(item.text_key)"
                  ></v-list-item-title>
                  <v-list-item-title v-else>{{ item.text }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                v-for="(child, i) in item.children"
                :key="i"
                :to="child.link"
              >
                <v-list-item-action v-if="child.icon">
                  <v-icon
                    :color="child.color ? child.color : ''"
                    style="width: 24px"
                    >{{ child.icon }}</v-icon
                  >
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title v-if="child.text_key">
                    <span class="pl-4">{{ $t(child.text_key) }}</span>
                  </v-list-item-title>
                  <v-list-item-title v-else>
                    <span class="pl-4">{{ child.text }}</span>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
            <v-list-item v-else :key="i" router :to="item.link">
              <v-list-item-action>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title v-if="item.text_key">
                  <span class="pl-4">{{ item.text_key }}</span>
                </v-list-item-title>
                <v-list-item-title v-else>
                  <span class="pl-4">{{ item.text }}</span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>

          <v-list-item @click="onLogout">
            <v-list-item-action>
              <v-icon>exit_to_app</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                <span class="pl-4">{{ $t('Common.logout') }}</span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>
        <div v-else>
          <v-list-item to="/signin">
            <v-list-item-action>
              <v-icon>lock_open</v-icon>
            </v-list-item-action>
            <v-list-item-content>{{ $t('Common.signin') }}</v-list-item-content>
          </v-list-item>
          <v-list-item to="/signup">
            <v-list-item-action>
              <v-icon>face</v-icon>
            </v-list-item-action>
            <v-list-item-content>{{ $t('Common.signup') }}</v-list-item-content>
          </v-list-item>
        </div>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      :color="isAdmin ? '#C2185B' : 'cyan'"
      dense
      dark
      app
      clipped-left
      class="noprint"
      fixed
    >
      <v-toolbar-title>
        <v-row no-gutters align="center">
          <img
            v-if="!userIsAuthenticated"
            width="120"
            src="/svg/takein_biz_logo.svg"
          />

          <v-app-bar-nav-icon
            @click.stop="drawer = !drawer"
            v-if="userIsAuthenticated"
          ></v-app-bar-nav-icon>

          <template v-if="isChef && !isAdmin">
            <v-btn text icon to="/">
              <v-icon class="mx-1">home</v-icon>
            </v-btn>

            <v-btn text icon to="/product/list">
              <v-icon class="mx-1">fas fa-utensils</v-icon>
            </v-btn>

            <v-btn text icon to="/product/menu">
              <v-icon large class="mx-1">menu_book</v-icon>
            </v-btn>
            <v-btn text icon to="/product/last_call">
              <v-icon large class="mx-1">timer</v-icon>
            </v-btn>
            <v-btn text icon class="ml-3" to="/order/list/v2.0">
              <v-icon large class="mx-1">attach_money</v-icon>
            </v-btn>
            <v-btn
              text
              icon
              class="ml-3"
              to="/my/profile"
              v-if="profile.isBusinessClose"
            >
              <img width="50" src="/svg/noun_closed_2640959.svg" />
            </v-btn>
          </template>
          <template v-if="isAdmin">
            <v-btn text icon class="ml-3" to="/admin/home">
              <v-icon large class="mx-1">store_mall_directory</v-icon>
            </v-btn>
            <v-btn text icon class="ml-3" to="/order/list">
              <v-icon large class="mx-1">attach_money</v-icon>
            </v-btn>
            <v-btn text icon class="ml-3" to="/admin/pm/list">
              <v-icon large class="mx-1">mdi-car</v-icon>
            </v-btn>
          </template>
        </v-row>
      </v-toolbar-title>

      <v-spacer></v-spacer>
      <template v-if="userIsAuthenticated">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon @click.stop="rightDrawer = !rightDrawer" v-on="on">
              <v-badge
                color="red"
                overlap
                :content="totalNotificationCount"
                :value="totalNotificationCount"
              >
                <!-- <span v-if="totalNotificationCount > 0" slot="badge">
                  {{
                  totalNotificationCount
                  }}
                </span> -->
                <v-icon>notifications</v-icon>
              </v-badge>
            </v-btn>
          </template>
          <span class="white--text"
            >{{ totalNotificationCount }} unread notifications</span
          >
        </v-tooltip>

        <!-- <v-menu>
          <v-btn icon slot="activator">
            <v-avatar class="white" size="32">
              <v-avatar size="40" color="red" v-if="profile && profile.avatar">
                <img :src="profile.avatar" :alt="profile.name" />
              </v-avatar>
              <v-avatar v-else class="primary" size="48px">
                <v-icon dark>person</v-icon>
              </v-avatar>
            </v-avatar>
          </v-btn>
          <v-list class="pa-0" v-if="profile" light>
            <v-list-item avatar>
              <v-list-item-content>
                <v-list-item-title>{{ profile.name }}</v-list-item-title>
                <v-list-item-subtitle v-if="isChef"
                  >Chef</v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>

            <v-list-item key="profile" to="/my/profile">
              <v-list-item-action> <v-icon>person</v-icon> </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>My Profile</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item key="messages" to="/messages">
              <v-list-item-action> <v-icon>mail</v-icon> </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title>Messages</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>

            <v-list-item key="lock_open" @click="onLogout">
              <v-list-item-action>
                <v-icon>lock_open</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Logout</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>-->
        <user-menu></user-menu>
      </template>
      <template v-else>
        <v-spacer></v-spacer>

        <v-toolbar-items>
          <v-btn text small to="/pricing">{{ $t('Chef.pricing') }}</v-btn>
          <v-btn text small to="/why-takein">{{
            $t('Common.why_takein')
          }}</v-btn>
          <v-btn text small to="/signup/chef?show_onboard=true">{{
            $t('Common.signup')
          }}</v-btn>
          <v-btn text small to="/signin">Login</v-btn>
          <v-menu offset-y open-on-hover>
            <template v-slot:activator="{ on }">
              <v-btn text small v-on="on">
                <v-icon>mdi-web</v-icon>
                {{ selectedLang }}
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(item, index) in langs"
                :key="index"
                v-on:click="changeLang(item)"
              >
                <v-list-item-title>{{ item.label }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-toolbar-items>
      </template>
    </v-app-bar>
    <notifications
      v-if="userIsAuthenticated"
      :open="rightDrawer"
      :deliver_state="deliverState"
      :order_count="activeOrderCount"
      :validProfile="validProfile"
      :chefApprovalStatus="chefApprovalStatus"
      :notification_count="newMessageCount"
      :invitation_count="newInvitationCount"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Notifications from '@/components/common/NotificationHeader'
import UserMenu from 'fod-core/src/components/profile/UserMenu.vue'

export default {
  data: () => ({
    drawer: false,
    rightDrawer: false,
    items: [],
    langs: [
      { label: 'English', value: 'en' },
      { label: '中文', value: 'ch' }
    ],
    selectedLang: 'English'
  }),
  props: {
    source: String
  },
  components: {
    Notifications,
    UserMenu
  },
  computed: {
    ...mapGetters({
      profile: 'profile',
      isChef: 'isChef',
      isAdmin: 'isAdmin',
      validProfile: 'validProfile',
      loading: 'loading',
      chefApprovalStatus: 'chefApprovalStatus',
      activeOrders: 'activeOrders',
      deliverState: 'deliverState',
      newMessageCount: 'newMessageCount',
      newInvitationCount: 'newInvitationCount'
    }),
    devMode() {
      return process.env.VUE_APP_ENV !== 'production'
        ? `(${process.env.VUE_APP_ENV})`
        : ''
    },
    showPendingMessage() {
      return this.isChef && this.chefApprovalStatus === 'pending'
    },
    userIsAuthenticated() {
      return (
        this.$store.getters.user !== null &&
        this.$store.getters.user !== undefined
      )
    },
    activeOrderCount() {
      return this.activeOrders ? this.activeOrders.length : 0
    },
    totalNotificationCount() {
      let count = this.activeOrderCount
      if (this.isChef && this.chefApprovalStatus === 'pending') {
        count++
      }
      if (!this.validProfile) {
        count++
      }
      if (!this.deliverState) {
        count++
      }
      return count
    },
    menuItems() {
      let items = []

      let extra = [
        { icon: 'home', text: 'Home', link: '/' },

        { icon: 'shopping_cart', text: 'Orders', link: '/order/list' },
        {
          icon: 'keyboard_arrow_up',
          'icon-alt': 'keyboard_arrow_down',
          text: 'Products',
          model: false,
          children: [
            {
              color: 'info',
              icon: 'list',
              text: 'Your products',
              link: '/product/list'
            },
            {
              color: 'info',
              icon: 'menu_book',
              text_key: 'Common.menu',
              link: '/product/menu'
            },
            {
              color: 'info',
              icon: 'mdi-food-variant',
              text_key: 'Common.condiments',
              link: '/condiments'
            },
            {
              color: 'info',
              icon: 'fas fa-cogs',
              text_key: 'Common.modifiers',
              link: '/product/managemodifiers'
            },
            {
              color: 'info',
              icon: 'fas fa-box',
              text_key: 'Common.inventory',
              link: '/product/inventory'
            }
          ]
        },
        /* {
          icon: 'keyboard_arrow_up',
          'icon-alt': 'keyboard_arrow_down',
          text: 'Calendar',
          model: false,
          children: [
            {
              color: 'info',
              icon: 'far fa-calendar-alt',
              text: 'Orders',
              text_key: 'Common.orders',
              link: '/my/calendar'
            },
            {
              color: 'info',
              icon: 'event_available',
              text: 'My working schedule',
              link: '/schedule/calendar'
            }
          ]
        },*/
        {
          icon: 'keyboard_arrow_up',
          'icon-alt': 'keyboard_arrow_down',
          text: 'Promotions',
          model: false,
          children: [
            {
              color: 'info',
              icon: 'fas fa-hand-holding-usd',
              text: 'Orders',
              text_key: 'Common.coupons',
              link: '/coupons/list'
            }
          ]
        },
        { divider: true },
        {
          icon: 'keyboard_arrow_up',
          'icon-alt': 'keyboard_arrow_down',
          text: 'Profile',
          model: false,
          children: [
            {
              color: 'info',
              icon: 'contacts',
              text: 'Edit Profile',
              text_key: 'Common.my_profile',
              link: '/my/profile'
            },
            {
              color: 'info',
              icon: 'photo',
              text_key: 'Common.album',
              link: '/chef/album'
            },
            {
              icon: 'vpn_key',
              text: 'Change Password',
              link: '/change-password'
            }
          ]
        },
        {
          'icon-alt': 'keyboard_arrow_down',
          text: 'Settings',
          model: false,
          children: [
            {
              color: 'info',
              icon: 'settings',
              text: 'System Settings',
              link: '/settings'
            },
            {
              color: 'info',
              icon: 'local_car_wash',
              text: 'Delivery Subsidy',
              link: '/settings/delivery-subsidy'
            },

            {
              color: 'info',
              icon: 'drafts',
              text: 'Invites',
              link: '/invite/list'
            },
            {
              color: 'info',
              icon: 'fas fa-user-friends',
              text: 'Followers',
              link: '/followers'
            },
            {
              color: 'info',
              icon: 'grade',
              text: 'Reviews',
              link: '/review/list/'
            }
          ]
        },
        { icon: 'mdi-finance', text: 'Reports', link: '/report' },

        { icon: 'payment', text: 'Accounts', link: '/accounts' }
      ]
      items = [...items, ...extra]

      return items
    },
    productHomeAddress() {
      if (this.isChef) {
        return '/product/home'
      }
      return '/'
    }
  },
  methods: {
    onLogout() {
      this.$store.dispatch('logout')
      this.$router.go('/signin')
    },
    showOrders() {
      console.log('showOrders')
      this.$router.push('/order/list')
    },
    changeLang(item) {
      this.selectedLang = item.label
      this.$i18n.locale = item.value
      this.$forceUpdate()
      this.$store.dispatch('changeLanguage', item.value)
    }
  }
}
</script>
<style>
.v-navigation-drawer {
  z-index: 100;
}
.v-list {
  z-index: 101;
}
</style>
