<template>
  <div>
    <v-combobox
      :label="$t('Common.food_ingredients')"
      :hint="$t('Common.food_ingredients_hint')"
      :items="ingredientList"
      v-model="ingredients"
      multiple
      chips
      clearable
      tags
      :delimiters="[',', ';']"
      persistent-hint
    >
      <template v-slot:selection="{ attrs, item, select, selected }">
        <v-chip
          close
          v-bind="attrs"
          :input-value="selected"
          @click="select"
          class="chip--select-multi"
          @click:close="remove(item)"
        >
          <v-avatar class="accent">{{
            item.slice(0, 1).toUpperCase()
          }}</v-avatar>
          {{ item }}
        </v-chip>
      </template>
    </v-combobox>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: ['list'],
  data() {
    return {
      ingredients: null
    }
  },
  computed: {
    ...mapGetters({
      ingredientList: 'ingredients'
    })
  },
  mounted() {
    this.ingredients = this.list
  },
  updated: function() {
    this.$emit('onUpdate', this.ingredients)
  },
  methods: {
    remove(item) {
      this.ingredients.splice(this.ingredients.indexOf(item), 1)
      this.ingredients = [...this.ingredients]
    }
  }
}
</script>
