<template>
  <div>
    <v-container fluid v-if="myOrder" class="mb-6">
      <template v-if="producerView">
        <v-row>
          <v-col cols="12" sm="8">
            <v-card-text v-if="myOrder.system_messages">
              <v-icon color="red" large>error_outline</v-icon>
              <v-expansion-panels>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <div class="red--text">
                      {{ message.title }}
                      {{ $moment(message.created).fromNow() }}
                    </div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content
                    v-for="(message, key) in myOrder.system_messages"
                    :key="key"
                  >
                    <v-alert type="error" outlined :value="true">
                      {{ message.message }}
                    </v-alert>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-card-text>
          </v-col>
        </v-row>
        <v-row v-if="myOrder.orderType === 'later'" justify="center">
          <v-col cols="6">
            <h3>
              Pre-Order
              <span v-if="today">
                Today {{ myOrder.pickupDateTime | formatTimeOnly }}
              </span>
              <span v-else>
                {{ myOrder.pickupDateTime | formatDateTime }}
              </span>
            </h3>
          </v-col></v-row
        >
        <v-row v-if="myOrder.products">
          <template v-for="item in myOrder.products">
            <v-col cols="12" sm="10" md="8" offset-sm="1" :key="item.dish.id">
              <food-view :hideChef="true" :item="item" />
            </v-col>
          </template>
        </v-row>
        <!--
      // TODO: Show  myOrder.delivery.optional_condiments
    -->
        <v-row v-if="myOrder.status === 'accepted'">
          <v-col sm="8" offset-sm="1" class="py-2 s12">
            <h4>{{ $t('Common.cooking_status') | capitalize }} :</h4>
            <v-btn-toggle
              v-model="cooking_status"
              @change="updateCookingStatus"
            >
              <v-btn value="cooking" large class="mx-2">
                <span>Cooking</span>
                <v-icon color="cyan">restaurant_menu</v-icon>
              </v-btn>
              <v-btn large color="green" dark value="ready">
                <span>Ready</span>
                <v-icon right>room_service</v-icon>
              </v-btn>
            </v-btn-toggle>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="10" offset-md="1">
            <v-list two-line class="pa-2">
              <v-divider />
              <v-list-item v-if="myOrder.buyer">
                <v-row
                  ><v-col cols="12" md="5">
                    <v-list-item-content>
                      <v-list-item-title
                        >Customer:
                        <router-link :to="'/profile/' + myOrder.buyer.id">{{
                          myOrder.buyer.name || myOrder.delivery.dropoff_name
                        }}</router-link>
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        Email: {{ myOrder.buyer.email }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle v-if="myOrder.buyer.phone">
                        Phone: {{ myOrder.buyer.phone }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-col>

                  <v-col cols="12" md="7" class="no-print">
                    <message-customer :order="myOrder" />
                  </v-col>
                </v-row>
              </v-list-item>
              <v-list-item v-else-if="userProfile">
                <v-list-item-content>
                  <!-- customer name -->
                  <v-list-item-title
                    >{{ $t('Common.customer_name') }} :
                    <router-link :to="'/profile/' + userProfile.id">
                      {{ userProfile.name }}</router-link
                    >
                  </v-list-item-title>

                  <!-- customer handle -->
                  <v-list-item-subtitle v-if="userProfile.handle">
                    <router-link :to="'/profile/' + userProfile.id">
                      @{{ userProfile.handle }}</router-link
                    >
                  </v-list-item-subtitle>

                  <!-- customer number -->
                  <v-list-item-subtitle v-if="userProfile.phone">
                    <v-icon class="mr-3">phone</v-icon>
                    <a :href="'tel:' + userProfile.phone" class="fs-16 ">{{
                      userProfile.phone
                    }}</a>
                  </v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-avatar>
                  <img :src="userProfile.avatar" />
                </v-list-item-avatar>
              </v-list-item>

              <v-list-item v-if="myOrder.delivery">
                <v-list-item-content>
                  <v-list-item-title v-if="myOrder.delivery.pickup_eta"
                    >Pickup ETA:
                    {{ myOrder.delivery.pickup_eta | formatTimeOnly }}
                  </v-list-item-title>
                  <v-list-item-title v-else-if="myOrder.pickupDateTime"
                    >Estimated Pickup Time:
                    {{ myOrder.pickupDateTime | formatDateTime }}
                  </v-list-item-title>
                  <v-list-item-title v-if="myOrder.delivery.picked_up_date"
                    >Picked up at :
                    {{ myOrder.delivery.picked_up_date | formatDateTime }}
                  </v-list-item-title>
                  <v-list-item-title
                    v-if="
                      myOrder.deliveryMethod === 'delivery' &&
                        myOrder.deliveryDateTime
                    "
                    >Delivery by:

                    <span v-if="today">
                      Today {{ myOrder.deliveryDateTime | formatTimeOnly }}
                    </span>
                    <span v-else>
                      {{ myOrder.deliveryDateTime | formatDateTime }}
                    </span>
                  </v-list-item-title>
                  <v-list-item-title v-else-if="myOrder.requestedDeliveryTime"
                    >Delivery Date:
                    {{
                      myOrder.requestedDeliveryTime | formatDate
                    }}</v-list-item-title
                  >
                  <v-list-item-title v-if="deliveryTime"
                    >Delivery Date: {{ deliveryDateTime }} ({{
                      deliveryDateTimeFromNow
                    }})</v-list-item-title
                  >

                  <v-list-item-subtitle class="text--primary"
                    >Order date:
                    {{ myOrder.orderDate | formatDate }}</v-list-item-subtitle
                  >
                </v-list-item-content>
                <v-list-item-action>
                  <v-list-item-action-text></v-list-item-action-text>
                </v-list-item-action>
              </v-list-item>
              <v-list-item>
                <v-list-item-content v-if="myOrder.delivery">
                  <v-list-item-title>
                    <v-row justify="space-between">
                      <v-col cols="12" md="6">
                        Delivery Method:
                        <span class="info--text mr-2">
                          {{ myOrder.deliveryMethod | capitalize }}
                        </span>
                      </v-col>
                      <v-col
                        cols="8"
                        md="4"
                        v-if="myOrder.delivery.delivery_status"
                      >
                        Delivery Status:
                        {{ myOrder.delivery.delivery_status | capitalize }}
                        <a
                          v-if="
                            track_url &&
                              myOrder.delivery.delivery_status !== 'delivered'
                          "
                          :href="track_url"
                          target="_blank"
                          >Track</a
                        >
                      </v-col>

                      <v-col cols="12" md="6">
                        <v-card
                          v-if="myOrder.delivery.courier"
                          class="mx-auto"
                          width="600"
                        >
                          <v-card-text>
                            <v-row>
                              <v-col cols="4">Driver:</v-col>
                              <v-col cols="7">
                                {{ myOrder.delivery.courier.name }}</v-col
                              >
                            </v-row>

                            <v-row>
                              <v-col cols="4">Phone:</v-col>
                              <v-col cols="7">
                                <a
                                  :href="
                                    'tel:' +
                                      myOrder.delivery.courier.phone_number
                                  "
                                  >{{
                                    myOrder.delivery.courier.phone_number
                                  }}</a
                                ></v-col
                              >
                            </v-row>
                            <v-row>
                              <v-col cols="4">Status:</v-col>
                              <v-col cols="7">
                                {{
                                  myOrder.delivery.delivery_status | capitalize
                                }}
                                <span
                                  v-if="
                                    myOrder.delivery.delivery_status !=
                                      'delivered'
                                  "
                                >
                                  <v-btn
                                    :href="track_url"
                                    target="_blank"
                                    small
                                    text
                                    color="info"
                                    >Track</v-btn
                                  >
                                </span>
                              </v-col>
                            </v-row>
                            <v-avatar v-if="myOrder.delivery.courier.img_href">
                              <v-img
                                class="elevation-6"
                                :src="myOrder.delivery.courier.img_href"
                              ></v-img
                            ></v-avatar>
                          </v-card-text>
                          <v-card-text v-if="myOrder.delivery.error">
                            <v-alert class="small" type="error" outlined>{{
                              myOrder.delivery.error
                            }}</v-alert>
                          </v-card-text>
                          <v-card-actions class="no-print">
                            <v-btn
                              small
                              color="info"
                              v-if="isAdmin"
                              @click.stop="onResubmitDelivery"
                              >Resubmit Delivery</v-btn
                            >

                            <v-spacer></v-spacer>
                            <span>
                              <v-btn
                                v-if="isAdmin"
                                small
                                color="error"
                                @click.stop="onCancelDelivery"
                                >Cancel Delivery</v-btn
                              >
                            </span>
                          </v-card-actions>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-divider />
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    <v-row justify="space-between">
                      <v-col cols="6" md="3">
                        Order #: <small>{{ myOrder.id }}</small>
                      </v-col>
                      <v-col cols="4" md="2"
                        ><div
                          :class="icon(myOrder.status).color + '--text mr-3'"
                          class="status_title"
                        >
                          {{ title(myOrder.status) | capitalize }} <br /></div
                      ></v-col>
                      <v-col cols="12" md="7 " class="text-wrap"
                        ><div
                          :class="icon(myOrder.status).color + '--text mr-3'"
                          class="status_title"
                        >
                          <div class="ml-3" v-if="myOrder.error">
                            <span class="body-2 error--text ">{{
                              myOrder.error
                            }}</span>
                          </div>
                        </div></v-col
                      >
                    </v-row>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item class="pa-0 ma-0">
                <v-list-item-content class="pl-4">
                  <v-row justify="space-between">
                    <v-col cols="11" v-if="myOrder.chefResponse">
                      <v-card flat>
                        <small>
                          <v-card-text class=" px-0">
                            Note:
                            <span class=" red--text">{{
                              myOrder.chefResponse | truncate(250)
                            }}</span>
                          </v-card-text>
                        </small>
                      </v-card>
                    </v-col>
                    <v-col cols="11" md="6" v-if="myOrder.notes">
                      Buyer's Note:
                      <span class="fs-16 blue--text">
                        {{ myOrder.notes | truncate(250) }}</span
                      >
                    </v-col>
                    <v-col
                      cols="11"
                      md="4"
                      v-if="myOrder.delivery && myOrder.delivery.driver_note"
                    >
                      Driver's Note:<span class="fs-16 blue--text">
                        {{ myOrder.delivery.driver_note }}</span
                      >
                    </v-col>
                    <!-- cancellation note -->
                    <v-col cols="11" v-if="myOrder.buyerResponse">
                      <v-card flat>
                        <small>
                          <v-card-text class=" px-0">
                            Cancellation Reason:
                            <span class="fs-16 red--text">
                              {{ myOrder.buyerResponse | truncate(250) }}</span
                            >
                          </v-card-text>
                        </small>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-list-item-content>
                <v-list-item-action> </v-list-item-action>
              </v-list-item>
              <v-divider />
              <v-row v-if="isAdmin" class="my-4" justify="space-between">
                <v-col cols="10" sm="8">
                  By:
                  <span class="body-1"
                    ><router-link
                      :to="'/admin/profile/' + myOrder.sellerUserId"
                      >{{ myOrder.seller.name }}</router-link
                    ></span
                  >

                  From:
                  <span class="body-1">{{
                    myOrder.delivery.pickup_address
                  }}</span>
                </v-col>
                <v-col cols="12" sm="4">
                  Phone:
                  <span class="body-1"
                    ><a :href="'tel:' + myOrder.delivery.pickup_phone_number">
                      {{ myOrder.delivery.pickup_phone_number }}</a
                    ></span
                  >
                </v-col>
              </v-row>

              <template v-if="isDelivery">
                <v-row align="center" justify="space-between">
                  <v-col cols="12" v-if="myOrder.delivery.dropoff_address"
                    >Delivery To:
                    <span class="body-1">{{
                      myOrder.delivery.dropoff_address
                    }}</span>
                    <v-row align="center" class=" mt-2"
                      ><v-col v-if="myOrder.delivery.dropoff_address2" cols="3">
                        Apt: {{ myOrder.delivery.dropoff_address2 }}
                      </v-col>

                      <v-col
                        v-if="myOrder.delivery.dropoff_address2"
                        cols="8"
                        md="4"
                      >
                        <i>{{
                          myOrder.delivery.dropoff_address_instruction
                        }}</i>
                      </v-col>
                      <v-col
                        v-if="myOrder.delivery.contactless_delivery"
                        cols="6"
                        md="3"
                      >
                        <v-icon color="green">fas fa-user-times</v-icon>
                        Contactless Delivery
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row align="center" justify="space-between">
                  <v-col cols="6" sm="4" v-if="myOrder.delivery.dropoff_name">
                    <v-icon
                      v-if="
                        userProfile &&
                          myOrder.delivery.dropoff_name !== userProfile.name
                      "
                      color="red"
                      >error</v-icon
                    >
                    Dropoff name:
                    <span class="body-1">{{
                      myOrder.delivery.dropoff_name
                    }}</span>
                  </v-col>
                  <v-col cols="6" sm="4">
                    Dropoff Phone:

                    <span
                      class="body"
                      v-if="myOrder.delivery.dropoff_phone_number"
                      ><a
                        :href="'tel:' + myOrder.delivery.dropoff_phone_number"
                      >
                        {{ myOrder.delivery.dropoff_phone_number }}</a
                      ></span
                    >
                    <span v-else class="body red--text ">N/A </span>
                  </v-col>
                </v-row>
              </template>

              <v-row v-if="myOrder.delivery">
                <v-col cols="4" v-if="myOrder.delivery.needNapkin === false"
                  >Napkin NOT needed
                  <v-icon color="green" large>far fa-sticky-note</v-icon></v-col
                >
                <v-col cols="4" v-if="myOrder.delivery.needUtensil === false"
                  >Utensil NOT needed
                  <v-icon color="green" large>restaurant</v-icon></v-col
                >
              </v-row>

              <v-divider />
              <v-row v-if="myOrder.payment.all_fees">
                <v-col cols="12">
                  <order-fee
                    :cart="myOrder.products"
                    :chef="chef"
                    :all_fees="myOrder.payment.all_fees"
                    :deliveryMethod="myOrder.deliveryMethod"
                    :deliveryCharge="myOrder.delivery.deliveryFee"
                    :payment="myOrder.payment"
                    :isChef="true"
                    :isAdmin="isAdmin"
                  />
                </v-col>
              </v-row>
              <template v-if="isAdmin">
                <v-row justify="end" class="mb-10 no-print">
                  <v-col cols="6" md="2">
                    <v-btn
                      x-large
                      dark
                      @click="producerView = !producerView"
                      color="pink"
                      >Yay I'm Admin</v-btn
                    >
                  </v-col>
                </v-row>
              </template>
              <v-divider />
              <template v-if="myOrder.status === 'new'">
                <template v-if="!bankAccount">
                  <v-row>
                    <v-col cols="12">
                      <v-alert
                        outlined
                        color="error"
                        icon="warning"
                        :value="true"
                        >{{ $t('Common.bank_account_sub_title') }}

                        <router-link to="/accounts/bank"
                          >bank account</router-link
                        >
                      </v-alert>
                    </v-col>
                  </v-row>
                </template>
                <template v-if="false && !dish.amountLeft">
                  <v-row>
                    <v-col cols="12">
                      <v-alert
                        outlined
                        color="error"
                        icon="warning"
                        :value="true"
                        >{{ $t('Common.no_amount_left') }}
                      </v-alert>
                    </v-col>
                  </v-row>
                </template>
                <v-row align="center">
                  <v-col cols="5" md="4">
                    <v-btn
                      color="primary"
                      @click.prevent.stop="acceptOrder"
                      raised
                      :disabled="disableAction"
                      >Accept
                      <v-icon dark right>check_circle</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col cols="4">
                    <v-btn dark color="red" @click.stop="showReject()"
                      >Reject
                    </v-btn>
                    <template v-if="!hasEnoughAmount">
                      <v-icon color="red" class="mr-1">error</v-icon>
                      {{ $t('Common.not_enough_left') }}
                    </template>
                  </v-col>
                </v-row>
              </template>

              <template>
                <v-row align="center" class="no-print">
                  <template v-if="myOrder.status === 'accepted'">
                    <v-col cols="4" md="2" v-if="deliveryTitle">
                      <v-btn
                        color="info"
                        small
                        @click.stop="setStatus(true)"
                        raised
                        >{{ deliveryTitle }}
                        <v-icon dark right>local_shipping</v-icon>
                      </v-btn>
                    </v-col>
                    <v-col cols="4" md="2" v-if="deliveryTitle">
                      <v-btn
                        color="secondary"
                        small
                        @click.stop="setStatus(false)"
                        >{{ noDeliveryTitle }}
                        <v-icon dark right>block</v-icon>
                      </v-btn>
                    </v-col>
                  </template>

                  <v-col cols="4" md="2" v-if="allowArchive(myOrder.status)">
                    <v-btn dark small @click.prevent="archive" color="info"
                      >Archive
                      <v-icon dark right>folder</v-icon>
                    </v-btn>
                  </v-col>

                  <v-col cols="5" sm="3" md="2">
                    <!-- refunding -->
                    <template v-if="canRefund">
                      <RefundOrder :maxRefund="maxRefund" :myOrder="myOrder" />
                    </template>
                  </v-col>
                  <v-col cols="5" sm="3" md="2" v-if="canRefund">
                    <ExtraCharge :maxCharge="20" :myOrder="myOrder" />
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col cols="3" md="2">
                    <v-btn
                      text
                      color="info"
                      :to="
                        `/order/view/v2.0/${myOrder.sellerUserId}/${myOrder.orderId}`
                      "
                    >
                      New View
                    </v-btn>
                  </v-col>
                </v-row>
              </template>
            </v-list>
          </v-col>

          <v-dialog v-model="cancelOrRejectDialog" persistent max-width="390">
            <v-card>
              <v-card-title>
                {{ cancelOrRejectStatus | capitalize }} Order
              </v-card-title>
              <v-card-text>
                <v-text-field
                  maxlength="250"
                  label="Reason"
                  v-model="response"
                ></v-text-field>
              </v-card-text>
              <v-card-actions>
                <v-btn dark color="red" @click.stop="cancelOrRejectOrder"
                  >Reject
                  <v-icon dark right>cancel</v-icon>
                </v-btn>
                <v-btn
                  color="primary"
                  text
                  @click.stop="cancelOrRejectDialog = false"
                  >Close</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>

        <v-row v-if="review" class="no-print">
          <v-col cols="12" md="6">
            <producer-review :data="review.producer_ratings" :readonly="true" />
          </v-col>
          <v-col cols="12" md="6">
            <products-review
              :data="review.products_ratings"
              :products="myOrder.products"
              :readonly="true"
            />
          </v-col>
        </v-row>
        <v-btn
          class="primary white--text no-print"
          @click="$router.go(-1)"
          icon
        >
          <v-icon>chevron_left</v-icon>
        </v-btn>
      </template>
      <template v-else>
        <OrderAdmin :order="myOrder" @showRefundBox="showRefundBox" />
        <v-row class="mt-4 no-print">
          <v-col
            ><v-btn @click="producerView = !producerView"
              >Back to order</v-btn
            ></v-col
          >
        </v-row>
      </template>

      <delete-confirmation-dialog
        ref="deleteConfirmationDialog"
        heading="Cancel Delivery"
        message="Are you sure you want to submit request to cancel delivery?"
        @onConfirm="cancelDelivery"
      >
      </delete-confirmation-dialog>
      <delete-confirmation-dialog
        ref="resubmitOnfirmationDialog"
        heading="Resbumit Delivery Request"
        message="Are you sure you want to submit request again?"
        @onConfirm="resubmitDelivery"
      >
      </delete-confirmation-dialog>
    </v-container>
    <v-container fluid v-else class="mb-6">
      <v-row v-if="isAdmin">
        Check consumer order
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import OrderFee from 'fod-core/src/components/order/OrderFee.vue'
import FoodView from 'fod-core/src/components/order/OrderFoodView.vue'
import ProducerReview from 'fod-core/src/components/review/ProducerReview.vue'
import ProductsReview from 'fod-core/src/components/review/ProductsReview.vue'

import { OrderMixin } from 'fod-core/src/mixins/orderMixin'
import { FoodMixin } from 'fod-core/src/mixins/foodMixin'
import MessageCustomer from './MessageCustomer'

import moment from 'moment'

export default {
  name: 'ViewOrder',
  mixins: [FoodMixin, OrderMixin],
  components: {
    FoodView,
    OrderFee,
    MessageCustomer,
    ProducerReview,
    ProductsReview,
    OrderAdmin: () => import('./OrderAdmin'),
    RefundOrder: () => import('./RefundOrder'),
    ExtraCharge: () => import('./ExtraCharge')
  },
  data: () => ({
    producerView: true,
    show: false,
    cancelOrRejectDialog: false,
    cancelOrRejectStatus: null,
    response: null,
    deliveryDate: null,
    buyer: null,
    hasEnoughAmount: true,
    acceptApplied: false,
    cooking_status: 'prepping',
    showRefund: false,
    refundMsg: null,
    refundAmtType: '$',
    refundAmt: null,
    refundType: 'money',
    review: null,
    uid: null,
    reverse_transfer: true
  }),
  computed: {
    ...mapGetters({
      myOrder: 'order',
      user: 'user',
      dishList: 'dishList',
      isAdmin: 'isAdmin',
      userProfile: 'userProfile',
      bankAccount: 'bankAccount'
    }),
    chef() {
      return this.myOrder ? this.myOrder.seller : null
    },
    isDelivery() {
      return this.myOrder.deliveryMethod === 'delivery'
    },
    deliveryTitle() {
      // pick up case
      if (this.myOrder.deliveryMethod === 'pickup') {
        return 'Picked Up'
      } else if (this.myOrder.deliveryMethod === 'delivery') {
        return 'Delivered'
      }
      return ''
    },
    noDeliveryTitle() {
      // pick up case
      if (this.myOrder.deliveryMethod === 'pickup') {
        return 'No Pick Up'
      }
      // self-delivery case
      else if (this.myOrder.deliveryMethod === 'delivery') {
        return 'No Delivery'
      }
      return ''
    },

    deliveryDateTime() {
      if (this.myOrder) {
        return moment(
          this.myOrder.deliveryDate + ' ' + this.myOrder.deliveryTime,
          'YYYY-MM-DD HH:mm'
        ).format('MM/DD/YYYY hh:mm')
      }
    },
    deliveryDateTimeFromNow() {
      if (this.myOrder) {
        return moment(
          this.myOrder.deliveryDate + ' ' + this.myOrder.deliveryTime,
          'YYYY-MM-DD HH:mm'
        ).fromNow()
      }
    },

    disableAction() {
      //return false
      if (this.isAdmin) {
        return false
      }
      return !this.bankAccount || !this.hasEnoughAmount
    },
    dishListAsMap() {
      var map = new Object() // or var map = {};
      this.dishList.forEach(dish => {
        map[dish.id] = dish
      })
      return map
    },
    track_url() {
      return this.myOrder.delivery &&
        this.myOrder.delivery.delivery_track &&
        this.myOrder.delivery.delivery_track.kind === 'delivery'
        ? `https://postmates.com/track/${this.myOrder.delivery.delivery_track.id}`
        : null
    },

    canResubmit() {
      if (
        this.myOrder.status == 'accepted' &&
        this.myOrder.deliveryMethod === 'delivery'
      ) {
        return true
      } else {
        return false
      }
    },
    isActive() {
      const minutes = 30
      const timeLimit = moment
        .utc()
        .add(-minutes, 'm')
        .valueOf()
      return (
        this.myOrder.status === 'accepted' ||
        this.myOrder.status === 'confirmed' ||
        this.myOrder.updatedTime >= timeLimit
      )
    },
    today() {
      return moment(this.myOrder.deliveryDateTime).isSame(moment(), 'day')
    }

    // taxAndFee() {
    //   return this.myOrder.payment
    //     ? this.myOrder.payment.fee + this.myOrder.payment.tax
    //     : 0
    // }
  },
  watch: {
    isAdmin() {
      this.init()
    },
    myOrder: function() {
      let action = this.$route.query.action
      console.log(`${action} Appling`, this.myOrder)

      if (
        action &&
        action.toLowerCase() === 'accept' &&
        this.myOrder &&
        this.myOrder.status === 'new' &&
        this.acceptApplied == false
      ) {
        console.log('Accepting order')
        this.acceptOrder()
      }
    }
  },

  mounted: async function() {
    window.scrollTo(0, 0)
    //this.$vuetify.goTo(0)
    await this.init()
  },
  methods: {
    async init() {
      this.uid =
        this.isAdmin && this.$route.params.uid
          ? this.$route.params.uid
          : this.profile
          ? this.profile.id
          : null
      let orderId = this.$route.params.order_id
      let source = this.$route.query.source || this.$route.query.target

      let action = this.$route.query.action
      console.log(this.userProfile, this.chef, this.myOrder, 'this.userProfile')
      console.log(
        `Loading order: ${this.$route.params.order_id} ,  source:${source} action:${action}`
      )
      await this.$store.dispatch('loadDishList', { force: true })
      if (orderId) {
        await this.$store
          .dispatch('loadOrder', { uid: this.uid, orderId, source })
          .then(observer => {
            this.observer = observer
            this.loadOrder()
          })

        this.review = await this.$store.dispatch('loadOrderReview', { orderId })
      } else {
        this.$store.dispatch('setError', {
          message: 'Unable to load order: ' + orderId
        })
      }
    },

    loadOrder() {
      if (this.myOrder) {
        this.cooking_status = this.myOrder.cooking_status

        if (!this.myOrder.seller) {
          if (this.myOrder.chefId && this.user.id !== this.myOrder.chefId) {
            this.$store.dispatch('loadChefById', this.myOrder.chefId)
            this.buyer = true
          }
        }

        if (
          this.myOrder.buyerUserId &&
          this.user.id !== this.myOrder.buyerUserId
        ) {
          this.buyer = false
          this.$store.dispatch('loadUserProfile', {
            uid: this.myOrder.buyerUserId
          })
        }
      }
    },

    setStatus(success) {
      let request = {
        uid: this.uid,
        response: this.response,
        id: this.$route.params.order_id,
        chefResponse: this.response
      }
      if (success) {
        // pick up case
        if (this.myOrder.deliveryMethod === 'pickup') {
          request.status = 'picking_up'
        }
        // self-delivery case
        else if (
          this.myOrder.deliveryMethod === 'Delivery' &&
          !this.myOrder.delivery.provider
        ) {
          request.status = 'delivering'
        }
      } else {
        // pick up case
        if (this.myOrder.deliveryMethod === 'pickup') {
          request.status = 'no_pickup'
        }
        // self-delivery case
        else if (
          this.myOrder.deliveryMethod === 'Delivery' &&
          !this.myOrder.delivery.provider
        ) {
          request.status = 'no_delivery'
        }
      }
      if (request.status === 'delivering' || request.status === 'picking_up') {
        request.deliveryDate = new Date().getTime()
      }
      console.log('Submitting', request)
      this.$store.dispatch('setOrderStatus', request)
      this.$router.push('/order/list')
    },
    acceptOrder() {
      this.acceptApplied = true

      let request = {
        uid: this.uid,
        response: this.response,
        acceptDate: moment().format(),
        id: this.$route.params.order_id,
        status: 'accepting'
      }

      this.$store.dispatch('setOrderStatus', request)
      this.$router.push('/order/list')
    },
    showCancel() {
      this.cancelOrRejectStatus = 'cancelling'
      this.cancelOrRejectDialog = true
    },
    showRefundBox(showRefund) {
      this.showRefund = showRefund
    },
    showReject() {
      this.cancelOrRejectStatus = 'rejecting'
      this.cancelOrRejectDialog = true
    },
    cancelOrRejectOrder() {
      let request = {
        uid: this.uid,
        response: this.response,
        id: this.$route.params.order_id,
        status: this.cancelOrRejectStatus
      }
      this.$store.dispatch('setOrderStatus', request)
      this.$router.push('/order/list')
      this.cancelOrRejectDialog = false
    },
    archive() {
      console.log('Archiving', this.myOrder)
      let request = {
        uid: this.uid,
        id: this.$route.params.order_id,
        archive: true
      }
      this.$store.dispatch('setOrderStatus', request).then(() => {
        this.$router.push('/order/list')
      })
    },
    checkInventory() {
      if (this.myOrder.products) {
        for (let index = 0; index < this.myOrder.products.length; index++) {
          const item = this.myOrder.products[index]
          let dish = this.dishListAsMap[item.dish.id]
          item.dish = dish
          this.myOrder.products[index] = item
          if (
            item.dish.amountLeft < item.orderAmount &&
            item.dish.amountLeft != -1
          ) {
            this.hasEnoughAmount = false
          }
        }
      }
    },
    updateCookingStatus() {
      console.log('Saving cooking status: ', this.cooking_status)
      if (!this.cooking_status) {
        console.warn('Status is empty')
        return
      }
      this.$store
        .dispatch('setCookingStatus', {
          id: this.$route.params.order_id,
          cooking_status: this.cooking_status
        })
        .then(() => {
          this.$store.dispatch('setMessage', {
            title: 'Cooking Status',
            body: 'saved'
          })
        })
    },

    onCancelDelivery() {
      this.$refs.deleteConfirmationDialog.openDialog()
    },
    onResubmitDelivery() {
      this.$refs.resubmitOnfirmationDialog.openDialog()
    },
    resubmitDelivery() {
      this.$refs.resubmitOnfirmationDialog.close()
      console.log('Resubmitting Delivery:', this.myOrder.orderId)

      this.$store.dispatch('resubmitDeliveryRequest', {
        id: this.myOrder.orderId,
        uid: this.uid
      })
      this.$store.dispatch('setMessage', {
        title: 'Request Submitted',
        body: 'Thanks'
      })
    },
    cancelDelivery() {
      this.$refs.deleteConfirmationDialog.close()
      console.log('Canceling Delivery:', this.myOrder.orderId)

      this.$store.dispatch('setDeliveryRequest', {
        id: this.myOrder.orderId,
        uid: this.uid,
        code: 'cancelling'
      })
      this.$store.dispatch('setMessage', {
        title: 'Request Submitted',
        body: 'Thanks'
      })
    }
  }
}
</script>
<style scoped>
.status_title {
  font-size: 1em;
}
.fsx-12 {
  font-size: 0.8em;
}
</style>
