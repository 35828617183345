<template>
  <v-row align="center" no-gutters>
    <v-col cols="6" sm="4">
      <v-text-field
        v-model="name"
        label="Type product name"
        append-icon="search"
        clearable
      ></v-text-field>
    </v-col>
    <v-col cols="1"></v-col>
    <v-col v-if="menus" cols="6" sm="3">
      <v-combobox
        dense
        v-model="filteredMenus"
        :items="menus"
        item-text="name"
        item-value="id"
        label="Menu filter"
        multiple
        class="px-2"
      ></v-combobox>
    </v-col>
    <v-col cols="3">
      <v-checkbox
        dense
        v-model="inMarket"
        label="Available In Market"
        class="ml-2"
      ></v-checkbox>
    </v-col>
    <v-col v-if="selectable" cols="2">
      <v-checkbox
        v-model="selectAll"
        @change="selectAllProducts"
        label="Select All"
      ></v-checkbox>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: 'search-product',
  props: {
    dishList: {
      type: Array,
      default: null
    },
    menus: {
      type: Array,
      default: null
    },
    selectable: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    inMarket: false,
    name: '',
    selectAll: false,
    filteredMenus: []
  }),
  watch: {
    filteredMenus(nv) {
      let list = this.dishList
      let trimName = this.name ? this.name.trim().toLocaleLowerCase() : ''

      if (this.inMarket) {
        list = this.dishList
          .filter(d => d.visibility === 'public')
          .filter(d => d.name.toLocaleLowerCase().includes(trimName))
      } else {
        list = this.dishList.filter(
          dish => dish.name && dish.name.toLocaleLowerCase().includes(trimName)
        )
      }
      if (nv && nv.length > 0) {
        const productsInMenus = nv.map(d => d.items).flat()
        list = list.filter(e => productsInMenus.includes(e.id))
      }
      this.$emit('filterProduct', list)
    },
    inMarket(val) {
      let list = this.dishList
      let trimName = this.name ? this.name.trim().toLocaleLowerCase() : ''

      if (val) {
        list = this.dishList
          .filter(d => d.visibility === 'public')
          .filter(d => d.name.toLocaleLowerCase().includes(trimName))
      } else {
        list = this.dishList.filter(
          dish => dish.name && dish.name.toLocaleLowerCase().includes(trimName)
        )
      }
      if (this.filteredMenus && this.filteredMenus.length > 0) {
        const productsInMenus = this.filteredMenus.map(d => d.items).flat()
        list = list.filter(e => productsInMenus.includes(e.id))
      }
      this.$emit('filterProduct', list)
    },
    name(val) {
      let list = this.dishList

      if (val && val.trim().length > 0) {
        let search = val.trim().toLocaleLowerCase()
        list = this.dishList.filter(
          dish => dish.name && dish.name.toLocaleLowerCase().includes(search)
        )
        if (this.inMarket) {
          list = list.filter(d => d.visibility === 'public')
        }
      } else {
        list = this.dishList
      }

      if (this.filteredMenus && this.filteredMenus.length > 0) {
        const productsInMenus = this.filteredMenus.map(d => d.items).flat()
        list = list.filter(e => productsInMenus.includes(e.id))
      }
      this.$emit('filterProduct', list)
    }
  },
  methods: {
    selectAllProducts() {
      this.$emit('onSelectAll', this.selectAll)
    }
  }
}
</script>
