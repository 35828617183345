import firebase from '@/firebase-init'

export default {
  state: {
    review: null,
    chefRating: null
  },
  mutations: {
    setReview(state, payload) {
      state.review = payload
    },
    setChefRating(state, payload) {
      state.chefRating = payload
    }
  },
  actions: {
    // Set Review as
    // /reviews/chefs/<chefId>/<orderId>/
    saveChefReview({ commit, getters }, payload) {
      let uid = getters.user.id
      ;(payload.reviewer = uid), (payload.updatedTime = new Date().getTime())

      commit('setLoading', true)
      const orderRef = firebase
        .firestore()
        .collection('reviews')
        .doc(payload.type)
        .collection(payload.chefId)
        .doc(payload.orderId)
      return orderRef
        .set(payload)
        .then(function() {
          commit('setLoading', false)
          commit('setReview', payload)
          console.log('Review successfully updated!')
        })
        .catch(function(error) {
          // The document probably doesn't exist.
          commit('setLoading', false)
          console.error('Error updating document: ', error)
        })
    },
    /// reviews/chefs/<chefId>/<orderId>/
    loadChefReview({ commit }, payload) {
      //let uid = getters.user.id
      console.log(
        `Loading reviews for chef ${payload.id} and order ${payload.orderId}`
      )
      commit('setLoading', true)
      const orderRef = firebase
        .firestore()
        .collection('reviews')
        .doc(payload.type)
        .collection(payload.id)
        .doc(payload.orderId)
      return orderRef
        .get()
        .then(data => {
          if (data.exists) {
            let review = data.data()

            //if (review.reviewer === uid) {
            commit('setLoading', false)
            commit('setReview', review)
            console.log('Review successfully loaded!')
            return review
            /*} else {
              commit('setError', { message: 'Unable to load different user review' })
            } */
          }
        })
        .catch(function(error) {
          // The document probably doesn't exist.
          commit('setLoading', false)
          console.error('Error updating document: ', error)
        })
    },
    getChefRating({ commit }, chefId) {
      //let uid = getters.user.id
      console.log(`Loading ratings for chef ${chefId} `)
      //commit('setLoading', true)
      // admin/accounts/chefs/<chefId>
      const orderRef = firebase
        .firestore()
        .collection('admin')
        .doc('accounts')
        .collection('chefs')
        .doc(chefId)
      return orderRef
        .get()
        .then(data => {
          //commit('setLoading', false)
          if (data.exists) {
            let rating = data.data()

            //if (review.reviewer === uid) {
            commit('setChefRating', rating)
            console.log('Review successfully loaded!')
            return rating
            /*} else {
              commit('setError', { message: 'Unable to load different user review' })
            } */
          }
        })
        .catch(function(error) {
          // The document probably doesn't exist.
          commit('setLoading', false)
          console.error('Error reading document: ', error)
        })
    },
    saveDishReview({ commit, getters }, payload) {
      let uid = getters.user.id
      payload.reviewer = payload.reviewer.uid || uid
      payload.updatedTime = new Date().getTime()

      commit('setLoading', true)
      const orderRef = firebase
        .firestore()
        .collection('reviews')
        .doc('dishes')
        .collection(payload.foodId)
        .doc(payload.orderId)
      return orderRef
        .set(payload)
        .then(function() {
          commit('setLoading', false)
          commit('setReview', payload)
          console.log('Review successfully updated!')
        })
        .catch(function(error) {
          // The document probably doesn't exist.
          commit('setLoading', false)
          console.error('Error updating document: ', error)
        })
    },
    loadDishReview({ commit }, payload) {
      //let uid = getters.user.id
      console.log(
        `Loading reviews for dish ${payload.foodId} and order ${payload.orderId}`
      )
      commit('setLoading', true)
      const orderRef = firebase
        .firestore()
        .collection('reviews')
        .doc('dishes')
        .collection(payload.foodId)
        .doc(payload.orderId)
      return orderRef
        .get()
        .then(data => {
          if (data.exists) {
            let review = data.data()

            //if (review.reviewer === uid) {
            commit('setLoading', false)
            commit('setReview', review)
            console.log('Review successfully loaded!')
            return review
            /*} else {
              commit('setError', { message: 'Unable to load different user review' })
            } */
          }
        })
        .catch(function(error) {
          // The document probably doesn't exist.
          commit('setLoading', false)
          console.error('Error updating document: ', error)
        })
    }
  },
  getters: {
    review: state => {
      return state.review
    },
    chefRating: state => {
      return state.chefRating
    }
  }
}
