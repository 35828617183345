import firebase from '@/firebase-init'
import moment from 'moment'

export default {
  state: {
    userSettings: null,
    deliverState: true,
    newAppVersion: false,
    systemUpdates: []
  },
  mutations: {
    setUserSettings(state, payload) {
      state.userSettings = payload
    },
    setDeliverState(state, payload) {
      state.deliverState = payload
    },
    setSystemUpdates(state, payload) {
      state.systemUpdates = payload
    },
    setNewAppVersion(state, payload) {
      state.newAppVersion = payload
    }
  },
  actions: {
    changeDeliverState({ commit }, payload) {
      commit('setDeliverState', payload)
    },
    saveSettings(context, payload) {
      const profileRef = firebase
        .firestore()
        .collection('profile')
        .doc(context.getters.user.id)

      return profileRef
        .set(
          {
            settings: payload
          },
          { merge: true }
        )
        .then(function() {
          // context.commit('setMessage', {
          //   title: 'Saved',
          //   message: 'Setting saved successfully'
          // })
        })
        .catch(function(error) {
          context.commit('setLoading', false)
          console.warn('Transaction failed: ', error)
          context.commit('setError', {
            title: 'Unable to complete the save?',
            message: 'Unable to complete the save'
          })
        })
    },
    saveDeliverySettings({ commit, getters }, payload) {
      if (!getters.user) {
        console.error('error, not user detected')
        return
      }
      commit('setLoading', true)
      let uid = getters.user.id
      console.log(`Saving settings of uid ${uid}`, payload)
      return firebase
        .firestore()
        .collection('profile')
        .doc(uid)
        .set({ settings: payload }, { merge: true })
        .then(() => {
          commit('setLoading', false)
          commit('setUserSettings', payload)
          return payload
        })
        .catch(error => {
          commit('setLoading', false)
          commit('setError', error)
          console.log(error)
          return null
        })
    },
    getUserSettings({ commit, getters }) {
      if (!getters.user) {
        console.error('error, not user detected')
        return
      }

      if (getters.profile) {
        commit('setUserSettings', getters.profile.settings)
        return
      }

      commit('setLoading', true)
      let uid = getters.user.id
      console.log(`Getting settings of uid ${uid}`)
      return firebase
        .firestore()
        .collection('profile')
        .doc(uid)
        .get()
        .then(data => {
          if (data.exists) {
            let profile = data.data()
            commit('setUserSettings', profile.settings)
          }
          commit('setLoading', false)
          return data
        })
        .catch(error => {
          commit('setLoading', false)
          commit('setError', error)
          console.log(error)
          return null
        })
    },
    loadDefaultDeliverySubsidy() {
      return firebase
        .firestore()
        .doc('/admin/settings')
        .get()
        .then(d => d.data())
        .catch(e => {
          console.error(e)
        })
    },
    updateSubsidySetting({ commit, getters }, payload) {
      if (!getters.user) {
        console.error('error, not user detected')
        return
      }
      const uid = getters.user.id
      console.log(`Updating subsidy settings of uid ${uid}`, payload)
      return firebase
        .firestore()
        .collection('profile')
        .doc(uid)
        .set({ settings: { ...payload } }, { merge: true })
        .then(() => {
          commit('setUserSettings', payload)
          return payload
        })
        .catch(error => {
          commit('setLoading', false)
          commit('setError', error)
          console.log(error)
          return null
        })
    },
    getUserSetting(context, payload) {
      let uid = context.getters.user.id
      return firebase
        .firestore()
        .collection('profile')
        .doc(uid)
        .collection('settings')
        .doc(payload.type)
        .get()
        .then(d => d.data())
        .catch(e => {
          console.error(e)
        })
    },
    saveUserSetting({ commit, getters }, payload) {
      commit('setLoading', true)
      let uid = getters.user.id
      firebase
        .firestore()
        .collection('profile')
        .doc(uid)
        // .collection('settings')
        // .doc(payload.type)
        .set(
          {
            delivery_subsidy: payload.data
          },
          { merge: true }
        )
        .then(() => {
          commit('setLoading', false)
          commit('setMessage', { title: 'Saved' })
        })
        .catch(e => {
          commit('setLoading', false)
          console.error(e)
        })
    },
    saveSubSetting({ commit, getters }, payload) {
      commit('setLoading', true)
      let uid = getters.user.id
      firebase
        .firestore()
        .collection('profile')
        .doc(uid)
        .collection('settings')
        .doc(payload.type)
        .set(
          {
            delivery_subsidy: payload.data
          },
          { merge: true }
        )
        .then(() => {
          commit('setLoading', false)
          commit('setMessage', { title: 'Saved' })
        })
        .catch(e => {
          commit('setLoading', false)
          console.error(e)
        })
    },
    async getSystemUpdates(context, payload) {
      context.commit('setLoading', true)
      const pref = await firebase
        .firestore()
        .doc(`/profile/${context.getters.user.id}/settings/system_updates`)
        .get()
        .then(d => d.data())

      return firebase
        .firestore()
        .collection('admin/updates/product')
        .where('target_user', 'array-contains', 'producers')
        .get()
        .then(snapshot => {
          let list = []
          snapshot.forEach(data => {
            const obj = data.data()
            obj.id = data.id
            if (pref && pref.last_dismiss_date && payload.recent) {
              if (pref.last_dismiss_date < obj.createdTime) {
                list.push(obj)
              }
            } else {
              list.push(obj)
            }
          })
          context.commit('setSystemUpdates', list)
          context.commit('setLoading', false)
          return list
        })
    },
    updateDismiss(context) {
      return firebase
        .firestore()
        .doc(`/profile/${context.getters.user.id}/settings/system_updates`)
        .set({ last_dismiss_date: moment.utc().valueOf() }, { merge: true })
        .catch(function(error) {
          console.warn('Unable to update the dismiss: ', error)
        })
    },
    checkVersion(ctx) {
      const remoteConfig = firebase.remoteConfig()
      remoteConfig.settings = {
        fetchTimeoutMillis: 10000,
        minimumFetchIntervalMillis:
          process.env.VUE_APP_ENV === 'production'
            ? 1000 * 60 * 10
            : 1000 * 60 * 1
      }

      remoteConfig
        .fetchAndActivate()
        .then(() => {
          const biz_app_version = remoteConfig
            .getValue('biz_app_version')
            .asString()

          console.log(
            `biz_app_version: ${biz_app_version} and current version is : ${process.env.VUE_APP_VERSION}`
          )

          ctx.commit(
            'setNewAppVersion',
            process.env.VUE_APP_VERSION !== biz_app_version
          )
        })
        .catch(err => {
          console.error(err)
        })
    }
  },
  getters: {
    userSettings: state => state.userSettings,
    deliverState: state => state.deliverState,
    systemUpdates: state => state.systemUpdates.filter(s => s.active),
    newAppVersion: state => state.newAppVersion
  }
}
