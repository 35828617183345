<template>
  <!-- <v-row>
    <v-col cols="12" class="pa-0 ma-0">
      <div v-for="(modifier, name) in list" :key="modifier.id" class="d-flex justify-start">
        <span class="font-weight-medium font-italic">{{ name }}:</span
        >
        <span v-for="(value, key) in modifier" :key="key">
          {{ value.name }}
          <span v-if="value.price > 0"> ({{ value.price | currency }})&nbsp;&nbsp;</span>
        </span>
      </div>
    </v-col>
  </v-row>-->

  <v-list>
    <template v-for="(modifier, name) in list" class="d-flex justify-start">
      <span class="body-2 font-weight-bold" v-if="name" :key="name">
        <v-icon small color="info">mdi-arrow-right</v-icon> {{ name }}:
      </span>

      <template v-for="value in modifier">
        <v-list-item-content :key="value.id" class="pa-1 mx-5">
          <v-row no-gutters justify="space-between">
            <v-col cols="6" class="body-2"> {{ value.name }}</v-col>

            <v-col cols="2" md="1">
              <v-spacer></v-spacer>
              <span class="caption">{{ value.price | currency }} </span></v-col
            >
          </v-row>

          <!-- <v-list-item-subtitle v-html="item.subtitle"></v-list-item-subtitle> -->
        </v-list-item-content>
      </template>
    </template>
  </v-list>
</template>

<script>
export default {
  props: {
    modifiers: {
      type: Object,
    },
  },
  computed: {
    list() {
      let my = {};
      if (this.modifiers) {
        Object.values(this.modifiers).forEach((item) => {
          if (item.modifier && item.modifier.options) {
            let selected = item.modifier.options.filter((option) =>
              item.selected.map((i) => `${i}`).includes(`${option.id}`)
            );
            if (selected) {
              my[item.modifier.name] = selected;
            }
          }
        });
      }
      return my;
    },
  },
};
</script>
