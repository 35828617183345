<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12">
        <v-sheet class="shadow pa-8">
          <v-row class="my-4" v-if="product">
            <v-col cols="12">
              <span class="grey--text subtitle-2"
                >{{ $t('Common.product_name') }}:</span
              >
              <span class="main_text--text subtitle-2">{{ product.name }}</span>
            </v-col>
          </v-row>
          <v-row class="my-4">
            <v-col>
              <v-btn
                color="takein"
                dark
                :ripple="false"
                depressed
                to="/profile/wizard/menus/new_modifier/new"
              >
                <v-icon left>mdi-plus</v-icon>
                {{ $t('Common.add_modifier') }}
              </v-btn>
            </v-col>
          </v-row>

          <v-row class="my-4" v-if="product">
            <v-col>
              <span class="main_text--text">
                {{ $t('Common.product_modifiers') }}
              </span>
            </v-col>
          </v-row>

          <v-row class="my-4" v-if="hasModifiers" justify="start">
            <v-col cols="12">
              <v-list>
                <v-list-group
                  v-for="item in productModifiers"
                  :key="item.id"
                  v-model="item.active"
                  no-action
                >
                  <template v-slot:activator>
                    <v-list-item>
                      <v-btn
                        @click="updateModifier(false, item)"
                        color="red"
                        text
                        class="mr-4"
                      >
                        <v-icon>remove</v-icon>
                      </v-btn>

                      <v-list-item-content>
                        <v-list-item-title>{{ item.name }}</v-list-item-title>
                      </v-list-item-content>
                      <v-btn
                        icon
                        text
                        color="success"
                        :to="
                          '/profile/wizard/menus/new_modifier/edit/' + item.id
                        "
                      >
                        <v-icon>edit</v-icon>
                      </v-btn>
                    </v-list-item>
                  </template>

                  <v-list-item
                    v-for="subItem in item.options"
                    :key="subItem.name"
                  >
                    <v-list-item-content>
                      <v-list-item-title>{{ subItem.name }}</v-list-item-title>
                    </v-list-item-content>
                    <small>{{ subItem.available ? '' : 'NA' }}</small>
                    <v-list-item-action>
                      <span>{{ subItem.price | currency }}</span>
                    </v-list-item-action>
                  </v-list-item>
                </v-list-group>
              </v-list>
            </v-col>
          </v-row>

          <v-row class="my-4">
            <v-col>
              <span class="main_text--text">
                {{ $t('Common.available_modifiers') }}
              </span>
            </v-col>
          </v-row>
          <v-row
            class="my-4"
            v-if="modifiersList && modifiersList.length > 0"
            justify="start"
          >
            <v-col cols="12">
              <v-list>
                <v-list-group
                  v-for="item in modifiersList"
                  :key="item.id"
                  v-model="item.active"
                  no-action
                >
                  <template v-slot:activator>
                    <v-list-item>
                      <v-btn
                        v-if="dish"
                        @click="updateModifier(true, item)"
                        text
                        class="mr-4"
                      >
                        <v-icon color="green">add</v-icon>
                      </v-btn>

                      <v-list-item-content>
                        <v-list-item-title>{{ item.name }}</v-list-item-title>
                      </v-list-item-content>
                      <v-btn
                        icon
                        text
                        color="success"
                        :to="
                          '/profile/wizard/menus/new_modifier/edit/' + item.id
                        "
                      >
                        <v-icon>edit</v-icon>
                      </v-btn>
                    </v-list-item>
                  </template>

                  <v-list-item
                    v-for="subItem in item.options"
                    :key="subItem.name"
                  >
                    <v-list-item-content>
                      <v-list-item-title>{{ subItem.name }}</v-list-item-title>
                    </v-list-item-content>
                    <small>{{ subItem.available ? '' : 'NA' }}</small>
                    <v-list-item-action>
                      <span>{{ subItem.price | currency }}</span>
                    </v-list-item-action>
                  </v-list-item>
                </v-list-group>
              </v-list>
            </v-col>
          </v-row>

          <!-- <v-row>
            <v-col cols="12">
              
              <v-btn class="mr-3" color="red" dark @click="$router.go(-1)" v-if="product_id">
                <v-icon left>keyboard_backspace</v-icon>Go Back to Dish
              </v-btn>
              <v-btn color="info" to="/product/modifier/new">
                <v-icon left>add</v-icon>new Modifier
              </v-btn>
            </v-col>
          </v-row>-->
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  data() {
    return { modifiersList: null, product: null }
  },
  computed: {
    ...mapGetters({
      profile: 'profile',
      dish: 'dish',
      modifiers: 'modifiers'
    }),
    hasModifiers() {
      return (
        this.product &&
        this.product.modifiers &&
        Object.keys(this.product.modifiers).length > 0
      )
    },
    productModifiers() {
      if (this.product && this.product.modifiers) {
        return this.product.modifiers
      }
      return []
    }
  },
  mounted() {
    if (this.$route.params.id && this.dish) {
      this.product = this.dish
      this.$store.dispatch('loadModifiers').then(list => {
        this.modifiersList =
          list.length > 0 && this.hasModifiers
            ? list.filter(mod => this.product.modifiers[mod.id] == null)
            : list
      })
    } else {
      this.$store.dispatch('loadModifiers').then(list => {
        this.modifiersList = list
      })
    }
  },
  methods: {
    updateModifier(addModToDish, item) {
      if (addModToDish) {
        if (!this.product.modifiers) {
          this.product.modifiers = {}
        }
        this.product.modifiers[item.id] = item
        this.modifiersList = this.modifiersList.filter(
          mod => mod.id !== item.id
        )
      } else {
        delete this.product.modifiers[item.id]
        this.modifiersList.push(item)
      }

      this.$store.dispatch('saveDish', { food: this.product }).then(id => {
        console.log('Saving product:', id)
        this.$store.dispatch('loadDishList').then(list => {
          this.product = list.filter(item => item.id === this.product.id)[0]
        })
      })
    }
  }
}
</script>
<style scoped>
.shadow {
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
    0px 4px 8px rgba(0, 0, 0, 0.04);
}
</style>
