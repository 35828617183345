<template>
  <v-container v-if="!loading && customer">
    <v-row>
      <v-col cols="12">
        <h4>You are reviewing {{ customer.name }}</h4>
        <router-link :to="'/profile/' + customer.id">
          <v-avatar :tile="customer.name" class="grey lighten-4">
            <img
              v-if="customer.photoUrl"
              :src="customer.photoUrl"
              alt="avatar"
            />
          </v-avatar>
        </router-link>
      </v-col>
    </v-row>
    <v-row class="mt-5">
      <v-col cols="12" sm="7">
        <h3>Overall Rating</h3>
        <star-rating v-model="rating" :rating="rating"></star-rating>
      </v-col>
    </v-row>
    <v-row class="mt-3">
      <v-col cols="12" sm="7">
        <v-text-field
          multi-line
          name="review"
          rows="2"
          placeholder="Few words about the customer"
          label="Public Review"
          id="review"
          v-model="publicComment"
        >
        </v-text-field>
      </v-col>
    </v-row>

    <v-row class="mt-3">
      <v-col cols="12" sm="7">
        <v-text-field
          multi-line
          name="privateComment"
          rows="2"
          placeholder="Only chef will see this message."
          label="Private Comment"
          v-model="privateComment"
        >
        </v-text-field>
      </v-col>
    </v-row>

    <v-row class="mt-3">
      <v-col cols="12" sm="7">
        <v-text-field
          multi-line
          name="feedback"
          rows="2"
          placeholder="Send your feed to FOD team. This is also private"
          label="Feedback to FOD"
          v-model="feedback"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn dark color="info darken-3" @click.stop="submitReview"
          >Submit</v-btn
        >
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      publicComment: null,
      privateComment: null,
      feedback: null,
      rating: null
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
      customer: 'chef',
      review: 'review',
      loading: 'loading'
    })
  },
  mounted() {
    let customer_id = this.$route.params.id
    let orderId = this.$route.params.order_id
    console.log('Loading customer ', customer_id)
    this.$store.dispatch('loadChefById', customer_id)
    this.$store
      .dispatch('loadChefReview', {
        id: customer_id,
        orderId,
        type: 'customers'
      })
      .then(data => {
        if (data) {
          this.rating = data.rating
          this.publicComment = data.publicComment
          this.privateComment = data.privateComment
          this.feedback = data.feedback
        }
      })
    console.log('Trying to order foodId', customer_id, orderId)
  },
  methods: {
    submitReview() {
      let review = {
        chefId: this.$route.params.id,
        orderId: this.$route.params.order_id,
        privateComment: this.privateComment,
        publicComment: this.publicComment,
        feedback: this.feedback,
        rating: this.rating,
        type: 'customers'
      }
      this.$store.dispatch('saveChefReview', review)
      console.log('Submitting review', review)
    }
  }
}
</script>
