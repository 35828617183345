<template>
  <div>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-row align="center">
        <v-col cols="12" sm="6" md="4" class="pr-5">
          <!-- <v-text-field
            :label="$t('Chef.price_per_unit')"
            v-model="all.unitPrice"
            placeholder="10.00"
            @change="update"
            prefix="$"
          ></v-text-field> -->
          <v-alert
            outlined
            v-if="priceIsZero"
            color="secondary"
            :value="true"
            icon="warning"
            >The price can't be zero
          </v-alert>
          <v-alert
            outlined
            v-if="inventoryIsZero"
            color="secondary"
            :value="true"
            icon="warning"
            >Inventory cannot be Empty
          </v-alert>
          <v-currency-field
            :label="$t('Chef.price_per_unit')"
            :hint="$t('Chef.price_per_unit_hint')"
            v-bind="currency_config"
            :error-messages="errors.positive"
            v-model="price"
            :rules="[rules.positive]"
          ></v-currency-field>
        </v-col>
        <v-col cols="6" sm="4" class="offset-sm1" v-if="false">
          <v-text-field
            min="0"
            type="number"
            :rules="[rules.positive]"
            :label="$t('Chef.minimum_order')"
            v-model="all.minimumOrder"
            @change="update"
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="4">
          <small @click.stop="getPromotions()">Set Coupons and Promotion</small>
          <v-icon class="px-3" color="cyan" @click.stop="getPromotions()"
            >fas fa-hand-holding-usd</v-icon
          >
          <small v-if="all.promotion && all.promotion.length > 0"
            >has {{ all.promotion.length }}</small
          >
          <template v-if="validPromotions && validPromotions.length > 0">
            <v-select
              :items="validPromotions"
              v-model="all.promotion"
              @change="update"
              item-text="title"
              item-value="id"
              multiple
              label="Promotion"
            ></v-select>
          </template>
          <template v-else-if="validPromotions && validPromotions.length == 0">
            <small class="red--text">No promotion found</small>
          </template>
        </v-col>
      </v-row>
      <v-row>
        <!-- <v-col cols="12" sm="6" md="4" class="pr-5">
          <v-text-field
            placeholder="Units"
            :label="$t('Chef.init_inventory')"
            :hint="$t('Chef.init_inventory_hint')"
            prefix="#"
            mask="##"
            @change="update"
            :rules="[rules.amountRule, rules.positive]"
            v-model="all.totalServing"
          ></v-text-field>
        </v-col> -->
        <v-col cols="6" sm="4" class="pr-5">
          <v-text-field
            name="left"
            placeholder="Units"
            :label="$t('Chef.inventory_available_now')"
            :hint="$t('Chef.inventory_available_now')"
            prefix="#"
            mask="##"
            @change="changeAmount"
            :disabled="inventoryIsUnlimited"
            v-model="tempAmount"
          ></v-text-field>

          <v-alert
            outlined
            v-if="showAmountLeftMessage"
            color="secondary"
            :value="true"
            icon="warning"
            >No amount left or sold out
          </v-alert>
        </v-col>
        <v-col cols="6" sm="4" class="pr-5">
          <!-- checkbox for unlimited inventory option -->
          <v-checkbox
            v-model="inventoryIsUnlimited"
            label="Inventory is Unlimited"
            color="primary"
            @change="changeUnlimitedInventory"
          >
          </v-checkbox>
        </v-col>
        <v-col cols="12" sm="4" class="pr-5">
          <!-- checkbox for hide product if not available for ordering -->
          <v-checkbox
            v-model="all.hideNotAvailable"
            label="Hide if not available"
            color="primary"
            @change="changeHideNotAvailable"
          >
          </v-checkbox>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="6" md="3" class="pr-5">
          <v-text-field
            placeholder="Lead time in minutes"
            :label="$t('Chef.lead_time')"
            :hint="$t('Chef.lead_time_hint')"
            @change="update"
            mask="##"
            :suffix="all.leadTime ? 'minutes' : ''"
            prepend-icon="access_time"
            v-model="all.leadTime"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="5" class="pr-5">
          <v-text-field
            name="left"
            :label="$t('Chef.pre_order_deadline')"
            :placeholder="$t('Chef.pre_order_deadline_p')"
            :hint="$t('Chef.pre_order_deadline_hint')"
            @change="update"
            mask="##"
            prepend-icon="calendar_today"
            v-model.number="all.preOrderDeadline"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-select
            :disabled="!all.preOrderDeadline"
            label="Unit"
            v-model="all.deadlineUnit"
            :items="['Hour', 'Day', 'Week']"
          >
          </v-select>
        </v-col>
      </v-row>

      <v-row class="my-2">
        <v-col cols="12">
          <v-btn
            small
            color="primary"
            id="dishSavePrice"
            :disabled="!valid"
            @click="checkPrice"
            class="mr-3"
            >Save Price</v-btn
          >
          <v-btn
            small
            color="gray"
            text
            id="dishSavePriceCancel"
            :to="'/product/list/' + uid"
            >Cancel</v-btn
          >
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import Vue from 'vue'

import currency from 'v-currency-field'
Vue.use(currency)
export default {
  // components: {
  //   'v-currency-field': currency
  // },
  props: [
    'uid',
    'status',
    'unitPrice',
    'amountLeft',
    'minimumOrder',
    'leadTime',
    'promotion',
    'preOrderDeadline',
    'deadlineUnit',
    'hideNotAvailable'
  ],
  data() {
    return {
      all: {
        unitPrice: 0.0,
        amountLeft: 0,
        minimumOrder: 1,
        leadTime: null,
        deadlineUnit: 'Day',
        promotion: [],
        hideNotAvailable: null,
        preOrderDeadline: null
      },
      valid: true,
      errors: {},
      price: 0,
      priceIsZero: false,
      inventoryIsZero: false,
      promotions: '',
      inventoryIsUnlimited: false,
      tempAmount: '',
      currency_config: {
        decimal: '.',
        thousands: ',',
        prefix: '$ ',
        precision: 2,
        masked: false,
        allowBlank: false,
        min: 0.0,
        max: Number.MAX_SAFE_INTEGER
      },
      rules: {
        positive: value => {
          let val = value ? value.toString() : ''
          val = val.replace(/,/g, '')
          return Number(val) > 0 || 'Should be greater than zero.'
        },
        amount: value => {
          let val = value ? value.toString() : ''
          val = val.replace(/,/g, '')
          if (Number(val) < 1) {
            return this.inventoryIsUnlimited || 'Should be greater than zero.'
          } else {
            return Number(val) > 0 || 'Should be greater than zero.'
          }
        }
      }
    }
  },
  mounted() {
    this.all = {
      unitPrice: this.unitPrice,
      amountLeft: this.amountLeft,
      leadTime: this.leadTime,
      promotion: this.promotion,
      preOrderDeadline: this.preOrderDeadline || 0,
      deadlineUnit: this.deadlineUnit,
      minimumOrder: this.minimumOrder || 1,
      hideNotAvailable: this.hideNotAvailable || null
    }
    this.price = this.unitPrice

    if (this.amountLeft == -1) {
      this.inventoryIsUnlimited = true
    } else {
      this.tempAmount = this.amountLeft
    }
  },
  computed: {
    showAmountLeftMessage() {
      return this.all.amountLeft == 0
    },
    validPromotions() {
      if (this.promotions) {
        return this.promotions.filter(p => !p.all_products)
      }
      return null
    }
  },
  watch: {
    price() {
      this.all.unitPrice = this.price
      this.$emit('onUpdatePrice', this.all)
    }
  },
  methods: {
    checkPrice() {
      if (!this.price || this.price === 0) {
        this.priceIsZero = true
      } else if (!this.all.amountLeft || this.all.amountLeft === 0) {
        this.inventoryIsZero = true
      } else {
        this.savePrice()
      }
    },
    update() {
      console.log(this.all, 'this.!!!!!!!!!!!!!!')
      this.$emit('onUpdatePrice', this.all)
      if (this.price != 0) {
        this.priceIsZero = false
      }
      if (this.all.amountLeft != 0) {
        this.inventoryIsZero = false
      }
    },
    savePrice() {
      this.$emit('savePrice')
    },
    getPromotions() {
      this.$store.dispatch('getPromotions', { load: false }).then(list => {
        console.log('list:', list)
        this.promotions = list
        let filter = []
        if (this.promotions) {
          filter = this.promotions.map(p => p.id)
        }
        if (this.promotion) {
          let x = this.promotion.filter(x => filter.includes(x))
          this.all.promotion = x
          this.$emit('onUpdatePrice', this.all)
        }
      })
    },
    // sets amountLeft to -1 for unlimited inventory option
    changeUnlimitedInventory() {
      if (this.inventoryIsUnlimited) {
        this.all.amountLeft = -1
        this.tempAmount = ''
      } else {
        this.all.amountLeft = 0
        this.tempAmount = 0
      }
      this.update()
    },
    changeHideNotAvailable() {
      this.update()
    },
    // temp amount is so that the amount text area does not sow -1 if unlimited
    changeAmount() {
      this.all.amountLeft = this.tempAmount
      this.update()
    }
  }
}
</script>
