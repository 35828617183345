<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card v-if="chef" flat>
          <v-row class="mx-auto">
            <v-col cols="10">
              <h4 :class="this.$vuetify.breakpoint.xs ? ' text-center' : ''">
                You are reviewing {{ chef.name }}
              </h4>
            </v-col>

            <v-col cols="2" align-self="center">
              <div :class="this.$vuetify.breakpoint.xs ? 'text-center' : ''">
                <router-link :to="'/chef/' + chef.id">
                  <v-avatar :title="chef.name" class="grey lighten-4">
                    <img :src="chef.avatar" alt="avatar" />
                  </v-avatar>
                </router-link>
              </div>
            </v-col>
          </v-row>
        </v-card>
        <v-row>
          <v-col cols="12" sm="6" align-self="center">
            <h3
              :class="
                this.$vuetify.breakpoint.xs ? 'text-center' : 'title' + ' ma-0'
              "
            >
              Overall Rating
            </h3>
          </v-col>
          <v-col cols="12" sm="6" align-self="center">
            <div :class="this.$vuetify.breakpoint.xs ? 'text-center' : ''">
              <v-rating
                v-model="ratings['overall']"
                :rating="rating"
                background-color="orange lighten-3"
                color="orange"
                large
                :readonly="readonly"
              >
              </v-rating>
            </div>
          </v-col>
        </v-row>
        <v-row class="mx-0">
          <v-col cols="12">
            <v-expansion-panels :mandatory="hasReview">
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <h4 class="mb-0">More rating options</h4>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row>
                    <v-col cols="12" sm="6" align-self="center">
                      <h4>If you messaged Producer, did he/she respond?</h4>
                    </v-col>
                    <v-col cols="12" sm="6" align-self="center">
                      <div
                        :class="
                          this.$vuetify.breakpoint.xs ? 'text-center' : ''
                        "
                      >
                        <v-rating
                          v-model="ratings['responsive']"
                          :rating="rating"
                          background-color="orange lighten-3"
                          color="orange"
                          large
                          :readonly="readonly"
                        >
                        </v-rating>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="6" align-self="center">
                      <h4>
                        If you had a special request, did he/she fulfill it?
                      </h4>
                    </v-col>
                    <v-col cols="12" sm="6" align-self="center">
                      <div
                        :class="
                          this.$vuetify.breakpoint.xs ? 'text-center' : ''
                        "
                      >
                        <v-rating
                          v-model="ratings['special request']"
                          :rating="rating"
                          background-color="orange lighten-3"
                          color="orange"
                          large
                          :readonly="readonly"
                        >
                        </v-rating>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="6" align-self="center">
                      <h4>
                        Were Producer's profile, photos, and descriptions
                        accurate?
                      </h4>
                    </v-col>
                    <v-col cols="12" sm="6" align-self="center" v-if="ratings">
                      <div
                        :class="
                          this.$vuetify.breakpoint.xs ? 'text-center' : ''
                        "
                      >
                        <v-rating
                          v-model="ratings['profile_accurate']"
                          :rating="rating"
                          background-color="orange lighten-3"
                          color="orange"
                          large
                          :readonly="readonly"
                        >
                        </v-rating>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="6" align-self="center">
                      <h4>Would you recommend this Producer?</h4>
                    </v-col>
                    <v-col cols="12" sm="6" align-self="center">
                      <div
                        :class="
                          this.$vuetify.breakpoint.xs ? 'text-center' : ''
                        "
                      >
                        <v-rating
                          v-model="ratings['recommend']"
                          :rating="rating"
                          background-color="orange lighten-3"
                          color="orange"
                          large
                          :readonly="readonly"
                        >
                        </v-rating>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="6" align-self="center">
                      <h4>Would you purchase from this Producer again?</h4>
                    </v-col>
                    <v-col>
                      <div
                        :class="
                          this.$vuetify.breakpoint.xs ? 'text-center' : ''
                        "
                      >
                        <v-rating
                          v-model="ratings['purchase_again']"
                          :rating="rating"
                          background-color="orange lighten-3"
                          color="orange"
                          large
                          :readonly="readonly"
                        >
                        </v-rating>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" align-self="center">
                      <v-textarea
                        name="review"
                        placeholder="Few words about this producer"
                        label="Public Review"
                        id="review"
                        v-model="ratings['publicComment']"
                        :readonly="readonly"
                      >
                      </v-textarea>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-textarea
                        name="privateComment"
                        placeholder="Only chef will see this message."
                        label="Private Comment"
                        v-model="ratings['privateComment']"
                        :readonly="readonly"
                      >
                      </v-textarea>
                    </v-col>
                  </v-row>
                  <v-row v-if="chef">
                    <v-col cols="12">
                      <v-textarea
                        name="feedback"
                        placeholder="Send your feed to TakeIn team. This is also private"
                        label="Feedback to TakeIn"
                        v-model="ratings['feedback']"
                      >
                      </v-textarea>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    chef: { Type: Object },
    data: { Type: Object },
    readonly: { Type: Boolean, default: false },
  },
  computed: {
    hasReview() {
      return (
        !!this.data &&
        !!this.data.ratings &&
        !!(
          this.data.ratings.profile_accurate ||
          this.data.ratings.purchase_again ||
          this.data.ratings.recommend ||
          this.data.ratings.responsive ||
          this.data.ratings.feedback ||
          this.data.ratings.publicComment
        )
      );
    },
  },
  data() {
    return {
      rating: null,
      ratings: {},
    };
  },
  mounted() {
    if (this.data) {
      this.ratings = this.data.ratings || {};
    }
  },
  watch: {
    data: function() {
      if (this.data) {
        this.ratings = this.data.ratings || {};
      }
    },

    ratings: {
      handler: function() {
        this.$emit("onRatingUpdate", {
          ratings: this.ratings,
        });
      },
      deep: true,
    },
  },
};
</script>
