<template>
  <v-container>
    <v-row>
      <v-col cols="12"> <h3>Manage your Condiments and Sauces</h3></v-col>
      <v-col cols="12">
        <h5>
          Save unnecessary costs Send your Condiments and Sauces only if
          customer requests
        </h5>
      </v-col>
      <v-col cols="12" md="8" class="mt-6">
        <v-row
          ><v-col
            ><span class="grey--text"
              >The counts of populating items can't be more than 4.</span
            ></v-col
          ></v-row
        >
        <v-row>
          <v-col cols="10">
            <v-card tile min-height="240px" class="px-2 py-4">
              <template v-if="populatingItems.length">
                <v-list-item
                  two-line
                  v-for="(item, i) in populatingItems"
                  :key="i"
                >
                  <v-list-item-content>
                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                    <v-list-item-subtitle v-if="item.price > 0">{{
                      item.price | currency
                    }}</v-list-item-subtitle>
                    <v-list-item-subtitle v-else>Free</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action @click.stop>
                    <v-btn icon @click.stop.prevent="removeItem(item)">
                      <v-icon class="red--text">mdi-close</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </template>
              <template v-else>
                <span class="grey--text"
                  >No available condiments or sauces.</span
                >
              </template>
            </v-card>
          </v-col>
          <v-col cols="2"
            ><v-btn
              fab
              color="success"
              @click="dialog = true"
              :disabled="populatingItems.length >= 4"
              ><v-icon>mdi-plus</v-icon></v-btn
            ></v-col
          >
        </v-row>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
    <v-snackbar v-model="snackbar" color="success" :timeout="3000">
      The selected condiments are populated.
    </v-snackbar>
    <v-dialog v-model="dialog" max-width="480px" persistent>
      <v-card>
        <v-card-title class="h5">
          Add Condiment or Sauce
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-combobox
                  v-model="addingCondiment.name"
                  :items="items"
                  hide-selected
                  clearable
                  label="Select from List or add your own custom item"
                >
                </v-combobox>
              </v-col>
            </v-row>
            <v-row align="center">
              <v-col cols="6">
                <v-currency-field
                  label="Price"
                  v-bind="currency_config"
                  v-model="addingCondiment.price"
                ></v-currency-field>
              </v-col>
              <v-col cols="6">
                <span class="caption" v-if="addingCondiment.price === 0"
                  >(Free)</span
                >
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="darken-1" @click="dialog = false">
            Cancel
          </v-btn>
          <v-btn
            color="success darken-1"
            :disabled="!addingCondiment.name"
            @click="onAdd"
          >
            Add
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { v4 as uuidv4 } from 'uuid'
import currency from 'v-currency-field'
Vue.use(currency)

export default {
  data() {
    return {
      addingCondiment: {
        key: '',
        id: '',
        name: '',
        price: 0
      },
      dialog: false,
      snackbar: false,
      prepopulatedItems: [
        'Ketchup',
        'Relish',
        'Tartar Sauce',
        'Yellow Mustard',
        'Ranch',
        'Hoisin Sauce',
        'Soy Sauce',
        'Barbecue Sauce',
        'Sriracha',
        'Mayonnaise',
        'Honey Mustard',
        'Vinegar',
        'Salsa'
      ],
      model: '',
      currency_config: {
        decimal: '.',
        thousands: ',',
        prefix: '$ ',
        precision: 2,
        masked: false,
        allowBlank: false,
        min: 0.0,
        max: Number.MAX_SAFE_INTEGER
      },
      items: [],
      populatingItems: []
    }
  },
  computed: {
    ...mapGetters({
      profile: 'profile'
    })
  },
  mounted() {
    if (
      !this.profile ||
      !this.profile.settings ||
      !this.profile.settings.optional_condiments
    )
      return

    this.populatingItems = this.profile.settings.optional_condiments
    this.prepopulatedItems.forEach(item => {
      if (!this.populatingItems.map(n => n.name).includes(item)) {
        this.items.push(item)
      }
    })
  },

  methods: {
    removeItem(item) {
      this.populatingItems = this.populatingItems.filter(
        pItem => pItem.key !== item.key
      )
      this.items = [...this.items, item.name]
      this.$store
        .dispatch('updateCondiments', this.populatingItems)
        .then(() => {
          this.snackbar = true
        })
    },
    onAdd() {
      this.dialog = false
      const unique = uuidv4()

      const newItem = {
        ...this.addingCondiment,
        key: unique,
        id: unique
      }
      this.populatingItems = [...this.populatingItems, newItem]
      this.items = this.items.filter(item => item !== this.addingCondiment.name)
      this.addingCondiment = {
        key: '',
        id: '',
        name: '',
        price: 0
      }
      this.$store
        .dispatch('updateCondiments', this.populatingItems)
        .then(() => {
          this.snackbar = true
        })
    },
    onSave() {}
  }
}
</script>
