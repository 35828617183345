//import NewMessage from "./components/message/NewMessage.vue";
//import ListMessages from "./components/message/ListMessages.vue";
//import UserMenu from "./components/profile/UserMenu.vue";
import messageStore from "./store/message/message";
import i18n from "./lang/i18n";
import globalMixin from "./mixins/globalMixin";
import profileMixin from "./mixins/profileMixin";

//const PostDish = () => import("./components/dish/PostDish.vue");

//export default NewMessage;
export {  messageStore, i18n , globalMixin, profileMixin};
