import firebase, { analytics } from '@/firebase-init'
import _ from 'lodash'
import Geohash from 'latlon-geohash'
import { changeImageUrl, ThumbPrefix } from '../shared/util'
import moment from 'moment'
import Vue from 'vue'

export default {
  state: {
    food: null,
    dish: null,
    nearbyFoods: [],
    nearbyDishes: [],
    foodTypes: [],
    ingredients: [],
    dishList: null,
    chefDishList: null,
    foodCategories: null,
    searchFilter: {},
    searching: false,
    pageSize: 12,
    currentDiets: [],
    currentOrigins: [],
    schedule: null,
    schedules: [],
    modifiers: [],
    menus: [],
    cuisineList: [],
    suggestedProducts: [],
    suggestedMenus: []
  },
  mutations: {
    setFoodTypes(state, payload) {
      state.foodTypes = payload
    },
    setCategories(state, payload) {
      state.foodCategories = payload
    },
    setCuisines(state, payload) {
      state.cuisineList = payload
    },
    setFood(state, payload) {
      state.food = payload
    },
    setDish(state, payload) {
      state.dish = payload
    },
    setDishList(state, payload) {
      state.dishList = payload
    },
    setChefDishList(state, payload) {
      state.chefDishList = payload
    },
    setIngredients(state, payload) {
      state.ingredients = payload
    },
    setNearbyFoods(state, payload) {
      state.nearbyFoods = payload
    },
    setNearbyDishes(state, payload) {
      state.nearbyDishes = payload
    },
    addNearbyFood(state, payload) {
      if (!state.nearbyFoods.find(f => f.id === payload.id)) {
        state.nearbyFoods.push(payload)
      }
    },
    addNearbyDish(state, payload) {
      if (
        !state.nearbyFoods.find(f => f.dish.id === payload.id) &&
        !state.nearbyDishes.find(f => f.id === payload.id)
      ) {
        state.nearbyDishes.push(payload)
      }
    },
    removeDishList(state, id) {
      if (state.dishList) {
        state.dishList = state.dishList.filter(dish => dish.id !== id)
      }
    },
    setSearchFilter(state, payload) {
      state.searchFilter = payload
    },
    updateSearchFilter(state, payload) {
      state.searchFilter = _.merge(state.searchFilter, payload)
    },

    setSearching(state, payload) {
      state.searching = payload
    },
    setPageSize(state, payload) {
      state.pageSize = payload
    },
    updateDiets(state, payload) {
      state.currentDiets = payload
    },
    updateOrigins(state, payload) {
      state.currentOrigins = payload
    },
    updateSuggestedMenus(state, payload) {
      state.suggestedMenus = payload
    },
    updateSuggestedProducts(state, payload) {
      state.suggestedProducts = payload
    },
    setSchedule(state, payload) {
      state.schedule = payload
    },
    setSchedules(state, payload) {
      state.schedules = payload
    },
    setModifiers(state, payload) {
      state.modifiers = payload
    },
    setFoodCategories(state, payload) {
      state.foodCategories = payload
    },
    setMenus(state, payload) {
      state.menus = payload
    }
  },
  actions: {
    async loadFoodTypes(context) {
      let profile = context.getters.profile
      if (!profile) {
        console.error(`profile is empty`)
        return
      }
      let cfo = !!profile.cfo

      if (
        !context.getters.foodCategories ||
        context.getters.foodCategories.length == 0
      ) {
        context.commit('setLoading', true)
        await firebase
          .firestore()
          .collection('types')
          .where('type', '==', 'category')
          .get()
          .then(querySnapshot => {
            let types = []
            querySnapshot.forEach(data => {
              if (data.exists) {
                const obj = data.data()
                obj.id = data.id
                if (obj.type === 'all' || (cfo && obj.type === 'cfo')) {
                  types.push(obj)
                } else {
                  types.push(obj)
                }
              }
            })

            types = types.sort((a, b) => (a.order > b.order ? 1 : -1))

            context.commit('setFoodCategories', types)

            context.commit('setLoading', false)
          })
          .catch(error => {
            console.error(error)
            context.commit('setLoading', false)
            context.commit('setError', error)
          })
      }
      if (!context.getters.diets || context.getters.diets.length == 0) {
        return await firebase
          .firestore()
          .collection('types')
          .get()
          .then(querySnapshot => {
            const types = []
            querySnapshot.forEach(data => {
              if (data.exists) {
                const obj = data.data()
                obj.id = data.id
                /* if (data.id === 'categories') {
                let map = {}
                let list = obj.en
                for (let index = 0; index < list.length; index++) {
                  const element = list[index]

                  if (cfo) {
                    if (element.type === 'cfo') {
                      map[element.name] = `svg/${element.icon}`
                    }
                  } else {
                    if (element.type === 'cater') {
                      map[element.name] = `svg/${element.icon}`
                    }
                  }
                }
                commit('setCategories', map)
              } */

                if (data.id === 'sub_ingredients') {
                  //console.log('sub_ingredients', obj)
                }

                // get cuisine data from fb
                if (data.id === 'cuisines') {
                  let list = obj.en
                  context.commit('setCuisines', list)
                }
                types.push(obj)
              }
            })
            console.log('loaded food types:', types)
            context.commit('setFoodTypes', types)
            return true
          })
          .catch(error => {
            console.log(error)
            context.commit('setLoading', false)
            context.commit('setError', error)
            return false
          })
      }
    },
    loadFoodTypesSimple(context, payload) {
      context.commit('setLoading', true)
      return firebase
        .firestore()
        .collection('types')
        .where('type', '==', payload.type)
        .get()
        .then(querySnapshot => {
          const types = []
          querySnapshot.forEach(data => {
            if (data.exists) {
              const obj = data.data()
              obj.id = data.id
              types.push(obj)
            }
          })
          console.log('loaded food types => ', types)
          context.commit('setCategories', types)
          context.commit('setLoading', false)
          return true
        })
        .catch(error => {
          console.log(error)
          context.commit('setLoading', false)
          context.commit('setError', error)
          return false
        })
    },

    saveFoodTypes(context, payload) {
      console.log('saveFoodTypes ', payload)
      if (payload.id) {
        payload.updatedTime = moment.utc().valueOf()
        return firebase
          .firestore()
          .collection('types')
          .doc(payload.id)
          .update(payload)
          .catch(e => console.error(e))
      }
      payload.createdTime = moment.utc().valueOf()
      return firebase
        .firestore()
        .collection('types')
        .add(payload)
        .catch(e => console.error(e))
    },
    removeFoodTypes(context, payload) {
      if (payload.id) {
        return firebase
          .firestore()
          .collection('types')
          .doc(payload.id)
          .delete()
          .catch(e => console.error(e))
      }
    },
    setPageSize({ commit }, payload) {
      commit('setPageSize', payload)
    },

    findDishById({ dispatch }, id) {
      return dispatch('findById', id)
    },
    getProductBySlug(_, payload) {
      console.log('Lookup product by slug', payload)
      return firebase
        .firestore()
        .collection('dish')
        .where('slug', '==', payload)
        .get()
        .then(querySnapshot => {
          let product = null
          querySnapshot.forEach(function(data) {
            product = { id: data.id, ...data.data() }
            return product
          })

          return product
        })
    },
    getDishById({ commit }, payload) {
      commit('setDish', null)
      commit('setLoading', true)
      //commit('setLoading', true)
      console.log(`Loading product id: ${payload.id}`)
      return firebase
        .firestore()
        .collection('dish')
        .doc(payload.id)
        .get()
        .then(data => {
          if (data.exists) {
            const dish = data.data()
            console.log('Got dish data:', payload)
            dish.id = payload.id
            commit('setDish', dish)
            commit('updateDiets', dish.diets)
            commit('updateOrigins', dish.origins)
            commit('updateSuggestedMenus', dish.suggested_menus)
            commit('updateSuggestedProducts', dish.suggested_products)
            commit('setLoading', false)

            // commit('setLoading', false)
            return dish
          }
          return null
          // commit('setLoading', false)
        })
    },
    findById({ commit, dispatch }, payload) {
      commit('setLoading', true)
      commit('setFood', null)
      commit('setDish', null)
      console.log('Getting dish', payload)
      return firebase
        .firestore()
        .collection('dish')
        .doc(payload)
        .get()
        .then(data => {
          if (data.exists) {
            const obj = data.data()
            obj.id = data.id
            if (obj.uid) {
              dispatch('loadChefById', obj.uid)
            }
            commit('setDish', obj)
            return obj
          } else {
            console.log(`Requested dish does not exist  ${payload}`)
            //commit('setError', { message: 'Requested food does not exist' })
          }
          commit('setLoading', false)
          return null
        })
        .catch(error => {
          console.log(error)
          commit('setLoading', false)
          commit('setError', error)
        })
    },
    addNearbyProduct({ commit, state }, payload) {
      console.log('addNearby', payload)

      let searchFilter = state.searchFilter
      let diets,
        categories,
        price_max,
        cookingNowOnly = null
      if (searchFilter) {
        if (searchFilter.cookingNowOnly) {
          cookingNowOnly = searchFilter.cookingNowOnly
        }
        if (searchFilter.diets && searchFilter.diets.length > 0) {
          diets = searchFilter.diets
        }
        if (searchFilter.categories && searchFilter.categories.length > 0) {
          categories = searchFilter.categories
        }
        if (searchFilter.price_range) {
          price_max = searchFilter.price_range
        }
      }

      firebase
        .firestore()
        .collection(payload.type.toLowerCase())
        .doc(payload.id)
        .get()
        .then(data => {
          if (data.exists) {
            const food = data.data()
            food.id = data.id
            let addPlease,
              skip = false

            let dish = food.dish
            console.log('Processing nearby  ' + payload.type, payload.id)
            //console.log(diets)
            //console.log(categories)
            //console.log(price_max)
            //console.log(dish)

            if (cookingNowOnly && food.status !== 'cooking') {
              console.log('Skipping  ' + payload.type, payload.id)
              skip = true
            }
            if (!skip) {
              if (!categories && !diets) {
                addPlease = true
              }
              if (dish) {
                if (diets && dish.diets.length > 0) {
                  if (dish.diets.find(n => diets.includes(n))) {
                    // should add
                    addPlease = true
                  } else {
                    //addPlease = false
                    console.log(`Food did not pass the diets filter ${diets}`)
                  }
                }
                if (
                  categories &&
                  dish.categories &&
                  dish.categories.length > 0
                ) {
                  if (categories.find(n => n === dish.categories)) {
                    // should add
                    addPlease = true
                  } else {
                    //addPlease = addPlease
                    console.log(
                      `Food did not pass the categories filter ${categories}`
                    )
                  }
                }
              }
              if (price_max && food.price > price_max) {
                addPlease = false
              }
            }
            if (addPlease) {
              commit('addNearby' + payload.type, food)
            }
          } else {
            console.log('Requested food does not exist', payload.id)
            //commit('setError', { message: 'Request food does not exist' })
          }
        })
        .catch(error => {
          console.log(error)
          commit('setError', error)
        })
    },
    saveSearchFilter({ commit }, filter) {
      commit('setSearchFilter', filter)
    },
    updateSearchFilter({ commit }, filter) {
      commit('updateSearchFilter', filter)
    },

    async loadDishList({ commit, getters }, payload = {}) {
      let publicOnly = false

      const id =
        payload.uid && getters.isAdmin
          ? payload.uid
          : getters.user
          ? getters.user.id
          : null

      if (payload.id) {
        // publicOnly = true
      }
      if (payload.publicOnly) {
        publicOnly = true
      }

      if (!id) {
        console.warn('Unable to load dish list id is null')
        return
      }
      // if (!payload.force && getters.dishList) {
      //   console.log('Getting dish-list from local cache')
      //   return
      // }

      let foodRef = firebase.firestore().collection('dish')
      foodRef = foodRef.where('uid', '==', id)
      if (publicOnly) {
        foodRef = foodRef.where('visibility', '==', 'public')
      }
      const trace = firebase.performance().trace('load_products')
      commit('setLoading', true)
      trace.start()
      return await foodRef
        .get()
        .then(querySnapshot => {
          const dishList = []
          if (querySnapshot.size > 0) {
            querySnapshot.forEach(data => {
              if (data.exists) {
                const obj = data.data()
                obj.id = data.id
                if (payload.summary) {
                  const {
                    id,
                    name,
                    price,
                    visibility,
                    status,
                    amountLeft,
                    promotion,
                    photos,
                    description,
                    popular,
                    meta,
                    score
                  } = obj
                  dishList.push({
                    id,
                    name,
                    price,
                    visibility,
                    status,
                    amountLeft,
                    promotion,
                    photos,
                    description,
                    popular,
                    meta,
                    score
                  })
                } else {
                  dishList.push(obj)
                }
              } else {
                console.log('No data exists')
              }
            })
          }
          if (publicOnly) {
            commit('setChefDishList', dishList)
          } else {
            commit('setDishList', dishList)
          }
          commit('setLoading', false)
          console.log(`Total ${dishList.length} product loaded for ${id}`)
          trace.stop()

          return dishList
        })
        .catch(error => {
          console.log(error)
          commit('setLoading', false)
          commit('setError', error)
        })
    },
    setFood({ commit }, payload) {
      commit('setFood', payload)
    },
    setDish({ commit }, payload) {
      commit('setDish', payload)
    },
    loadUserIngredients({ commit, getters }) {
      let uid = getters.user.id
      console.log(`Loading Ingredients uid: ${uid} `)
      firebase
        .firestore()
        .collection('profile')
        .doc(uid)
        .collection('food_info')
        .doc('ingredients')
        .get()
        .then(doc => {
          if (doc.exists) {
            let list = doc.data()
            const ingredients = []
            for (var key in list) {
              ingredients.push(key)
            }
            commit('setIngredients', ingredients)
          }
        })
    },
    saveFood({ commit, getters }, payload) {
      commit('setLoading', true)

      if (getters.defaultAddress && !payload.location) {
        payload.location = getters.defaultAddress
      }
      //payload.dishRef =  firebase.firestore().collection('dish').doc(payload.dish.id)

      if (payload.id) {
        let id = payload.id
        delete payload.id

        if (payload.totalServing && !payload.amountLeft) {
          payload.amountLeft = payload.totalServing
        }
        firebase
          .firestore()
          .collection('food')
          .doc(id)
          .update(payload)
          .then(() => {
            commit('setLoading', false)
            commit('setDish', payload)
            console.log('payload.status', payload.status)
            // store in realtime db until firestore supports geo query
            /* if (payload.location) {
              if (payload.status === 'hide') {
                geoFire.remove('food_' + id).then(e => {
                  console.log('location remove', e)
                })
              } else {
                let newLocation = [
                  payload.location.latitude,
                  payload.location.longitude
                ]
                geoFire.set('food_' + id, newLocation).then(() => {
                  console.log('location saved')
                })
              }
            }*/
          })
          .catch(error => {
            commit('setLoading', false)
            commit('setError', error)
            console.log(error)
          })
      } else {
        payload.uid = getters.user.id
        payload.createdTime = new Date().getTime()

        firebase
          .firestore()
          .collection('food')
          .add(payload)
          .then(data => {
            commit('setLoading', false)
            payload.id = data.id
            /*if (payload.location) {
              let newLocation = [
                payload.location.latitude,
                payload.location.longitude
              ]
              geoFire.set('food_' + data.id, newLocation).then(() => {
                console.log('location saved')
              })
            }*/
            commit('setDish', payload)
          })
          .catch(error => {
            commit('setLoading', false)
            commit('setError', error)
            console.log(error)
          })
      }
    },

    deleteFood({ commit, dispatch }, id) {
      commit('setLoading', true)
      if (id) {
        let food = {}
        food[id] = firebase.firestore.FieldValue.delete()
        firebase
          .firestore()
          .collection('food')
          .doc(id)
          .delete()
          .then(() => {
            commit('setLoading', false)
            dispatch('loadDishList')
          })
          .catch(error => {
            commit('setLoading', false)
            commit('setError', error)
            console.log(error)
          })
      } else {
        console.error('missing food id')
        commit('setLoading', false)
      }
    },

    saveDishStatus: async ({ dispatch }, payload) => {
      await dispatch('updateProfile', {
        cookingStatus: payload.food.status
      })
      //payload.food.uid = this.userProfile.id
      await dispatch('saveDish', {
        food: payload.food
      })
      return 'done'
    },
    updateDishInfo(context, payload) {
      return firebase
        .firestore()
        .collection('dish')
        .doc(payload.id)
        .update(payload)
        .then(d => {
          return d
        })
        .catch(error => {
          context.commit('setError', error)
        })
    },
    saveMultipleDishes({ commit, dispatch }, payload) {
      commit('setLoading', true)
      let sfRef = null
      let batch = firebase.firestore().batch()
      payload.forEach(dish => {
        sfRef = firebase
          .firestore()
          .collection('dish')
          .doc(dish.id)
        batch.update(sfRef, dish)
      })

      return batch.commit().then(function() {
        return dispatch('loadDishList').then(list => {
          commit('setDishList', list)
          commit('setLoading', false)
          console.log('batch committed!')
          return true
        })
      })
    },
    saveDish({ commit, getters, state }, payload) {
      commit('setLoading', true)
      analytics.logEvent('biz_product_save', {
        value: payload.food.name,
        id: payload.food.id,
        category: 'product',
        action: 'save'
      })

      Object.keys(payload.food).forEach(
        key => payload.food[key] === undefined && delete payload.food[key]
      )

      if (
        getters.defaultAddress &&
        getters.defaultAddress.location
        // && !payload.food.location
      ) {
        payload.food.location = getters.defaultAddress.location
        // called
        console.log('Dish Location Saved to :' + payload.food.location)
      } else {
        commit('setError', { message: 'Your location / address is not set.' })
      }

      if (payload.food.name) {
        payload.food.name_search = payload.food.name
          .toLowerCase()
          .replace(/ /g, '')
      }

      if (payload.food.id && payload.food.id !== 'new') {
        /*
         reserve photo collection
        */
        if (!state.dish) {
          console.warn('Dish is not set in store')

          //commit('setLoading', false)
          //return
        }
        // else {
        //   payload.photos = state.dish.photos || []
        // }

        if (getters.chefRating && getters.chefRating.rating) {
          payload.chef.rating = getters.chefRating.rating
        }
        //payload.uid = getters.user.id
        let id = payload.food.id

        Vue.gtm.trackEvent({
          event: 'takein_event', // Event type [default = 'interaction'] (Optional)
          category: 'product',
          action: 'save_product',
          value: payload.food.name,
          method: 'save_product',
          payload: { method: 'save_product', name: payload.food.name }
        })
        return firebase
          .firestore()
          .collection('dish')
          .doc(id)
          .update(payload.food)
          .then(() => {
            commit('setLoading', false)
            // commit('setDish', payload)
            /*console.log('payload.visibility', payload.visibility)
            // store in realtime db until firestore supports geo query
            if (payload.location && payload.visibility === 'public') {
              let newLocation = [
                payload.location.latitude,
                payload.location.longitude
              ]
              geoFire.set('dish_' + id, newLocation).then(() => {
                console.log('location saved')
              })
            }

            if (payload.visibility === 'hide') {
              geoFire.remove('dish_' + id).then(e => {
                console.log('location remove', e)
              })
            }*/
            return payload.food.id
          })
          .catch(error => {
            commit('setLoading', false)
            commit('setError', error)
            console.log('save product error: ', error)
          })
      } else {
        Vue.gtm.trackEvent({
          event: 'takein_event', // Event type [default = 'interaction'] (Optional)
          category: 'product',
          action: 'add_product',
          value: payload.food.name,
          method: 'add_product',
          payload: { method: 'add_product', name: payload.food.name }
        })
        payload.food.createdTime = new Date().getTime()
        return firebase
          .firestore()
          .collection('dish')
          .add(payload.food)
          .then(data => {
            commit('setLoading', false)
            payload.food.id = data.id
            commit('setDish', payload.food)
            return data.id
          })
          .catch(error => {
            commit('setLoading', false)
            commit('setError', error)
            console.log('New product error: ', error)
          })
      }
    },
    deletePhoto({ commit, state }, payload) {
      commit('setLoading', true)
      if (payload.id) {
        firebase
          .firestore()
          .collection('dish')
          .doc(payload.id)
          .update(payload)
          .then(() => {
            commit('setLoading', false)
            let dish = state.dish
            dish.photos = payload.photos
            commit('setDish', dish)
          })
          .catch(error => {
            commit('setLoading', false)
            commit('setError', error)
            console.log(error)
          })
      }
    },
    deleteDish({ commit }, id) {
      commit('setLoading', true)

      if (id) {
        let food = {}
        food[id] = firebase.firestore.FieldValue.delete()
        firebase
          .firestore()
          .collection('dish')
          .doc(id)
          .delete()
          .then(() => {
            commit('setLoading', false)
            commit('removeDishList', id)
          })
          .catch(error => {
            commit('setLoading', false)
            commit('setError', error)
            console.log(error)
          })
      } else {
        console.error('missing food id')
        commit('setLoading', false)
      }
    },
    uploadDishImage({ commit, getters }, payload) {
      commit('setLoading', true)
      let uid = getters.user.id
      const filename = payload.file.name
      const ext = filename.slice(filename.lastIndexOf('.'))

      let path = 'dish/' + uid + '/' + payload.id + '_' + payload.index + ext
      console.log('Uploading to:', path)
      return firebase
        .storage()
        .ref(path)
        .put(payload.file)
        .then(fileData => {
          return fileData.ref.getDownloadURL().then(imageUrl => {
            console.log('Just uploaded ', imageUrl)
          })
        })
        .then(() => {
          commit('setLoading', false)
        })
        .catch(error => {
          console.log('Failed to Uploaded ', path)
          commit('setLoading', false)
          commit('setError', error)
          console.error('error: ', error)
        })
    },
    uploadMediaUrl({ commit, state }, payload) {
      let dish = state.dish
      if (!dish) {
        commit('setLoading', false)
        console.error('Dish is not in store!')
        return
      }
      let photos = dish.photos || []
      photos.push({
        type: payload.type,
        url: payload.url,
        imageUrl: payload.url
      })

      return firebase
        .firestore()
        .collection('dish')
        .doc(payload.id)
        .update({ photos: photos })

        .then(() => {
          commit('setDish', dish)
          // commit('setLoading', false)
          return true
        })
        .catch(error => {
          console.log('Failed to Uploaded ', payload.id)
          // commit('setLoading', false)
          commit('setError', error)
          console.error('error: ', error)
          return false
        })
    },
    uploadDishPhoto({ commit, state }, payload) {
      console.log(payload, 'payload image')
      let putData = payload.src ? payload.src : payload.image
      // putData.url = payload.image.url
      // console.log(putData, 'putData')

      commit('setLoading', true)
      let uid = payload.uid
      let dish = state.dish
      if (!dish) {
        commit('setLoading', false)
        console.error('Dish is not in store!')
        return
      }

      let photos = dish.photos || []
      let dishId = payload.id

      const filename = payload.file.name
      const ext = filename.slice(filename.lastIndexOf('.'))
      let path = 'dish/' + uid + '/' + dishId + '_' + payload.index + ext
      console.log('Uploading to:', path, dishId)

      let ref = null
      if (putData && putData.length > 10) {
        ref = firebase
          .storage()
          .ref(path)
          .putString(putData, 'data_url')
      } else {
        ref = firebase
          .storage()
          .ref(path)
          .put(payload.file)
      }
      return ref
        .then(fileData => {
          fileData.ref.getDownloadURL().then(imageUrl => {
            if (imageUrl.indexOf('mp4') < 0) {
              imageUrl = changeImageUrl(imageUrl, ThumbPrefix)
            }

            console.log('pre photos', photos)
            photos.push({
              type: 'photo',
              url: imageUrl,
              imageUrl: imageUrl,
              position: payload.position
            })
            console.log('post photos', photos)
            dish.photos = photos

            return firebase
              .firestore()
              .collection('dish')
              .doc(dishId)
              .set({ photos: photos }, { merge: true })
          })
        })
        .then(() => {
          commit('setDish', dish)
          // commit('setLoading', false)
          return true
        })
        .catch(error => {
          console.log('Failed to Uploaded ', path)
          // commit('setLoading', false)
          commit('setError', error)
          console.error('error: ', error)
          return false
        })
    },
    uploadDishLabel({ commit, getters }, payload) {
      commit('setLoading', true)
      let uid = getters.user.id
      let dish = payload.dish
      const filename = payload.file.name
      let path = 'dish/' + uid + '/' + dish.id + '/label/' + filename
      console.log('Uploading to:', path)
      return firebase
        .storage()
        .ref(path)
        .put(payload.file)
        .then(fileData => {
          return fileData.ref.getDownloadURL().then(imageUrl => {
            return firebase
              .firestore()
              .collection('dish')
              .doc(dish.id)
              .update({ label: imageUrl })
          })
        })
        .then(() => {
          commit('setLoading', false)
        })
        .catch(error => {
          console.log('Failed to Uploaded ', path)
          commit('setLoading', false)
          commit('setError', error)
          console.error('error: ', error)
        })
    },
    deleteDishLabel({ commit }, payload) {
      commit('setLoading', true)
      console.log('Deleting label for this:', payload)
      return firebase
        .firestore()
        .collection('dish')
        .doc(payload.id)
        .update({ label: null })
        .then(() => {
          commit('setLoading', false)
          // TODO: Delete gcs folder
        })
        .catch(error => {
          commit('setLoading', false)
          commit('setError', error)
          console.error('error: ', error)
        })
    },
    updateDiets({ commit }, payload) {
      commit('updateDiets', payload)
    },
    updateOrigins({ commit }, payload) {
      commit('updateOrigins', payload)
    },
    updateSuggestedMenus({ commit }, payload) {
      commit('updateSuggestedMenus', payload)
    },
    updateSuggestedProducts({ commit }, payload) {
      commit('updateSuggestedProducts', payload)
    },
    uploadAssets({ dispatch }, payload) {
      const results = []

      payload.items.forEach(async item => {
        if (item.files && item.files.length > 0) {
          item.thumbs = []
          let file = item.files[0]

          results.push(
            dispatch('uploadStorage', {
              file: file.file,
              path: `/dish/${payload.id}/extra/${file.name}`
            }).then(path => {
              item.thumbs.push(path)
            })
          )
          delete item.files
        }
      })
      return Promise.all(results)
    },
    saveSchedule(context, payload) {
      payload.uid = context.getters.user.id
      context.commit('setSchedule', payload)
      if (payload.id) {
        return firebase
          .firestore()
          .doc(`schedule/${payload.id}`)
          .update(payload)
      } else {
        return firebase
          .firestore()
          .collection(`schedule`)
          .add(payload)
      }
    },
    deleteSchedule(context, payload) {
      context.commit('setSchedule', null)
      if (payload.id) {
        return firebase
          .firestore()
          .doc(`schedule/${payload.id}`)
          .delete()
          .then(() => {
            console.log('schedule deleted')
          })
      }
    },
    loadSchedules(context) {
      context.commit('setLoading', true)
      return firebase
        .firestore()
        .collection('schedule')
        .where('uid', '==', context.getters.user.id)
        .get()
        .then(querySnapshot => {
          let list = []
          querySnapshot.forEach(s => {
            console.log(s)

            let data = { id: s.id, ...s.data() }
            list.push(data)
          })
          context.commit('setSchedules', list)
          context.commit('setLoading', false)
        })
        .catch(e => {
          context.commit('setLoading', false)
          console.log(e)
        })
    },
    getSchedule(context, payload) {
      context.commit('setLoading', true)
      return firebase
        .firestore()
        .doc(`schedule/${payload}`)
        .get()
        .then(s => {
          console.log(s)

          let data = { id: s.id, ...s.data() }
          context.commit('setSchedule', data)

          context.commit('setLoading', false)
        })
        .catch(e => {
          context.commit('setLoading', false)
          console.log(e)
        })
    },
    saveMenus(context, payload) {
      analytics.logEvent('biz_menu_save', {
        value: payload.name,
        id: payload.id,
        category: 'menu',
        action: 'save'
      })
      /* Vue.gtm.trackEvent({
        event: 'save_menu', // Event type [default = 'interaction'] (Optional)
        category: 'product',
        action: 'save_menu',
        value: payload.name,
        method: 'save_menu',
        payload: { method: 'save_menu', name: payload.name }
      }) */
      if (payload.menu.constructor === Array) {
        payload.menu.forEach(menu => {
          menu.uid = payload.uid || context.getters.user.id
          menu.updatedTime = moment.utc().valueOf()

          if (menu.id) {
            return firebase
              .firestore()
              .doc(`menu/${menu.id}`)
              .set(menu)
          } else {
            return firebase
              .firestore()
              .collection(`menu`)
              .add(menu)
              .catch(e => {
                console.error(e)
              })
          }
        })
      } else {
        payload.uid = payload.uid || context.getters.user.id
        payload.menu.uid = payload.uid
        payload.menu.updatedTime = moment.utc().valueOf()
        if (payload.menu.id) {
          return firebase
            .firestore()
            .doc(`menu/${payload.menu.id}`)
            .set(payload.menu)
        } else {
          return firebase
            .firestore()
            .collection(`menu`)
            .add(payload.menu)
            .catch(e => {
              console.error(e)
            })
        }
      }
    },
    getLastCall(context, payload) {
      return firebase
        .firestore()
        .collection('last_call')
        .where('uid', '==', context.getters.user.id)
        .where('type', '==', payload.type)
        .get()
        .then(querySnapshot => {
          let list = []
          querySnapshot.forEach(data => {
            list.push({ ...data.data(), id: data.id })
          })
          return list
        })
    },
    async saveLastCall(context, payload) {
      payload.uid = context.getters.user.id
      const chef = {
        id: context.getters.user.id,
        name: context.getters.user.name
      }
      payload.chef = chef
      const location = context.getters.defaultAddress
        ? context.getters.defaultAddress.location
        : null
      payload.type = payload.type ? payload.type : 'default'
      if (location) {
        const geohash = Geohash.encode(location.latitude, location.longitude, 9)

        payload.location = {
          geohash: geohash,
          geopoint: location
        }
        // called
        console.log('Dish Location Saved to :' + payload.location)
      } else {
        context.commit('setError', {
          message: 'Your location / address is not set.'
        })
      }

      Object.keys(payload).forEach(
        key => payload[key] === undefined && delete payload[key]
      )
      const ref = firebase
        .firestore()
        .collection('last_call')
        .where('uid', '==', payload.uid)
        .where('type', '==', payload.type)
      // .get()
      const found = await ref.get().then(querySnapshot => {
        let list = []
        querySnapshot.forEach(data => {
          list.push({ ...data.data(), id: data.id })
        })
        return list
      })
      console.log(found)
      payload.updatedTime = moment.utc().valueOf()
      if (!found || found.length == 0) {
        payload.createdTime = moment.utc().valueOf()
        await firebase
          .firestore()
          .collection('last_call')
          .add(payload)
          .then(d => console.log(`Saved new ${d.id}`))
          .catch(e => console.error(e))
      } else {
        const record = found[0]
        await firebase
          .firestore()
          .doc(`last_call/${record.id}`)
          .set(payload)
          .catch(e => console.error(e))
      }
      /*await ref
        .set(payload, { merge: true })
        .then(() => {
          console.log('Saved')
        })
        .catch(e => console.error(e))*/
    },
    deleteMenu(context, payload) {
      console.log(payload, '!!!!!!!!!!!!!!!!!!!')
      context.commit('setLoading', true)
      return firebase
        .firestore()
        .doc(`/menu/${payload}`)
        .delete()
        .then(() => {
          context.commit('setLoading', false)
        })
    },
    deleteMenu2(context, payload) {
      context.commit('setLoading', true)
      return firebase
        .firestore()
        .doc(`profile/${context.getters.user.id}/menus/${payload}`)
        .delete()
        .then(() => {
          context.commit('setLoading', false)
        })
    },
    loadMenus(context, payload = {}) {
      context.commit('setLoading', true)
      const uid =
        payload.uid && context.getters.isAdmin
          ? payload.uid
          : context.getters.user.id

      return firebase
        .firestore()
        .collection('menu')
        .where('uid', '==', uid)
        .get()
        .then(snapshot => {
          let menu_list = []
          snapshot.forEach(doc => {
            let menu = doc.data()
            menu.id = doc.id
            menu_list.push(menu)
          })
          context.commit('setLoading', false)
          context.commit('setMenus', menu_list)
          console.log(`Total ${menu_list.length} menu loaded for ${uid}`)
          return menu_list
        })
    },

    loadMenu(context, payload) {
      context.commit('setLoading', true)
      console.log('Loading menu', payload)

      return firebase
        .firestore()
        .doc(`/menu/${payload}`)
        .get()
        .then(snapshot => {
          context.commit('setLoading', false)
          if (snapshot.exists) {
            let data = snapshot.data()
            data.id = payload
            return data
          }
        })
    },
    saveModifier(context, payload) {
      payload.uid = context.getters.user.id
      if (payload.options) {
        payload.options.forEach(option => {
          if (option.price) {
            try {
              option.price = parseFloat(option.price)
            } catch (error) {
              console.log('Bad format for modifier price')
            }
          }
        })
      }
      analytics.logEvent('biz_modifier_save', {
        value: payload.name,
        id: payload.id,
        uid: payload.uid,
        category: 'modifier',
        action: 'save'
      })
      if (payload.id) {
        return firebase
          .firestore()
          .doc(`modifier/${payload.id}`)
          .set(payload)
      } else {
        return firebase
          .firestore()
          .collection(`modifier`)
          .add(payload)
          .then(data => {
            return data.id
          })
      }
    },
    saveModifier2(context, payload) {
      payload.uid = context.getters.user.id
      if (payload.options) {
        payload.options.forEach(option => {
          if (option.price) {
            try {
              option.price = parseFloat(option.price)
            } catch (error) {
              console.log('Bad format for modifier price')
            }
          }
        })
      }
      if (payload.id) {
        return firebase
          .firestore()
          .doc(`modifier/${payload.id}`)
          .set(payload)
      } else {
        return firebase
          .firestore()
          .collection(`modifiers`)
          .add(payload)
          .then(data => {
            return data.id
          })
      }
    },
    saveModifiers(context, modifiers) {
      const uid = context.getters.user.id
      modifiers.forEach(modifier => {
        modifier.uid = uid
        if (modifier.options) {
          modifier.options.forEach(option => {
            if (option.price) {
              try {
                option.price = parseFloat(option.price)
              } catch (error) {
                console.log('Bad format for modifier price')
              }
            }
          })
        }
        if (modifier.id) {
          return firebase
            .firestore()
            .doc(`modifier/${modifier.id}`)
            .set(modifier)
        } else {
          return firebase
            .firestore()
            .collection(`modifier`)
            .add(modifier)
            .then(data => {
              return data.id
            })
        }
      })
    },
    loadModifier(context, payload) {
      context.commit('setLoading', true)
      console.log('Loading modifier:', payload)

      return firebase
        .firestore()
        .doc(`modifier/${payload}`)
        .get()
        .then(snapshot => {
          context.commit('setLoading', false)
          if (snapshot.exists) {
            let m = snapshot.data()
            m.id = snapshot.id
            return m
          }
        })
    },
    loadModifier2(context, payload) {
      context.commit('setLoading', true)
      console.log('Loading modifier:', payload)

      return firebase
        .firestore()
        .doc(`profile/${context.getters.user.id}/modifiers/${payload}`)
        .get()
        .then(snapshot => {
          context.commit('setLoading', false)
          if (snapshot.exists) {
            let m = snapshot.data()
            m.id = snapshot.id
            return m
          }
        })
    },
    loadModifiers(context) {
      //context.commit('setLoading', true)

      return firebase
        .firestore()
        .collection(`modifier`)
        .where('uid', '==', context.getters.user.id)
        .get()
        .then(snapshot => {
          let list = []
          snapshot.forEach(doc => {
            let m = doc.data()
            m.id = doc.id
            list.push(m)
          })
          context.commit('setLoading', false)
          context.commit('setModifiers', list)
          return list
        })
    },
    loadModifiers2(context) {
      //context.commit('setLoading', true)

      return firebase
        .firestore()
        .collection(`profile/${context.getters.user.id}/modifiers`)
        .get()
        .then(snapshot => {
          let list = []
          snapshot.forEach(doc => {
            let m = doc.data()
            m.id = doc.id
            list.push(m)
          })
          context.commit('setLoading', false)
          context.commit('setModifiers', list)
          return list
        })
    },
    deleteModifier(context, payload) {
      context.commit('setLoading', true)
      return firebase
        .firestore()
        .doc(`modifier/${payload}`)
        .delete()
        .then(() => {
          context.commit('setLoading', false)
        })
    },
    deleteModifier2(context, payload) {
      context.commit('setLoading', true)
      return firebase
        .firestore()
        .doc(`profile/${context.getters.user.id}/modifiers/${payload}`)
        .delete()
        .then(() => {
          context.commit('setLoading', false)
        })
    },
    saveProductModifiers(context, payload) {
      context.commit('setLoading', true)
      console.log('Saving Product modifiers:', payload)
      return firebase
        .firestore()
        .collection('dish')
        .doc(payload.id)
        .update({ modifiers: payload.modifiers })
        .then(() => {
          context.commit('setLoading', false)
        })
    },
    saveCategory(context, payload) {
      payload.uid = context.getters.user.id
      if (payload.id) {
        return firebase
          .firestore()
          .doc(`/category/${payload.id}`)
          .set(payload)
      } else {
        return firebase
          .firestore()
          .collection(`category`)
          .add(payload)
      }
    },
    saveCategory2(context, payload) {
      if (payload.id) {
        return firebase
          .firestore()
          .doc(`profile/${context.getters.user.id}/categories/${payload.id}`)
          .set(payload)
      } else {
        return firebase
          .firestore()
          .collection(`profile/${context.getters.user.id}/categories`)
          .add(payload)
      }
    },

    async importModifiers(ctx, payload) {
      if (payload) {
        for (const modifier of payload.data) {
          console.log(modifier)
          modifier.uid = payload.uid
          if (payload.merge) {
            await firebase
              .firestore()
              .doc(`/modifier/${modifier.id}`)
              .set(modifier, { merge: true })
              .then(() => {
                console.log('updating modifier with id:', modifier.id)
              })
              .catch(e => console.error(e))
          } else {
            await firebase
              .firestore()
              .collection('modifier')
              .add(modifier)
              .then(e => {
                console.log('new modifier created with id', e.id)
              })
              .catch(e => console.error(e))
          }
        }
      }
    },
    async importMenus(ctx, payload) {
      if (payload) {
        const all_ids = await firebase
          .firestore()
          .collection('dish')
          .where('uid', '==', payload.uid)
          .get()
          .then(querySnapshot => {
            let ids = []
            querySnapshot.forEach(data => {
              ids.push({ id: data.id, old_id: data.data().old_id })
            })
            return ids
          })

        for (const menu of payload.data) {
          console.log(menu)
          menu.uid = payload.uid
          menu.products = []
          if (menu.items) {
            for (let index = 0; index < menu.items.length; index++) {
              const id = menu.items[index]
              const data = all_ids.find(e => e.old_id === id)
              if (data && data.old_id) {
                console.log(`ID ${id} replaced with ${data.id}`)
                menu.items[index] = data.id
              } else {
                console.warn(`ID ${id} not found`)
              }
            }
          }
          console.log('after replace', menu)

          if (payload.merge) {
            await firebase
              .firestore()
              .doc(`/menu/${menu.id}`)
              .set(menu, { merge: true })
              .then(() => {
                console.log('updating menu with id:', menu.id)
              })
              .catch(e => console.error(e))
          } else {
            await firebase
              .firestore()
              .collection('menu')
              .add(menu)
              .then(e => {
                console.log('new menu created with id', e.id)
              })
              .catch(e => console.error(e))
          }
        }
      }
    },
    async importProducts(ctx, payload) {
      // console.log(payload)
      if (payload) {
        for (const product of payload.data) {
          product.uid = payload.uid
          product.slug = null
          product.visibility = null
          product.old_id = product.id

          const {
            name,
            avatar,
            businessPhone,
            businessName,
            businessEmail,
            status,
            settings,
            handle
          } = payload.profile
          product.chef = {
            name,
            businessName,
            avatar,
            businessPhone,
            businessEmail,
            settings,
            status,
            handle,
            id: payload.uid
          }
          product.updatedTime = moment.utc().valueOf()
          if (payload.merge) {
            await firebase
              .firestore()
              .doc(`/dish/${product.id}`)
              .set(product, { merge: true })
              .then(e => {
                console.log('updating dish with id', e.id)
              })
              .catch(e => console.error(e))
          } else {
            delete product.id
            await firebase
              .firestore()
              .collection('dish')
              .add(product)
              .then(e => {
                console.log('new dish created with id', e.id)
              })
              .catch(e => console.error(e))
          }
        }
      }
    },
    updateCondiments(ctx, payload) {
      const curProfile = ctx.getters.user
      const condiments = payload
      if (!curProfile || !curProfile.id) return
      analytics.logEvent('biz_condiments_save', {
        value: payload,
        uid: curProfile.uid,
        category: 'condiments',
        action: 'save'
      })
      ctx.commit('setLoading', true)
      return firebase
        .firestore()
        .collection('profile')
        .doc(curProfile.id)
        .update({ 'settings.optional_condiments': condiments })
        .then(() => {
          ctx.commit('setLoading', false)
        })
    }
  },
  getters: {
    food(state) {
      return state.food
    },
    dish(state) {
      return state.dish
    },
    searchFilters(state) {
      return state.searchFilter
    },
    searching(state) {
      return state.searching
    },
    dishList(state) {
      return state.dishList
    },
    chefDishList(state) {
      return state.chefDishList
    },
    foodCategories(state) {
      return state.foodCategories
    },
    cuisineList(state) {
      return state.cuisineList
    },
    nearbyFoods(state) {
      return state.nearbyFoods
    },
    currentDiets(state) {
      return state.currentDiets
    },
    currentOrigins(state) {
      return state.currentOrigins
    },
    suggestedProducts(state) {
      return state.suggestedProducts
    },
    suggestedMenus(state) {
      return state.suggestedMenus
    },
    nearbyDishes(state) {
      let foods = state.nearbyFoods

      if (foods.length > 0) {
        return state.nearbyDishes.filter(
          d => !foods.find(f => f.dish.id === d.id)
        )
      }
      return state.nearbyDishes
    },
    cookingNow(state) {
      return state.nearbyFoods && state.nearbyFoods.length > 0
        ? state.nearbyFoods.filter(f => f.status === 'cooking')
        : []
    },
    diets(state) {
      let list = state.foodTypes
        ? state.foodTypes.filter(t => t.id === 'diets')
        : []
      if (list.length > 0) {
        return list[0].en
      }
      return []
    },
    defaultPageSize(state) {
      return state.pageSize
    },
    ingredients(state) {
      let list = state.foodTypes
        ? state.foodTypes.filter(t => t.id === 'ingredients')
        : []
      // merge user ingredients with system level
      let user = state.ingredients || []
      if (list.length > 0) {
        user = user.concat(list[0].en)
      }
      return user.sort(function(a, b) {
        if (a.toLowerCase() < b.toLowerCase()) return -1
        if (a.toLowerCase() > b.toLowerCase()) return 1
        return 0
      })
    },
    categories(state) {
      let list = state.foodTypes
        ? state.foodTypes.filter(t => t.id === 'categories')
        : []
      if (list.length > 0) {
        return list[0].en
      }
      return []
    },
    subIngredients: state => id => {
      let list = state.foodTypes
        ? state.foodTypes.filter(t => t.id === 'sub_ingredients')
        : []

      if (list.length > 0 && list[0][id]) {
        return list[0][id]
      }

      return null
    },
    subIngredientList: state => {
      let list = state.foodTypes
        ? state.foodTypes.filter(t => t.id === 'sub_ingredients')
        : []

      if (list.length > 0) {
        // change the object to Array
        //console.log(Object.entries(list[0]))
        return Object.entries(list[0])
      }

      return list
    },
    schedule(state) {
      return state.schedule
    },
    schedules(state) {
      return state.schedules
    },
    modifiers(state) {
      return state.modifiers
    },
    menus(state) {
      return state.menus
    }
    /* food_categories(state) {
      return state.food_categories
    } */
  }
}
