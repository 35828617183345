const ThumbPartner = '%2F'

export const ThumbPrefix = 'thumb_'
export function changeImageUrl(source, pattern) {
  let pos = source.lastIndexOf(ThumbPartner)
  let dest =
    source.substring(0, pos + ThumbPartner.length) +
    pattern +
    source.substring(pos + ThumbPartner.length - 1 + 1)
  return dest
}
export const profilePicture = profile => {
  return !profile
    ? null
    : profile.avatar
    ? profile.avatar
    : profile.profilePicture
    ? profile.profilePicture
    : profile.photoUrl
}

export const profileAvatar = profile => {
  return !profile
    ? null
    : profile.avatar
    ? profile.avatar
    : profile.profilePicture
    ? profile.profilePicture
    : profile.photoUrl
}
