<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" class="pa-0" v-if="$parent.$parent.$parent.showWizard">
        <small
          class="main_text--text"
          v-html="$t('Common.menus_menu_page_description')"
        />
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <v-col cols="6" sm="3">
        <v-btn
          color="takein"
          dark
          :ripple="false"
          rounded
          depressed
          to="/profile/wizard/menus/menus_sub/new_menu"
        >
          <v-icon left>mdi-plus</v-icon>
          {{ $t('Common.add_menus') }}
        </v-btn>
      </v-col>
      <v-col cols="6" sm="3" lg="1">
        <v-btn text to="/profile/wizard/menus/menus_sub/list_menus">
          <v-icon left>list</v-icon>
          {{ $t('Common.menus') }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pa-0">
        <router-view></router-view>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  data() {
    return {}
  }
}
</script>
<style></style>
