<template>
  <v-card height="550px" v-if="profile">
    <v-container>
      <v-toolbar color="white" height="40" class="mt-2 elevation-0">
        <v-badge left v-if="orderCount">
          <template v-slot:badge>
            <div class="white--text">{{ orderCount }}</div>
          </template>
          <v-icon color="cyan darken-1">shopping_cart</v-icon>
        </v-badge>
        <v-spacer></v-spacer>

        <v-toolbar-items>
          <!-- popular icon -->
          <v-tooltip top v-if="dish.popular">
            <template v-slot:activator="{ on }">
              <v-icon width="50" v-on="on" class="mr-2" color="orange"
                >star</v-icon
              >
            </template>
            <span class="white--text">Popular</span>
          </v-tooltip>

          <!-- takein exclusive icon -->
          <v-tooltip top v-if="dish.takein_exclusive">
            <template v-slot:activator="{ on }">
              <v-icon width="50" v-on="on" class="mr-2" color="orange darken-2"
                >fastfood</v-icon
              >
            </template>
            <span class="white--text">Takein Exclusive</span>
          </v-tooltip>

          <!-- takein exclusive icon -->
          <v-tooltip top v-if="dish.new">
            <template v-slot:activator="{ on }">
              <v-icon width="50" v-on="on" class="mr-2" color="orange darken-4"
                >new_releases</v-icon
              >
            </template>
            <span class="white--text">New</span>
          </v-tooltip>

          <!-- promotion icon -->
          <v-tooltip top v-if="dish.promotion && dish.promotion.length > 0">
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on">
                <img width="50" src="/svg/coupon.svg" />
              </v-btn>
            </template>
            <span>Has promotional discount</span>
          </v-tooltip>

          <!-- PQS display -->
          <v-tooltip top v-if="dish.score">
            <template v-slot:activator="{ on }">
              <v-avatar
                class="mt-1"
                color="takein_logo"
                size="30"
                v-on="on"
                @click="pqsShow(dish.score)"
              >
                <span class="white--text">
                  {{ parseInt(dish.score.total_score * 100) }}
                </span>
              </v-avatar>
            </template>
            <span class="white--text">
              This is your Product Quality Score.
              <br />Click for more info.
            </span>
          </v-tooltip>
        </v-toolbar-items>
      </v-toolbar>
      <template v-if="imageSrc(dish)">
        <v-img
          :src="imageSrc(dish)"
          @click="$router.push(`/product/edit/${profile.id}/${dish.id}`)"
          height="250px"
        ></v-img>
      </template>

      <template v-else>
        <v-sheet height="250px">
          <h3 class="grey--text font-weight-thin font-italic">No Picture</h3>
        </v-sheet>
      </template>
      <v-card-title>
        <div class="w-100">
          <h4>{{ dish.name | truncate(30) }}</h4>
          <template v-if="dish.description">
            <vue-perfect-scrollbar
              style="height:90px;"
              :settings="scroll_settings"
            >
              <div
                class="fs-14 word-breaking"
                v-html="markedDown"
                style="height:100%"
              ></div>
            </vue-perfect-scrollbar>
          </template>
          <template v-else>
            <span class="grey--text fs-14 font-italic">No Description</span>
          </template>

          <v-spacer></v-spacer>
          <span class="green--text">{{ dish.price | currency }}</span>
        </div>
      </v-card-title>
      <v-card-actions>
        <v-bottom-navigation :value="true" absolute color="transparent">
          <v-btn
            small
            text
            class="mr-2 caption"
            :to="`/product/edit/${profile.id}/${dish.id}`"
          >
            <span>Edit</span>
            <v-icon color="info">edit</v-icon>
          </v-btn>

          <v-btn text value="nearby" :to="'/product/edit/' + dish.id">
            <span class="caption">
              <template v-if="dish.amountLeft && dish.amountLeft != -1"
                >Amount Left: {{ dish.amountLeft }}</template
              >
              <template v-else-if="dish.amountLeft == 0"
                ><span class="body-1 red--text">Sold out</span></template
              >
              <template v-else-if="dish.amountLeft == -1">Unlimited</template>
              <template v-else>
                <v-icon color="error">error</v-icon>amount left?
              </template>
            </span>
          </v-btn>

          <v-btn text v-if="false">
            <span class="caption">
              <v-tooltip top>
                <v-icon
                  slot="activator"
                  right
                  :color="_statusIconColor(dish.status)"
                  >{{ _statusIcon(dish.status) }}</v-icon
                >
                <span>{{ dish.status }}</span>
              </v-tooltip>
            </span>
          </v-btn>

          <v-dialog persistent max-width="330" v-model="confirmHide">
            <ProductVisibilityDialog
              @closeAction="closeAction"
              @confirmVisibility="confirmVisibility"
            />
          </v-dialog>

          <!-- dialog for PQS info -->
          <v-dialog v-model="showPQGDialog" width="500px" v-if="temp_score">
            <v-card>
              <v-card-title>
                <v-icon class="mb-2 mr-3" color="grey">check_circle</v-icon>
                <h2>
                  Your Product Quality Score:
                  {{ parseInt(temp_score.total_score * 100) }}
                </h2>
              </v-card-title>
              <h4 class="px-4 mb-4">
                Your Product Quality Score (PQS) is determined by the
                information you have provided on this dish. This score affects
                the dish's visibility to consumers.
                <br />
                <br />
                Consider improving on the dish's {{ lowestScore() }} to improve
                your overall score.
              </h4>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="showPQGDialog = false">Ok</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <!-- @click calls  showConfirm(), which activates dialog-->
          <v-btn text @click.prevent="showConfirm">
            <section v-if="isVisible">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon color="green" v-on="on">mdi-eye</v-icon>
                </template>
                <span class="white--text"
                  >Product is visible in the marketplace</span
                >
              </v-tooltip>
            </section>
            <section v-else>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon color="red" v-on="on">mdi-eye-off</v-icon>
                </template>
                <span class="white--text"
                  >Product is hidden and NOT available in marketplace</span
                >
              </v-tooltip>
            </section>
          </v-btn>
        </v-bottom-navigation>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import marked from 'marked'
// import { OrderMixin } from 'fod-core/src/mixins/orderMixin'
import ProductVisibilityDialog from './ProductVisibilityDialog.vue'

export default {
  props: ['dish', 'profile'],
  // mixins: [OrderMixin],

  data() {
    return {
      confirmHide: false,

      showPQGDialog: false,
      temp_score: null,
      scroll_settings: {
        maxScrollbarLength: 60
      }
    }
  },
  components: {
    ProductVisibilityDialog
  },
  computed: {
    isVisible() {
      return this.dish && this.dish.visibility === 'public'
    },
    orderCount() {
      return this.dish.meta ? this.dish.meta.orderCount || 0 : ''
    },
    /* markedDown() {
      return this.dish.description
        ? marked(this.dish.description, { sanitize: true })
        : ''
    },*/
    markedDown() {
      return this.dish.description
    }
  },

  methods: {
    // gets variable changes from ProductVisibilityDialog and sets them here
    closeAction(ch, cv) {
      this.confirmHide = ch
      this.dish.visibility = cv
    },
    confirmVisibility() {
      this.confirmHide = false
      this.onUpdateVisibility('hide')
    },

    // if isVisible returns true, sets cookingVisibility to public
    showConfirm() {
      this.$store
        .dispatch('loadDishList', { uid: this.profile.id })
        .then(dishList => {
          const isSubmitted = dishList.filter(
            dishInList => dishInList.id == this.dish.id
          )[0].submitted
        })

      if (this.dish.visibility === 'public') {
        // food is visible
        this.confirmHide = true
        this.onUpdateVisibility('hide')
      } else {
        // food is not visible, calls onUpdateVisibility to make dish visible
        this.onUpdateVisibility('public')
      }
    },
    onUpdateVisibility(data = 'public') {
      this.dish.visibility = data
      let food = {
        id: this.dish.id,
        visibility: this.dish.visibility || 'public'
      }
      ;(food.uid = this.profile.id),
        this.$store.dispatch('saveDish', {
          food: food
        })
    },
    imageSrc(dish) {
      if (dish.photos) {
        if (dish.photos.length > 0 && dish.photos[0] !== undefined) {
          return dish.photos[0].url || dish.photos[0].imageUrl
        }
        if (dish.photos.length > 0 && dish.photos[1] !== undefined) {
          return dish.photos[1].url || dish.photos[0].imageUrl
        }
      }
      return null
    },
    viewDishOrders() {
      this.$router.push('/order/list?dish_id=' + this.dish.id)
    },
    pqsShow(score) {
      this.temp_score = score
      this.showPQGDialog = true
    },
    // returns lowest score value to producer
    lowestScore() {
      let lowest_key = ''
      let lowest_score = -1

      Object.keys(this.temp_score).forEach(key => {
        if (this.temp_score[key] <= lowest_score || lowest_score == -1) {
          lowest_key = key
          lowest_score = this.temp_score[key]
        }
      })

      switch (lowest_key) {
        case 'category_score':
          return 'category'
        case 'desc_score':
          return 'description'
        case 'ingredient_score':
          return 'ingredients'
        case 'name_score':
          return 'name'
        case 'origin_score':
          return 'origins'
        case 'picture_score':
          return 'pictures'
        case 'tags_score':
          return 'tags'
      }
      return '-'
    }
  }
}
</script>

<style scoped>
.word-breaking {
  word-break: normal !important;
}
</style>
