<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on }">
      <v-btn v-on="on" icon> <v-icon>attach_file</v-icon> </v-btn>
    </template>

    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="dialog = false">
          <v-icon>close</v-icon>
        </v-btn>
        <v-toolbar-title>Upload Document</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items> <v-icon>cloud</v-icon> </v-toolbar-items>
      </v-toolbar>
      <v-list three-line subheader class="pa-4">
        <v-subheader v-if="message">
          <v-icon color="accent">error</v-icon> {{ message }}
        </v-subheader>
        <v-subheader
          >File Formats: PDF, Microsoft Word, Image files</v-subheader
        >
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>
              <file-upload
                class="btn btn-primary"
                post-action="/upload/post"
                extensions="gif,jpg,jpeg,png,webp"
                accept="image/png,image/gif,image/jpeg,image/webp,application/pdf,application/msword"
                :multiple="true"
                :size="1024 * 1024 * 20"
                v-model="files"
                @input-filter="inputFilter"
                ref="upload"
              >
                <v-icon>attachment</v-icon>
                Select File
              </file-upload>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-row v-if="files.length > 0">
              <v-col cols="12" sm="6">
                <v-btn color="success" class="ml-4" @click.prevent="upload"
                  >Upload</v-btn
                >
              </v-col>
              <v-col cols="12" sm="6">
                <v-btn text @click.prevent="$refs.upload.clear">Cancel</v-btn>
              </v-col>
            </v-row>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
  </v-dialog>
</template>

<script>
import FileUpload from 'vue-upload-component'

export default {
  components: {
    FileUpload
  },
  props: {
    id: {
      Type: String
    },
    index: {
      Type: Number
    },
    type: {
      Type: String
    },
    sub_type: {
      Type: String
    },
    message: {
      Type: String
    }
  },

  data() {
    return {
      dialog: false,
      files: []
    }
  },
  methods: {
    inputFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        if (/(\/|^)(Thumbs\.db|desktop\.ini|\..+)$/.test(newFile.name)) {
          return prevent()
        }
        if (/\.(php5?|html?|jsx?)$/i.test(newFile.name)) {
          return prevent()
        }
      }
    },
    upload() {
      this.$store
        .dispatch('uploadFile', {
          file: this.files[0].file,
          type: this.type,
          sub_type: this.sub_type,
          id: this.id,
          key: this.index
        })
        .then(path => {
          this.files = []
          console.log('Path:', path)
          this.$emit('onFilePathUpdate', { path: path, index: this.index })
          this.$store.dispatch('setMessage', {
            color: 'info',
            title: 'Upload',
            body: 'Document Successfully Uploaded.'
          })
        })
    }
  }
}
</script>
