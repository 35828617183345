<template>
  <div>
    <template v-for="(experience, key) in experiences">
      <v-row :key="key">
        <v-col class="hidden-sm-and-up" cols="12" v-if="key > 0">
          <v-divider></v-divider>
        </v-col>

        <v-col cols="12" sm="4">
          <v-text-field
            name="experience"
            :label="'Experience ' + (key + 1)"
            :id="'experience' + key"
            v-model="experiences[key].experience"
          ></v-text-field>
        </v-col>
        <v-col cols="11" sm="3">
          <v-text-field
            name="employer"
            label="Employer"
            :id="'employee' + key"
            v-model="experiences[key].employee"
          ></v-text-field>
        </v-col>
        <v-col cols="10" sm="2">
          <v-menu
            :close-on-content-click="true"
            v-model="menu[key]"
            transition="scale-transition"
            offset-y
            :nudge-right="40"
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-on="on"
                label="Work Start Date"
                v-model="experiences[key].startDate"
                allowed-dates="Function"
                prepend-icon="event"
                readonly
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="experiences[key].startDate"
              no-title
              type="month"
              :max="experiences[key].endDate"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="10" sm="2">
          <v-menu
            :close-on-content-click="true"
            v-model="menu1[key]"
            allowed-dates="allowedDate"
            transition="scale-transition"
            offset-y
            :nudge-right="40"
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-on="on"
                label="End Date"
                v-model="experiences[key].endDate"
                prepend-icon="event"
                readonly
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="experiences[key].endDate"
              no-title
              type="month"
              :min="minEndDate(key)"
              :max="maxStartDate"
            ></v-date-picker>
          </v-menu>
        </v-col>

        <v-col cols="1" sm="1">
          <v-btn icon dark small color="red" @click="removeExperience(key)">
            <v-icon dark>remove</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </template>

    <v-row v-if="!wizard">
      <v-btn color="primary" class="ma-3" @click="addExperience">Add</v-btn>
      <form @submit.prevent="onUpdateexperience">
        <v-btn primary class="ma-3" type="submit">Save</v-btn>
      </form>
      <router-link @click="addExperience" to="/">
        <v-btn primary class="ma-3" type="submit">Close</v-btn>
      </router-link>
    </v-row>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import moment from 'moment'

export default {
  props: ['wizard', 'profile'],

  data() {
    return {
      experiences: [],
      menu: [],
      menu1: [],
      allowedDate: null
    }
  },
  computed: {
    ...mapGetters({
      loading: 'loading'
    }),
    maxStartDate() {
      return moment().format('Y-M')
    }
  },
  mounted() {
    this.experiences = this.profile.experiences || []
    console.log(this.experiences, 'experiences')
  },
  watch: {
    experiences: function() {
      if (this.wizard) {
        let payload = { type: 'experiences', experiences: this.experiences }
        this.$emit('onUpdateWork', payload)
      }
    },
    $route() {
      this.experiences = this.profile.experiences || []
    }
  },
  methods: {
    minEndDate(key) {
      if (this.experiences[key].startDate) {
        return this.experiences[key].startDate
      }
    },
    addExperience() {
      let experience = {
        experience: '',
        employee: '',
        startDate: null,
        endDate: null,
        id: this.experiences ? this.experiences.length : 0
      }
      this.experiences.push(experience)
      this.menu.push(false)
    },
    removeExperience(expIndex) {
      this.experiences.splice(expIndex, 1)
    },
    onUpdateexperience() {
      this.$store.dispatch('savePermit', {
        profile: this.profile,
        permitData: { experiences: this.experiences }
      })
    }
    // allowedDates() {
    //   if (this.experiences[key].startDate < this.experiences[key].endDate) {
    //     this.allowedDate = true
    //   }
    // },
    // disallowedDates() {
    //   if (this.experiences[key].startDate < this.experiences[key].endDate) {
    //     this.allowedDate = false
    //   }
    // }
  }
}
</script>
