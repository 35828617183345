import Vue from 'vue'
import Vuex from 'vuex'
import shared from './shared'
import user from './user/user'
import cook from './cook/cook'
import profile from './profile/profile'
import company from './profile/company'
// import stream from './profile/stream'
import account from './account/account'
import order from './order/order'
import review from './review/review'
import settings from './settings/settings'
import coupon from './settings/coupon'
import follow from './social/follow'
import admin from './admin/admin'
import { messageStore } from 'fod-core'
import base_profile from 'fod-core/src/store/base_profile'
import adminEnvironment from './settings/adminEnvironment'

import invite from 'fod-core/src/store/invite'
import upload from 'fod-core/src/store/profile/upload'
Vue.use(Vuex)

export const store = new Vuex.Store({
  modules: {
    shared: shared,
    user: user,
    cook: cook,
    profile: profile,
    // stream: stream,
    account: account,
    order: order,
    review: review,
    follow: follow,
    settings: settings,
    invite,
    company,
    upload,
    coupon,
    admin,
    base_profile,
    adminEnvironment,
    message: messageStore
  }
})
