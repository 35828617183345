<template>
  <div>
    Device Status:
    <span class="body-2">
      {{ touchTime }}
    </span>

    <v-badge
      :color="connected ? 'success' : 'error'"
      class="blink_me"
      inline
      overlap
      dot
    />
  </div>
</template>

<script>
import firebase from '@/firebase-init'
import moment from 'moment'

export default {
  props: {
    profile: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      unsubscribe: null,
      connection: null
    }
  },
  computed: {
    touchTime() {
      return this.connection
        ? moment(this.connection.touchTime).fromNow()
        : null
    },
    connected() {
      const now = moment().utc()
      return this.connection
        ? moment(this.connection.touchTime)
            .add(4, 'minutes')
            .isAfter(now)
        : false
    }
  },
  mounted() {
    if (this.profile) {
      this.unsubscribe = firebase
        .firestore()
        .collection('profile')
        .doc(this.profile.id)
        .collection('settings')
        .doc('connectivity')
        .onSnapshot(snapshot => {
          if (snapshot.exists) this.connection = snapshot.data()
        })
    }
  },
  beforeDestroy() {
    if (this.unsubscribe) {
      this.unsubscribe()
    }
  }
}
</script>

<style scoped>
.blink_me {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
</style>
