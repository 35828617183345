<template>
  <v-container v-if="!loading">
    <v-row>
      <v-col cols="12" v-if="followers && followers.length > 0" sm="6">
        List of followers:
        <v-list>
          <v-list-item
            v-for="follower in followers"
            :key="follower.name"
            avatar
          >
            <v-list-item-action>
              <v-icon v-if="follower.icon" color="pink">star</v-icon>
            </v-list-item-action>
            <v-list-item-avatar v-if="follower.avatar">
              <img :src="follower.avatar" />
            </v-list-item-avatar>
            <v-list-item-avatar v-else>
              <v-icon>face</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <router-link :to="'/profile/' + follower.id">
                <v-list-item-title
                  :to="'/profile/' + follower.id"
                  v-text="follower.name || follower.id"
                ></v-list-item-title>
              </router-link>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>
      <v-col v-else sm="12">
        <v-icon color="info">error</v-icon>Currently, you don't have any
        followers!
      </v-col>
    </v-row>
    <v-divider class="pt-5"></v-divider>
    <v-row>
      <v-col cols="12" sm="6">
        Let's build up your network.
        <br />Please send
        <v-btn text color="orange" to="/invite">Invitation</v-btn>to your
        friends
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      loading: 'loading',
      followers: 'followers'
    }),
    hasFollower() {
      return this.followers && this.followers.length > 0
    }
  },
  data() {
    return {
      uid: this.uid
    }
  },
  mounted() {
    this.uid = this.$route.params.id
    this.$store.dispatch('loadFollowers', { uid: this.uid })
  }
}
</script>
