import firebase from '@/firebase-init'
import promotion from 'fod-core/src/store/producer/promotion.js'
import moment from 'moment'

export default {
  state: {
    promotions: [],
    promotion: null
  },
  mutations: {
    setPromotion(state, payload) {
      state.promotion = payload
    },
    setPromotions(state, payload) {
      state.promotions = payload
    }
  },
  actions: {
    savePromotion(context, payload) {
      let uid = payload.uid || context.getters.user.id

      context.commit('setLoading', true)
      let ref = firebase
        .firestore()
        .collection('campaign')
        .doc(uid)
        .collection('promotions')

      let call = null
      if (payload.id) {
        call = ref.doc(payload.id).update(payload)
      } else {
        payload.status = 'new'
        call = ref.add(payload)
      }

      return call
        .then(function(doc) {
          context.commit('setLoading', false)
          context.commit('setMessage', {
            title: 'Promotion Saved',
            message: 'Setting saved successfully'
          })

          return doc ? doc.id : null
        })
        .catch(function(error) {
          console.warn('Transaction failed: ', error)
          context.commit('setLoading', false)
          context.commit('setError', {
            title: 'Unable to save the production?',
            message: ''
          })
        })
    },
    getPromotion(context, payload) {
      context.commit('setLoading', true)
      const uid = payload.uid ? payload.uid : context.getters.user.id
      console.log(`Getting promotion of uid ${uid}`)
      return firebase
        .firestore()
        .collection('campaign')
        .doc(uid)
        .collection('promotions')

        .doc(payload.id)
        .get()
        .then(data => {
          if (data.exists) {
            context.commit('setLoading', false)
            return data.data()
          }
        })
        .catch(error => {
          context.commit('setLoading', false)
          console.log(error)
          return null
        })
    },
    getPromotions(context, payload) {
      if (payload.load) {
        context.commit('setLoading', true)
      }

      const uid =
        payload.uid && context.getters.isAdmin
          ? payload.uid
          : context.getters.user.id

      console.log(`Getting promotions of uid ${uid}`)
      return firebase
        .firestore()
        .collection('campaign')
        .doc(uid)
        .collection('promotions')
        .get()
        .then(querySnapshot => {
          let list = []
          querySnapshot.forEach(function(doc) {
            let c = doc.data()
            c.id = doc.id
            list.push(c)
          })
          context.commit('setLoading', false)
          //context.commit('promotions', list)

          return list
        })
        .catch(error => {
          context.commit('setLoading', false)
          console.log(error)
          return null
        })
    },

    getCoupons(context, payload) {
      if (payload.load) {
        context.commit('setLoading', true)
      }
      const uid = payload.uid ? payload.uid : context.getters.user.id
      console.log(`Getting coupons of uid ${uid} and promotion: ${payload.id}`)
      return firebase
        .firestore()
        .collection('campaign')
        .doc(uid)
        .collection('coupons')
        .where('promotion_id', '==', payload.id)
        .get()
        .then(querySnapshot => {
          let list = []
          querySnapshot.forEach(function(doc) {
            let c = doc.data()
            c.id = doc.id
            list.push(c)
          })
          context.commit('setLoading', false)
          //context.commit('promotions', list)

          return list
        })
        .catch(error => {
          context.commit('setLoading', false)
          console.error(error)
          return null
        })
    },
    lookupCoupon: async (context, payload) => {
      return promotion.actions._getCoupon(context, payload)
    },
    lookupPromotion: async (context, payload) => {
      return promotion.actions._getPromotion(context, payload)
    },
    getCoupon(context, payload) {
      let uid = context.getters.user.id
      console.log(`Getting coupon of uid ${uid} and code: ${payload.code}`)
      return firebase
        .firestore()
        .collection('campaign')
        .doc(uid)
        .collection('coupons')
        .doc(payload.code)
        .get()
        .then(doc => {
          if (doc.exists) return doc.data()
        })
        .catch(error => {
          context.commit('setLoading', false)
          console.error(error)
          return null
        })
    },
    deletePromotion(context, payload) {
      context.commit('setLoading', true)
      const uid = payload.uid ? payload.uid : context.getters.user.id
      console.log(`Deleting promotion ${uid} and promotion: ${payload.id}`)
      return firebase
        .firestore()
        .collection('campaign')
        .doc(uid)
        .collection('promotions')
        .doc(payload.id)
        .delete()
        .then(() => {
          context.commit('setLoading', false)
        })
        .catch(e => {
          context.commit('setLoading', false)
          console.error(e)
        })
    },
    sendPromo(context, payload) {
      payload.updatedAt = moment.utc().valueOf()
      console.log('Sharing promo: ', payload)
      firebase
        .firestore()
        .collection('campaign')
        .doc(payload.uid)
        .collection('coupon_publish')
        .add(payload)
        .then(() => {
          context.commit('setLoading', false)
          console.log('Coupon sent', payload)
        })
        .catch(error => {
          context.commit('setLoading', false)
          console.log(error)
        })
    }
  },

  getters: {
    promotions: state => {
      return state.promotions
    }
  }
}
