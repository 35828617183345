<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" sm="10" xl="8">
        <v-sheet class="pa-1 main_text--text shadow mb-4">
          <slices-block :slices="slices" />

          <v-row justify="center" class="mt-5">
            <v-col cols="8" class="d-flex justify-center w-100">
              <v-btn
                v-if="newUser"
                color="takein"
                depressed
                rounded
                :ripple="false"
                to="/profile/wizard/profile"
                class="white--text"
              >
                {{ $t('Common.get_started2') }}
                <v-icon>mdi-play</v-icon>
              </v-btn>
              <v-btn
                v-else
                color="takein"
                depressed
                rounded
                :ripple="false"
                class="white--text"
                to="/profile/wizard/profile"
              >
                {{ $t('Common.resume') }}
                <v-icon>mdi-play</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import SlicesBlock from '@/components/SlicesBlock.vue'

import { mapGetters } from 'vuex'
export default {
  components: { SlicesBlock },
  computed: {
    ...mapGetters({
      user: 'user',
      newUser: 'newUser'
    })
  },
  data() {
    return {
      slices: null
    }
  },
  mounted() {
    //Query to get post content

    this.$prismic.client
      .getByUID('page', 'welcome-to-onboarding')
      .then(response => {
        this.slices = response.data.page_content
        // document contains the document content
      })
  }
}
</script>
<style>
.video-container {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
}
.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 80%;
  height: 80%;
}
.shadow {
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
    0px 4px 8px rgba(0, 0, 0, 0.04);
}
</style>
