<template>
  <v-container>
    <v-row :fluid="$vuetify.breakpoint.md">
      <v-col cols="10" sm="6" offset-sm3>
        <h1 class="display-2 font-weight-bold py-4">Messages:</h1>
    </v-col>
    <v-col cols="2">
      <v-btn class="mx-2" fab dark color="indigo" to="/messages/new">
      <v-icon dark>mdi-plus</v-icon>
    </v-btn>
    </v-col>
    </v-row>
    <v-row :fluid="$vuetify.breakpoint.md">    
        <v-list two-line row>
          <template v-for="(item, index) in threads">
            <v-list-item
              :key="index"
              @click="$router.push('/messages/' + item.id)"
              v-if="item.lastMessage"
            >
              <!-- RECIPIENT PICTURE -->
              <v-list-item-avatar
                v-if="item.member_info"
                size="50"
                color="blue-grey lighten-4"
              >
                <v-img v-if="getMemberInfo(item.member_info, 'avatar')" :src="getMemberInfo(item.member_info, 'avatar')" />
              </v-list-item-avatar>

              <v-list-item-content>
                <!-- RECIPIENT NAME -->
                <v-list-item-title v-if="item.member_info">{{
                  getMemberInfo(item.member_info, "name")
                }}</v-list-item-title>
                <!-- LATEST MESSAGE -->
                <v-list-item-subtitle>{{
                  item.lastMessage ? item.lastMessage.body : ""
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider
              :key="'divider' + index"
              v-if="item.lastMessage"
            ></v-divider>
          </template>
        </v-list>
      </v-col>

      <v-flex xs1>
        <!-- REFRESH BUTTON -->
        <v-btn fab dark color="lime" top @click.native.stop="reload">
          <v-icon>refresh</v-icon>
        </v-btn>
      </v-flex>
    </v-row>
  </v-container>
</template>

<script>
  import { mapGetters } from "vuex";
  export default {
    data() {
      return {
        localLoading: false,
        users: [],
      };
    },
    computed: {
      ...mapGetters({
        threads: "threads",
        loading: "loading",
        profile: "profile",
      }),
    },
    mounted() {
      this.$store.dispatch("clearUsers");
      this.$store.dispatch("loadThreads");
    },
    methods: {
      reload() {
        this.$store.dispatch("loadThreads");
      },
      // gets memeber info by finding which id is not user's
      // @param =  member_list = list of memebers in tht thread, type = info that needs to be extracted (name, avatar, etc)
      getMemberInfo(member_info, type) {
        if (!member_info) return null
        
        for (let profile in member_info) {
          if (profile != this.profile.id) {
            return member_info[profile][type];
          }
        }
        return null;
      },
    },
  };
</script>
