<template>
  <v-container fluid>
    <ValidationObserver ref="form" v-slot="{ invalid }">
      <v-row v-if="profile" justify="center">
        <v-col cols="12" xl="8">
          <v-sheet class="shadow">
            <product-help :step="vstep" />
            <h4 class="font-weight-bold text-uppercase">
              {{ $t('Common.step') }} {{ $t('Common.one') }} -
              {{ $t('Common.profile') }}
            </h4>

            <v-stepper v-model="vstep" vertical non-linear>
              <v-stepper-step
                editable
                :complete="vstep > 1"
                step="1"
                color="takein"
              >
                <span
                  class="text-uppercase text-underline subtitle-2 main_text--text"
                  >{{ $t('Common.profile_branding') }}</span
                >
                <!-- <small>Summarize if needed</small> -->
              </v-stepper-step>

              <v-stepper-content step="1">
                <v-sheet class="shadow pl-4">
                  <v-row>
                    <v-col cols="10">
                      <ValidationProvider
                        v-slot="{ errors }"
                        :name="$t('Common.profile_restaurant')"
                        rules="required|min:5|max:50"
                      >
                        <v-text-field
                          :label="$t('Common.profile_restaurant')"
                          color="takein"
                          v-model="businessName"
                          :error-messages="errors"
                        ></v-text-field>
                      </ValidationProvider>
                    </v-col>
                    <v-spacer></v-spacer>
                  </v-row>
                  <v-row>
                    <v-col cols="10">
                      <ValidationProvider
                        v-slot="{ errors }"
                        :name="$t('Common.profile_tagline')"
                        rules="required|min:5|max:50"
                      >
                        <v-text-field
                          :label="$t('Common.profile_tagline')"
                          v-model="title"
                          :placeholder="$t('Chef.chef_title_placeholder')"
                          hint="This is the title of your business. (i.e Southern Italian Cuisine). It will be displayed on your website and in search results."
                          color="takein"
                          :error-messages="errors"
                        ></v-text-field>
                      </ValidationProvider>
                    </v-col>
                    <v-spacer></v-spacer>
                  </v-row>
                  <v-row>
                    <v-col cols="10">
                      <v-textarea
                        color="takein"
                        rows="3"
                        :placeholder="
                          $t('Chef.chef_description_placeholder_help')
                        "
                        v-model="businessDescription"
                        :label="$t('Common.profile_restaurant_description')"
                        :no-resize="true"
                      ></v-textarea>
                    </v-col>
                    <v-spacer></v-spacer>
                  </v-row>
                  <v-row>
                    <v-col sm="10" md="6">
                      <ValidationProvider
                        v-slot="{ errors }"
                        :name="$t('Common.business_phone')"
                        rules="required|min:10"
                      >
                        <v-text-field
                          :label="$t('Common.business_phone')"
                          color="takein"
                          :rules="[rules.checkPhone]"
                          v-model="businessPhone"
                          :error-messages="errors"
                        ></v-text-field>
                      </ValidationProvider>
                    </v-col>
                    <v-col sm="10" md="6">
                      <ValidationProvider
                        v-slot="{ errors }"
                        :name="$t('Common.business_phone')"
                        rules="required|email"
                      >
                        <v-text-field
                          label="Email address of orders"
                          :messages="
                            $t('Common.email_order_receipt_instruction') +
                              ' (' +
                              $t('Common.required') +
                              ')'
                          "
                          :error-messages="errors"
                          v-model="emailOrder"
                          color="takein"
                        ></v-text-field>
                      </ValidationProvider>
                    </v-col>
                    <v-spacer />
                  </v-row>
                </v-sheet>
                <v-btn
                  color="takein white--text"
                  :disabled="invalid"
                  class="mt-4"
                  @click="continueStep1"
                  >{{ $t('Common.continue') }}</v-btn
                >
                <!-- <v-btn depressed :ripple="false">{{ $t('Common.cancel') }}</v-btn> -->
              </v-stepper-content>

              <v-stepper-step
                editable
                color="takein"
                :complete="vstep > 2"
                step="2"
              >
                <span
                  class="text-uppercase text-underline subtitle-2 main_text--text"
                  >{{ $t('Common.address') }}</span
                >
              </v-stepper-step>

              <v-stepper-content step="2">
                <v-sheet class="d-flex flex-column pa-4 shadow mb-8 mt-4">
                  <profile-address :profile="profile" />
                </v-sheet>
                <v-btn
                  color="takein  white--text"
                  :ripple="false"
                  depressed
                  class="mr-4"
                  :disabled="invalid"
                  @click="continueStep2"
                  >{{ $t('Common.continue') }}</v-btn
                >
                <!-- <v-btn depressed :ripple="false">{{ $t('Common.cancel') }}</v-btn> -->
              </v-stepper-content>

              <v-stepper-step editable color="takein" step="3">
                <span
                  class="text-uppercase text-underline subtitle-2 main_text--text"
                  >{{ $t('Common.business_hours') }}</span
                >
              </v-stepper-step>
              <v-stepper-content step="3">
                <v-sheet class="shadow ">
                  <v-row justify="center" class="pl-4 pr-4 py-2">
                    <v-col>
                      <small
                        class="main_text--text"
                        v-html="$t('Common.business_hours_description')"
                      ></small>
                    </v-col>
                  </v-row>
                  <business-hours
                    @onBusinessHoursUpdate="onBusinessHoursUpdate"
                    @onBusinessClose="onBusinessClose"
                    :businessHours="businessHours"
                    :isBusinessClose="isBusinessClose"
                    :isNotApproved="isNotApproved"
                  />
                  <v-btn
                    color="takein  white--text"
                    :ripple="false"
                    depressed
                    class="mr-4"
                    :disabled="invalid"
                    @click="continueStep3AndSave"
                    >{{ $t('Common.continue') }}</v-btn
                  >
                </v-sheet>

                <!-- <v-btn depressed :ripple="false">{{ $t('Common.cancel') }}</v-btn> -->
              </v-stepper-content>
              <v-stepper-step editable color="takein" step="4">
                <span
                  class="text-uppercase text-underline subtitle-2 main_text--text"
                  >Business Logo</span
                >
              </v-stepper-step>
              <v-stepper-content step="4">
                <business-photo :profile="profile" />
              </v-stepper-content>
            </v-stepper>

            <v-row>
              <v-col cols="12" class="d-flex justify-center py-4">
                <v-btn
                  color="takein"
                  depressed
                  rounded
                  :ripple="false"
                  class="white--text"
                  @click="saveAll"
                >
                  {{ $t('Common.next') }}
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-sheet>
        </v-col>
      </v-row>
      <v-snackbar
        v-model="snackbar"
        :bottom="true"
        :color="snackType"
        :timeout="snackTimeout"
      >
        {{ snackMessage }}
        <v-btn color="white" text @click="snackbar = false">Close</v-btn>
      </v-snackbar>
    </ValidationObserver>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
export default {
  components: {
    ProductHelp: () =>
      import('@/components/profilewizard/StepProfile/ProductHelp.vue'),
    ProfileAddress: () =>
      import('fod-core/src/components/profile/news/ProfileAddress.vue'),
    BusinessHours: () =>
      import('@/components/profilewizard/StepProfile/BusinessHours.vue'),
    BusinessPhoto: () =>
      import('@/components/profilewizard/StepProfile/BusinessPhoto.vue')
  },
  computed: {
    ...mapGetters({
      profile: 'profile',
      newUser: 'newUser',
      approvalStatus: 'approvalStatus'
    }),
    isNotApproved() {
      return (
        this.newUser ||
        (this.approvalStatus && this.approvalStatus != 'approved')
      )
    }
  },
  watch: {
    profile() {
      this.loadProfile()
    }
  },
  data() {
    return {
      snackTimeout: 2500,
      snackbar: false,
      snackMessage: '',
      snackType: 'success',
      vstep: 1,
      businessName: null,
      title: null,
      addresses: [],
      businessDescription: null,
      businessPhone: null,
      orderPhoneExtension: null,
      secondaryPhone: null,
      emailOrder: null,
      emailMessages: null,
      emailComplains: null,
      emailNotifications: null,
      copyemailForOrder: false,
      copyemailForMessage: false,
      copyemailForComplain: false,
      copyemailForNotification: false,
      businessHours: [],
      isBusinessClose: false,
      rules: {
        required: value => !!value || 'Required.',
        checkPhone: value =>
          this.isValidPhone(value) || 'Invalid phone number format'
      }
    }
  },
  mounted() {
    if (!this.profile) {
      return
    }
    this.loadProfile()
  },
  methods: {
    loadProfile() {
      if (!this.profile) {
        console.warn('Profile not loaded')
        return
      }
      this.businessName = this.profile.businessName
      this.title = this.profile.title || this.profile.businessName
      this.businessDescription = this.profile.description
      this.addresses = this.profile.addresses || []
      this.businessPhone = this.profile.phone
      this.orderPhoneExtension = this.profile.orderPhoneExtension
      this.secondaryPhone = this.profile.secondaryPhone
      this.emailOrder = this.profile.emailOrder

      if (!this.emailOrder) {
        this.emailOrder = this.profile.email
      }
      this.emailMessages = this.profile.emailMessages
      this.emailComplains = this.profile.emailComplains
      this.emailNotifications = this.profile.emailNotifications
      this.businessHours = this.profile.businessHours || []
      this.isBusinessClose = this.profile.isBusinessClose || false
    },
    saveProfile() {
      this.$store.dispatch('updateProfile', {
        email: this.profile.email || null,
        name: this.profile.name || null,
        businessName: this.businessName || null,
        title: this.title || this.businessName || null,
        description: this.businessDescription || null
      })
    },

    continueStep1() {
      if (!this.businessName) {
        this.snackMessage = 'The Restaurant/Business Name should be filled out.'
        // 'The Restaurant/Business Name and Tagline should be filled out.'
        this.snackType = 'error'
        this.snackbar = true
        // this.$store.dispatch('setError', {
        //   message:
        //     'The Restaurant/Business Name and Tagline should be filled out.'
        // })
        return
      }
      this.vstep = 2
      this.$store.dispatch('updateProfile', {
        email: this.profile.email || null,
        name: this.profile.name || null,
        businessName: this.businessName || null,
        title: this.title || this.businessName || null,
        description: this.businessDescription || null
      })
    },
    continueStep2() {
      if (this.addresses.length === 0) {
        this.snackMessage = 'You should add one address at least.'
        this.snackType = 'error'
        this.snackbar = true
        // this.$store.dispatch('setError', {
        //   message: 'You should add one address at least.'
        // })
        return
      }
      this.vstep = 3
    },

    continueStep3() {
      if (!this.businessPhone) {
        this.snackMessage = 'The Business Phone should be filled out.'
        this.snackType = 'error'
        this.snackbar = true
        // this.$store.dispatch('setError', {
        //   message: 'The Business Phone should be filled out.'
        // })
        return
      }
      if (!this.isValidPhone(this.businessPhone)) {
        this.snackMessage = 'The Business Phone number is invalid format.'
        this.snackType = 'error'
        this.snackbar = true
        // this.$store.dispatch('setError', {
        //   message: 'The Business Phone should be filled out.'
        // })
        return
      }
      this.$store.dispatch('updateProfile', {
        email: this.profile.email,
        name: this.profile.name,
        businessPhone: this.businessPhone,
        orderPhoneExtension: this.orderPhoneExtension || null,
        phone: this.businessPhone
      })
      this.vstep = 4
    },
    continueStep4() {
      if (!this.emailOrder) {
        this.snackMessage = 'The Order email address should be filled out.'
        this.snackType = 'error'
        this.snackbar = true
        // this.$store.dispatch('setError', {
        //   message: 'The Order email address should be filled out.'
        // })
        return
      }
      this.$store.dispatch('updateProfile', {
        email: this.profile.email,
        name: this.profile.name,
        emailOrder: this.emailOrder || null,
        emailMessages: this.emailMessages || null,
        emailComplains: this.emailComplains || null,
        emailNotifications: this.emailNotifications || null
      })
      this.vstep = 5
    },
    continueStep3AndSave() {
      if (this.businessHours.length === 0) {
        this.snackMessage = 'Please set business hours.'
        this.snackType = 'error'
        this.snackbar = true
        // this.$store.dispatch('setError', {
        //   message: 'Please set business hours.'
        // })
        return
      }
      for (let businessHour of this.businessHours) {
        if (
          !businessHour.weekday ||
          !businessHour.endHour ||
          !businessHour.fromHour
        ) {
          this.snackMessage = 'Wrong business hours.'
          this.snackType = 'warning'
          this.snackbar = true
          // this.$store.dispatch('setError', {
          //   message: 'Wrong business hours!'
          // })
          return
        }
      }

      this.$store
        .dispatch('updateProfile', {
          email: this.profile.email,
          name: this.profile.name,
          businessHours: this.businessHours
        })
        .then(() => {
          this.snackMessage = 'Profile saved successfully.'
          this.snackType = 'success'
          this.snackbar = true
          // if (res) {
          // this.$store.dispatch('setMessage', {
          //   title: 'Profile saved successfully.',
          //   body: ''
          // })
          // }
        })
      this.vstep = 4
    },
    saveAll() {
      if (
        !this.businessName ||
        this.addresses.length === 0 ||
        !this.businessPhone ||
        !this.emailOrder ||
        this.businessHours.length === 0
      ) {
        this.snackMessage = 'Missed profile information.'
        this.snackType = 'error'
        this.snackbar = true
        // this.$store.dispatch('setError', {
        //   message: 'Missed profile information.'
        // })
        return
      }

      this.$store
        .dispatch('updateProfile', {
          email: this.profile.email,
          name: this.profile.name,
          businessName: this.businessName,
          title: this.title,
          description: this.businessDescription,
          addresses: this.addresses || [],
          businessPhone: this.businessPhone,
          orderPhoneExtension: this.orderPhoneExtension,
          phone: this.businessPhone,
          emailOrder: this.emailOrder,
          emailMessages: this.emailMessages,
          emailComplains: this.emailComplains,
          emailNotifications: this.emailNotifications,
          businessHours: this.businessHours || [],
          isBusinessClose: this.isBusinessClose || false
        })
        .then(() => {
          this.snackMessage = 'Profile saved successfully.'
          this.snackType = 'success'
          this.snackbar = true
          this.$router.push('/profile/wizard/menus/products_sub')
          // setTimeout(() => {
          // this.$router.push('/profile/wizard/finance')
          // }, this.snackTimeout)

          // this.$store
          //   .dispatch('setMessage', {
          //     title: 'Profile saved successfully.',
          //     body: ''
          //   })
          //   .then(() => {

          //   })
        })
    },
    onBusinessHoursUpdate(payload) {
      this.businessHours = payload
    },
    onBusinessClose(payload) {
      this.isBusinessClose = payload.isBusinessClose
      let profile = {
        id: this.profile.id,
        isBusinessClose: payload.isBusinessClose
      }
      this.$store.dispatch('updateProfile', profile)
    },
    copyEmail(typeOf) {
      switch (typeOf) {
        case 'order':
          if (this.copyemailForOrder) {
            this.emailOrder = this.profile.email
          } else {
            this.emailOrder = ''
          }
          break
        case 'messages':
          if (this.copyemailForMessage) {
            this.emailMessages = this.profile.email
          } else {
            this.emailMessages = ''
          }
          break
        case 'complains':
          if (this.copyemailForComplain) {
            this.emailComplains = this.profile.email
          } else {
            this.emailComplains = ''
          }
          break
        case 'notifications':
          if (this.copyemailForNotification) {
            this.emailNotifications = this.profile.email
          } else {
            this.emailNotifications = ''
          }
          break
        default:
          return
      }
    },
    isValidPhone(value) {
      if (!value) return false
      const p = parsePhoneNumberFromString(value.toString(), 'US')
      if (!p) return false
      if (!p.isValid()) return false
      return true
    }
  }
}
</script>
<style>
.v-label {
  font-size: 12px;
}
.col {
  padding: 0 12px;
}
.shadow {
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
    0px 4px 8px rgba(0, 0, 0, 0.04);
}
.v-stepper {
  box-shadow: none;
}
.v-stepper__content {
  padding: 16px 40px 16px 23px;
}
.v-stepper__wrapper {
  padding: 4px;
}
.text-underline {
  text-decoration: underline;
}
</style>
